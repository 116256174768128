import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button } from "@mui/material";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from 'src/hooks/useRequestResource';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'src/style.css'

const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    instrument_type: yup.string().required("Instrument Type is required"),
    instrument_installation_number: yup.string()
    .required("Instrument installation number is required")
    .test('number', 'Instrument installation number must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Instrument installation number must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Instrument installation number must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    }),
    installation_depth_meters: yup.string()
    .required("Instrument depth meters is required")
    .test('number', 'Instrument depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Instrument depth meters must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Instrument depth meters must be greater than or equal to -9999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -9999.99;
        }
        return true;
    })
    .test('max-value', 'Instrument depth meters must be less than or equal to 9999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.99;
        }
        return true;
    }),
    installation_date: yup.string().required("Date is required"),
    installed_by: yup.string().required("Name is required").max(255, "Max Length is 255!"),
    perforation_start_depth: yup.string()
    .test('number', 'Perforation start depth must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Perforation start depth must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Perforation start depth must be greater than or equal to -9999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -9999.99;
        }
        return true;
    })
    .test('max-value', 'Perforation start depth must be less than or equal to 9999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.99;
        }
        return true;
    }),
    perforation_end_depth: yup.string()
    .test('number', 'Perforation end depth must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Perforation end depth must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Perforation end depth must be greater than or equal to -9999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -9999.99;
        }
        return true;
    })
    .test('max-value', 'Perforation end depth must be less than or equal to 9999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.99;
        }
        return true;
    }),
})



function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;   
}

export default function InstrumentInstallationDetails() {
    const { id } = useParams();
    const { resources, getResource, updateResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "instrumentinstallation", resourceLabel: "Instrument Installation Details" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole list"})
    const navigate = useNavigate();
    const [installationDate, handleInstallationDateChange] = React.useState(null);
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [borehole, setBorehole] = useState("")
    const [showInfiniteScroll, setInfiniteScroll] = useState(false)
    let offset = 25;
    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 
    const [exp, setExp] = useState("")


    const [initialValues, setInitialValues] = useState({
        borehole: "",
        instrument_type: "",
        instrument_installation_number: "",
        installation_depth_meters: "",
        installation_date: Date.now(),
        installed_by: "",
        perforation_start_depth: "",
        perforation_end_depth: "",
        has_supervisor_signed_borehole: false,
        has_witness_signed_borehole: false
    });

    const instrumentTypeOptions = [
        { value: 'WSP', label: 'Water Standpipe' },
        { value: 'CP', label: 'Casagrande Piezometer Standpipe' },
        { value: 'PZ', label: 'Pneumatic Piezometer' },
        { value: 'INC', label: 'Inclinometer' },
        { value: 'MAE', label: 'Magnetic Extensometer' },
        { value: 'ROE', label: 'Rod Extensometer' },
        { value: 'VWP', label: 'Vibrating Wire Piezomete' }
    ]

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);
    
    const fetchFile = (path) => {
        if (path === null || path.trim() === "") {
            return null;
        }
        // TODO
        const url = `${process.env.REACT_APP_API_URL}/media/` + path;
        const blob = fetch(url)
                        .then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], 'image', { type: blob.type });
                            return file
                        });
        return blob;
    };

    useEffect(() => {
        if (resources) {
            const imageUrl = resources['url'].trim();
            const fetchPromises = fetchFile(imageUrl);
            Promise.all([fetchPromises]).then((fetchedFiles) => {
                let fetchedFile = null;
                if (fetchedFiles.length > 0) {
                    fetchedFile = fetchedFiles[0];
                }
                setImage(fetchedFile);
                const data = resources['data'];
                setBorehole(`${data.project_title} Borehole: ${data.borehole_number}`);
                handleInstallationDateChange(convertUTCDateToLocalDate(new Date(data.installation_date)));
                setInitialValues({
                    borehole: data.borehole,
                    instrument_type: data.instrument_type,
                    instrument_installation_number: data.instrument_installation_number,
                    installation_depth_meters: data.installation_depth_meters,
                    installation_date: new Date(data.installation_date),
                    installed_by: data.installed_by,
                    perforation_start_depth: data.perforation_start_depth,
                    perforation_end_depth: data.perforation_end_depth,
                    has_supervisor_signed_borehole: data.has_supervisor_signed_borehole,
                    has_witness_signed_borehole: data.has_witness_signed_borehole
                })
            })
        }
    }, [resources])

    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }



    useEffect(() => {
        if (image != null) {
            setImageURL(URL.createObjectURL(image));
        } else {
            setImageURL(null);
        }
        getResourceList();
    }, [getResourceList, image])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    function onImageChange(e) {
        setImage(e.target.files[0])
    }

    function deleteImage(e) {
        e.preventDefault();
        setImage(null);
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split("T")
        return arr[0]
    }

    const handleSubmit = values => {
        const formattedValues = {
            borehole: values.borehole,
            instrument_type: values.instrument_type,
            instrument_installation_number: values.instrument_installation_number,
            installation_depth_meters: values.installation_depth_meters,
            installation_date: convertDate(installationDate),
            installed_by: values.installed_by,
            perforation_start_depth: values.perforation_start_depth,
            perforation_end_depth: values.perforation_end_depth
        }
        
        let formData = new FormData();
        formData.append(`image`, image);
        formData.set("json_data", JSON.stringify(formattedValues));
        updateResource(id, formData, () => {
            navigate(`/instrumentinstallations`)
        })
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
                
            }}>
                <Typography variant="h6" mh={4}>
                    Instrument Installation
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                {...formik.getFieldProps('borehole')}
                                                disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                ref={boreholeTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="instrument_type"
                                                label="Installation Type"
                                                {...formik.getFieldProps('instrument_type')}
                                                select
                                                value={formik.values.instrument_type}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}
                                                error={formik.touched.instrument_type && Boolean(formik.errors.instrument_type)}
                                                helperText={formik.touched.instrument_type && formik.errors.instrument_type}
                                            >
                                                {instrumentTypeOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="instrument_installation_number"
                                                label="Instrument Installation Number"
                                                {...formik.getFieldProps('instrument_installation_number')}
                                                disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}
                                                error={formik.touched.instrument_installation_number && Boolean(formik.errors.instrument_installation_number)}
                                                helperText={formik.touched.instrument_installation_number && formik.errors.instrument_installation_number}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="installation_depth_meters"
                                                label="Installation Depth Meters"
                                                {...formik.getFieldProps('installation_depth_meters')}
                                                disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}
                                                error={formik.touched.installation_depth_meters && Boolean(formik.errors.installation_depth_meters)}
                                                helperText={formik.touched.installation_depth_meters && formik.errors.installation_depth_meters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Installation Date'
                                                selected={ installationDate }
                                                onChange={ handleInstallationDateChange }
                                                disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}
                                                name="installationDate"
                                                dateFormat="MMMM d, yyyy"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="installed_by"
                                                label="Installed By"
                                                {...formik.getFieldProps('installed_by')}
                                                disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}
                                                error={formik.touched.installed_by && Boolean(formik.errors.installed_by)}
                                                helperText={formik.touched.installed_by && formik.errors.installed_by}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="perforation_start_depth"
                                                label="Perforation Start Depth"
                                                {...formik.getFieldProps('perforation_start_depth')}
                                                disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}
                                                error={formik.touched.perforation_start_depth && Boolean(formik.errors.perforation_start_depth)}
                                                helperText={formik.touched.perforation_start_depth && formik.errors.perforation_start_depth}
                                            />
                                        </Grid>
                                    
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="perforation_end_depth"
                                                label="Perforation End Depth"
                                                {...formik.getFieldProps('perforation_end_depth')}
                                                disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}
                                                error={formik.touched.perforation_end_depth && Boolean(formik.errors.perforation_end_depth)}
                                                helperText={formik.touched.perforation_end_depth && formik.errors.perforation_end_depth}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <input type="file" accept="image/*" onChange={onImageChange} disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}/>
                                        </Grid>

                                        <Grid item xs={12}>
                                            {imageURL != null && (
                                                <Grid> 
                                                <img src={imageURL} />
                                                <button onClick={(e) => { deleteImage(e) }} disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}>Delete</button>
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/instrumentinstallations`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            {initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole ? <div></div> : <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Edit Instrument Installation
                                            </Button>}

                                        </Grid>


                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}