import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuList, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from 'src/hooks/useRequestResource';
import 'src/style.css'      


export default function LogDetails() {
    const { id } = useParams();
    const { resources, getResource } = useRequestResource({ endpoint: "auth", query: "log", resourceLabel: "Log Details" });
    const navigate = useNavigate();
    const [ details, setDetails ] = useState([]);
    const [initialValues, setInitialValues] = useState({
        username: "",
        method: "",
        date_and_time: "",
        content_type: "",
        obj: []
    });

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);
    
    function processDetails(texts) {
        if (texts) {
            texts = texts.split(" | ")
            const details = [];
            texts.forEach((text) => {details.push(text)})
            setDetails(details)
        }
    }

    useEffect(() => {

        if (resources) {
            processDetails(resources.obj);
            setInitialValues({
                username: resources.username === null ? "" : resources.username,
                method: resources.method === null ? "" : resources.method,
                date_and_time: resources.date_and_time === null ? "" : resources.date_and_time,
                content_type: resources.content_type === null ? "" : resources.content_type,
                obj: resources.obj === null ? "" : resources.obj
            })
        }
    }, [resources])



    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
                
            }}>
                <Typography variant="h6" mh={4}>
                    Activity Log
                </Typography>

                <br />

                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                >
                    {
                        (formik) => {
                            return (

                                <form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="username"
                                                label="Username"
                                                {...formik.getFieldProps('username')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>
  
                                        
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="method"
                                                label="Action"
                                                {...formik.getFieldProps('method')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="date_and_time"
                                                label="Date and Time"
                                                {...formik.getFieldProps('date_and_time')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="content_type"
                                                label="Type of content"
                                                {...formik.getFieldProps('content_type')}
                                                InputProps={{
                                                    readOnly: true,
                                                  }}
                                            />
                                        </Grid>
                                        
                                        {
                                            details.map((state, index) => {
                                                return (
                                                <Grid item xs={12}>
                                                    <TextField
                                                    fullWidth
                                                    id="obj"
                                                    label="Details"
                                                    value = {state}
                                                    InputProps={{
                                                        readOnly: true,
                                                      }}
                                                    multiline
                                                    />
                                                </Grid>
                                                )
                                            })
                                        }

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/logs`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                        </Grid>


                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}