import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import DatePicker from 'react-datepicker';
import SignatureCanvas from "react-signature-canvas";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import AddIcon from '@mui/icons-material/Add';
import Overlay from 'react-bootstrap/Overlay';
import 'src/style.css'

import useRequestResource from 'src/hooks/useRequestResource';
import { Input } from 'reactstrap';
import { filter } from 'lodash';

const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    drilling_start_depth_for_the_day: yup.string()
    .required("Drilling start depth is required")
    .test('number', 'Drilling start depth must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Drilling start depth must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Drilling start depth must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Drilling start depth must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),    
    drilling_end_depth_for_the_day: yup.string()
    .required("Drilling end depth is required")
    .test('number', 'Drilling end depth must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Drilling end depth must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Drilling end depth must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Drilling end depth must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),   
    weather: yup.string().required("Weather conditions is required"),
    weather_start_time: yup.string(),
    weather_end_time: yup.string(),
    site_supervisor_name: yup.string().max(32, "Max Length is 32!"),
    site_supervisor_signature: yup.string(),
    site_supervisor_signed_date: yup.string(),
    witness_name: yup.string().max(32, "Max Length is 32!"),
    witness_signature: yup.string(),
    witness_signed_date: yup.string(),
    client_name: yup.string().max(32, "Max Length is 32!"),
    client_signature: yup.string(),
    client_signed_date:  yup.string()
})

const Base64Image = ({ base64Url }) => {
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      if (base64Url) {
        fetch(base64Url)
          .then(response => response.blob())
          .then(blob => {
            const objectUrl = URL.createObjectURL(blob);
            setImageUrl(objectUrl);
          });
      }
    }, [base64Url]);
  
    return (
      <img src={imageUrl} alt="Base64 Image" />
    );
  };

export default function DrillinglogEdit() {
    const { id } = useParams();
    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 
    const { filterList, filterResources } = useRequestResource({ endpoint: "auth/borehole_log", query: "borehole", resourceLabel: "Borehole Details" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "borehole list"})
    const { addResource, getResource, resources, updateResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "drillinglog", resourceLabel: "edit drilling log"})
    const navigate = useNavigate();
    const [weather_start_time, handleWeatherStartTimeChange] = React.useState(null);
    const [weather_end_time, handleWeatherEndTimeChange] = React.useState(null);
    const [drilling_Date, handleDrillingDateChange] = React.useState(null);
    const [site_supervisor_signed_date, handleSiteSupervisorSignedDateChange] = React.useState(null);
    const [witness_signed_date, handleWitnessSignedDateChange] = React.useState(null);
    const [client_signed_date, handleClientSignedDateChange] = React.useState(null);
    const [is_supervisor_signed, setSupervisorSign] = useState(false)
    const [is_witness_signed, setWitnessSign] = useState(false)
    const [is_client_signed, setClientSign] = useState(false)
    const sigSupervisorCanvas = useRef(null)
    const sigWitnessCanvas = useRef(null)
    const sigClientCanvas = useRef(null)
    const [ borehole, setBorehole ] = useState("")
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const offset = 25;
    const [exp, setExp] = useState("")
    const [initialValues, setInitialValues] = useState({
        borehole: "",
        drilling_start_depth_for_the_day: "",
        drilling_end_depth_for_the_day: "",
        weather: "",
        site_supervisor_name: "",
        witness_name: "",
        client_name: ""
    });

    const weatherOptions = [
        { value: 'Rain', label: 'Rain' },
        { value: 'No Rain', label: 'No Rain' },
    ]
    
    const handleSubmit = values => {
        const formattedValues = {
            borehole: values.borehole,
            drilling_start_depth_for_the_day: values.drilling_start_depth_for_the_day,
            drilling_end_depth_for_the_day: values.drilling_end_depth_for_the_day,
            weather_condition: values.weather,
            site_supervisor_name: values.site_supervisor_name,
            witness_name: values.witness_name,
            client_name: values.client_name,
            drilling_date: drilling_Date ? convertDate(drilling_Date) : null,
            weather_start_time: weather_start_time ? convertDate(weather_start_time) : null,
            weather_end_time: weather_end_time ? convertDate(weather_end_time) : null,
            site_supervisor_signed_date: site_supervisor_signed_date ? convertDate(site_supervisor_signed_date) : null,
            witness_signed_date: witness_signed_date ? convertDate(witness_signed_date) : null,
            client_signed_date: client_signed_date ? convertDate(client_signed_date) : null,
            site_supervisor_signature: is_supervisor_signed && sigSupervisorCanvas.current != null ? sigSupervisorCanvas.current.toDataURL() : resources.site_supervisor_signature,
            witness_signature: is_witness_signed && sigWitnessCanvas.current != null ? sigWitnessCanvas.current.toDataURL() : resources.witness_signature,
            client_signature: is_client_signed && sigClientCanvas.current != null ? sigClientCanvas.current.toDataURL() : resources.client_signature,
        }

        let formData = new FormData();
        formData.set("json_data", JSON.stringify(formattedValues))

        updateResource(id, formData, () => {
            navigate(`/drillinglogs`)
        })

    }

    const clearSupervisorCanvas = () => {
        if (sigSupervisorCanvas.current) {
            sigSupervisorCanvas.current.clear()
            setSupervisorSign(prev => false)
            handleSiteSupervisorSignedDateChange("")
            setSupervisorSignatureCanvas(
                <Grid item xs={12}>
                <h5>Supervisor Signature</h5>
                <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 200, height: 200 }}
                    ref={sigSupervisorCanvas}
                />
                <Grid>
                    <Button 
                        onClick={clearSupervisorCanvas}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={ supervisorSigned}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Sign
                    </Button>
                </Grid>
                <Grid>
                    <div style={{ height: "50px" }} />
                </Grid>
            </Grid>)
        }
    }

    const clearWitnessCanvas = () => {
        if (sigWitnessCanvas.current) {
            sigWitnessCanvas.current.clear()
            setWitnessSign(prev => false)
            handleWitnessSignedDateChange("")
            setWitnessSignatureCanvas(
                <Grid item xs={12}>
                <h5>Witness Signature</h5>
                <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 200, height: 200 }}
                    ref={sigWitnessCanvas}
                />
                <Grid>
                    <Button 
                        onClick={clearWitnessCanvas}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={ witnessSigned}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Sign
                    </Button>
                </Grid>
                <Grid>
                    <div style={{ height: "50px" }} />
                </Grid>
            </Grid>)
        }
    }

    const clearClientCanvas = () => {
        if (sigClientCanvas.current) {
            sigClientCanvas.current.clear()
            setClientSign(prev => false)
            handleClientSignedDateChange("")
            setClientSignatureCanvas(
                <Grid item xs={12}>
                <h5>Client Signature</h5>
                <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 200, height: 200 }}
                    ref={sigWitnessCanvas}
                />
                <Grid>
                    <Button 
                        onClick={clearClientCanvas}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={ clientSigned}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Sign
                    </Button>
                </Grid>
                <Grid>
                    <div style={{ height: "50px" }} />
                </Grid>
            </Grid>)
        }
    }

    const deleteWitnessSignature = () => {
        setWitnessSign(prev => false)
        handleWitnessSignedDateChange("")
        setWitnessSignatureCanvas(
            <Grid item xs={12}>
            <h5>Witness Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigWitnessCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearWitnessCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ witnessSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        setInitialValues(prevValues => ({
            ...prevValues,
            is_witness_signed: false,
            witness_name: "",
            witness_signature: "" // Update the value as needed
          }));
    }

    const deleteSupervisorSignature = () => {
        setSupervisorSign(prev => false)
        handleSiteSupervisorSignedDateChange("")
        setSupervisorSignatureCanvas(
            <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        setInitialValues(prevValues => ({
            ...prevValues,
            is_supervisor_signed: false,
            site_supervisor_name: "",
            site_supervisor_signature: "" // Update the value as needed
          }));
    }


    const deleteClientSignature = () => {
        setClientSign(prev => false)
        handleClientSignedDateChange("")
        setClientSignatureCanvas(
            <Grid item xs={12}>
            <h5>Client Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigClientCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearClientCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ clientSigned }
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        setInitialValues(prevValues => ({
            ...prevValues,
            is_client_signed: false,
            client_name: "",
            client_signature: "" // Update the value as needed
          }));
    }

    function supervisorSigned() {
        setSupervisorSign(prev => true)
        setSupervisorSignatureCanvas(
            <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                    disabled={true}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>
        )
    }

    function witnessSigned() {
        setWitnessSign(prev => true)
        setWitnessSignatureCanvas(
            <Grid item xs={12}>
            <h5>Witness Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigWitnessCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearWitnessCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={witnessSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                    disabled={true}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>
        )
    }

    function clientSigned() {
        setClientSign(prev => true)
        setClientSignatureCanvas(
            <Grid item xs={12}>
            <h5>Client Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigClientCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearClientCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={clientSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                    disabled={true}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>
        )
    }

    const [supervisorSignatureCanvas, setSupervisorSignatureCanvas] = useState(     
        <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        

    const [witnessSignatureCanvas, setWitnessSignatureCanvas] = useState( 
        <Grid item xs={12}>
            <h5>Witness Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigWitnessCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearWitnessCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={witnessSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)

const [clientSignatureCanvas, setClientSignatureCanvas] = useState( 
    <Grid item xs={12}>
        <h5>Client Signature</h5>

        <SignatureCanvas
            penColor="blue"
            canvasProps={{ width: 200, height: 200 }}
            ref={sigWitnessCanvas}
        />
        <Grid>
            <Button 
                onClick={clearClientCanvas}
                size="medium"
                variant="contained"
                sx={{ mr: 2 }}
            >
                Reset
            </Button>
            <Button 
                onClick={clientSigned}
                size="medium"
                variant="contained"
                sx={{ mr: 2 }}
            >
                Sign
            </Button>
        </Grid>
        <Grid>
            <div style={{ height: "50px" }} />
        </Grid>
    </Grid>)

    useEffect(() => {
        if (id) {
            getResource(id);
        }
        getResourceList()
    }, [id, getResource, getResourceList]);

    useEffect(() => {
        if (resources) {
            const is_manager = localStorage.getItem("is_manager")
            const data = resources
            if (data.drilling_date) {
                handleDrillingDateChange(new Date(data.drilling_date))
            }
            if (data.weather_start_time) {
                handleWeatherStartTimeChange(new Date(data.weather_start_time))
            }
            if (data.weather_end_time) {
                handleWeatherEndTimeChange(new Date(data.weather_end_time))
            }
            if (data.site_supervisor_signed_date) {
                handleSiteSupervisorSignedDateChange(new Date(data.site_supervisor_signed_date))
            }
            if (data.witness_signed_date) {
                handleWitnessSignedDateChange(new Date(data.witness_signed_date))
            }
            if (data.client_signed_date) {
                handleClientSignedDateChange(new Date(data.client_signed_date))
            }
                
            // TODO
            setSupervisorSign(data.site_supervisor_signature !== null)
            setWitnessSign(data.witness_signature !== null)
            setClientSign(data.client_signature !== null)
        
            if (data.site_supervisor_signature !== null) {
                setSupervisorSignatureCanvas(
                    <Grid item xs={12}>
                        <Base64Image base64Url={data.site_supervisor_signature}/>
                        {is_manager === "true" ? <Button 
                                onClick={deleteSupervisorSignature}
                                size="medium"
                                variant="contained"
                                sx={{ mr: 2 }}
                            >
                                Delete Signature
                            </Button> : <div></div>}
                        <h5>{data.site_supervisor_name}</h5>
                        <Grid>
                            <div style={{ height: "50px" }} />
                        </Grid>
                    </Grid>);
            }
            if (data.witness_signature !== null) {
                setWitnessSignatureCanvas(
                    <Grid item xs={12}>
                        <Base64Image base64Url={data.witness_signature}/>
                        { is_manager === "true" ? <Button 
                                onClick={deleteWitnessSignature}
                                size="medium"
                                variant="contained"
                                sx={{ mr: 2 }}
                            >
                                Delete Signature
                            </Button> : <div></div>}
                        <h5>{data.witness_name}</h5>
                        <Grid>
                            <div style={{ height: "50px" }} />
                        </Grid>
                    </Grid>);
            }   
            if (data.client_signature !== null) {
                setClientSignatureCanvas(
                    <Grid item xs={12}>
                        <Base64Image base64Url={data.client_signature}/>
                        { is_manager === "true" ? <Button 
                                onClick={deleteClientSignature}
                                size="medium"
                                variant="contained"
                                sx={{ mr: 2 }}
                            >
                                Delete Signature
                            </Button> : <div></div>}
                        <h5>{data.client_name}</h5>
                        <Grid>
                            <div style={{ height: "50px" }} />
                        </Grid>
                    </Grid>);
            }
            setBorehole(`${data.project_title} Borehole: ${data.borehole_number}`)
            setInitialValues({
                borehole: data.borehole,
                drilling_start_depth_for_the_day: data.drilling_start_depth_for_the_day,
                drilling_end_depth_for_the_day: data.drilling_end_depth_for_the_day,
                weather: data.weather_condition,
                site_supervisor_name: data.site_supervisor_name,
                witness_name: data.witness_name,
                client_name: data.client_name,
            })
        }
    }, [resources]);

    useEffect(() => {
        console.log(filterList)
    }, [filterResources, filterList])

    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>
                <Typography variant="h6" mh={4}>
                    Drilling Summary Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>
                                    <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                ref={boreholeTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Drilling Date'
                                                selected={ drilling_Date }
                                                onChange={(e) => {handleDrillingDateChange(e)}  }
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                name="drilling_date"
                                                showTimeSelect
                                                required
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="drilling_start_depth_for_the_day"
                                                label="Drilling start depth forthe day"
                                                inputMode='numeric'
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                {...formik.getFieldProps('drilling_start_depth_for_the_day')}
                                                error={formik.touched.drilling_start_depth_for_the_day && Boolean(formik.errors.drilling_start_depth_for_the_day)}
                                                helperText={formik.touched.drilling_start_depth_for_the_day && formik.errors.drilling_start_depth_for_the_day}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="drilling_end_depth_for_the_day"
                                                label="Drilling end depth forthe day"
                                                inputMode='numeric'
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                {...formik.getFieldProps('drilling_end_depth_for_the_day')}
                                                error={formik.touched.drilling_end_depth_for_the_day && Boolean(formik.errors.drilling_end_depth_for_the_day)}
                                                helperText={formik.touched.drilling_end_depth_for_the_day && formik.errors.drilling_end_depth_for_the_day}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="weather"
                                                label="Weather"
                                                {...formik.getFieldProps('weather')}
                                                select
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                value={formik.values.weather}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.Weather && Boolean(formik.errors.Weather)}
                                                helperText={formik.touched.Weather && formik.errors.Weather}
                                            >
                                                {weatherOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Weather start time'
                                                selected={ weather_start_time }
                                                onChange={(e) => {handleWeatherStartTimeChange(e)}  }
                                                name="Weather Start Time"
                                                showTimeSelect
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Weather end time'
                                                selected={ weather_end_time }
                                                onChange={(e) => {handleWeatherEndTimeChange(e)}  }
                                                name="Weather End Time"
                                                showTimeSelect
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="site_supervisor_name"
                                                label="Site Supervisor Name"
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                {...formik.getFieldProps('site_supervisor_name')}
                                                error={formik.touched.site_supervisor_name && Boolean(formik.errors.site_supervisor_name)}
                                                helperText={formik.touched.site_supervisor_name && formik.errors.site_supervisor_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Site Supervisor Signed Date'
                                                selected={ site_supervisor_signed_date }
                                                onChange={(e) => {handleSiteSupervisorSignedDateChange(e)}  }
                                                name="Site Supervisor Signed Date"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                disabled={is_supervisor_signed}
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="witness_name"
                                                label="Witness Name"
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                {...formik.getFieldProps('witness_name')}
                                                error={formik.touched.witness_name && Boolean(formik.errors.witness_name)}
                                                helperText={formik.touched.witness_name && formik.errors.witness_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Witness Signed Date'
                                                selected={ witness_signed_date }
                                                onChange={(e) => {handleWitnessSignedDateChange(e)}  }
                                                name="Witness Signed Date"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                disabled={is_witness_signed}
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="client_name"
                                                label="Client Name"
                                                disabled={is_supervisor_signed && is_witness_signed && is_client_signed}
                                                {...formik.getFieldProps('client_name')}
                                                error={formik.touched.client_name && Boolean(formik.errors.client_name)}
                                                helperText={formik.touched.client_name && formik.errors.client_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Client Signed Date'
                                                selected={ client_signed_date }
                                                onChange={(e) => {handleClientSignedDateChange(e)}  }
                                                name="Client Signed Date"
                                                showTimeSelect
                                                disabled={is_client_signed}
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        {supervisorSignatureCanvas}
                                        
                                        {witnessSignatureCanvas}
                                        
                        
                                        {clientSignatureCanvas}

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/drillinglogs`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save Drilling Summary
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}