import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ApiIcon from '@mui/icons-material/Api';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CategoryIcon from '@mui/icons-material/Category';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import CreateIcon from '@mui/icons-material/Create';
import GradientIcon from '@mui/icons-material/Gradient';
import GrassIcon from '@mui/icons-material/Grass';
import GroupsIcon from '@mui/icons-material/Groups';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ListIcon from '@mui/icons-material/List';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PatternOutlinedIcon from '@mui/icons-material/PatternOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ScienceIcon from '@mui/icons-material/Science';
import TableViewIcon from '@mui/icons-material/TableView';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import PatternIcon from '@mui/icons-material/Pattern';
import WaterIcon from '@mui/icons-material/Water';
import BiotechIcon from '@mui/icons-material/Biotech';
import ConstructionIcon from '@mui/icons-material/Construction';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import YardIcon from '@mui/icons-material/Yard';
import HandymanIcon from '@mui/icons-material/Handyman';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoreHole, faSitemap, faFlaskVial } from '@fortawesome/free-solid-svg-icons'
import { TbAlpha, TbBeta, TbOmega } from "react-icons/tb";
import { GiWaterDrop, GiDrill, GiStoneStack,GiStarFormation, GiMountains, GiMiner, GiGasMask, GiTriforce, GiChemicalDrop, GiChemicalTank } from "react-icons/gi";
import { FaSearchLocation, FaAtlassian, FaFlask, FaFilter } from "react-icons/fa";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { BsJournalBookmarkFill, BsPaintBucket } from "react-icons/bs";

import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
import { GlobalStyles, useTheme } from "@mui/material";
import { BuildCircle, InvertColors } from "@mui/icons-material";
import useRequestAuth from 'src/hooks/useRequestAuth';
import Accordion from 'react-bootstrap/Accordion';

const drawerWidth = 300;

const gsm = [
    {
        key: "projects",
        to: "/projects",
        name: "Projects",
        icon: <AiOutlineFundProjectionScreen size={24}/>,
        isProject: true
    },
    {
        key: "boreholes",
        to: "/boreholes",
        name: "Boreholes",
        icon: <FontAwesomeIcon icon={faBoreHole} size={24}/>,
        isBorehole: true,
        number: 1
    },
    {
        key: "drillinglogs",
        to: "/drillinglogs",
        name: "Drilling Summary",
        icon: <GiDrill size={24}/>,
        isBorehole: true,
        number: 2
    },
    {
        key: "spts",
        to: "/spts",
        name: "SPTs",
        icon: <AssessmentIcon size={24}/>,
        isBorehole: true,
        number: 3
    },
    {
        key: "stratumresults",
        to: "/stratumresults",
        name: "Stratum Results",
        icon: <ApiIcon size={24}/>,
        isBorehole: true,
        number: 4
    },
    {
        key: "rockresults",
        to: "/rockresults",
        name: "Rock Results",
        icon: <GiStoneStack size={24}/>,
        isBorehole: true,
        number: 18
    },
    {
        key: "samples",
        to: "/samples",
        name: "Samples",
        icon: <BiotechIcon size={24}/>,
        isBorehole: true,
        number: 19
    },
    {
        key: "sitetests",
        to: "/sitetests",
        name: "Site Tests",
        icon: <FaSearchLocation size={24} />,
        isBorehole: true,
        number: 23
    },
    {
        key: "instrumentinstallationsheets",
        to: "/instrumentinstallationsheets",
        name: "Instrument Installation Sheets",
        icon: <DriveFolderUploadIcon size={24}/>,
        isBorehole: true,
        number: 24
    },
    {
        key: "instrumentinstallations",
        to: "/instrumentinstallations",
        name: "Instrument Installations",
        icon: <ConstructionIcon size={24}/>,
        isBorehole: true,
        number: 25
    },
    {
        key: "dailywaterlevel",
        to: "/dailywaterlevel",
        name: "Daily Water Level Readings ",
        icon: <GiWaterDrop size={24}/>,
        isBorehole: true,
        number: 26
    },
    {
        key: "backfillandgroutings",
        to: "/backfillandgroutings",
        name: "Backfill And Grouting Details",
        icon: <BsPaintBucket size={24}/>,
        isBorehole: true,
        number: 27
    },
    {
        key: "fieldlogs",
        to: "/fieldlogs",
        name: "Field Logs",
        icon: <YardIcon size={24}/>,
        isFieldLog: true,
    },
    {
        key: "drillingrigmovementrecords",
        to: "/drillingrigmovementrecords",
        name: "Drilling Rig Movement Records",
        icon: <AirportShuttleIcon size={24}/>,
        isDrillingRigmovement: true,
    },
    {
        key: "journalentries",
        to: "/journalentries",
        name: "Journal Entries",
        icon: <BsJournalBookmarkFill size={24}/>,
        isJournal: true
    },
];

const sm = [
    {
        key: "stratumtrialpitshandaugers",
        to: "/stratumtrialpitshandaugers",
        name: "Stratum: Trial Pits Hand Augers",
        icon: <StraightenOutlinedIcon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 5
    },
    {
        key: "stratumcolorchroma",
        to: "/stratumcolorchroma",
        name: "Stratum: Color Chroma",
        icon: <GradientIcon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 6
    },
    {
        key: "stratumcolorlightness",
        to: "/stratumcolorlightness",
        name: "Stratum: Color Lightness",
        icon: <Brightness7Icon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 7
    },
    {
        key: "stratumcolorhue",
        to: "/stratumcolorhue",
        name: "Stratum: Color Hue",
        icon: <ColorLensIcon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 8
    },
    {
        key: "stratumcolornonuniform",
        to: "/stratumcolornonuniform",
        name: "Stratum: Color Non-Uniform",
        icon: <InvertColorsIcon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 9
    },
    {
        key: "stratumconsistencyorrelativedensityrock",
        to: "/stratumconsistencyorrelativedensityrock",
        name: "Stratum: Consistency or Relative Density Rock",
        icon: <LandscapeIcon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 10
    },
    {
        key: "stratumconsistencyorrelativedensitysoil",
        to: "/stratumconsistencyorrelativedensitysoil",
        name: "Stratum: Consistency or Relative Density Soil",
        icon: <GrassIcon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 11
    },
    {
        key: "stratumgeologicalclassifications",
        to: "/stratumgeologicalclassifications",
        name: "Stratum: Geological Classifications",
        icon: <FaAtlassian size={24} />,
        isBorehole: true,
        isAdmin: true,
        number: 12
    },
    {
        key: "stratumgeologicalformationgroupcentre",
        to: "/stratumgeologicalformationgroupcentre",
        name: "Stratum: Geological Formation Group / Centre",
        icon: <GiMountains size={24} />,
        isBorehole: true,
        isAdmin: true,
        number: 13
    },
    {
        key: "stratumgeologicalformationmembers",
        to: "/stratumgeologicalformationmembers",
        name: "Stratum: Geological Formation Members",
        icon: <FontAwesomeIcon icon={faSitemap} size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 14
    },
    {
        key: "stratumgeologicalformationplutons",
        to: "/stratumgeologicalformationplutons",
        name: "Stratum: Geological Formation Plutons",
        icon: <GiStarFormation size={24} />,
        isBorehole: true,
        isAdmin: true,
        number: 15
    },
    {
        key: "stratumothers",
        to: "/stratumothers",
        name: "Stratum: Others",
        icon: <MoreHorizOutlinedIcon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 16
    },
    {
        key: "stratumpatterns",
        to: "/stratumpatterns",
        name: "Stratum: Pattern",
        icon: <CategoryIcon size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 17
    },
    {
        key: "sampledescriptionformajorsoilname",
        to: "/sampledescriptionformajorsoilname",
        name: "Sample: Description for Major Soil Name",
        icon: <TbAlpha size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 20
    },
    {
        key: "sampledescriptionforminorsoilname1",
        to: "/sampledescriptionforminorsoilname1",
        name: "Sample: Description for Minor Soil Name 1",
        icon: <TbBeta size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 21
    },
    {
        key: "sampledescriptionforminorsoilname2",
        to: "/sampledescriptionforminorsoilname2",
        name: "Sample: Description for Minor Soil Name 2",
        icon: <TbOmega size={24}/>,
        isBorehole: true,
        isAdmin: true,
        number: 22
    },
    {
        key: "activities",
        to: "/activities",
        name: "Journal Entry: Activities",
        icon: <EngineeringIcon size={24}/>,
        isJournal: true
    },
    {
        key: "agstoxls",
        to: "/agstoxls",
        name: "AGS To XLS Converter",
        icon: <MoveUpIcon size={24}/>,
        isConverter: true
    },
    {
        key: "agswriter",
        to: "/agswriter",
        name: "AGS Writer",
        icon: <SaveAsIcon size={24}/>,
        isConverter: true
    },
    {
        key: "xlstoags",
        to: "/xlstoags",
        name: "XLS To AGS Converter",
        icon: <MoveDownIcon size={24}/>,
        isConverter: true
    },
    {
        key: "/borelogs",
        to: "/borelogs",
        name: "Bore Logs",
        icon: <SummarizeIcon size={24}/>,
        isBorelog: true
    },
    {
        key: "excelwriter",
        to: "/excelwriter",
        name: "Excel Writer",
        icon: <TableViewIcon size={24}/>,
        isConverter: true
    },
    {
        key: "crosssectionlogs",
        to: "/crosssectionlogs",
        name: "Cross Section Logs",
        icon: <PatternIcon size={24}/>,
        isCrossSection: true,
    },
]

const ml = [
    {
        key: "chemicaltests",
        to: "/chemicaltests",
        name: "Test: Chemical Tests",
        icon: <GiChemicalDrop size={24}/>,
        isLaboratory: true
    },
    {
        key: "consolidationtests",
        to: "/consolidationtests",
        name: "Test: Consolidation Tests",
        icon: <ScienceIcon size={24}/>,
        isLaboratory: true
    },
    {
        key: "laboratorypermeabilitytests",
        to: "/laboratorypermeabilitytests",
        name: "Test: Laboratory Permeability Tests",
        icon: <FontAwesomeIcon icon={faFlaskVial} size={24}/>,
        isLaboratory: true
    },
    {
        key: "normallaboratorytests",
        to: "/normallaboratorytests",
        name: "Test: Normal Laboratory Tests",
        icon: <FaFlask size={24}/>,
        isLaboratory: true
    },
    {
        key: "rocktests",
        to: "/rocktests",
        name: "Test: Rock Tests",
        icon: <GiMiner size={24}/>,
        isLaboratory: true
    },
    {
        key: "sievinghydrometertests",
        to: "/sievinghydrometertests",
        name: "Test: Sieving Hydrometer Tests",
        icon: <FaFilter size={24}/>,
        isLaboratory: true
    },
    {
        key: "specimens",
        to: "/specimens",
        name: "Test: Specimens",
        icon: <GiChemicalTank size={24}/>,
        isLaboratory: true
    },
    
    {
        key: "toxicitytests",
        to: "/toxicitytests",
        name: "Test: Toxicity Tests",
        icon: <GiGasMask size={24}/>,
        isLaboratory: true
    },
    {
        key: "triaxialtests",
        to: "/triaxialtests",
        name: "Test: Triaxial Tests",
        icon: <GiTriforce size={24}/>,
        isLaboratory: true
    },
];

const SidebarGlobalStyles = () => {
    const theme = useTheme();
    return (
        <GlobalStyles
            styles={{
                ".sidebar-nav-item": {
                    color: "unset",
                    textDecoration: "none",
                },
                ".sidebar-nav-item-active": {
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                    "& .MuiSvgIcon-root": {
                        color: theme.palette.primary.main,
                    },
                    "& .MuiTypography-root": {
                        fontWeight: 500,
                        color: theme.palette.primary.main,
                    },
                },
            }}
        />
    );
};
const SidebarGlobalStylesMemo = React.memo(SidebarGlobalStyles);


export function SideMenu(props) {
    const { mobileOpen, setMobileOpen } = props;
    const [activeKey, setActiveKey] = useState(0)
    const handleChangeAccordion = (eventKey) => {
        localStorage.setItem("activeKey", eventKey)
        setActiveKey(eventKey)
    } 

    useEffect(() => {

    }, [activeKey])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    var listItems = [];
    const is_geologist = localStorage.getItem("is_geologist")
    const is_senior_geologist = localStorage.getItem("is_senior_geologist")
    const is_manager = localStorage.getItem("is_manager")
    const is_lab_technician = localStorage.getItem("is_lab_technician")
    listItems = is_geologist === "true" ? [...gsm] : listItems
    listItems = is_senior_geologist === "true" ? [...gsm, ...sm] : listItems
    listItems = is_lab_technician === "true" ? [...listItems, ...ml] : listItems
    listItems = is_manager === "true" ? [{
        key: "admin",
        to: "/admin",
        name: "User Info",
        icon: <AdminPanelSettingsIcon size={24}/>,
        isCore: true
    },{
        key: "logs",
        to: "/logs",
        name: "User Activity",
        icon: <SummarizeIcon size={24}/>,
        isCore: true    
    }, ...gsm, ...sm, ...ml] : listItems


    // listItems = listItems.sort((a, b) => {
    //     const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    //     const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    //     if (nameA < nameB) {
    //       return -1;
    //     }
    //     if (nameA > nameB) {
    //       return 1;
    //     }
      
    //     // names must be equal
    //     return 0;
    //   })

    let core = []
    let borehole = []
    let converter = []
    let journal = []
    let lab = []
    let borelog = []
    let drillingRig = []
    let fieldLog = []
    let crossSection = []
    let project = []

    listItems.forEach((elem) => {
        if (elem.isBorehole) {
            borehole.push(elem)
        } else if (elem.isJournal) {
            journal.push(elem)
        } else if (elem.isConverter) {
            converter.push(elem)
        } else if (elem.isLaboratory) {
            lab.push(elem)
        } else if (elem.isCore) {
            core.push(elem)
        } else if (elem.isBorelog) {
            borelog.push(elem)
        } else if (elem.isDrillingRigmovement) {
            drillingRig.push(elem)
        } else if (elem.isFieldLog) {
            fieldLog.push(elem)
        } else if (elem.isCrossSection) {
            crossSection.push(elem)
        } else if (elem.isProject) {
            project.push(elem)
        }
    })

    // console.log(borelog)

    borehole.sort((a, b) => a.number - b.number)

    const drawer = (
        <Box>
            <Toolbar />
            <Divider />
            <Accordion defaultActiveKey="0" activeKey={localStorage.getItem("activeKey") ? localStorage.getItem("activeKey") : "0"} onSelect={handleChangeAccordion}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <ListItemIcon><QueryStatsIcon size={24}/></ListItemIcon>
                        Dashboard
                    </Accordion.Header>
                    <Accordion.Body>
                    <NavLink
                            end={"/dashboard" === "/" ? true : false}
                            className={(props) => {
                                return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                            }}
                            to="/dashboard"
                            key="dashboard"
                        >
                            <ListItem button>
                                <ListItemIcon><QueryStatsIcon /></ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                        </NavLink>
                    </Accordion.Body>
                </Accordion.Item>
                {core.length !== 0 ? <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    <ListItemIcon><AdminPanelSettingsIcon size={24}/></ListItemIcon>
                        Admin
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {core.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {project.length !== 0 ? <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    <ListItemIcon><AiOutlineFundProjectionScreen size={24}/></ListItemIcon>
                        Project
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {project.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {borehole.length !== 0 ? <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    <ListItemIcon><FontAwesomeIcon icon={faBoreHole} size={24}/></ListItemIcon>
                        Borehole Info
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {borehole.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primaryTypographyProps={{fontWeight: li.isAdmin ? 'bold' : ''}} primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {drillingRig.length !== 0 ? <Accordion.Item eventKey="4">
                    <Accordion.Header>
                    <ListItemIcon><AirportShuttleIcon size={24}/></ListItemIcon>
                        Drilling Rig Movement Record
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {drillingRig.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {journal.length !== 0 ? <Accordion.Item eventKey="5">
                    <Accordion.Header>
                    <ListItemIcon><BsJournalBookmarkFill size={24}/></ListItemIcon>
                        Journal
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {journal.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {fieldLog.length !== 0 ? <Accordion.Item eventKey="6">
                    <Accordion.Header>
                    <ListItemIcon><YardIcon size={24}/></ListItemIcon>
                        Field Log
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {fieldLog.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {lab.length !== 0 ? <Accordion.Item eventKey="7">
                    <Accordion.Header>
                        <ListItemIcon><GiChemicalDrop size={24}/></ListItemIcon>
                        Laboratory
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {lab.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {borelog.length !== 0 ? <Accordion.Item eventKey="8">
                    <Accordion.Header>
                    <ListItemIcon><SummarizeIcon size={24}/></ListItemIcon>
                        Final Bore Log
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {borelog.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {crossSection.length !== 0 ? <Accordion.Item eventKey="9">
                    <Accordion.Header>
                    <ListItemIcon><PatternIcon size={24}/></ListItemIcon>
                        Soil Profile Cross Section
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {crossSection.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
                {converter.length !== 0 ? <Accordion.Item eventKey="10">
                    <Accordion.Header>
                        <ListItemIcon><SaveAsIcon size={24}/></ListItemIcon>
                        AGS XLS Writer & Converter
                        
                    </Accordion.Header>
                    <Accordion.Body>
                        <List>
                            {converter.map((li) => {
                                return (
                                    <NavLink
                                        end={li.to === "/" ? true : false}
                                        className={(props) => {
                                            return `${props.isActive ? 'sidebar-nav-item-active' : 'sidebar-nav-item'}`;
                                        }}
                                        to={li.to}
                                        key={li.key}
                                    >
                                        <ListItem button>
                                            <ListItemIcon>{li.icon}</ListItemIcon>
                                            <ListItemText primary={li.name} />
                                        </ListItem>
                                    </NavLink>
                                );
                            })}
                        </List>
                    </Accordion.Body>
                </Accordion.Item> : <div></div>}
            </Accordion>
        </Box>
    );

    return (
        <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        >
            <SidebarGlobalStylesMemo />

            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "block", md: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "none", md: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

SideMenu.propTypes = {
    mobileOpen: PropTypes.bool,
    setMobileOpen: PropTypes.func.isRequired,
};

export default SideMenu;