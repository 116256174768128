import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import DatePicker from 'react-datepicker';
import SignatureCanvas from "react-signature-canvas";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import AddIcon from '@mui/icons-material/Add';
import Overlay from 'react-bootstrap/Overlay';
import 'src/style.css'

import useRequestResource from 'src/hooks/useRequestResource';
import { Input } from 'reactstrap';
import { filter } from 'lodash';
import { SendTimeExtensionSharp } from '@mui/icons-material';

const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    drilling_start_depth_for_the_day: yup.string()
    .required("Drilling start depth is required")
    .test('number', 'Drilling start depth must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Drilling start depth must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Drilling start depth must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Drilling start depth must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),    
    drilling_end_depth_for_the_day: yup.string()
    .required("Drilling end depth is required")
    .test('number', 'Drilling end depth must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Drilling end depth must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Drilling end depth must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Drilling end depth must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),   
    weather: yup.string().required("Weather conditions is required"),
    weather_start_time: yup.string(),
    weather_end_time: yup.string(),
    site_supervisor_name: yup.string().max(32, "Max Length is 32!"),
    site_supervisor_signature: yup.string(),
    site_supervisor_signed_date: yup.string(),
    witness_name: yup.string().max(32, "Max Length is 32!"),
    witness_signature: yup.string(),
    witness_signed_date: yup.string(),
    client_name: yup.string().max(32, "Max Length is 32!"),
    client_signature: yup.string(),
    client_signed_date:  yup.string()
})

export default function DrillinglogCreate() {
    const { id } = useParams();
    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 
    const { filterList, filterResources } = useRequestResource({ endpoint: "auth/borehole_log", query: "borehole", resourceLabel: "Borehole Details" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole"})
    const { addResource, getResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "drillinglog", resourceLabel: "Drilling Log"})
    const navigate = useNavigate();
    const [weather_start_time, handleWeatherStartTimeChange] = React.useState(null);
    const [weather_end_time, handleWeatherEndTimeChange] = React.useState(null);
    const [drilling_date, handleDrillingDateChange] = React.useState(null);
    const [site_supervisor_signed_date, handleSiteSupervisorSignedDateChange] = React.useState(null);
    const [witness_signed_date, handleWitnessSignedDateChange] = React.useState(null);
    const [client_signed_date, handleClientSignedDateChange] = React.useState(null);
    const [is_supervisor_signed, setSupervisorSign] = useState(false)
    const [is_witness_signed, setWitnessSign] = useState(false)
    const [is_client_signed, setClientSign] = useState(false)
    const sigSupervisorCanvas = useRef(null)
    const sigWitnessCanvas = useRef(null)
    const sigClientCanvas = useRef(null)
    const [ borehole, setBorehole ] = useState("")
    const [ exp, setExp ] = useState("")
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const offset = 25;
    const [initialValues, setInitialValues] = useState({
        borehole: "",
        drilling_start_depth_for_the_day: "",
        drilling_end_depth_for_the_day: "",
        weather: "",
        site_supervisor_name: "",
        witness_name: "",
        client_name: ""
    });

    const weatherOptions = [
        { value: 'Rain', label: 'Rain' },
        { value: 'No Rain', label: 'No Rain' },
    ]
    
    const formatValues = values => {
        const formattedValues = {
            borehole: values.borehole,
            drilling_start_depth_for_the_day: values.drilling_start_depth_for_the_day,
            drilling_end_depth_for_the_day: values.drilling_end_depth_for_the_day,
            weather_condition: values.weather,
            site_supervisor_name: values.site_supervisor_name,
            witness_name: values.witness_name,
            client_name: values.client_name,
            drilling_date: drilling_date ? convertDate(drilling_date) : null,
            weather_start_time: weather_start_time ? convertDate(weather_start_time) : null,
            weather_end_time: weather_end_time ? convertDate(weather_end_time) : null,
            site_supervisor_signed_date: site_supervisor_signed_date ? convertDate(site_supervisor_signed_date) : null,
            witness_signed_date: witness_signed_date ? convertDate(witness_signed_date) : null,
            client_signed_date: client_signed_date ? convertDate(client_signed_date) : null,
            site_supervisor_signature: is_supervisor_signed ? sigSupervisorCanvas.current.toDataURL() : null,
            witness_signature: is_witness_signed ? sigWitnessCanvas.current.toDataURL() : null,
            client_signature: is_client_signed ? sigClientCanvas.current.toDataURL() : null
        }

        let formData = new FormData();
        formData.set("json_data", JSON.stringify(formattedValues))
        return formData;
    }

    const handleSubmit = values => {
        addResource(formatValues(values), () => {
            navigate(`/drillinglogs`)
        })
    }

    const handleSubmitReload = values => {
        addResource(formatValues(values), () => {
            window.location.reload();
        })
    }

    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }

    const clearSupervisorCanvas = () => {
        if (sigSupervisorCanvas.current) {
            sigSupervisorCanvas.current.clear()
            setSupervisorSign(prev => false)
        }
    }

    const clearWitnessCanvas = () => {
        if (sigWitnessCanvas.current) {
            sigWitnessCanvas.current.clear()
            setWitnessSign(prev => false)
        }
    }

    const clearClientCanvas = () => {
        if (sigClientCanvas.current) {
            sigClientCanvas.current.clear()
            setClientSign(prev => false)
        }
    }

    function supervisorSigned() {
        setSupervisorSign(prev => true)
    }

    function witnessSigned() {
        setWitnessSign(prev => true)
    }

    function clientSigned() {
        setClientSign(prev => true)
    }

    useEffect(() => {
        getResourceList();
    }, [getResourceList])

    useEffect(() => {
        //console.log(filterList)
    }, [filterResources, filterList])


    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>
                <Typography variant="h6" mh={4}>
                    Drilling Summary Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>
                                    <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                ref={boreholeTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Drilling Date' 
                                                selected={ drilling_date }
                                                onChange={(e) => {handleDrillingDateChange(e)}  }
                                                name="drilling_date"
                                                required
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="drilling_start_depth_for_the_day"
                                                label="Drilling start depth"
                                                inputMode='numeric'
                                                {...formik.getFieldProps('drilling_start_depth_for_the_day')}
                                                error={formik.touched.drilling_start_depth_for_the_day && Boolean(formik.errors.drilling_start_depth_for_the_day)}
                                                helperText={formik.touched.drilling_start_depth_for_the_day && formik.errors.drilling_start_depth_for_the_day}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="drilling_end_depth_for_the_day"
                                                label="Drilling end depth"
                                                inputMode='numeric'
                                                {...formik.getFieldProps('drilling_end_depth_for_the_day')}
                                                error={formik.touched.drilling_end_depth_for_the_day && Boolean(formik.errors.drilling_end_depth_for_the_day)}
                                                helperText={formik.touched.drilling_end_depth_for_the_day && formik.errors.drilling_end_depth_for_the_day}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="weather"
                                                label="Weather"
                                                {...formik.getFieldProps('weather')}
                                                select
                                                value={formik.values.weather}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.weather && Boolean(formik.errors.weather)}
                                                helperText={formik.touched.weather && formik.errors.weather}
                                            >
                                                {weatherOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Weather start time'
                                                selected={ weather_start_time }
                                                onChange={(e) => {handleWeatherStartTimeChange(e)}  }
                                                name="Weather Start Time"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Weather end time'
                                                selected={ weather_end_time }
                                                onChange={(e) => {handleWeatherEndTimeChange(e)}  }
                                                name="Weather End Time"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="site_supervisor_name"
                                                label="Site Supervisor Name"
                                                {...formik.getFieldProps('site_supervisor_name')}
                                                error={formik.touched.site_supervisor_name && Boolean(formik.errors.site_supervisor_name)}
                                                helperText={formik.touched.site_supervisor_name && formik.errors.site_supervisor_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="witness_name"
                                                label="Witness Name"
                                                {...formik.getFieldProps('witness_name')}
                                                error={formik.touched.witness_name && Boolean(formik.errors.witness_name)}
                                                helperText={formik.touched.witness_name && formik.errors.witness_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="client_name"
                                                label="Client Name"
                                                {...formik.getFieldProps('client_name')}
                                                error={formik.touched.client_name && Boolean(formik.errors.client_name)}
                                                helperText={formik.touched.client_name && formik.errors.client_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <h5>Supervisor Signature</h5>
                                            <SignatureCanvas
                                                penColor="blue"
                                                canvasProps={{ width: 200, height: 200 }}
                                                ref={sigSupervisorCanvas}
                                            />
                                                <Grid>
                                                    <Button 
                                                        onClick={clearSupervisorCanvas}
                                                        size="medium"
                                                        variant="contained"
                                                        sx={{ mr: 2 }}
                                                    >
                                                        Reset
                                                    </Button>
                                                    <Button 
                                                        onClick={ supervisorSigned}
                                                        size="medium"
                                                        variant="contained"
                                                        disabled={is_supervisor_signed}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        Sign
                                                    </Button>
                                                </Grid>
                                                <Grid>
                                                    <div style={{ height: "50px" }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DatePicker
                                                        placeholderText='Site Supervisor Signed Date'
                                                        selected={ site_supervisor_signed_date }
                                                        onChange={(e) => {handleSiteSupervisorSignedDateChange(e)}  }
                                                        name="Site Supervisor Signed Date"
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={20}
                                                        timeCaption="time"
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        autoComplete='off'
                                                        popperPlacement="top-start"
                                                    >    
                                                    </DatePicker>
                                                </Grid>
                                                <Grid>
                                                    <div style={{ height: "50px" }} />
                                                </Grid>
                                            <h5>Witness Signature</h5>
                                            <SignatureCanvas
                                                penColor="blue"
                                                canvasProps={{ width: 200, height: 200 }}
                                                ref={sigWitnessCanvas}
                                            />
                                                <Grid>
                                                    <Button 
                                                        onClick={clearWitnessCanvas}
                                                        size="medium"
                                                        variant="contained"
                                                        sx={{ mr: 2 }}
                                                    >
                                                        Reset
                                                    </Button>
                                                    <Button 
                                                        onClick={ witnessSigned}
                                                        size="medium"
                                                        variant="contained"
                                                        disabled={is_witness_signed}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        Sign
                                                    </Button>
                                                </Grid>
                                                <Grid>
                                                    <div style={{ height: "50px" }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DatePicker
                                                        placeholderText='Witness Signed Date'
                                                        selected={ witness_signed_date }
                                                        onChange={(e) => {handleWitnessSignedDateChange(e)}  }
                                                        name="Witness Signed Date"
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={20}
                                                        timeCaption="time"
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        autoComplete='off'
                                                        popperPlacement="top-start"
                                                    >    
                                                    </DatePicker>
                                                </Grid>
                                                <Grid>
                                                    <div style={{ height: "50px" }} />
                                                </Grid>
                                                <h5>Client Signature</h5>
                                            <SignatureCanvas
                                                penColor="blue"
                                                canvasProps={{ width: 200, height: 200 }}
                                                ref={sigClientCanvas}
                                            />
                                                <Grid>
                                                    <Button 
                                                        onClick={clearClientCanvas}
                                                        size="medium"
                                                        variant="contained"
                                                        sx={{ mr: 2 }}
                                                    >
                                                        Reset
                                                    </Button>
                                                    <Button 
                                                        onClick={ clientSigned}
                                                        size="medium"
                                                        variant="contained"
                                                        disabled={is_client_signed}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        Sign
                                                    </Button>
                                                </Grid>
                                                <Grid>
                                                    <div style={{ height: "50px" }} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DatePicker
                                                        placeholderText='Client Signed Date'
                                                        selected={ client_signed_date }
                                                        onChange={(e) => {handleClientSignedDateChange(e)}  }
                                                        name="Client Signed Date"
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={20}
                                                        timeCaption="time"
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        autoComplete='off'
                                                        popperPlacement="top-start"
                                                    >    
                                                    </DatePicker>
                                                </Grid>
                                                <Grid>
                                                    <div style={{ height: "50px" }} />
                                                </Grid>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/drillinglogs`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Drilling Summary
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                onClick={(event) => handleSubmitReload(formik.values)}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save and add another
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}