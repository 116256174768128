import ReactDOM from 'react-dom';
import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequestResource from 'src/hooks/useRequestResource';
import ColorPicker from "src/components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import { set, slice } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import SignatureCanvas from "react-signature-canvas";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/style.css'

const validationSchema = yup.object({
    project: yup.number().typeError("Select a project from the dropdown").required('Project is required'),
    location: yup.string(),
    status: yup.string().required("Status is required"),
    borehole_number: yup.string().required("Borehole number is required").max(255, "Max Length is 255!"),
    northing_meters: yup.string()
    .test('number', 'Northing meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Northing meters must have at most 3 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,3})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Northing meters must be greater than or equal to -9999999.999', (value) => {
        if (value) {
            return parseFloat(value) >= -9999999.999;
        }
        return true
    })
    .test('max-value', 'Northing meters must be less than or equal to 9999999.999', (value) => {
        if (value) {
            return parseFloat(value) <= 9999999.999;
        }
        return true
    }),
    easting_meters: yup.string()
    .test('number', 'Easting meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Easting meters must have at most 3 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,3})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Easting meters must be greater than or equal to -9999999.999', (value) => {
        if (value) {
            return parseFloat(value) >= -9999999.999;
        }
        return true;
    })
    .test('max-value', 'Easting meters must be less than or equal to 9999999.999', (value) => {
        if (value) {
            return parseFloat(value) <= 9999999.999;
        }
        return true;
    }),
    ground_elevation_meters: yup.string()
    .test('number', 'Ground elevation must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Ground elevation must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Ground elevation must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Ground elevation must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
    drilling_start_date: yup.string(),
    drilling_completed_date: yup.string(),
    borehole_diameter_millimeters: yup.string()
    .test('number', 'Borehole diameter must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Borehole diameter must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    }),
    corehole_diameter_millimeters: yup.string()
    .test('number', 'Corehole diameter must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Corehole diameter must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    }),
    hole_type: yup.string(),
    rig_type_number: yup.string().max(255, "Max Length is 255!"),
    drilled_by: yup.string().max(255, "Max Length is 255!"),
    logged_by: yup.string().max(255, "Max Length is 255!"),
    prepared_by: yup.string().max(255, "Max Length is 255!"),
    checked_by: yup.string().max(255, "Max Length is 255!"),
    remarks: yup.string().max(255, "Max Length is 255!"),
    drilling_depth_meters: yup.string()
    .test('number', 'Drilling depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Drilling depth meters must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Drilling depth meters must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true
    })
    .test('max-value', 'Drilling depth meters must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true
    }),
    energy_ratio: yup.string()
    .test('number', 'Energy ratio must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Energy ratio must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    }),
    hammer_id: yup.string().max(32, "Max Length is 32!"),
    site_supervisor_name: yup.string().max(32, "Max Length is 32!"),
    witness_name: yup.string().max(32, "Max Length is 32!"),
    images: yup.array(),
    is_supervisor_signed: yup.boolean(),
    is_witness_signed: yup.boolean(),
    site_supervisor_signature: yup.string(),
    witness_signature: yup.string()
})


const Base64Image = ({ base64Url }) => {
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      if (base64Url) {
        fetch(base64Url)
          .then(response => response.blob())
          .then(blob => {
            const objectUrl = URL.createObjectURL(blob);
            setImageUrl(objectUrl);
          });
      }
    }, [base64Url]);
  
    return (
      <img src={imageUrl} alt="Base64 Image" />
    );
  };
  

export default function BoreHoleDetails() {
    const { id } = useParams();
    const infiniteScrollRef = useRef(null); 
    const projectTextField = useRef(null); 
    const { resources, getResource, updateResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "borehole", resourceLabel: "Project Details" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "projects", resourceLabel: "Project list"})
    const navigate = useNavigate();
    const [startDate, handleStartDateChange] = React.useState(null);
    const [endDate, handleEndDateChange] = React.useState(null);
    const [images, setImages] = useState([])
    const [imageURLs, setImageURLs] = useState([])
    const [is_supervisor_signed, setSupervisorSign] = useState(false)
    const [is_witness_signed, setWitnessSign] = useState(false)
    const sigSupervisorCanvas = useRef(null)
    const sigWitnessCanvas = useRef(null)    
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const [ project, setProject ] = useState("")
    let offset = 25;
    const [exp, setExp] = useState("")

    const [initialValues, setInitialValues] = useState({
        project: "",
        status: "",
        borehole_number: "",
        northing_meters: "",
        easting_meters: "",
        ground_elevation_meters: "",
        drilling_start_date: Date.now(),
        drilling_completed_date: Date.now(),
        borehole_diameter_millimeters: "",
        corehole_diameter_millimeters: "",
        hole_type: "",
        rig_type_number: "",
        drilled_by: "",
        logged_by: "",
        prepared_by: "",
        checked_by: "",
        remarks: "",
        drilling_depth_meters: "",
        energy_ratio: "",
        hammer_id: "",
        site_supervisor_name: "",
        witness_name: "",
        images: [],
        is_witness_signed: false,
        is_supervisor_signed: false,
        site_supervisor_signature: "",
        witness_signature: ""
    });
    

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);
    
    const fetchFile = (path, idx) => {
        // TODO
        const url = `${process.env.REACT_APP_API_URL}/media/` + path;
        const blob = fetch(url)
                        .then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], 'image', { type: blob.type });
                            return file
                        });
        return blob;
    };


    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (projectTextField.current && projectTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

        
    const clearSupervisorCanvas = () => {
        if (sigSupervisorCanvas.current) {
            sigSupervisorCanvas.current.clear()
            setSupervisorSign(prev => false)
            setSupervisorSignatureCanvas(
                <Grid item xs={12}>
                <h5>Supervisor Signature</h5>
                <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 200, height: 200 }}
                    ref={sigSupervisorCanvas}
                />
                <Grid>
                    <Button 
                        onClick={clearSupervisorCanvas}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={ supervisorSigned}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Sign
                    </Button>
                </Grid>
                <Grid>
                    <div style={{ height: "50px" }} />
                </Grid>
            </Grid>)
        }
    }

    const clearWitnessCanvas = () => {
        if (sigWitnessCanvas.current) {
            sigWitnessCanvas.current.clear()
            setWitnessSign(prev => false)
            setWitnessSignatureCanvas(
                <Grid item xs={12}>
                <h5>Witness Signature</h5>
                <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 200, height: 200 }}
                    ref={sigWitnessCanvas}
                />
                <Grid>
                    <Button 
                        onClick={clearWitnessCanvas}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={ witnessSigned}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Sign
                    </Button>
                </Grid>
                <Grid>
                    <div style={{ height: "50px" }} />
                </Grid>
            </Grid>)
        }
    }

    const deleteWitnessSignature = () => {
        setWitnessSign(prev => false)
        setWitnessSignatureCanvas(
            <Grid item xs={12}>
            <h5>Witness Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigWitnessCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearWitnessCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ witnessSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        setInitialValues(prevValues => ({
            ...prevValues,
            is_witness_signed: false,
            site_witness_name: "",
            site_witness_signature: "" // Update the value as needed
          }));
    }

    const deleteSupervisorSignature = () => {
        setSupervisorSign(prev => false)
        setSupervisorSignatureCanvas(
            <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        setInitialValues(prevValues => ({
            ...prevValues,
            is_supervisor_signed: false,
            site_supervisor_name: "",
            site_supervisor_signature: "" // Update the value as needed
          }));
    }

    function supervisorSigned() {
        setSupervisorSign(prev => true)
        setSupervisorSignatureCanvas(
            <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                    disabled={true}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>
        )
    }

    function witnessSigned() {
        setWitnessSign(prev => true)
        setWitnessSignatureCanvas(
            <Grid item xs={12}>
            <h5>Witness Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigWitnessCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearWitnessCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={witnessSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                    disabled={true}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>
        )
    }

    const [supervisorSignatureCanvas, setSupervisorSignatureCanvas] = useState(     
        <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        

    const [witnessSignatureCanvas, setWitnessSignatureCanvas] = useState( 
        <Grid item xs={12}>
            <h5>Witness Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigWitnessCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearWitnessCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={witnessSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)

    useEffect(() => {
        if (resources) {
            const is_manager = localStorage.getItem("is_manager")
            const imagesUrl = resources['images'].trim().slice(1,-1).split(",").filter(ele => ele !== "")
            const fetchPromises = imagesUrl.map((imageUrl, index) => fetchFile(imageUrl.trim().slice(1, -1), index));
            Promise.all(fetchPromises)
                .then((fetchedFiles) => {
                    // All files have been fetched
                    
                    setImages(fetchedFiles);
                    const data = resources['data']
                    setProject(data['project_title'])

                    if (data.drilling_start_date) {
                        handleStartDateChange(new Date(data.drilling_start_date))
                    }
                    if (data.drilling_completed_date) {
                        handleEndDateChange(new Date(data.drilling_completed_date))
                    }
                        
                    // TODO
                    setSupervisorSign(data.is_supervisor_signed)
                    setWitnessSign(data.is_witness_signed)

                
                    if (data.is_supervisor_signed) {
                        setSupervisorSignatureCanvas(
                            <Grid item xs={12}>
                                <Base64Image base64Url={data.site_supervisor_signature}/>
                                {is_manager === "true" || !data.is_witness_signed? <Button 
                                        onClick={deleteSupervisorSignature}
                                        size="medium"
                                        variant="contained"
                                        sx={{ mr: 2 }}
                                    >
                                        Delete Signature
                                    </Button> : <div></div>}
                                <h5>{data.site_supervisor_name}</h5>
                                <Grid>
                                    <div style={{ height: "50px" }} />
                                </Grid>
                            </Grid>);
                    }
                    if (data.is_witness_signed) {
                        setWitnessSignatureCanvas(
                            <Grid item xs={12}>
                                <Base64Image base64Url={data.witness_signature}/>
                                {!data.is_supervisor_signed || !data.is_witness_signed? <Button 
                                        onClick={deleteWitnessSignature}
                                        size="medium"
                                        variant="contained"
                                        sx={{ mr: 2 }}
                                    >
                                        Delete Signature
                                    </Button> : <div></div>}
                                <h5>{data.witness_name}</h5>
                                <Grid>
                                    <div style={{ height: "50px" }} />
                                </Grid>
                            </Grid>);
                    }   

                    setInitialValues({
                        project: data.project === null ? "" :  data.project,
                        location: data.location === null ? "" : data.location,
                        status: data.status === null ? "" :  data.status,
                        borehole_number: data.borehole_number === null ? "" :  data.borehole_number,
                        northing_meters: data.northing_meters === null ? "" :  data.northing_meters,
                        easting_meters: data.easting_meters === null ? "" :  data.easting_meters,
                        ground_elevation_meters: data.ground_elevation_meters === null ? "" :  data.ground_elevation_meters,
                        drilling_start_date: data.drilling_start_date === null ? "" : new Date(data.drilling_start_date),
                        drilling_completed_date: data.drilling_completed_date === null ? "" : new Date(data.drilling_completed_date),
                        borehole_diameter_millimeters: data.borehole_diameter_millimeters === null ? "" : data.borehole_diameter_millimeters,
                        corehole_diameter_millimeters: data.corehole_diameter_millimeters === null ? "" : data.corehole_diameter_millimeters,
                        hole_type: data.hole_type === null ? "" : data.hole_type,
                        rig_type_number: data.rig_type_number === null ? "" : data.rig_type_number,
                        drilled_by: data.drilled_by === null ? "" : data.drilled_by,
                        logged_by: data.logged_by === null ? "" : data.logged_by,
                        prepared_by: data.prepared_by === null ? "" : data.prepared_by,
                        checked_by: data.checked_by === null ? "" : data.checked_by,
                        remarks: data.remarks === null ? "" : data.remarks,
                        drilling_depth_meters: data.drilling_depth_meters === null ? "" : data.drilling_depth_meters,
                        energy_ratio: data.energy_ratio === null ? "" : data.energy_ratio,
                        hammer_id: data.hammer_id === null ? "" : data.hammer_id,
                        site_supervisor_name: data.site_supervisor_name === null ? "" : data.site_supervisor_name,
                        witness_name: data.witness_name === null ? "" : data.witness_name,
                        is_witness_signed: data.is_witness_signed === null ? "" : data.is_witness_signed,
                        is_supervisor_signed: data.is_supervisor_signed === null ? "" : data.is_supervisor_signed,
                        site_supervisor_signature: data.site_supervisor_signature === null ? "" : data.site_supervisor_signature,
                        witness_signature: data.witness_signature === null ? "" : data.witness_signature
                    })
                }
            )
        }
    }, [resources]);

    const statusOptions = [
        { value: 'IP', label: 'In Progress' },
        { value: 'C', label: 'Completed' },
    ]

    const holeTypeOptions = [
        { value: 'CP', label: 'Cable Percussion' },
        { value: 'RC', label: 'Rotary Cored' },
        { value: 'RCG', label: 'Rotary Drilling Common Ground' },
        { value: 'RO', label: 'Rotary Open Hole' },
        { value: 'W', label: 'Wash Boring' },
        { value: 'O', label: 'Others' },
    ]
    

    const handleSubmit = values => {

        const formattedValues = {
            project: values.project === "" ? null : values.project,
            location: values.location === "" ? null : values.location,
            status: values.status === "" ? null: values.status,
            borehole_number: values.borehole_number === "" ? null : values.borehole_number,
            northing_meters: values.northing_meters  === "" ? null : values.northing_meters,
            easting_meters: values.easting_meters  === "" ? null : values.easting_meters,
            ground_elevation_meters: values.ground_elevation_meters  === "" ? null : values.ground_elevation_meters,
            drilling_start_date: startDate ? convertDate(startDate) : null,
            drilling_completed_date: endDate ? convertDate(endDate) : null,
            borehole_diameter_millimeters: values.borehole_diameter_millimeters  === "" ? null : values.borehole_diameter_millimeters,
            corehole_diameter_millimeters: values.corehole_diameter_millimeters  === "" ? null : values.corehole_diameter_millimeters,
            hole_type: values.hole_type  === "" ? null : values.hole_type,
            rig_type_number: values.rig_type_number  === "" ? null : values.rig_type_number,
            drilled_by: values.drilled_by  === "" ? null : values.drilled_by,
            logged_by: values.logged_by  === "" ? null : values.logged_by,
            prepared_by: values.prepared_by  === "" ? null : values.prepared_by,
            checked_by: values.checked_by  === "" ? null : values.checked_by,
            remarks: values.remarks  === "" ? null : values.remarks,
            drilling_depth_meters: values.drilling_depth_meters  === "" ? null : values.drilling_depth_meters,
            energy_ratio: values.energy_ratio  === "" ? null : values.energy_ratio,
            hammer_id: values.hammer_id  === "" ? null : values.hammer_id,
            site_supervisor_name: values.site_supervisor_name  === "" ? null : values.site_supervisor_name,
            witness_name: values.witness_name  === "" ? null : values.witness_name,
            is_witness_signed: is_witness_signed,
            is_supervisor_signed: is_supervisor_signed,
            site_supervisor_signature: is_supervisor_signed ? (sigSupervisorCanvas.current !== null ? sigSupervisorCanvas.current.toDataURL() : values.site_supervisor_signature) : null,
            witness_signature: is_witness_signed ? (sigWitnessCanvas.current !== null ? sigWitnessCanvas.current.toDataURL() : values.witness_signature) : null
        }
        let formData = new FormData();
        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            formData.append(`image${i + 1}`, file);
        }
        formData.set("json_data", JSON.stringify(formattedValues))

        if (id) {
            updateResource(id, formData, () => {
                navigate(`/boreholes`)
            })
            return;
        } else {
            //console.log("No ID PROVIDED NO SERVICE!")
        }
    }

    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setProject(exp)
            getSearchAllList(exp, 0)
        } else {
            setProject("")
            getResourceList(0)
        }
    }

    useEffect(() => {
        const newImageUrls = []
        images.forEach(image => newImageUrls.push(URL.createObjectURL(image)))
        setImageURLs(newImageUrls)

        getResourceList();
    }, [getResourceList, images])

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    function onImageChange(e) {
        setImages([...images,...e.target.files])
    }

    function deleteImage(index, e) {
        e.preventDefault();
        images.splice(index, 1)
        setImages([...images,])
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>
                <Typography variant="h6" mh={4}>
                    Borehole Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>
                                    <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="project"
                                                label={"Project"}
                                                {...formik.getFieldProps('project')}
                                                value={project}
                                                onChange={(e) => {formik.setFieldValue("project", ""); searchAll(e.target.value)}}
                                                ref={projectTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                error={formik.touched.project && Boolean(formik.errors.project)}
                                                helperText={formik.touched.project && formik.errors.project}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("project", option.id)
                                                                formik.setFieldTouched("project", false)
                                                                formik.setFieldError("project", "")
                                                                setProject(`${option.project_title}`)
                                                                setInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                    
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="location"
                                                label="Location"
                                                {...formik.getFieldProps('location')}
                                                error={formik.touched.location && Boolean(formik.errors.location)}
                                                helperText={formik.touched.location && formik.errors.location}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="status"
                                                label="Status"
                                                {...formik.getFieldProps('status')}
                                                select
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                value={formik.values.status}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.status && Boolean(formik.errors.status)}
                                                helperText={formik.touched.status && formik.errors.status}
                                            >
                                                {statusOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="borehole_number"
                                                label="Borehole Number"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('borehole_number')}
                                                error={formik.touched.borehole_number && Boolean(formik.errors.borehole_number)}
                                                helperText={formik.touched.borehole_number && formik.errors.borehole_number}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="northing_meters"
                                                label="Northing Meters"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('northing_meters')}
                                                error={formik.touched.northing_meters && Boolean(formik.errors.northing_meters)}
                                                helperText={formik.touched.northing_meters && formik.errors.northing_meters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="easting_meters"
                                                label="Easting Meters"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('easting_meters')}
                                                error={formik.touched.easting_meters && Boolean(formik.errors.easting_meters)}
                                                helperText={formik.touched.easting_meters && formik.errors.easting_meters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="ground_elevation_meters"
                                                label="Ground Elevation Meters"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('ground_elevation_meters')}
                                                error={formik.touched.ground_elevation_meters && Boolean(formik.errors.ground_elevation_meters)}
                                                helperText={formik.touched.ground_elevation_meters && formik.errors.ground_elevation_meters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Start Date'
                                                selected={ startDate }
                                                disabled={is_witness_signed && is_supervisor_signed }
                                                onChange={ handleStartDateChange }
                                                name="startDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                                
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Complete Date'
                                                selected={ endDate }
                                                disabled={is_witness_signed && is_supervisor_signed }
                                                onChange={ handleEndDateChange }
                                                name="completeDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={10}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="borehole_diameter_millimeters"
                                                label="Borehole Diameter Millimeters"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('borehole_diameter_millimeters')}
                                                error={formik.touched.borehole_diameter_millimeters && Boolean(formik.errors.borehole_diameter_millimeters)}
                                                helperText={formik.touched.borehole_diameter_millimeters && formik.errors.borehole_diameter_millimeters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="corehole_diameter_millimeters"
                                                label="Corehole Diameter Millimeters"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('corehole_diameter_millimeters')}
                                                error={formik.touched.corehole_diameter_millimeters && Boolean(formik.errors.corehole_diameter_millimeters)}
                                                helperText={formik.touched.corehole_diameter_millimeters && formik.errors.corehole_diameter_millimeters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="hole_type"
                                                label="Hole Type"
                                                {...formik.getFieldProps('hole_type')}
                                                select
                                                value={formik.values.hole_type}
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.hole_type && Boolean(formik.errors.hole_type)}
                                                helperText={formik.touched.hole_type && formik.errors.hole_type}
                                            >
                                                {holeTypeOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="rig_type_number"
                                                label="Rig Type Number"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('rig_type_number')}
                                                error={formik.touched.rig_type_number && Boolean(formik.errors.rig_type_number)}
                                                helperText={formik.touched.rig_type_number && formik.errors.rig_type_number}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="drilled_by"
                                                label="Drilled By"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('drilled_by')}
                                                error={formik.touched.drilled_by && Boolean(formik.errors.drilled_by)}
                                                helperText={formik.touched.drilled_by && formik.errors.drilled_by}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="logged_by"
                                                label="Logged By"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('logged_by')}
                                                error={formik.touched.logged_by && Boolean(formik.errors.logged_by)}
                                                helperText={formik.touched.logged_by && formik.errors.logged_by}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="prepared_by"
                                                label="Prepared By"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('prepared_by')}
                                                error={formik.touched.prepared_by && Boolean(formik.errors.prepared_by)}
                                                helperText={formik.touched.prepared_by && formik.errors.prepared_by}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="checked_by"
                                                label="Checked By"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('checked_by')}
                                                error={formik.touched.checked_by && Boolean(formik.errors.checked_by)}
                                                helperText={formik.touched.checked_by && formik.errors.checked_by}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="remarks"
                                                label="Remarks"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('remarks')}
                                                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                                                helperText={formik.touched.remarks && formik.errors.remarks}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="drilling_depth_meters"
                                                label="Drilling Depth Meters"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('drilling_depth_meters')}
                                                error={formik.touched.drilling_depth_meters && Boolean(formik.errors.drilling_depth_meters)}
                                                helperText={formik.touched.drilling_depth_meters && formik.errors.drilling_depth_meters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="energy_ratio"
                                                label="Energy Ratio in Percentage"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('energy_ratio')}
                                                error={formik.touched.energy_ratio && Boolean(formik.errors.energy_ratio)}
                                                helperText={formik.touched.energy_ratio && formik.errors.energy_ratio}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="hammer_id"
                                                label="Hammer Id"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('hammer_id')}
                                                error={formik.touched.hammer_id && Boolean(formik.errors.hammer_id)}
                                                helperText={formik.touched.hammer_id && formik.errors.hammer_id}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="site_supervisor_name"
                                                label="Site Supervisor Name"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('site_supervisor_name')}
                                                error={formik.touched.site_supervisor_name && Boolean(formik.errors.site_supervisor_name)}
                                                helperText={formik.touched.site_supervisor_name && formik.errors.site_supervisor_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="witness_name"
                                                label="Witness Name"
                                                InputProps={{ readOnly: is_witness_signed && is_supervisor_signed }}
                                                {...formik.getFieldProps('witness_name')}
                                                error={formik.touched.witness_name && Boolean(formik.errors.witness_name)}
                                                helperText={formik.touched.witness_name && formik.errors.witness_name}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <input type="file" multiple accept="image/*" onChange={onImageChange} disabled={formik.values.is_witness_signed && formik.values.is_supervisor_signed }/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {imageURLs.map((imageSrc, index) => 
                                                <Grid> 
                                                    <img src={imageSrc}/>
                                                    <button disabled={is_witness_signed && is_supervisor_signed } onClick={(e) => {
                                                    deleteImage(index, e)}}> Delete </button>
                                                </Grid>

                                            )}       
                                        </Grid>

                                        {supervisorSignatureCanvas}

                                        {witnessSignatureCanvas}
                                      
                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/boreholes`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                // type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleSubmit(formik.values)}
                                            >
                                                Save Borehole Information
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}
