import React, { useEffect, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogActions} from "@mui/material"; 
import useRequestResource from 'src/hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "../../../App.css"

function PDFViewer({ backendUrl }) {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
    return (
      <div>
        {backendUrl && (
          <Document className="centered-container" file={backendUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} renderTextLayer={false}/>
          </Document>
        )}
        {/* {numPages && (
          <p>
            Page {pageNumber} of {numPages}
          </p>
        )} */}
      </div>
    );
  }
  

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  export default function FieldLogView() {

    const {getResource, resources, deleteResource} = useRequestResource({endpoint: "auth/borehole_log", query: "fieldboringlog", resourceLabel: "Field Log"})
    const { id } = useParams()
    useEffect(() => {
        getResource(id)
    }, [getResource, id])

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
        window.history.back()
    }

    return (
        <div>
          <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
            <h3 style={headerStyle}>
                Field Boring Log
            </h3>
            { resources ? <div>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="0">Details</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="borehole"
                                        label="Borehole"
                                        value={resources.borehole_number}
                                        InputProps={{ readOnly: true }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="spt_number_of_values"
                                        label="SPT number of values"
                                        value={resources.spt_number_of_values}
                                        InputProps={{ readOnly: true }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="date_of_creation"
                                        label="Date of Creation"
                                        value={new Date(resources.date_of_creation).toLocaleString()}
                                        InputProps={{ readOnly: true }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="convention_choice"
                                        label="Convention Choice"
                                        value={resources.convention_choice}
                                        InputProps={{ readOnly: true }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="display_vane_sheer_test_readings"
                                        label="Display Vane Sheer Test Readings"
                                        value={resources.display_vane_sheer_test_readings===true ? "True" : "False"}
                                        InputProps={{ readOnly: true }}
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} >

                                    <Button
                                        onClick={() => handleConfirmDelete(resources.id)}
                                        type="delete"
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Delete Field Boring Log
                                    </Button>

                                </Grid>
                            </Grid>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="1">View</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                        <PDFViewer backendUrl={`${process.env.REACT_APP_API_URL}${resources.upload}`}></PDFViewer>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>: <div></div>}
        </div>
    )
  }