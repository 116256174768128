import React from 'react'

const headerStyle = {
  color: "white",
  backgroundColor: "Green",
  padding: "10px",
  fontFamily: "Arial"
};

const DashBoard = () => {
  return (
    <div>
      <h3 style={headerStyle} onClick={() => window.open('https://www.geomotion.com.sg/', '_blank')}>
        DashBoard
      </h3>
      <iframe 
        src = 'https://www.geomotion.com.sg/' 
        width="100%"
        height="700px"
        style={{ border: 'none' }}>
      </iframe>
    </div>
  )
}

export default DashBoard