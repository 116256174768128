import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import DatePicker from 'react-datepicker';
import SignatureCanvas from "react-signature-canvas";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import { Input } from 'reactstrap';
import 'src/style.css'
import InfiniteScroll from 'react-infinite-scroll-component';

const Base64Image = ({ base64Url }) => {
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      if (base64Url) {
        fetch(base64Url)
          .then(response => response.blob())
          .then(blob => {
            const objectUrl = URL.createObjectURL(blob);
            setImageUrl(objectUrl);
          });
      }
    }, [base64Url]);
  
    return (
      <img src={imageUrl} alt="Base64 Image" />
    );
  };

  const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    type_of_work: yup.string().required('Type of work is required'),
    machine_or_lorry_supplier: yup.string().required('Machine or lorry supplier is required'),
    machine_or_lorry_other_supplier: yup.string().max(255, "Max Length is 255!"),
    manpower_or_labour_supplier: yup.string().required('Manpower or labour supplier is required'),
    manpower_or_labour_other_supplier: yup.string().max(255, "Max Length is 255!"),
    method_of_movement: yup.string().required('Method of movement is required').max(255, "Max Length is 255!"),
    gms_personnel_on_site: yup.string().required('Gms personnel on site is required').max(255, "Max Length is 255!"),
    remarks: yup.string().max(255, "Max Length is 255!"),
    client_person_in_charge: yup.string().max(255, "Max Length is 255!"),
    client_signature: yup.string(),
    client_signed_date: yup.string(),
    client_remarks: yup.string().max(255, "Max Length is 255!")
})

//const validationSchema = yup.object({})
export default function DrillingRigMovementRecordDetails() {
    const offset = 25;
    const { id } = useParams();
    const { resources, getResource, updateResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "drillingrigmovementrecord", resourceLabel: "Drilling Rig Movement Record" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole list"})
    const navigate = useNavigate();
    const [startDate, handleStartDateChange] = React.useState(null);
    const [clientSignedDate, handleClientSignedDateChange] = React.useState(null);
    const [is_client_signed, setClientSign] = useState(false);
    const sigClientCanvas = useRef(null);
    const [ borehole, setBorehole ] = useState("");
    const [ showInfiniteAddScroll, setShowInfiniteAddScroll ] = useState(false);
    const [exp, setExp] = useState("")

    const [initialValues, setInitialValues] = useState({
        borehole: "",
        date_and_time: Date.now(),
        type_of_work: "",
        machine_or_lorry_supplier: "",
        machine_or_lorry_other_supplier: "",
        manpower_or_labour_supplier: "",
        manpower_or_labour_other_supplier: "",
        method_of_movement: "",
        gms_personnel_on_site: "",
        remarks: "",
        client_person_in_charge: "",
        client_signature: "",
        client_signed_date: "",
        client_remarks: ""
    });

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);

    const fetchForeignKeyData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }

    const typeOfWorkOptions = [
        { value: '', label: '------'},
        { value: 'Mobilization', label: 'Mobilization' },
        { value: 'Demobilization', label: 'Demobilization' },
        { value: 'Shifting', label: 'Shifting' },
    ]

    const supplierOptions = [
        { value: '', label: '------'},
        { value: 'Geomotion', label: 'Geomotion' },
        { value: 'Main Contractor', label: 'Main Contractor' },
        { value: 'Other', label: 'Other' }
    ]
    
    const handleSubmit = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null: values.borehole,
            date_and_time: startDate ? convertDate(startDate) : null,
            type_of_work: values.type_of_work === "" ? null: values.type_of_work,
            machine_or_lorry_supplier: values.machine_or_lorry_supplier === "" ? null: values.machine_or_lorry_supplier,
            machine_or_lorry_other_supplier: values.machine_or_lorry_other_supplier === "" ? null: values.machine_or_lorry_other_supplier,
            manpower_or_labour_supplier: values.manpower_or_labour_supplier === "" ? null: values.manpower_or_labour_supplier,
            manpower_or_labour_other_supplier: values.manpower_or_labour_other_supplier === "" ? null: values.manpower_or_labour_other_supplier,
            method_of_movement: values.method_of_movement === "" ? null: values.method_of_movement,
            gms_personnel_on_site: values.gms_personnel_on_site === "" ? null: values.gms_personnel_on_site,
            remarks: values.remarks === "" ? null: values.remarks,
            client_person_in_charge: values.client_person_in_charge === "" ? null: values.client_person_in_charge,
            client_signature: is_client_signed ? (sigClientCanvas.current !== null ? sigClientCanvas.current.toDataURL() : values.client_signature) : null,
            client_signed_date: clientSignedDate? convertDate(clientSignedDate) : null,
            client_remarks: values.client_remarks === "" ? null: values.client_remarks
        }

        updateResource(id, formattedValues, () => {
            navigate(`/drillingrigmovementrecords`)
        })
    }

    const clearClientCanvas = () => {
        if (sigClientCanvas.current) {
            sigClientCanvas.current.clear()
            setClientSign(prev => false)
            setClientSignatureCanvas(
                <Grid item xs={12}>
                <h5>Client Signature</h5>
                <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 200, height: 200 }}
                    ref={sigClientCanvas}
                />
                <Grid>
                    <Button 
                        onClick={clearClientCanvas}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={ ClientSigned}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Sign
                    </Button>
                </Grid>
                <Grid>
                    <div style={{ height: "50px" }} />
                </Grid>
            </Grid>)
        }
    }

    const deleteClientSignature = () => {
        setClientSign(prev => false)
        setClientSignatureCanvas(
            <Grid item xs={12}>
            <h5>Client Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 500, height: 200 }}
                ref={sigClientCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearClientCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ ClientSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        setInitialValues(prevValues => ({
            ...prevValues,
            is_client_signed: false,
            client_person_in_charge: "",
            client_signature: "" // Update the value as needed
          }));
    }

    function ClientSigned() {
        setClientSign(prev => true)
        setClientSignatureCanvas(
            <Grid item xs={12}>
            <h5>Client Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 500, height: 200 }}
                ref={sigClientCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearClientCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ClientSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                    disabled={true}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>
        )
    }

    const [clientSignatureCanvas, setClientSignatureCanvas] = useState( 
        <Grid item xs={12}>
            <h5>Client Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 500, height: 200 }}
                ref={sigClientCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearClientCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ClientSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)

    useEffect(() => {
        if (resources) {
            const data = resources

            if (data.date_and_time) {
                handleStartDateChange(new Date(data.date_and_time))
            }
            if (data.client_signed_date) {
                handleClientSignedDateChange(new Date(data.client_signed_date))
            }
    
            setClientSign(data.client_signature !== null)
            if (data.client_signature !== null) {
                setClientSignatureCanvas(
                    <Grid item xs={12}>
                        <Base64Image base64Url={data.client_signature}/>
                        <h5>{data.client_name}</h5>
                        <Grid>
                            <div style={{ height: "50px" }} />
                        </Grid>
                    </Grid>);
            }

            setBorehole(`${data.project_title} Borehole: ${data.borehole_number}`)
            setInitialValues({
                borehole: data.borehole === null ? "" : data.borehole  ,
                type_of_work: data.type_of_work === null ? "" : data.type_of_work  ,
                machine_or_lorry_supplier: data.machine_or_lorry_supplier === null ? "" : data.machine_or_lorry_supplier  ,
                machine_or_lorry_other_supplier: data.machine_or_lorry_other_supplier === null ? "" : data.machine_or_lorry_other_supplier  ,
                manpower_or_labour_supplier: data.manpower_or_labour_supplier === null ? "" : data.manpower_or_labour_supplier  ,
                manpower_or_labour_other_supplier: data.manpower_or_labour_other_supplier === null ? "" : data.manpower_or_labour_other_supplier  ,
                method_of_movement: data.method_of_movement === null ? "" : data.method_of_movement  ,
                gms_personnel_on_site: data.gms_personnel_on_site === null ? "" : data.gms_personnel_on_site  ,
                remarks: data.remarks === null ? "" : data.remarks  ,
                client_signature: data.client_signature === null ? "" : data.client_signature,
                client_person_in_charge: data.client_person_in_charge === null ? "" : data.client_person_in_charge  ,
                client_remarks: data.client_remarks === null ? "" : data.client_remarks  
            })
        }
    }, [resources]);

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>
                <Typography variant="h6" mh={4}>
                    Drilling Rig Movement Record Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                onClick={() => setShowInfiniteAddScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>

                                        </Grid>
                                            { showInfiniteAddScroll ? <Grid item xs={12}>
                                            <InfiniteScroll
                                                dataLength={resourceList.results.length}
                                                next={fetchForeignKeyData}
                                                hasMore={currentListSize.current >= offset}
                                                loader={<h4>Loading...</h4>}
                                                height={400}
                                                endMessage={
                                                    <p style={{ textAlign: "center" }}>
                                                    <b>Yay! You have seen it all</b>
                                                    </p>
                                                
                                                }>
                                                        
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setShowInfiniteAddScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                            </InfiniteScroll>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Input the date and time'
                                                selected={ startDate }
                                                disabled={is_client_signed}
                                                onChange={ handleStartDateChange }
                                                name="Date and time"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={10}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                                required
                                            >    
                                            </DatePicker>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="type_of_work"
                                                label="Type of Work"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('type_of_work')}
                                                select
                                                value={formik.values.type_of_work}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.type_of_work && Boolean(formik.errors.type_of_work)}
                                                helperText={formik.touched.type_of_work && formik.errors.type_of_work}
                                            >
                                                {typeOfWorkOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="machine_or_lorry_supplier"
                                                label="Machine or Lorry Supplier"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('machine_or_lorry_supplier')}
                                                select
                                                value={formik.values.machine_or_lorry_supplier}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.machine_or_lorry_supplier && Boolean(formik.errors.machine_or_lorry_supplier)}
                                                helperText={formik.touched.machine_or_lorry_supplier && formik.errors.machine_or_lorry_supplier}
                                            >
                                                {supplierOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="machine_or_lorry_other_supplier"
                                                label="Machine or Lorry Other Supplier"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('machine_or_lorry_other_supplier')}
                                                error={formik.touched.machine_or_lorry_other_supplier && Boolean(formik.errors.machine_or_lorry_other_supplier)}
                                                helperText={formik.touched.machine_or_lorry_other_supplier && formik.errors.machine_or_lorry_other_supplier}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="manpower_or_labour_supplier"
                                                label="Manpower or Labour Supplier"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('manpower_or_labour_supplier')}
                                                select
                                                value={formik.values.manpower_or_labour_supplier}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.manpower_or_labour_supplier && Boolean(formik.errors.manpower_or_labour_supplier)}
                                                helperText={formik.touched.manpower_or_labour_supplier && formik.errors.manpower_or_labour_supplier}
                                            >
                                                {supplierOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="manpower_or_labour_other_supplier"
                                                label="Manpower or Labour other Suppliers"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('manpower_or_labour_other_supplier')}
                                                error={formik.touched.manpower_or_labour_other_supplier && Boolean(formik.errors.manpower_or_labour_other_supplier)}
                                                helperText={formik.touched.manpower_or_labour_other_supplier && formik.errors.manpower_or_labour_other_supplier}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="method_of_movement"
                                                label="Method of Movement"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('method_of_movement')}
                                                error={formik.touched.method_of_movement && Boolean(formik.errors.method_of_movement)}
                                                helperText={formik.touched.method_of_movement && formik.errors.method_of_movement}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="gms_personnel_on_site"
                                                label="Gms personnel on site"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('gms_personnel_on_site')}
                                                error={formik.touched.gms_personnel_on_site && Boolean(formik.errors.gms_personnel_on_site)}
                                                helperText={formik.touched.gms_personnel_on_site && formik.errors.gms_personnel_on_site}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="remarks"
                                                label="Remarks"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('remarks')}
                                                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                                                helperText={formik.touched.remarks && formik.errors.remarks}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="client_person_in_charge"
                                                label="Client Person in Charge"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('client_person_in_charge')}
                                                error={formik.touched.client_person_in_charge && Boolean(formik.errors.client_person_in_charge)}
                                                helperText={formik.touched.client_person_in_charge && formik.errors.client_person_in_charge}
                                            />
                                        </Grid>

                                        {clientSignatureCanvas}

                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Client Signed Date'
                                                selected={ clientSignedDate }
                                                disabled={is_client_signed}
                                                onChange={ handleClientSignedDateChange }
                                                name="Date and time"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={10}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="client_remarks"
                                                label="Client Remarks"
                                                disabled={is_client_signed}
                                                {...formik.getFieldProps('client_remarks')}
                                                error={formik.touched.client_remarks && Boolean(formik.errors.client_remarks)}
                                                helperText={formik.touched.client_remarks && formik.errors.client_remarks}
                                            />
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/drillingrigmovementrecords`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save Drilling Rig Movement Record
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}