import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import ColorPicker from "src/components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from 'src/components/utils/DropdownSelect';
import DatePicker from 'react-datepicker';
import Accordion from 'react-bootstrap/Accordion';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const validationSchema = yup.object({
    trial_pit_length_meters: yup.string()
    .required('Trial pit length meters is required')
    .test('number', 'Trial pit length meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Trial pit length meters must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1, 1})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Trial pit length meters must be greater than or equal to -99.9', (value) => {
        if (value) {
            return parseFloat(value) >= -99.9;
        }
        return true;
    })
    .test('max-value', 'Trial pit length meters must be less than or equal to 99.9', (value) => {
        if (value) {
            return parseFloat(value) <= 99.9;
        }
        return true;
    }),
    trial_pit_breadth_meters: yup.string()
    .required('Trial pit breadth meters is required')
    .test('number', 'Trial pit breadth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Trial pit breadth meters must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1, 1})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Trial pit breadth meters must be greater than or equal to -99.9', (value) => {
        if (value) {
            return parseFloat(value) >= -99.9;
        }
        return true;
    })
    .test('max-value', 'Trial pit breadth meters must be less than or equal to 99.9', (value) => {
        if (value) {
            return parseFloat(value) <= 99.9;
        }
        return true;
    }),
    trial_pit_depth_meters: yup.string()
    .required('Trial pit depth meters is required')
    .test('number', 'Trial pit depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Trial pit depth meters must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1, 1})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Trial pit depth meters must be greater than or equal to -99.9', (value) => {
        if (value) {
            return parseFloat(value) >= -99.9;
        }
        return true;
    })
    .test('max-value', 'Trial pit depth meters must be less than or equal to 99.9', (value) => {
        if (value) {
            return parseFloat(value) <= 99.9;
        }
        return true;
    }),
    hand_auger_start_depth_meters: yup.string()
    .required('Hand auger start depth meters is required')
    .test('number', 'Hand auger start depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Hand auger start depth meters must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1, 1})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Hand auger start depth meters must be greater than or equal to -99.9', (value) => {
        if (value) {
            return parseFloat(value) >= -99.9;
        }
        return true;
    })
    .test('max-value', 'THand auger start depth meters must be less than or equal to 99.9', (value) => {
        if (value) {
            return parseFloat(value) <= 99.9;
        }
        return true;
    }),
    hand_auger_end_depth_meters: yup.string()
    .required('Hand auger end depth meters is required')
    .test('number', 'Hand auger end depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Hand auger end depth meters must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1, 1})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Hand auger end depth meters must be greater than or equal to -99.9', (value) => {
        if (value) {
            return parseFloat(value) >= -99.9;
        }
        return true;
    })
    .test('max-value', 'Hand auger end depth meters must be less than or equal to 99.9', (value) => {
        if (value) {
            return parseFloat(value) <= 99.9;
        }
        return true;
    }),
})

const StratumTrialPitsHandAugers = () => {
    const offset = 25;
    const { addResource: addStratumTrialPitsHandAugerList, deleteResource: deleteStratumTrialPitsHandAugerList, getResourceList: getStratumTrialPitsHandAugerList, 
        resourceList: stratumTrialPitsHandAugerList, currentListSize} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "stratatrialpithandauger", resourceLabel: "Stratum: Trial Pits Hand Augers" });
    const { resources : stratumTrialPitsHandAugerResource, updateResource :  updateStratumTrialPitsHandAugerResource, searchResources, searchList} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "stratumtrialpithandauger", resourceLabel: "Stratum: Trial Pit Hand Auger" });

    const [initialValues, setInitialValues] = useState({
        trial_pit_length_meters: "",
        trial_pit_breadth_meters: "",
        trial_pit_depth_meters: "",
        hand_auger_start_depth_meters: "",
        hand_auger_end_depth_meters: ""
    });

    const [editedValues, setEditedValues] = useState({
        trial_pit_length_meters: "",
        trial_pit_breadth_meters: "",
        trial_pit_depth_meters: "",
        hand_auger_start_depth_meters: "",
        hand_auger_end_depth_meters: ""
    });

    const [exp, setExp] = useState("")

    const [idToEdit, setIdToEdit] = useState(null);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const sectionRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [sorting_query, setSortingQuery] = useState(null)

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteStratumTrialPitsHandAugerList(idToDelete);
    }

    useEffect(() => {
        getStratumTrialPitsHandAugerList();
    }, [getStratumTrialPitsHandAugerList]);

    const handleSubmit = values => {
        const formattedValues = {
            trial_pit_length_meters: values.trial_pit_length_meters,
            trial_pit_breadth_meters: values.trial_pit_breadth_meters,
            trial_pit_depth_meters: values.trial_pit_depth_meters,
            hand_auger_start_depth_meters: values.hand_auger_start_depth_meters,
            hand_auger_end_depth_meters: values.hand_auger_end_depth_meters
        }
        
        addStratumTrialPitsHandAugerList(formattedValues, () => {
            window.location.reload(); 
        })
    }

    const handleEditWindow = (e) => {
        setEditedValues({
            trial_pit_length_meters: e.trial_pit_length_meters,
            trial_pit_breadth_meters: e.trial_pit_breadth_meters,
            trial_pit_depth_meters: e.trial_pit_depth_meters,
            hand_auger_start_depth_meters: e.hand_auger_start_depth_meters,
            hand_auger_end_depth_meters: e.hand_auger_end_depth_meters
        })
        setIdToEdit(e.id);
        setIsEditVisible(true);
    }

    useEffect(() => {
        if (isEditVisible && sectionRef.current) {
            setActiveKey('2')
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }, [isEditVisible]);

    const handleEditSubmit = values => {
        const formattedValues = {
            trial_pit_length_meters: values.trial_pit_length_meters,
            trial_pit_breadth_meters: values.trial_pit_breadth_meters,
            trial_pit_depth_meters: values.trial_pit_depth_meters,
            hand_auger_start_depth_meters: values.hand_auger_start_depth_meters,
            hand_auger_end_depth_meters: values.hand_auger_end_depth_meters
        }

        if (idToEdit) {
            updateStratumTrialPitsHandAugerResource(idToEdit, formattedValues, () => {
                window.location.reload(); 
            })
            return;
        } else {
            //console.log("No ID PROVIDED NO SERVICE!")
        }
    }

    const handleChangeAccordian = (eventKey) => {
        if (eventKey != '2') {
            setIdToEdit(null);
            setIsEditVisible(false);
        }
        setActiveKey(eventKey)
    }

    const fetchData = () => {
        getStratumTrialPitsHandAugerList(offset, sorting_query)
    }

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getStratumTrialPitsHandAugerList(0, sorting_query)
        }
    }, [sorting_query, getStratumTrialPitsHandAugerList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return stratumTrialPitsHandAugerList.results
        }
    }

    return (
        <div>
        <Dialog open={open} onClose={handleDeleteClose}>
            <DialogTitle>
                Are you sure you want to delete this??
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDelete}>
                    YES
                </Button>
                <Button onClick={handleDeleteClose}>
                    NO!
                </Button>
            </DialogActions>
        </Dialog>

        <Accordion activeKey = {activeKey} onSelect={handleChangeAccordian}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Add Stratum: Trial Pit Hand Auger</Accordion.Header>
                    <Accordion.Body>
                        <Paper sx={{
                            borderRadius: "2px",
                            bpxShadow: (theme) => theme.shadows[4],
                            padding: (theme) => theme.spacing(2, 4, 3)
                        }}>

                            <Grid item>
                                <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                            </Grid>
                            <Typography variant="h6" mh={4}>
                                Add Stratum: Trial Pit Hand Auger
                            </Typography>

                            <br />

                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid container spacing={3}>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="trial_pit_length_meters"
                                                            label="Trial Pit Length Meters"
                                                            {...formik.getFieldProps('trial_pit_length_meters')}
                                                            error={formik.touched.trial_pit_length_meters && Boolean(formik.errors.trial_pit_length_meters)}
                                                            helperText={formik.touched.trial_pit_length_meters && formik.errors.trial_pit_length_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="trial_pit_breadth_meters"
                                                            label="Trial Pit Breadth Meters"
                                                            {...formik.getFieldProps('trial_pit_breadth_meters')}
                                                            error={formik.touched.trial_pit_breadth_meters && Boolean(formik.errors.trial_pit_breadth_meters)}
                                                            helperText={formik.touched.trial_pit_breadth_meters && formik.errors.trial_pit_breadth_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="trial_pit_depth_meters"
                                                            label="Trial Pit Depth Meters"
                                                            {...formik.getFieldProps('trial_pit_depth_meters')}
                                                            error={formik.touched.trial_pit_depth_meters && Boolean(formik.errors.trial_pit_depth_meters)}
                                                            helperText={formik.touched.trial_pit_depth_meters && formik.errors.trial_pit_depth_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="hand_auger_start_depth_meters"
                                                            label="Hand Auger Start Depth Meters"
                                                            {...formik.getFieldProps('hand_auger_start_depth_meters')}
                                                            error={formik.touched.hand_auger_start_depth_meters && Boolean(formik.errors.hand_auger_start_depth_meters)}
                                                            helperText={formik.touched.hand_auger_start_depth_meters && formik.errors.hand_auger_start_depth_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="hand_auger_end_depth_meters"
                                                            label="Hand Auger End Depth Meters"
                                                            {...formik.getFieldProps('hand_auger_end_depth_meters')}
                                                            error={formik.touched.hand_auger_end_depth_meters && Boolean(formik.errors.hand_auger_end_depth_meters)}
                                                            helperText={formik.touched.hand_auger_end_depth_meters && formik.errors.hand_auger_end_depth_meters}
                                                        />
                                                    </Grid>
                                                    
                                                    <Grid item xs={12} >

                                                        <Button
                                                            type="submit"
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Create New Stratum: Trial Pit Hand Auger
                                                        </Button>

                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
                        </Paper>
                    </Accordion.Body>
                </Accordion.Item>
 
            <Accordion.Item eventKey="1">
                <Accordion.Header>Stratum: Trial Pits Hand Augers List</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <TableContainer component={Paper}>
                                <h3 style={headerStyle}>
                                    Stratum: Trial Pits Hand Augers
                                </h3>

                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="stratum_trial_pits_hand_augers"
                                            label="Stratum Trial Pits Hand Augers"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                
                                <div style={{ height: "20px" }} />

                                <Table size="small">
                                    <TableHead width="100%">
                                        <TableRow>
                                            <TableCell align="center" width="20%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("trial_pit_length_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Trial Pit Length Meters</div><div>{sorting_query === "trial_pit_length_meters" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-trial_pit_length_meters")}></FaSortUp> : 
                                                    sorting_query === "-trial_pit_length_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("trial_pit_length_meters")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("trial_pit_breadth_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Trial Pit Breadth Meters</div><div>{sorting_query === "trial_pit_breadth_meters" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-trial_pit_breadth_meters")}></FaSortUp> : 
                                                    sorting_query === "-trial_pit_breadth_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("trial_pit_breadth_meters")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("trial_pit_depth_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Trial Pit Depth Meters</div><div>{sorting_query === "trial_pit_depth_meters" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-trial_pit_depth_meters")}></FaSortUp> : 
                                                    sorting_query === "-trial_pit_depth_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("trial_pit_depth_meters")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("hand_auger_start_depth_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Hand Auger Start Depth Meters</div><div>{sorting_query === "hand_auger_start_depth_meters" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-hand_auger_start_depth_meters")}></FaSortUp> : 
                                                    sorting_query === "-hand_auger_start_depth_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("hand_auger_start_depth_meters")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("hand_auger_end_depth_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Hand Auger End Depth Meters</div><div>{sorting_query === "hand_auger_end_depth_meters" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-hand_auger_end_depth_meters")}></FaSortUp> : 
                                                    sorting_query === "-hand_auger_end_depth_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("hand_auger_end_depth_meters")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" width="15%">
                                                Action
                                            </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {
                                            getList().map((r) => {
                                                return (
                                                    <TableRow
                                                    >
                                                        <TableCell align="center" width="20%">
                                                            {r.trial_pit_length_meters}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.trial_pit_breadth_meters}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.trial_pit_depth_meters}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.hand_auger_start_depth_meters}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.hand_auger_end_depth_meters}
                                                        </TableCell>
                                                        <TableCell align="center" width="15%">
                                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                                <IconButton size="small" onClick={() => {
                                                                    handleEditWindow(r);
                                                                }}>
                                                                    <EditIcon />
                                                                </IconButton>

                                                                <IconButton size="small" onClick={() => {
                                                                    handleConfirmDelete(r.id)
                                                                }}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                <Button onClick={fetchData}>
                                            LOAD MORE!
                                </Button>
                            </div>}
                        </div >
                    </Accordion.Body>
                </Accordion.Item>

                { isEditVisible? 
                <Accordion.Item eventKey="2" ref={sectionRef}>
                <Accordion.Header>Edit Stratum: Trial Pit Hand Auger </Accordion.Header>
                <Accordion.Body>
                        
                        <Paper sx={{
                                borderRadius: "2px",
                                bpxShadow: (theme) => theme.shadows[4],
                                padding: (theme) => theme.spacing(2, 4, 3)
                            }}>
    
                                <Grid item>
                                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                                </Grid>
                                <Typography variant="h6" mh={4}>
                                    Edit Stratum: Trial Pit Hand Auger
                                </Typography>
    
                                <br />
                                <Formik onSubmit={handleEditSubmit}
                                    initialValues={editedValues}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                >
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                    <Grid container spacing={3}>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="trial_pit_length_meters"
                                                            label="Trial Pit Length Meters"
                                                            {...formik.getFieldProps('trial_pit_length_meters')}
                                                            error={formik.touched.trial_pit_length_meters && Boolean(formik.errors.trial_pit_length_meters)}
                                                            helperText={formik.touched.trial_pit_length_meters && formik.errors.trial_pit_length_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="trial_pit_breadth_meters"
                                                            label="Trial Pit Breadth Meters"
                                                            {...formik.getFieldProps('trial_pit_breadth_meters')}
                                                            error={formik.touched.trial_pit_breadth_meters && Boolean(formik.errors.trial_pit_breadth_meters)}
                                                            helperText={formik.touched.trial_pit_breadth_meters && formik.errors.trial_pit_breadth_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="trial_pit_depth_meters"
                                                            label="Trial Pit Depth Meters"
                                                            {...formik.getFieldProps('trial_pit_depth_meters')}
                                                            error={formik.touched.trial_pit_depth_meters && Boolean(formik.errors.trial_pit_depth_meters)}
                                                            helperText={formik.touched.trial_pit_depth_meters && formik.errors.trial_pit_depth_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="hand_auger_start_depth_meters"
                                                            label="Hand Auger Start Depth Meters"
                                                            {...formik.getFieldProps('hand_auger_start_depth_meters')}
                                                            error={formik.touched.hand_auger_start_depth_meters && Boolean(formik.errors.hand_auger_start_depth_meters)}
                                                            helperText={formik.touched.hand_auger_start_depth_meters && formik.errors.hand_auger_start_depth_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="hand_auger_end_depth_meters"
                                                            label="Hand Auger End Depth Meters"
                                                            {...formik.getFieldProps('hand_auger_end_depth_meters')}
                                                            error={formik.touched.hand_auger_end_depth_meters && Boolean(formik.errors.hand_auger_end_depth_meters)}
                                                            helperText={formik.touched.hand_auger_end_depth_meters && formik.errors.hand_auger_end_depth_meters}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} >

                                                        <Button
                                                            type="submit"
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Save Stratum: Trial Pit Hand Auger
                                                        </Button>
                                                    </Grid>
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Paper>
                        </Accordion.Body>
                </Accordion.Item>
                :<div></div>
                }
        </Accordion>
        </div>

    )
}
export default StratumTrialPitsHandAugers;