import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, Card, TextField, MenuList, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import InfiniteScroll from 'react-infinite-scroll-component';
import Alert from '@mui/material/Alert';

const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    stratum_result: yup.number().typeError("Select a stratum result from the dropdown").required('Stratum result is required'),
    rock_coring_sample: yup.number().typeError("Select a rock coring sample from the dropdown"),
    spt_sample: yup.number().typeError("Select a SPT sample from the dropdown"),
    sample_type: yup.string().required("Sample Type is required"),
    sample_number: yup.string()
    .required('Sample number is required')
    .test('number', 'Sample number must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Sample number must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Sample number must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    }),
    depth_meters: yup.string()
    .required('Depth meters is required')
    .test('number', 'Depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Depth meters must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Depth meters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Depth meters must be less than or equal to 9999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.99;
        }
        return true;
    }),
    length_centimeters: yup.string()
    .required('Length centimeters is required')
    .test('number', 'Length centimeters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Length centimeters must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Length centimeters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true
    })
    .test('max-value', 'Length centimeters must be less than or equal to 100', (value) => {
        if (value) {
            return parseFloat(value) <= 100;
        }
        return true
    }),
    recovery_centimeters: yup.string()
    .required('Recovery centimeters is required')
    .test('number', 'Recovery centimeters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Recovery centimeters must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Recovery centimeters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true
    })
    .test('max-value', 'Recovery centimeters must be less than or equal to 100', (value) => {
        if (value) {
            return parseFloat(value) <= 100;
        }
        return true
    }),
    sample_diameter_millimeters: yup.string()
    .required('Sample diameter millimeters is required')
    .test('number', 'Sample diameter millimeters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Sample diameter millimeters must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Sample diameter millimeters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true
    }),
    remarks: yup.string().max(255, "Max Length is 255!")
})

export default function SampleCreate() {
    const { id } = useParams();
    const { filterList: stratumResultList, filterResources: filterStratumResults } = useRequestResource({ endpoint: "auth/borehole_log", query: "stratumresult", resourceLabel: "Stratum Results" });
    const { filterList: rockCoringSampleList, filterResources: filterRockCoringSamples } = useRequestResource({ endpoint: "auth/borehole_log", query: "rockresult", resourceLabel: "Rock Results" });
    const { filterList: sptSampleList, filterResources: filterSptSamples } = useRequestResource({ endpoint: "auth/borehole_log", query: "spt", resourceLabel: "SPTs" });
    const { getResource, addResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "sample", resourceLabel: "Sample Details" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole list"})
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    let offset = 25;
    const [ borehole, setBorehole ] = useState("")
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const [exp, setExp] = useState("")

    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 

    const [initialValues, setInitialValues] = useState({
        borehole: "",
        stratum_result: "",
        rock_coring_sample: "",
        spt_sample: "",
        sample_type: "",
        sample_number: "",
        depth_meters: "",
        length_centimeters: "",
        recovery_centimeters: "",
        sample_diameter_millimeters: "",
        remarks: ""
    });

    const sampleOptions = [
        { value: 'TW', label: 'Thin Wall Sample' },
        { value: 'PS', label: 'Piston Sample' },
        { value: 'MZ', label: 'Maizer Sample' },
        { value: 'CR', label: 'Rock Coring Sample' },
        { value: 'SPT', label: 'SPT sample' },
        { value: 'TW*', label: 'Thin Wall Sample Attempted'},
        { value: 'PS*', label: 'Piston Sample attempted'},
        { value: 'MZ*', label: 'Maizer Sample attempted'},
        { value: 'CR*', label: 'Rock Coring Sample attempted'}
    ]
    
    const formatValues = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null : values.borehole,
            stratum_result: values.stratum_result === "" ? null : values.stratum_result,
            rock_coring_sample: values.rock_coring_sample === "" || !values.sample_type.includes("CR") ? null : values.rock_coring_sample,
            spt_sample: values.spt_sample === "" || !values.sample_type.includes("SPT") ? null : values.spt_sample,
            sample_type: values.sample_type === "" ? null : values.sample_type,
            sample_number: values.sample_number === "" ? null : values.sample_number,
            depth_meters: values.depth_meters === "" ? null : values.depth_meters,
            length_centimeters: values.length_centimeters === "" ? null : values.length_centimeters,
            recovery_centimeters: values.recovery_centimeters === "" ? null : values.recovery_centimeters,
            sample_diameter_millimeters: values.sample_diameter_millimeters === "" ? null : values.sample_diameter_millimeters,
            remarks: values.remarks === "" ? null : values.remarks
        }
        
        let formData = new FormData();

        formData.append(`image`, image);
        
        formData.set("json_data", JSON.stringify(formattedValues));


        return formData;
    }

    const handleSubmit = values => {
        addResource(formatValues(values), () => {
            navigate(`/samples`)
        })
    }

    const handleSubmitReload = values => {
        addResource(formatValues(values), () => {
            window.location.reload();
        })
    }
    
    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }

    useEffect(() => {
        // if (images.length < 1) return;
        if (image != null) {
            setImageURL(URL.createObjectURL(image))
        } else {
            setImageURL(null);
        }
        getResourceList();
    }, [getResourceList, image])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    function onImageChange(e) {
        setImage(e.target.files[0])
    }

    function deleteImage(e) {
        e.preventDefault();
        setImage(null);
    }

    function getRockAndSptSample(id) {
        const values = {
            borehole: id
        }
        filterStratumResults(values)
        filterRockCoringSamples(values)
        filterSptSamples(values)
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
                
            }}>
                <Typography variant="h6" mh={4}>
                    Create Sample
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                ref={boreholeTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                formik.setFieldValue("stratum_result", "")
                                                                formik.setFieldValue("spt_sample", "")
                                                                formik.setFieldValue("rock_coring_sample", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setInfiniteScroll(false)
                                                                getRockAndSptSample(option.id)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        {
                                            stratumResultList.results.length > 0 ?
                                            <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="stratum_result"
                                                label="Stratum Result"
                                                {...formik.getFieldProps('stratum_result')}
                                                select
                                                value={formik.values.stratum_result}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.stratum_result && Boolean(formik.errors.stratum_result)}
                                                helperText={formik.touched.stratum_result && formik.errors.stratum_result}
                                            >
                                                {stratumResultList.results.map((option, index) => (
                                                    <MenuItem key={index} value={option.id}>
                                                        Stratum Result @ {option.soil_layer_start_depth_meters}m
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            </Grid> : 
                                            <Grid item xs={12}>
                                                <Alert variant="outlined" severity="error">
                                                    Select a borehole with valid stratum result!
                                                </Alert>
                                            </Grid>
                                        }

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="sample_type"
                                                label="Sample Type"
                                                {...formik.getFieldProps('sample_type')}
                                                select
                                                value={formik.values.sample_type}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.sample_type && Boolean(formik.errors.sample_type)}
                                                helperText={formik.touched.sample_type && formik.errors.sample_type}
                                            >
                                                {sampleOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        {
                                            rockCoringSampleList.results.length > 0 && formik.values.sample_type.includes("CR") ?
                                            <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="rock_coring_sample"
                                                label="Rock Coring Sample"
                                                {...formik.getFieldProps('rock_coring_sample')}
                                                select
                                                value={formik.values.rock_coring_sample}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.rock_coring_sample && Boolean(formik.errors.rock_coring_sample)}
                                                helperText={formik.touched.rock_coring_sample && formik.errors.rock_coring_sample}
                                            >
                                                {rockCoringSampleList.results.map((option, index) => (
                                                    <MenuItem key={index} value={option.id}>
                                                        Rock Coring {option.rock_coring_number} @ {option.depth_meters}m
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            </Grid> : <div></div>
                                        }

                                        {
                                            sptSampleList.results.length > 0 && formik.values.sample_type.includes("SPT") ?
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="spt_sample"
                                                    label="SPT Sample"
                                                    {...formik.getFieldProps('spt_sample')}
                                                    select
                                                    value={formik.values.spt_sample}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.spt_sample && Boolean(formik.errors.spt_sample)}
                                                    helperText={formik.touched.spt_sample && formik.errors.spt_sample}
                                                >
                                                    {sptSampleList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                            SPT {option.spt_number} @ {option.depth_to_top_of_test_meters}m
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid> : <div></div>
                                        }
                                        
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="sample_number"
                                                label="Sample Number"
                                                {...formik.getFieldProps('sample_number')}
                                                error={formik.touched.sample_number && Boolean(formik.errors.sample_number)}
                                                helperText={formik.touched.sample_number && formik.errors.sample_number}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="depth_meters"
                                                label="Depth Meters"
                                                {...formik.getFieldProps('depth_meters')}
                                                error={formik.touched.depth_meters && Boolean(formik.errors.depth_meters)}
                                                helperText={formik.touched.depth_meters && formik.errors.depth_meters}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="length_centimeters"
                                                label="Length Centimeters"
                                                {...formik.getFieldProps('length_centimeters')}
                                                error={formik.touched.length_centimeters && Boolean(formik.errors.length_centimeters)}
                                                helperText={formik.touched.length_centimeters && formik.errors.length_centimeters}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="recovery_centimeters"
                                                label="Recovery Centimeters"
                                                {...formik.getFieldProps('recovery_centimeters')}
                                                error={formik.touched.recovery_centimeters && Boolean(formik.errors.recovery_centimeters)}
                                                helperText={formik.touched.recovery_centimeters && formik.errors.recovery_centimeters}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="sample_diameter_millimeters"
                                                label="Sample Diameter millimeters"
                                                {...formik.getFieldProps('sample_diameter_millimeters')}
                                                error={formik.touched.sample_diameter_millimeters && Boolean(formik.errors.sample_diameter_millimeters)}
                                                helperText={formik.touched.sample_diameter_millimeters && formik.errors.sample_diameter_millimeters}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="remarks"
                                                label="Remarks"
                                                {...formik.getFieldProps('remarks')}
                                                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                                                helperText={formik.touched.remarks && formik.errors.remarks}
                                            />
                                        </Grid>
                                    
                                        <Grid item xs={12}>
                                            <input type="file" accept="image/*" onChange={onImageChange} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            {imageURL != null && (
                                                <Grid> 
                                                <img src={imageURL} />
                                                <button onClick={(e) => { deleteImage(e) }}>Delete</button>
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/samples`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Sample
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                onClick={(event) => handleSubmitReload(formik.values)}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save and add another
                                            </Button>
                                        </Grid>


                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}