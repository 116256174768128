import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, Card, TextField, MenuList, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import InfiniteScroll from 'react-infinite-scroll-component';

const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    soil_layer_start_depth_meters: yup.string()
    .required('Soil layer start depth meters is required')
    .test('number', 'Depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Depth meters must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Depth meters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Depth meters must be less than or equal to 9999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.99;
        }
        return true;
    }),
    pattern: yup.number().typeError("Select a stratum pattern from the dropdown").required('Stratum pattern is required'),
    trial_pit_and_hand_auger: yup.number().typeError("Select a stratum trial pit and hand auger from the dropdown"),
    consistency_or_relative_density_rock: yup.number().typeError("Select a stratum consistency or relative density rock from the dropdown"),
    consistency_or_relative_density_soil: yup.number().typeError("Select a stratum consistency or relative density soil from the dropdown"),
    color_lightness: yup.number().typeError("Select a stratum color lightness from the dropdown"),
    color_chroma: yup.number().typeError("Select a stratum color chroma from the dropdown"),
    color_hue: yup.number().typeError("Select a stratum color hue from the dropdown"),
    color_nonuniform: yup.number().typeError("Select a stratum color nonuniform from the dropdown"),
    color_nonuniform_lightness: yup.number().typeError("Select a stratum color nonuniform lightness from the dropdown"),
    color_nonuniform_chroma: yup.number().typeError("Select a stratum color nonuniform chroma from the dropdown"),
    color_nonuniform_hue: yup.number().typeError("Select a stratum color nonuniform hue from the dropdown"),
    major_soil_name: yup.number().typeError("Select a major soil from the dropdown"),
    minor_soil1_name: yup.number().typeError("Select a minor soil 1 from the dropdown"),
    minor_soil2_name: yup.number().typeError("Select a minor soil 2 from the dropdown"),
    rock_description: yup.string().max(255, 'Max Length is 255!'),
    remarks: yup.string().max(255, 'Max Length is 255!'),
    geological_formation_group: yup.number().typeError("Select a geological formation group from the dropdown"),
    geological_formation_member: yup.number().typeError("Select a geological formation member from the dropdown"),
    geological_formation_pluton: yup.number().typeError("Select a geological formation pluton from the dropdown"),
    geological_classification: yup.number().typeError("Select a geological classification from the dropdown"),
    others: yup.number().typeError("Select stratum others from the dropdown")
})

export default function StratumResultCreate() {

    const {id} = useParams()
    const { getResource, addResource, resources } = useRequestResource({ endpoint: "auth/borehole_log", query: "stratumresult", resourceLabel: "Stratum Result Details" });
    const { getResourceList: getBoreholeList, resourceList: boreholeList, currentListSize: boreholeListSize, getSearchAllList: getSearchAllBoreholeList } = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole list"})
    const { getResourceList: getStataPatternList, resourceList: strataPatternList, currentListSize: strataPatternListSize, getSearchAllList: getSearchAllPatternList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratapattern", resourceLabel: "Strata Pattern List"})
    const { getResourceList: getStrataTrialPithAndAugerList, resourceList: stratatrialpithandaugerList, currentListSize: stratatrialpithandaugerListSize, getSearchAllList: getSearchAllStrataTrialPithAndAugerList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratatrialpithandauger", resourceLabel: "stratatrialpithandauger List"})
    const { getResourceList: getStrataConsistencyOrRelativeDensitySoilList, resourceList: strataconsistencyorrelativedensitysoilList, currentListSize: strataconsistencyorrelativedensitysoilListSize, getSearchAllList: getSearchAllStrataConsistencyOrRelativeDensitySoilList} = useRequestResource({endpoint: "auth/borehole_log", query: "strataconsistencyorrelativedensitysoil", resourceLabel: "strataconsistencyorrelativedensitysoil List"})
    const { getResourceList: getStrataConsistencyOrRelativeDensityRockList, resourceList: strataconsistencyorrelativedensityrockList, currentListSize: strataconsistencyorrelativedensityrockListSize, getSearchAllList: getSearchAllStrataConsistencyOrRelativeDensityRockList} = useRequestResource({endpoint: "auth/borehole_log", query: "strataconsistencyorrelativedensityrock", resourceLabel: "strataconsistencyorrelativedensityrock List"})
    const { getResourceList: getStrataColorLightnessList, resourceList: stratacolorlightnessList, currentListSize: stratacolorlightnessListSize,  getSearchAllList: getSearchAllStrataColorLightnessList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratacolorlightness", resourceLabel: "stratacolorlightness List"})
    const { getResourceList: getStrataColorChromaList, resourceList: stratacolorchromaList, currentListSize: stratacolorchromaListSize, getSearchAllList: getSearchAllStrataColorChromaList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratacolorchroma", resourceLabel: "stratacolorchroma List"})
    const { getResourceList: getStrataColorHueList, resourceList: stratacolorhueList, currentListSize: stratacolorhueListSize, getSearchAllList: getSearchAllStrataColorHueList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratacolorhue", resourceLabel: "stratacolorhue List"})
    const { getResourceList: getStrataColorNonUniformList, resourceList: stratacolornonuniformList, currentListSize: stratacolornonuniformListSize, getSearchAllList: getSearchAllStrataColorNonUniformList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratacolornonuniform", resourceLabel: "stratacolornonuniform List"})
    const { getResourceList: getStrataColorNonUniformLightnessList, resourceList: stratacolornonuniformlightnessList, currentListSize: stratacolornonuniformlightnessListSize, getSearchAllList: getSearchAllStrataColorNonUniformLightnessList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratacolorlightness", resourceLabel: "stratacolornonuniformlightness List"})
    const { getResourceList: getStrataColorNonUniformChromaList, resourceList: stratacolornonuniformchromaList, currentListSize: stratacolornonuniformchromaListSize, getSearchAllList: getSearchAllStrataColorNonUniformChromaList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratacolorchroma", resourceLabel: "stratacolornonuniformchroma List"})
    const { getResourceList: getStrataColorNonUniformHueList, resourceList: stratacolornonuniformhueList, currentListSize: stratacolornonuniformhueListSize, getSearchAllList: getSearchAllStrataColorNonUniformHueList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratacolorhue", resourceLabel: "stratacolornonuniformhue List"})
    const { getResourceList: getMajorSoilsList, resourceList: majorsoilsList, currentListSize: majorsoilsListSize, getSearchAllList: getSearchAllMajorSoilsList} = useRequestResource({endpoint: "auth/borehole_log", query: "majorsoils", resourceLabel: "majorsoils List"})
    const { getResourceList: getMinorSoil1sList, resourceList: minorsoil1sList, currentListSize: minorsoil1sListSize, getSearchAllList: getSearchAllMinorSoil1sList} = useRequestResource({endpoint: "auth/borehole_log", query: "minorsoil1s", resourceLabel: "minorsoil1s List"})
    const { getResourceList: getMinorSoil2sList, resourceList: minorsoil2sList, currentListSize: minorsoil2sListSize, getSearchAllList: getSearchAllMinorSoil2sList} = useRequestResource({endpoint: "auth/borehole_log", query: "minorsoil2s", resourceLabel: "minorsoil2s List"})
    const { getResourceList: getStrataGeologicalFormationGrpList, resourceList: stratageologicalformationgrpList, currentListSize: stratageologicalformationgrpListSize, getSearchAllList: getSearchAllGeologicalGroupList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratageologicalformationgrp", resourceLabel: "stratageologicalformationgrp List"})
    const { filterList: geologicalFormationMemberList, filterResources: filtergeologicalFormationMembers } = useRequestResource({ endpoint: "auth/borehole_log", query: "stratumgeologicalformationmember", resourceLabel: "stratumgeologicalgroupmembers obtained" });
    const { filterList: geologicalFormationPlutonList, filterResources: filtergeologicalFormationPlutons } = useRequestResource({ endpoint: "auth/borehole_log", query: "stratumgeologicalformationpluton", resourceLabel: "stratumformationplutons obtained" });
    const { getResourceList: getStrataGeologicalClassificationList, resourceList: stratageologicalclassificationList, currentListSize: stratageologicalclassificationListSize, getSearchAllList: getSearchAllClassificationList} = useRequestResource({endpoint: "auth/borehole_log", query: "stratageologicalclassification", resourceLabel: "stratageologicalclassification List"})
    const { getResourceList: getStrataOthersList, resourceList: strataOthersList,  currentListSize: strataOthersListSize, getSearchAllList: getSearchAllStrataOthersList} = useRequestResource({endpoint: "auth/borehole_log", query: "strataothers", resourceLabel: "strataothers List"})
    

    const navigate = useNavigate();
    let offset = 25;
    const [ borehole, setBorehole ] = useState("")
    const [ pattern, setpattern ] = useState("")
    const [ trial_pit_and_hand_auger, settrial_pit_and_hand_auger ] = useState("")
    const [ trialPitAndHandAugerExp, setTrialPitAndHandAugerExp] = useState("")
    const [ consistency_or_relative_density_soil, setconsistency_or_relative_density_soil ] = useState("")
    const [ consistencyOrRelativeDensitySoilExp, setConsistencyOrRelativeDensitySoilExp] = useState("")
    const [ consistency_or_relative_density_rock, setconsistency_or_relative_density_rock ] = useState("")
    const [ consistencyOrRelativeDensityRockExp, setConsistencyOrRelativeDensityRockExp] = useState("")
    const [ color_lightness, setcolor_lightness ] = useState("")
    const [ colorLightnessExp, setColorLightnessExp] = useState("")
    const [ color_chroma, setcolor_chroma ] = useState("")
    const [ colorChromaExp, setColorChromaExp] = useState("")
    const [ color_hue, setcolor_hue ] = useState("")
    const [ colorHueExp, setColorHueExp ] = useState("")
    const [ color_nonuniform, setcolor_nonuniform ] = useState("")
    const [ colorNonUniformExp, setColorNonUniformExp ] = useState("")
    const [ color_nonuniform_lightness, setcolor_nonuniform_lightness ] = useState("")
    const [ colorNonUniformLightnessExp, setColorNonUniformLightnessExp ] = useState("")
    const [ color_nonuniform_chroma, setcolor_nonuniform_chroma ] = useState("")
    const [ colorNonUniformChromaExp, setColorNonUniformChromaExp ] = useState("")
    const [ color_nonuniform_hue, setcolor_nonuniform_hue ] = useState("")
    const [ colorNonUniformHueExp, setColorNonUniformHueExp ] = useState("")
    const [ major_soil_name, setmajor_soil_name ] = useState("")
    const [ majorSoilNameExp, setMajorSoilNameExp ] = useState("")
    const [ minor_soil1_name, setminor_soil1_name ] = useState("")
    const [ minorSoil1NameExp, setMinorSoil1NameExp ] = useState("")
    const [ minor_soil2_name, setminor_soil2_name ] = useState("")
    const [ minorSoil2NameExp, setMinorSoil2NameExp ] = useState("")
    const [ geological_formation_group, setgeological_formation_group ] = useState("")
    const [ geological_classification, setgeological_classification ] = useState("")
    const [ others, setothers ] = useState("")
    const [ othersExp, setOthersExp ] = useState("")
    const [boreholeExp, setBoreholeExp] = useState("")
    const [patternExp, setPatternExp] = useState("")
    const [classificationExp, setClassificationExp] = useState("")
    const [geologicalGroupExp, setGeologicalGroupExp] = useState("")

    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const [ showPatternInfiniteScroll, setPatternInfiniteScroll ] = useState(false)
    const [ showTrialHandInfiniteScroll, setshowTrialHandInfiniteScroll ] = useState(false)
    const [ showDensitySoilInfiniteScroll, setshowDensitySoilInfiniteScroll ] = useState(false)
    const [ showDensityRockInfiniteScroll, setshowDensityRockInfiniteScroll ] = useState(false)
    const [ showcolorlightnessInfiniteScroll, setcolorlightnessInfiniteScroll ] = useState(false)
    const [ showcolorchromaInfiniteScroll, setcolorchromaInfiniteScroll ] = useState(false)
    const [ showcolorhueInfiniteScroll, setcolorhueInfiniteScroll ] = useState(false)
    const [ showNonuniformcolorInfiniteScroll, setNonuniformcolorInfiniteScroll ] = useState(false)
    const [ showNonuniformLightnessInfiniteScroll, setNonuniformLightnessInfiniteScroll ] = useState(false)
    const [ showNonuniformchromaInfiniteScroll, setNonuniformchromaInfiniteScroll ] = useState(false)
    const [ showNonuniformhueInfiniteScroll, setNonuniformhueInfiniteScroll ] = useState(false)
    const [ showMajorSoilInfiniteScroll, setMajorSoilInfiniteScroll ] = useState(false)
    const [ showMinorSoil1InfiniteScroll, setMinorSoil1InfiniteScroll ] = useState(false)
    const [ showMinorSoil2InfiniteScroll, setMinorSoil2InfiniteScroll ] = useState(false)
    const [ showGeologicalFormationInfiniteScroll, setshowGeologicalFormationInfiniteScroll ] = useState(false)
    const [ showGeologicalClassificationInfiniteScroll, setshowGeologicalClassificationInfiniteScroll ] = useState(false)
    const [ showOthersInfiniteScroll, setOthersInfiniteScroll ] = useState(false)

    const boreholeRef = useRef();
    const boreholeInfiniteRef = useRef();
    const patternRef = useRef();
    const patternInfiniteRef = useRef();
    const trialRef = useRef();
    const trialInfiniteRef = useRef();
    const soilRef = useRef();
    const soilInfiniteRef = useRef();
    const rockRef = useRef();
    const rockInfiniteRef = useRef();
    const uniformLightnessRef = useRef();
    const uniformLightnessInfiniteRef = useRef();
    const uniformChromaRef = useRef();
    const uniformChromaInfiniteRef = useRef();
    const uniformHueRef = useRef();
    const uniformHueInfiniteRef = useRef();
    const nonuniformColorRef = useRef();
    const nonuniformColorInfiniteRef = useRef();
    const nonuniformLightnessRef = useRef();
    const nonuniformLightnessInfiniteRef = useRef();
    const nonuniformChromaRef = useRef();
    const nonuniformChromaInfiniteRef = useRef();
    const nonuniformHueRef = useRef();
    const nonuniformHueInfiniteRef = useRef();
    const majorSoilRef = useRef();
    const majorSoilInfiniteRef = useRef();
    const minorSoil1Ref = useRef();
    const minorSoil1InfiniteRef = useRef();
    const minorSoil2Ref = useRef();
    const minorSoil2InfiniteRef = useRef();
    const geologicalFormationRef = useRef();
    const geologicalFormationInfiniteRef = useRef();
    const geologicalClassificationRef = useRef();
    const geologicalClassificationInfiniteRef = useRef();
    const othersRef = useRef();
    const othersInfiniteRef = useRef();


    const [initialValues, setInitialValues] = useState({
        borehole: "",
        soil_layer_start_depth_meters: "",
        pattern: "",
        trial_pit_and_hand_auger: "",
        consistency_or_relative_density_rock: "",
        consistency_or_relative_density_soil: "",
        color_lightness: "",
        color_chroma: "",
        color_hue: "",
        color_nonuniform: "",
        color_nonuniform_lightness: "",
        color_nonuniform_chroma: "",
        color_nonuniform_hue: "",
        major_soil_name: "",
        minor_soil1_name: "",
        minor_soil2_name: "",
        rock_description: "",
        remarks: "",
        geological_formation_group: "",
        geological_formation_member: "",
        geological_formation_pluton: "",
        geological_classification: "",
        others: ""
    });
    
    const formatValues = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null : values.borehole,
            soil_layer_start_depth_meters: values.soil_layer_start_depth_meters === "" ? null : values.soil_layer_start_depth_meters,
            pattern: values.pattern === "" ? null : values.pattern,
            trial_pit_and_hand_auger: values.trial_pit_and_hand_auger === "" ? null : values.trial_pit_and_hand_auger,
            consistency_or_relative_density_rock: values.consistency_or_relative_density_rock === "" ? null : values.consistency_or_relative_density_rock,
            consistency_or_relative_density_soil: values.consistency_or_relative_density_soil === "" ? null : values.consistency_or_relative_density_soil,
            color_lightness: values.color_lightness === "" ? null : values.color_lightness,
            color_chroma: values.color_chroma === "" ? null : values.color_chroma,
            color_hue: values.color_hue === "" ? null : values.color_hue,
            color_nonuniform: values.color_nonuniform === "" ? null : values.color_nonuniform,
            color_nonuniform_lightness: values.color_nonuniform_lightness === "" ? null : values.color_nonuniform_lightness,
            color_non_uniform_chroma: values.color_nonuniform_chroma === "" ? null : values.color_nonuniform_chroma,
            color_nonuniform_hue: values.color_nonuniform_hue === "" ? null : values.color_nonuniform_hue,
            major_soil_name: values.major_soil_name === "" ? null : values.major_soil_name,
            minor_soil_name_1: values.minor_soil1_name === "" ? null : values.minor_soil1_name,
            minor_soil_name_2: values.minor_soil2_name === "" ? null : values.minor_soil2_name,
            rock_description: values.rock_description === "" ? null : values.rock_description,
            remarks: values.remarks === "" ? null : values.remarks,
            geological_formation_grp: values.geological_formation_group === "" ? null : values.geological_formation_group,
            geological_formation_member: values.geological_formation_member === "" ? null : values.geological_formation_member,
            geological_formation_pluton: values.geological_formation_pluton === "" ? null : values.geological_formation_pluton,
            geological_classification: values.geological_classification === "" ? null : values.geological_classification,
            others: values.others === "" ? null : values.others
        };
        return JSON.stringify(formattedValues);  
    }

    const handleSubmit = values => {
        addResource(formatValues(values), () => {
            navigate(`/stratumresults`)
        })
    }

    const handleSubmitReload = values => {
        addResource(formatValues(values), () => {
            window.location.reload();
        })
    }

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);


    useEffect(() => {
        if (resources) {
            setBorehole(`${resources.project_title} Borehole: ${resources.borehole_number}`)
            setpattern(`${resources.pattern_name}`)
            settrial_pit_and_hand_auger(resources.trial_pit_and_hand_auger_name)
            setconsistency_or_relative_density_rock(resources.consistency_or_relative_density_rock_label)
            setconsistency_or_relative_density_soil(resources.consistency_or_relative_density_soil_label)
            setcolor_lightness(resources.color_lightness_label)
            setcolor_chroma(resources.color_chroma_label)                
            setcolor_hue(resources.color_hue_label)
            setcolor_nonuniform(resources.color_nonuniform_label)
            setcolor_nonuniform_lightness(resources.color_nonuniform_lightness_label)
            setcolor_nonuniform_chroma(resources.color_non_uniform_chroma_label)
            setcolor_nonuniform_hue(resources.color_nonuniform_hue_label)
            setmajor_soil_name(resources.major_soil_name_description)
            setminor_soil1_name(resources.minor_soil_name_1_description)
            setminor_soil2_name(resources.minor_soil_name_2_description)
            setgeological_formation_group(resources.geological_formation_grp_label)
            filtergeologicalFormationMembers({formation_grp_id: resources.geological_formation_grp})
            filtergeologicalFormationPlutons({formation_grp_id: resources.geological_formation_grp})
            setgeological_classification(`${resources.geological_classification_label} | ${resources.geological_classification_description}`)
            setothers(resources.others_label)

            setInitialValues({
                borehole: resources.borehole === null ? "" : resources.borehole,
                // soil_layer_start_depth_meters: resources.soil_layer_start_depth_meters === null ? "" : resources.soil_layer_start_depth_meters,
                pattern: resources.pattern === null ? "" : resources.pattern,
                trial_pit_and_hand_auger: resources.trial_pit_and_hand_auger === null ? "" : resources.trial_pit_and_hand_auger,
                consistency_or_relative_density_rock: resources.consistency_or_relative_density_rock === null ? "" : resources.consistency_or_relative_density_rock,
                consistency_or_relative_density_soil: resources.consistency_or_relative_density_soil === null ? "" : resources.consistency_or_relative_density_soil,
                color_lightness: resources.color_lightness === null ? "" : resources.color_lightness,
                color_chroma: resources.color_chroma === null ? "" : resources.color_chroma,
                color_hue: resources.color_hue === null ? "" : resources.color_hue,
                color_nonuniform: resources.color_nonuniform === null ? "" : resources.color_nonuniform,
                color_nonuniform_lightness: resources.color_nonuniform_lightness === null ? "" : resources.color_nonuniform_lightness,
                color_nonuniform_chroma: resources.color_non_uniform_chroma === null ? "" : resources.color_non_uniform_chroma,
                color_nonuniform_hue: resources.color_nonuniform_hue === null ? "" : resources.color_nonuniform_hue,
                major_soil_name: resources.major_soil_name === null ? "" : resources.major_soil_name,
                minor_soil1_name: resources.minor_soil_name_1 === null ? "" : resources.minor_soil_name_1,
                minor_soil2_name: resources.minor_soil_name_2 === null ? "" : resources.minor_soil_name_2,
                rock_description: resources.rock_description === null ? "" : resources.rock_description,
                remarks: resources.remarks === null ? "" : resources.remarks,
                geological_formation_group: resources.geological_formation_grp === null ? "" : resources.geological_formation_grp,
                geological_formation_member: resources.geological_formation_member === null ? "" : resources.geological_formation_member,
                geological_formation_pluton: resources.geological_formation_pluton === null ? "" : resources.geological_formation_pluton,
                geological_classification: resources.geological_classification === null ? "" : resources.geological_classification,
                others: resources.others === null ? "" : resources.others
            })
        }
    }, [id, getResource, resources, filtergeologicalFormationMembers, filtergeologicalFormationPlutons]);

    const fetchBoreholeData = () => {
        if (boreholeExp && boreholeExp.trim() !== "") {
            getSearchAllBoreholeList(boreholeExp, offset)
        } else {
            getBoreholeList(offset)
        }
    }

    const searchAllBoreholes = (exp) => {
        setBoreholeExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllBoreholeList(exp)
        } else {
            setBorehole("")
            getBoreholeList(0)
        }
    }

    const fetchStrataPatternData = () => {
        if (patternExp && patternExp.trim() !== "") {
            getSearchAllPatternList(patternExp, offset)
        } else {
            getStataPatternList(offset)
        }
    }

    const searchAllPatterns = (exp) => {
        setPatternExp(exp)
        if (exp && exp.trim() !== "") {
            setpattern(exp)
            getSearchAllPatternList(exp)
        } else {
            setpattern("")
            getStataPatternList(0)
        }
    }

    const fetchStrataGeologicalClassificationData = () => {
        if (classificationExp && classificationExp.trim() !== "") {
            getSearchAllClassificationList(classificationExp, offset)
        } else {
            getStrataGeologicalClassificationList(offset)
        }
    }

    const searchAllClassifications = (exp) => {
        setClassificationExp(exp)
        if (exp && exp.trim() !== "") {
            
            setgeological_classification(exp)
            getSearchAllClassificationList(exp)
        } else {
            setgeological_classification("")
            getStrataGeologicalClassificationList(0)
        }
    }

    const fetchStrataTrialPithAndAugerData = () => {
        if (trialPitAndHandAugerExp && trialPitAndHandAugerExp.trim() !== "") {
            getSearchAllStrataTrialPithAndAugerList(trialPitAndHandAugerExp, offset)
        } else {
            getStrataTrialPithAndAugerList(offset)
        }
    }

    const searchAllStrataTrialPithAndAugerData = (exp) => {
        setTrialPitAndHandAugerExp(exp)
        if (exp && exp.trim() !== "") {
            settrial_pit_and_hand_auger(exp)
            getSearchAllStrataTrialPithAndAugerList(exp)
        } else {
            settrial_pit_and_hand_auger("")
            getStrataTrialPithAndAugerList(0)
            
        }
        
    }

    const fetchStrataConsistencyOrRelativeDensitySoilData = () => {
        if (consistencyOrRelativeDensitySoilExp && consistencyOrRelativeDensitySoilExp.trim() !== "") {
            getSearchAllStrataConsistencyOrRelativeDensitySoilList(consistencyOrRelativeDensitySoilExp, offset)
        } else {
            getStrataConsistencyOrRelativeDensitySoilList(offset)
        }
    }

    const searchAllStrataConsistencyOrRelativeDensitySoilData = (exp) => {
        setConsistencyOrRelativeDensitySoilExp(exp)
        if (exp && exp.trim() !== "") {
            setconsistency_or_relative_density_soil(exp)
            getSearchAllStrataConsistencyOrRelativeDensitySoilList(exp)
        } else {
            setconsistency_or_relative_density_soil("")
            getStrataConsistencyOrRelativeDensitySoilList(0)
            
        }
    }

    const fetchStrataConsistencyOrRelativeDensityRockData = () => {
        if (consistencyOrRelativeDensityRockExp && consistencyOrRelativeDensityRockExp.trim() !== "") {
            getSearchAllStrataConsistencyOrRelativeDensityRockList(consistencyOrRelativeDensityRockExp, offset)
        } else {
            getStrataConsistencyOrRelativeDensityRockList(offset)
        }
    }

    const searchAllStrataConsistencyOrRelativeDensityRockData = (exp) => {
        setConsistencyOrRelativeDensityRockExp(exp)
        if (exp && exp.trim() !== "") {
            setconsistency_or_relative_density_rock(exp)
            getSearchAllStrataConsistencyOrRelativeDensityRockList(exp)
        } else {
            setconsistency_or_relative_density_rock("")
            getStrataConsistencyOrRelativeDensityRockList(0)
            
        }
    }

    const fetchStrataColorLightnessData = () => {
        if (colorLightnessExp && colorLightnessExp.trim() !== "") {
            getSearchAllStrataColorLightnessList(colorLightnessExp, offset)
        } else {
            getStrataColorLightnessList(offset)
        }
    }

    const searchAllStrataColorLightnessData = (exp) => {
        setColorLightnessExp(exp)
        if (exp && exp.trim() !== "") {
            setcolor_lightness(exp)
            getSearchAllStrataColorLightnessList(exp)
        } else {
            setcolor_lightness(exp)
            getStrataColorLightnessList(0)
        }
    }

    const fetchStrataColorChromaData = () => {
        if (colorChromaExp && colorChromaExp.trim() !== "") {
            getSearchAllStrataColorChromaList(colorChromaExp, offset)
        } else {
            getStrataColorChromaList(offset)
        }
    }

    const searchAllStrataColorChromaData = (exp) => {
        setColorChromaExp(exp)
        if (exp && exp.trim() !== "") {
            setcolor_chroma(exp)
            getSearchAllStrataColorChromaList(exp)
        } else {
            setcolor_chroma(exp)
            getStrataColorChromaList(0)
            
        }
        
    }

    const fetchStrataColorHueData = () => {
        if (colorHueExp && colorHueExp.trim() !== "") {
            getSearchAllStrataColorHueList(colorHueExp, offset)
        } else {
            getStrataColorHueList(offset)
        }
    }

    const searchAllStrataColorHueData = (exp) => {
        setColorHueExp(exp)
        if (exp && exp.trim() !== "") {
            setcolor_hue(exp)
            getSearchAllStrataColorHueList(exp)
        } else {
            setcolor_hue(exp)
            getStrataColorHueList(0)
            
        }
    }

    const fetchStrataColorNonUniformData = () => {
        if (colorNonUniformExp && colorNonUniformExp.trim() !== "") {
            getSearchAllStrataColorNonUniformList(colorNonUniformExp, offset)
        } else {
            getStrataColorNonUniformList(offset)
        }
    }

    const searchAllStrataColorNonUniformData = (exp) => {
        setColorNonUniformExp(exp)
        if (exp && exp.trim() !== "") {
            setcolor_nonuniform(exp)
            getSearchAllStrataColorNonUniformList(exp)
        } else {
            setcolor_nonuniform(exp)
            getStrataColorNonUniformList(0)
            
        }
    }

    const fetchStrataColorNonUniformLightnessData = () => {
        if (colorNonUniformLightnessExp && colorNonUniformLightnessExp.trim() !== "") {
            getSearchAllStrataColorNonUniformLightnessList(colorNonUniformLightnessExp, offset)
        } else {
            getStrataColorNonUniformLightnessList(offset)
        }
    }

    const searchAllStrataColorNonUniformLightnessData = (exp) => {
        setColorNonUniformLightnessExp(exp)
        if (exp && exp.trim() !== "") {
            setcolor_nonuniform_lightness(exp)
            getSearchAllStrataColorNonUniformLightnessList(exp)
        } else {
            setcolor_nonuniform_lightness(exp)
            getStrataColorNonUniformLightnessList(0)
        }
    }    

    const fetchStrataColorNonUniformChromaData = () => {
        if (colorNonUniformChromaExp && colorNonUniformChromaExp.trim() !== "") {
            getSearchAllStrataColorNonUniformChromaList(colorNonUniformChromaExp, offset)
        } else {
            getStrataColorNonUniformChromaList(offset)
        }
    }

    const searchAllStrataColorNonUniformChromaData = (exp) => {
        setColorNonUniformChromaExp(exp)
        if (exp && exp.trim() !== "") {
            setcolor_nonuniform_chroma(exp)
            getSearchAllStrataColorNonUniformChromaList(exp)
        } else {
            setcolor_nonuniform_chroma(exp)
            getStrataColorNonUniformChromaList(0)
        }
    }

    const fetchStrataColorNonUniformHueData = () => {
        if (colorNonUniformHueExp && colorNonUniformHueExp.trim() !== "") {
            getSearchAllStrataColorNonUniformHueList(colorNonUniformHueExp, offset)
        } else {
            getStrataColorNonUniformHueList(offset)
        }
    }

    const searchAllStrataColorNonUniformHueData = (exp) => {
        setColorNonUniformHueExp(exp)
        if (exp && exp.trim() !== "") {
            setcolor_nonuniform_hue(exp)
            getSearchAllStrataColorNonUniformHueList(exp)
        } else {
            setcolor_nonuniform_hue(exp)
            getStrataColorNonUniformHueList(0)
        }
    }
    const fetchMajorSoilsData = () => {
        if (majorSoilNameExp && majorSoilNameExp.trim() !== "") {
            getSearchAllMajorSoilsList(majorSoilNameExp, offset)
        } else {
            getMajorSoilsList(offset)
        }
    }

    const searchAllMajorSoilsData = (exp) => {
        setMajorSoilNameExp(exp)
        if (exp && exp.trim() !== "") {
            setmajor_soil_name(exp)
            getSearchAllMajorSoilsList(exp)
        } else {
            setmajor_soil_name("")
            getMajorSoilsList(0)
            
        }
        
    }

    const fetchMinorSoil1sData = () => {
        if (minorSoil1NameExp && minorSoil1NameExp.trim() !== "") {
            getSearchAllMinorSoil1sList(minorSoil1NameExp, offset)
        } else {
            getMinorSoil1sList(offset)
        }
    }

    const searchAllMinorSoil1sData = (exp) => {
        setMinorSoil1NameExp(exp)
        if (exp && exp.trim() !== "") {
            setminor_soil1_name(exp)
            getSearchAllMinorSoil1sList(exp)
        } else {
            setminor_soil1_name("")
            getMinorSoil1sList(0)
            
        }
        
    }

    const fetchMinorSoil2sData = () => {
        if (minorSoil2NameExp && minorSoil2NameExp.trim() !== "") {
            getSearchAllMinorSoil2sList(minorSoil2NameExp, offset)
        } else {
            getMinorSoil2sList(offset)
        }
    }

    const searchAllMinorSoil2sData = (exp) => {
        setMinorSoil2NameExp(exp)
        if (exp && exp.trim() !== "") {
            setminor_soil2_name(exp)
            getSearchAllMinorSoil2sList(exp)
        } else {
            setminor_soil2_name("")
            getMinorSoil2sList(0)
            
        }
        
    }

    const fetchStrataGeologicalFormationGrpData = () => {
        if (geologicalGroupExp && geologicalGroupExp.trim() !== "") {
            getSearchAllGeologicalGroupList(geologicalGroupExp, offset)
        } else {
            getStrataGeologicalFormationGrpList(offset)
        }
    }

    const searchAllGeologicalGroupData = (exp) => {
        setGeologicalGroupExp(exp)
        if (exp && exp.trim() !== "") {
            setgeological_formation_group(exp)
            getSearchAllGeologicalGroupList(exp)
        } else {
            setgeological_formation_group("")
            getStrataGeologicalFormationGrpList(0)
        }
    }

    const fetchStrataOthersData = () => {
        if (othersExp && othersExp.trim() !== "") {
            getSearchAllStrataOthersList(othersExp, offset)
        } else {
            getStrataOthersList(offset)
        }
    }

    const searchAllStrataOthersData = (exp) => {
        setOthersExp(exp)
        if (exp && exp.trim() !== "") {
            setothers(exp)
            getSearchAllStrataOthersList(exp)
        } else {
            setothers("")
            getStrataOthersList(0)
            
        }
        
    }
    useEffect(() => {
        getBoreholeList();
        getStataPatternList();
        getMajorSoilsList()
        getMinorSoil1sList()
        getMinorSoil2sList()
        getStrataColorChromaList()
        getStrataColorHueList()
        getStrataColorLightnessList()
        getStrataColorNonUniformList()
        getStrataColorNonUniformLightnessList()
        getStrataColorNonUniformChromaList()
        getStrataColorNonUniformHueList()
        getStrataConsistencyOrRelativeDensityRockList()
        getStrataConsistencyOrRelativeDensitySoilList()
        getStrataGeologicalClassificationList()
        getStrataGeologicalFormationGrpList()
        getStrataTrialPithAndAugerList()
        getStrataOthersList()
    }, [getBoreholeList, getStataPatternList, getMajorSoilsList, getMinorSoil1sList, getMinorSoil2sList, 
        getStrataColorChromaList, getStrataColorHueList, getStrataColorLightnessList, getStrataColorNonUniformList, getStrataColorNonUniformLightnessList, 
        getStrataColorNonUniformChromaList, getStrataColorNonUniformHueList, getStrataConsistencyOrRelativeDensityRockList,
        getStrataConsistencyOrRelativeDensitySoilList, getStrataGeologicalClassificationList, getStrataGeologicalFormationGrpList, getStrataTrialPithAndAugerList, 
        getStrataOthersList])

    function getMembersAndPlutons(id) {
        const values = {
            formation_grp_id: id
        }

        filtergeologicalFormationMembers(values)
        filtergeologicalFormationPlutons(values)
    }

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (boreholeInfiniteRef.current && !boreholeInfiniteRef.current.contains(event.target)) {
            if (boreholeRef.current && boreholeRef.current.contains(event.target)) {
                return;
            }
            setInfiniteScroll(false)
        }
        
        if (patternInfiniteRef.current && !patternInfiniteRef.current.contains(event.target)) {
            if (patternRef.current && patternRef.current.contains(event.target)) {
                return;
            }
            setPatternInfiniteScroll(false)
        }
        
        if (trialInfiniteRef.current && !trialInfiniteRef.current.contains(event.target)) {
            if (trialRef.current && trialRef.current.contains(event.target)) {
                return;
            }
            setshowTrialHandInfiniteScroll(false)
        }
        
        if (soilInfiniteRef.current && !soilInfiniteRef.current.contains(event.target)) {
            if (soilRef.current && soilRef.current.contains(event.target)) {
                return;
            }
            setshowDensitySoilInfiniteScroll(false)
        }
        
        if (rockInfiniteRef.current && !rockInfiniteRef.current.contains(event.target)) {
            if (rockRef.current && rockRef.current.contains(event.target)) {
                return;
            }
            setshowDensityRockInfiniteScroll(false)
        }
        
        if (uniformLightnessInfiniteRef.current && !uniformLightnessInfiniteRef.current.contains(event.target)) {
            if (uniformLightnessRef.current && uniformLightnessRef.current.contains(event.target)) {
                return;
            }
            setcolorlightnessInfiniteScroll(false)
        }
        
        if (uniformChromaInfiniteRef.current && !uniformChromaInfiniteRef.current.contains(event.target)) {
            if (uniformChromaRef.current && uniformChromaRef.current.contains(event.target)) {
                return;
            }
            setcolorchromaInfiniteScroll(false)
        }
        
        if (uniformHueInfiniteRef.current && !uniformHueInfiniteRef.current.contains(event.target)) {
            if (uniformHueRef.current && uniformHueRef.current.contains(event.target)) {
                return;
            }
            setcolorhueInfiniteScroll(false)
        }
        
        if (nonuniformColorInfiniteRef.current && !nonuniformColorInfiniteRef.current.contains(event.target)) {
            if (nonuniformColorRef.current && nonuniformColorRef.current.contains(event.target)) {
                return;
            }
            setNonuniformcolorInfiniteScroll(false)
        }
        
        if (nonuniformLightnessInfiniteRef.current && !nonuniformLightnessInfiniteRef.current.contains(event.target)) {
            if (nonuniformLightnessRef.current && nonuniformLightnessRef.current.contains(event.target)) {
                return;
            }
            setNonuniformLightnessInfiniteScroll(false)
        }
        
        if (nonuniformChromaInfiniteRef.current && !nonuniformChromaInfiniteRef.current.contains(event.target)) {
            if (nonuniformChromaRef.current && nonuniformChromaRef.current.contains(event.target)) {
                return;
            }
            setNonuniformchromaInfiniteScroll(false)
        }
        
        if (nonuniformHueInfiniteRef.current && !nonuniformHueInfiniteRef.current.contains(event.target)) {
            if (nonuniformHueRef.current && nonuniformHueRef.current.contains(event.target)) {
                return;
            }
            setNonuniformhueInfiniteScroll(false)
        }
        
        if (majorSoilInfiniteRef.current && !majorSoilInfiniteRef.current.contains(event.target)) {
            if (majorSoilRef.current && majorSoilRef.current.contains(event.target)) {
                return;
            }
            setMajorSoilInfiniteScroll(false)
        }
        
        if (minorSoil1InfiniteRef.current && !minorSoil1InfiniteRef.current.contains(event.target)) {
            if (minorSoil1Ref.current && minorSoil1Ref.current.contains(event.target)) {
                return;
            }
            setMinorSoil1InfiniteScroll(false)
        }
        
        if (minorSoil2InfiniteRef.current && !minorSoil2InfiniteRef.current.contains(event.target)) {
            if (minorSoil2Ref.current && minorSoil2Ref.current.contains(event.target)) {
                return;
            }
            setMinorSoil2InfiniteScroll(false)
        }
        
        if (geologicalFormationInfiniteRef.current && !geologicalFormationInfiniteRef.current.contains(event.target)) {
            if (geologicalFormationRef.current && geologicalFormationRef.current.contains(event.target)) {
                return;
            }
            setshowGeologicalFormationInfiniteScroll(false)
        }
        
        if (geologicalClassificationInfiniteRef.current && !geologicalClassificationInfiniteRef.current.contains(event.target)) {
            if (geologicalClassificationRef.current && geologicalClassificationRef.current.contains(event.target)) {
                return;
            }
            setshowGeologicalClassificationInfiniteScroll(false)
        }
        
        if (othersInfiniteRef.current && !othersInfiniteRef.current.contains(event.target)) {
            if (othersRef.current && othersRef.current.contains(event.target)) {
                return;
            }
            setOthersInfiniteScroll(false)
        }
        
      };

    return (

        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
                
            }}>
                <Typography variant="h6" mh={4}>
                    Create Stratum Result
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAllBoreholes(e.target.value)}}
                                                ref={boreholeRef}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={boreholeInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={boreholeList.results.length}
                                                    next={fetchBoreholeData}
                                                    hasMore={boreholeListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {boreholeList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="soil_layer_start_depth_meters"
                                                label="Soil layer start depth meters"
                                                inputMode='numeric'
                                                {...formik.getFieldProps('soil_layer_start_depth_meters')}
                                                error={formik.touched.soil_layer_start_depth_meters && Boolean(formik.errors.soil_layer_start_depth_meters)}
                                                helperText={formik.touched.soil_layer_start_depth_meters && formik.errors.soil_layer_start_depth_meters}
                                            />
                                        </Grid>                                        
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="pattern"
                                                label="Pattern"
                                                {...formik.getFieldProps('pattern')}
                                                value={pattern}
                                                onChange={(e) => {formik.setFieldValue("pattern", ""); searchAllPatterns(e.target.value)}}
                                                ref={patternRef}
                                                onClick={() => setPatternInfiniteScroll(true)}
                                                error={(formik.touched.pattern) && Boolean(formik.errors.pattern)}
                                                helperText={(formik.touched.pattern) && formik.errors.pattern}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showPatternInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={patternInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={strataPatternList.results.length}
                                                    next={fetchStrataPatternData}
                                                    hasMore={strataPatternListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {strataPatternList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("pattern", option.id)
                                                                formik.setFieldTouched("pattern", false)
                                                                formik.setFieldError("pattern", "")
                                                                setpattern(`${option.label} : ${option.description}`)
                                                                setPatternInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label} : {option.description}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="trial_pit_and_hand_auger"
                                                label="Trial pit and hand auger"
                                                {...formik.getFieldProps('trial_pit_and_hand_auger')}
                                                value={trial_pit_and_hand_auger}
                                                onChange={(e) => {formik.setFieldValue("trial_pit_and_hand_auger", ""); searchAllStrataTrialPithAndAugerData(e.target.value)}}
                                                ref={trialRef}
                                                onClick={() => setshowTrialHandInfiniteScroll(true)}
                                                error={(formik.touched.trial_pit_and_hand_auger) && Boolean(formik.errors.trial_pit_and_hand_auger)}
                                                helperText={(formik.touched.trial_pit_and_hand_auger) && formik.errors.trial_pit_and_hand_auger}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showTrialHandInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={trialInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratatrialpithandaugerList.results.length}
                                                    next={fetchStrataTrialPithAndAugerData}
                                                    hasMore={stratatrialpithandaugerListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratatrialpithandaugerList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("trial_pit_and_hand_auger", option.id)
                                                                formik.setFieldTouched("trial_pit_and_hand_auger", false)
                                                                formik.setFieldError("trial_pit_and_hand_auger", "")
                                                                settrial_pit_and_hand_auger(`Trial Pit (${option.trial_pit_length_meters}x${option.trial_pit_breadth_meters}x${option.trial_pit_depth_meters}m) Hand Auger (${option.hand_auger_start_depth_meters}-${option.hand_auger_end_depth_meters}m)`)
                                                                setshowTrialHandInfiniteScroll(false)
                                                            }
                                                        }>
                                                            Trial Pit ({option.trial_pit_length_meters}x{option.trial_pit_breadth_meters}x{option.trial_pit_depth_meters}m) Hand Auger ({option.hand_auger_start_depth_meters}-{option.hand_auger_end_depth_meters}m)
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="consistency_or_relative_density_soil"
                                                label="Consistency or relative density soil"
                                                {...formik.getFieldProps('consistency_or_relative_density_soil')}
                                                value={consistency_or_relative_density_soil}
                                                onChange={(e) => {formik.setFieldValue("consistency_or_relative_density_soil", ""); searchAllStrataConsistencyOrRelativeDensitySoilData(e.target.value)}}
                                                ref={soilRef}
                                                onClick={() => setshowDensitySoilInfiniteScroll(true)}
                                                error={(formik.touched.consistency_or_relative_density_soil) && Boolean(formik.errors.consistency_or_relative_density_soil)}
                                                helperText={(formik.touched.consistency_or_relative_density_soil) && formik.errors.consistency_or_relative_density_soil}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showDensitySoilInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={soilInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={strataconsistencyorrelativedensitysoilList.results.length}
                                                    next={fetchStrataConsistencyOrRelativeDensitySoilData}
                                                    hasMore={strataconsistencyorrelativedensitysoilListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {strataconsistencyorrelativedensitysoilList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("consistency_or_relative_density_soil", option.id)
                                                                formik.setFieldTouched("consistency_or_relative_density_soil", false)
                                                                formik.setFieldError("consistency_or_relative_density_soil", "")
                                                                setconsistency_or_relative_density_soil(`${option.label}`)
                                                                setshowDensitySoilInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="consistency_or_relative_density_rock"
                                                label="Consistency or relative density rock"
                                                {...formik.getFieldProps('consistency_or_relative_density_rock')}
                                                value={consistency_or_relative_density_rock}
                                                onChange={(e) => {formik.setFieldValue("consistency_or_relative_density_rock", ""); searchAllStrataConsistencyOrRelativeDensityRockData(e.target.value)}}
                                                ref={rockRef}
                                                onClick={() => setshowDensityRockInfiniteScroll(true)}
                                                error={(formik.touched.consistency_or_relative_density_rock) && Boolean(formik.errors.consistency_or_relative_density_rock)}
                                                helperText={(formik.touched.consistency_or_relative_density_rock) && formik.errors.consistency_or_relative_density_rock}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showDensityRockInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={rockInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={strataconsistencyorrelativedensityrockList.results.length}
                                                    next={fetchStrataConsistencyOrRelativeDensityRockData}
                                                    hasMore={strataconsistencyorrelativedensityrockListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {strataconsistencyorrelativedensityrockList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("consistency_or_relative_density_rock", option.id)
                                                                formik.setFieldTouched("consistency_or_relative_density_rock", false)
                                                                formik.setFieldError("consistency_or_relative_density_rock", "")
                                                                setconsistency_or_relative_density_rock(`${option.label}`)
                                                                setshowDensityRockInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="color_lightness"
                                                label="Color lightness"
                                                {...formik.getFieldProps('color_lightness')}
                                                value={color_lightness}
                                                onChange={(e) => {formik.setFieldValue("color_lightness", ""); searchAllStrataColorLightnessData(e.target.value)}}
                                                ref={uniformLightnessRef}
                                                onClick={() => setcolorlightnessInfiniteScroll(true)}
                                                error={(formik.touched.color_lightness) && Boolean(formik.errors.color_lightness)}
                                                helperText={(formik.touched.color_lightness) && formik.errors.color_lightness}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showcolorlightnessInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={uniformLightnessInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratacolorlightnessList.results.length}
                                                    next={fetchStrataColorLightnessData}
                                                    hasMore={stratacolorlightnessListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratacolorlightnessList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("color_lightness", option.id)
                                                                formik.setFieldTouched("color_lightness", false)
                                                                formik.setFieldError("color_lightness", "")
                                                                setcolor_lightness(`${option.label}`)
                                                                setcolorlightnessInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="color_chroma"
                                                label="Color chroma"
                                                {...formik.getFieldProps('color_chroma')}
                                                value={color_chroma}
                                                onChange={(e) => {formik.setFieldValue("color_chroma", ""); searchAllStrataColorChromaData(e.target.value)}}
                                                ref={uniformChromaRef}
                                                onClick={() => setcolorchromaInfiniteScroll(true)}
                                                error={(formik.touched.color_chroma) && Boolean(formik.errors.color_chroma)}
                                                helperText={(formik.touched.color_chroma) && formik.errors.color_chroma}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showcolorchromaInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={uniformChromaInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratacolorchromaList.results.length}
                                                    next={fetchStrataColorChromaData}
                                                    hasMore={stratacolorchromaListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratacolorchromaList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("color_chroma", option.id)
                                                                formik.setFieldTouched("color_chroma", false)
                                                                formik.setFieldError("color_chroma", "")
                                                                setcolor_chroma(`${option.label}`)
                                                                setcolorchromaInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="color_hue"
                                                label="Color hue"
                                                {...formik.getFieldProps('color_hue')}
                                                value={color_hue}
                                                onChange={(e) => {formik.setFieldValue("color_hue", ""); searchAllStrataColorHueData(e.target.value)}}
                                                ref={uniformHueRef}
                                                onClick={() => setcolorhueInfiniteScroll(true)}
                                                error={(formik.touched.color_hue) && Boolean(formik.errors.color_hue)}
                                                helperText={(formik.touched.color_hue) && formik.errors.color_hue}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showcolorhueInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={uniformHueInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratacolorhueList.results.length}
                                                    next={fetchStrataColorHueData}
                                                    hasMore={stratacolorhueListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratacolorhueList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("color_hue", option.id)
                                                                formik.setFieldTouched("color_hue", false)
                                                                formik.setFieldError("color_hue", "")
                                                                setcolor_hue(`${option.label}`)
                                                                setcolorhueInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="color_nonuniform"
                                                label="Color non uniform"
                                                {...formik.getFieldProps('color_nonuniform')}
                                                value={color_nonuniform}
                                                onChange={(e) => {formik.setFieldValue("color_nonuniform", ""); searchAllStrataColorNonUniformData(e.target.value)}}
                                                ref={nonuniformColorRef}
                                                onClick={() => setNonuniformcolorInfiniteScroll(true)}
                                                error={(formik.touched.color_nonuniform) && Boolean(formik.errors.color_nonuniform)}
                                                helperText={(formik.touched.color_nonuniform) && formik.errors.color_nonuniform}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showNonuniformcolorInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={nonuniformColorInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratacolornonuniformList.results.length}
                                                    next={fetchStrataColorNonUniformData}
                                                    hasMore={stratacolornonuniformListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratacolornonuniformList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("color_nonuniform", option.id)
                                                                formik.setFieldTouched("color_nonuniform", false)
                                                                formik.setFieldError("color_nonuniform", "")
                                                                setcolor_nonuniform(`${option.label}`)
                                                                setNonuniformcolorInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="color_nonuniform_lightness"
                                                label="Color non uniform lightness"
                                                {...formik.getFieldProps('color_nonuniform_lightness')}
                                                value={color_nonuniform_lightness}
                                                onChange={(e) => {formik.setFieldValue("color_nonuniform_lightness", ""); searchAllStrataColorNonUniformLightnessData(e.target.value)}}
                                                ref={nonuniformLightnessRef}
                                                onClick={() => setNonuniformLightnessInfiniteScroll(true)}
                                                error={(formik.touched.color_nonuniform_lightness) && Boolean(formik.errors.color_nonuniform_lightness)}
                                                helperText={(formik.touched.color_nonuniform_lightness) && formik.errors.color_nonuniform_lightness}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showNonuniformLightnessInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={nonuniformLightnessInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratacolornonuniformlightnessList.results.length}
                                                    next={fetchStrataColorNonUniformLightnessData}
                                                    hasMore={stratacolornonuniformlightnessListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratacolornonuniformlightnessList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("color_nonuniform_lightness", option.id)
                                                                formik.setFieldTouched("color_nonuniform_lightness", false)
                                                                formik.setFieldError("color_nonuniform_lightness", "")
                                                                setcolor_nonuniform_lightness(`${option.label}`)
                                                                setNonuniformLightnessInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="color_nonuniform_chroma"
                                                label="Color non uniform chroma"
                                                {...formik.getFieldProps('color_nonuniform_chroma')}
                                                value={color_nonuniform_chroma}
                                                onChange={(e) => {formik.setFieldValue("color_nonuniform_chroma", ""); searchAllStrataColorNonUniformChromaData(e.target.value)}}
                                                ref={nonuniformChromaRef}
                                                onClick={() => setNonuniformchromaInfiniteScroll(true)}
                                                error={(formik.touched.color_nonuniform_chroma) && Boolean(formik.errors.color_nonuniform_chroma)}
                                                helperText={(formik.touched.color_nonuniform_chroma) && formik.errors.color_nonuniform_chroma}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showNonuniformchromaInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={nonuniformChromaInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratacolornonuniformchromaList.results.length}
                                                    next={fetchStrataColorNonUniformChromaData}
                                                    hasMore={stratacolornonuniformchromaListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratacolornonuniformchromaList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("color_nonuniform_chroma", option.id)
                                                                formik.setFieldTouched("color_nonuniform_chroma", false)
                                                                formik.setFieldError("color_nonuniform_chroma", "")
                                                                setcolor_nonuniform_chroma(`${option.label}`)
                                                                setNonuniformchromaInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="color_nonuniform_hue"
                                                label="Color non uniform Hue"
                                                {...formik.getFieldProps('color_nonuniform_hue')}
                                                value={color_nonuniform_hue}
                                                onChange={(e) => {formik.setFieldValue("color_nonuniform_hue", ""); searchAllStrataColorNonUniformHueData(e.target.value)}}
                                                ref={nonuniformHueRef}
                                                onClick={() => setNonuniformhueInfiniteScroll(true)}
                                                error={(formik.touched.color_nonuniform_hue) && Boolean(formik.errors.color_nonuniform_hue)}
                                                helperText={(formik.touched.color_nonuniform_hue) && formik.errors.color_nonuniform_hue}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showNonuniformhueInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={nonuniformHueInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratacolornonuniformhueList.results.length}
                                                    next={fetchStrataColorNonUniformHueData}
                                                    hasMore={stratacolornonuniformhueListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratacolornonuniformhueList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("color_nonuniform_hue", option.id)
                                                                formik.setFieldTouched("color_nonuniform_hue", false)
                                                                formik.setFieldError("color_nonuniform_hue", "")
                                                                setcolor_nonuniform_hue(`${option.label}`)
                                                                setNonuniformhueInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="major_soil_name"
                                                label="Major Soil Name"
                                                {...formik.getFieldProps('major_soil_name')}
                                                value={major_soil_name}
                                                onChange={(e) => {formik.setFieldValue("major_soil_name", ""); searchAllMajorSoilsData(e.target.value)}}
                                                ref={majorSoilRef}
                                                onClick={() => setMajorSoilInfiniteScroll(true)}
                                                error={(formik.touched.major_soil_name) && Boolean(formik.errors.major_soil_name)}
                                                helperText={(formik.touched.major_soil_name) && formik.errors.major_soil_name}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showMajorSoilInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={majorSoilInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={majorsoilsList.results.length}
                                                    next={fetchMajorSoilsData}
                                                    hasMore={majorsoilsListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {majorsoilsList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("major_soil_name", option.id)
                                                                formik.setFieldTouched("major_soil_name", false)
                                                                formik.setFieldError("major_soil_name", "")
                                                                setmajor_soil_name(`${option.description}`)
                                                                setMajorSoilInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.description}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="minor_soil1_name"
                                                label="Minor Soil 1 Name"
                                                {...formik.getFieldProps('minor_soil1_name')}
                                                value={minor_soil1_name}
                                                onChange={(e) => {formik.setFieldValue("minor_soil1_name", ""); searchAllMinorSoil1sData(e.target.value)}}
                                                ref={minorSoil1Ref}
                                                onClick={() => setMinorSoil1InfiniteScroll(true)}
                                                error={(formik.touched.minor_soil1_name) && Boolean(formik.errors.minor_soil1_name)}
                                                helperText={(formik.touched.minor_soil1_name) && formik.errors.minor_soil1_name}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showMinorSoil1InfiniteScroll ? <Grid item xs={12}>
                                        <div ref={minorSoil1InfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={minorsoil1sList.results.length}
                                                    next={fetchMinorSoil1sData}
                                                    hasMore={minorsoil1sListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {minorsoil1sList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("minor_soil1_name", option.id)
                                                                formik.setFieldTouched("minor_soil1_name", false)
                                                                formik.setFieldError("minor_soil1_name", "")
                                                                setminor_soil1_name(`${option.description}`)
                                                                setMinorSoil1InfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.description}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="minor_soil2_name"
                                                label="Minor Soil 2 Name"
                                                {...formik.getFieldProps('minor_soil2_name')}
                                                value={minor_soil2_name}
                                                onChange={(e) => {formik.setFieldValue("minor_soil2_name", ""); searchAllMinorSoil2sData(e.target.value)}}
                                                ref={minorSoil2Ref}
                                                onClick={() => setMinorSoil2InfiniteScroll(true)}
                                                error={(formik.touched.minor_soil2_name) && Boolean(formik.errors.minor_soil2_name)}
                                                helperText={(formik.touched.minor_soil2_name) && formik.errors.minor_soil2_name}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showMinorSoil2InfiniteScroll ? <Grid item xs={12}>
                                        <div ref={minorSoil2InfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={minorsoil2sList.results.length}
                                                    next={fetchMinorSoil2sData}
                                                    hasMore={minorsoil2sListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {minorsoil2sList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("minor_soil2_name", option.id)
                                                                formik.setFieldTouched("minor_soil2_name", false)
                                                                formik.setFieldError("minor_soil2_name", "")
                                                                setminor_soil2_name(`${option.description}`)
                                                                setMinorSoil2InfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.description}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="geological_formation_group"
                                                label="Geological Formation Group"
                                                {...formik.getFieldProps('geological_formation_group')}
                                                value={geological_formation_group}
                                                ref={geologicalFormationRef}
                                                onChange={(e) => {formik.setFieldValue("geological_formation_group", ""); searchAllGeologicalGroupData(e.target.value)}}
                                                onClick={() => setshowGeologicalFormationInfiniteScroll(true)}
                                                error={(formik.touched.geological_formation_group) && Boolean(formik.errors.geological_formation_group)}
                                                helperText={(formik.touched.geological_formation_group) && formik.errors.geological_formation_group}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showGeologicalFormationInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={geologicalFormationInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratageologicalformationgrpList.results.length}
                                                    next={fetchStrataGeologicalFormationGrpData}
                                                    hasMore={stratageologicalformationgrpListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratageologicalformationgrpList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("geological_formation_group", option.id)
                                                                formik.setFieldTouched("geological_formation_group", false)
                                                                formik.setFieldError("geological_formation_group", "")
                                                                formik.setFieldValue("geological_formation_member", "")
                                                                formik.setFieldValue("geological_formation_pluton", "")
                                                                setgeological_formation_group((option.code === null | option.code === "") ? `${option.label}` : `${option.code} | ${option.label}`)
                                                                setshowGeologicalFormationInfiniteScroll(false)
                                                                getMembersAndPlutons(option.id)
                                                            }
                                                        }>
                                                            {(option.code === null | option.code === "")  ? option.label : `${option.code} | ${option.label}`}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        {
                                            geologicalFormationMemberList.results.length > 0 ?
                                            <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="geological_formation_member"
                                                label="Geological formation member"
                                                {...formik.getFieldProps('geological_formation_member')}
                                                select
                                                value={formik.values.geological_formation_member}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.geological_formation_member && Boolean(formik.errors.geological_formation_member)}
                                                helperText={formik.touched.geological_formation_member && formik.errors.geological_formation_member}
                                            >
                                                {geologicalFormationMemberList.results.map((option, index) => (
                                                    <MenuItem key={index} value={option.id}>
                                                    {(option.code === null | option.code === "")  ? option.label : `${option.code} | ${option.label}`}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            </Grid> : <div></div>
                                        }

                                        {
                                            geologicalFormationPlutonList.results.length > 0 ? 
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="geological_formation_pluton"
                                                    label="Geological Formation Pluton"
                                                    {...formik.getFieldProps('geological_formation_pluton')}
                                                    select
                                                    value={formik.values.geological_formation_pluton}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.geological_formation_pluton && Boolean(formik.errors.geological_formation_pluton)}
                                                    helperText={formik.touched.geological_formation_pluton && formik.errors.geological_formation_pluton}
                                                >
                                                    {geologicalFormationPlutonList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                        {(option.code === null | option.code === "")  ? option.label : `${option.code} | ${option.label}`}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid> : <div></div>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="geological_classification"
                                                label="Geological Classification"
                                                {...formik.getFieldProps('geological_classification')}
                                                value={geological_classification}
                                                onChange={(e) => {formik.setFieldValue("geological_classification", ""); searchAllClassifications(e.target.value)}}
                                                ref={geologicalClassificationRef}
                                                onClick={() => setshowGeologicalClassificationInfiniteScroll(true)}
                                                error={(formik.touched.geological_classification) && Boolean(formik.errors.geological_classification)}
                                                helperText={(formik.touched.geological_classification) && formik.errors.geological_classification}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showGeologicalClassificationInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={geologicalClassificationInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={stratageologicalclassificationList.results.length}
                                                    next={fetchStrataGeologicalClassificationData}
                                                    hasMore={stratageologicalclassificationListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {stratageologicalclassificationList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("geological_classification", option.id)
                                                                formik.setFieldTouched("geological_classification", false)
                                                                formik.setFieldError("borehgeological_classificationole", "")
                                                                setgeological_classification(`${option.label} | ${option.description}`)
                                                                setshowGeologicalClassificationInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label} | {option.description}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="others"
                                                label="Others"
                                                {...formik.getFieldProps('others')}
                                                value={others}
                                                onChange={(e) => {formik.setFieldValue("others", ""); searchAllStrataOthersData(e.target.value)}}
                                                ref={othersRef}
                                                onClick={() => setOthersInfiniteScroll(true)}
                                                error={(formik.touched.others) && Boolean(formik.errors.others)}
                                                helperText={(formik.touched.others) && formik.errors.others}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showOthersInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={othersInfiniteRef}>
                                        <InfiniteScroll
                                                    dataLength={strataOthersList.results.length}
                                                    next={fetchStrataOthersData}
                                                    hasMore={strataOthersListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {strataOthersList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("others", option.id)
                                                                formik.setFieldTouched("others", false)
                                                                formik.setFieldError("others", "")
                                                                setothers(`${option.label}`)
                                                                setOthersInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="rock_description"
                                                label="Rock description"
                                                {...formik.getFieldProps('rock_description')}
                                                error={formik.touched.rock_description && Boolean(formik.errors.rock_description)}
                                                helperText={formik.touched.rock_description && formik.errors.rock_description}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="remarks"
                                                label="Remarks"
                                                {...formik.getFieldProps('remarks')}
                                                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                                                helperText={formik.touched.remarks && formik.errors.remarks}
                                            />
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/stratumresults`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                onClick={() => handleSubmit(formik.values)}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Stratum Result
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                onClick={() => handleSubmitReload(formik.values)}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save and add another
                                            </Button>
                                        </Grid>


                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}