import React, { useEffect, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"; 
import useRequestResource from 'src/hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from 'react-datepicker';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }


export default function AGSToXLS() {
    const { resourceList, getResourceList, deleteResource, currentListSize} = useRequestResource({ endpoint: "auth/agsxlsutils", query: "agsxlsfiles", resourceLabel: "AGSXLS List" });
    const { addResource, getResource, resources, deleteResourcesBefore } = useRequestResource({endpoint: "auth/agsxlsutils", query: "agsxls", resourceLabel: "AGSXLS"})
    const { searchResources, searchList} = useRequestResource({ endpoint: "auth/agsxlsutils", query: "agsxls", resourceLabel: "Search List" }) 
    const offset = 25;
    const [exp, setExp] = useState("")
    const [project_id, setProjectId] = useState(null)
    const [borehole, setBorehole] = useState(null)
    const [sorting_query, setSortingQuery] = useState(null)

    const [file, setFile] = useState()
    const navigate = useNavigate();
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);
    const is_manager = localStorage.getItem("is_manager")

    useEffect(() => {
        getResourceList();
    }, [getResourceList]);

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }


    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    function handleOnSubmit() {

        let formData = new FormData()
        const formattedValues = {
            project_id: project_id,
            borehole: borehole
        }
        formData.append("file", file)
        formData.append("json_data", JSON.stringify(formattedValues))
        addResource(formData, () => {
            window.location.reload();
        })
    }

    

    function onFileChange(value) {
        setFile(value.target.files[0])
    }

    function onProjectChange(e) {
        setProjectId(e.target.value)
    }

    function onBoreholeChange(e) {
        setBorehole(e.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    return (
        <div>
           <h3 style={headerStyle}>
                    AGS To XLS Converter
                </h3>
                <Accordion defaultActiveKey="1">
                    <Card onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                    handleOnSubmit()
                                }
                            }}>
                        <Card.Header>
                            <CustomToggle eventKey="0">Convert To XLS</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                               <Grid item xs={12}>
                               <Form.Group controlId="formFile" className="mb-3" required>
                                    <Form.Label>Add AGS File</Form.Label>
                                    <Form.Control type="file" onChange={ onFileChange }/>
                                </Form.Group>
                               </Grid>
                               <div style={{ margin: '20px' }}></div>
                               <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="project_id"
                                        label="Project"
                                        onChange={ onProjectChange }
                                    />
                                </Grid>
                                <div style={{ margin: '20px' }}></div>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="borehole"
                                        label="Borehole"
                                        onChange={ onBoreholeChange }
                                    />
                                </Grid>
                                <div style={{ margin: '10px' }}></div>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={ handleOnSubmit }
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Convert
                                    </Button>
                                </Grid>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header style={{ display: 'flex' }}>
                        <CustomToggle eventKey="1">Converted List</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    { is_manager === "true" ? 
                                    <div style={{ display: 'flex'}}>
                                        <DatePicker
                                                placeholderText='Delete Before'
                                                selected={ deleteByDate }
                                                onChange={ handleDeleteByDateChange }
                                                name="startDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                            >    
                                        </DatePicker>
                                        <Button 
                                                onClick={ onDeleteByDateSubmit }
                                                size="small"
                                                variant="contained"
                                            >
                                                Delete
                                        </Button>
                                    </div>: <div></div>}
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="ags-xls"
                                            label="AGS-XLS"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Table>
                                    <TableHead width="100%">
                                        <TableRow>
                                        <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("project_id")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Project</div><div>{sorting_query === "project_id" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-project_id")}></FaSortUp> : 
                                                    sorting_query === "-project_id" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("project_id")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("borehole")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Borehole</div><div>{sorting_query === "borehole" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole")}></FaSortUp> : 
                                                    sorting_query === "-borehole" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="40%">
                                                    Converted File
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("date_of_creation")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Datetime</div><div>{sorting_query === "date_of_creation" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-date_of_creation")}></FaSortUp> : 
                                                    sorting_query === "-date_of_creation" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("date_of_creation")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        getList().map((r) => {
                                            return (
                                                <TableRow key={r.id}>
                                                    <TableCell align="center" width="20%">
                                                        {r.project_id}
                                                    </TableCell>
                                                    <TableCell align="center" width="20%">
                                                        {r.borehole}
                                                    </TableCell>
                                                    <TableCell align="center" width="40%">
                                                    <a href={`${process.env.REACT_APP_API_URL}${r.converted_file}`}>
                                                        {`${process.env.REACT_APP_API_URL}${r.converted_file}`}
                                                    </a>
                                                        
                                                    </TableCell>
                                                    <TableCell align="center" width="20%">
                                                        {new Date(r.date_of_creation).toLocaleString()}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                                </Table>
                                {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                    <Button onClick={fetchData}>
                                                LOAD MORE!
                                    </Button>
                                </div>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        </div>
    )
}


