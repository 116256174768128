import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import Accordion from 'react-bootstrap/Accordion';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";


const validationSchema = yup.object({
    label: yup.string().required("Label is required").max(50, "Max Length is 50!"),
    code: yup.string().max(50, "Max Length is 50!")
})

const StratumGeologicalFormationGroupCentre = () => {
    const offset = 25;
    const { addResource: addStratumGeologicalFormationGroupCentreList, deleteResource: deleteStratumGeologicalFormationGroupCentreList, getResourceList: getStratumGeologicalFormationGroupCentreList, 
        resourceList: stratumGeologicalFormationGroupCentreList, currentListSize} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "stratageologicalformationgrp", resourceLabel: "Stratum: Geological Formation Groups / Centres" });
    const { resources : stratumGeologicalFormationGroupCentreResource, updateResource :  updateStratumGeologicalFormationGroupCentreResource, searchResources, searchList} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "stratumgeologicalformationgrp", resourceLabel: "Stratum: Geological Formation Group / Centre" });

    const [initialValues, setInitialValues] = useState({
        label: "",
        code: ""
    });

    const [editedValues, setEditedValues] = useState({
        label: "",
        code: ""
    });

    const [idToEdit, setIdToEdit] = useState(null);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const sectionRef = useRef(null);

    const [exp, setExp] = useState("")

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [sorting_query, setSortingQuery] = useState(null)

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteStratumGeologicalFormationGroupCentreList(idToDelete);
    }

    useEffect(() => {
        getStratumGeologicalFormationGroupCentreList();
    }, [getStratumGeologicalFormationGroupCentreList]);

    const handleSubmit = values => {
        const formattedValues = {
            label: values.label,
            code: values.code
        }
        
        addStratumGeologicalFormationGroupCentreList(formattedValues, () => {
            window.location.reload(); 
        })
    }

    const handleEditWindow = (e) => {
        setEditedValues({
            label: e.label === null ? "" : e.label,
            code: e.code === null ? "" : e.code
        })
        setIdToEdit(e.id);
        setIsEditVisible(true);
    }

    useEffect(() => {
        if (isEditVisible && sectionRef.current) {
            setActiveKey('2')
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }, [isEditVisible]);

    const handleEditSubmit = values => {
        const formattedValues = {
            label: values.label,
            code: values.code
        }

        if (idToEdit) {
            updateStratumGeologicalFormationGroupCentreResource(idToEdit, formattedValues, () => {
                window.location.reload(); 
            })
            return;
        } else {
            //console.log("No ID PROVIDED NO SERVICE!")
        }
    }

    const handleChangeAccordian = (eventKey) => {
        if (eventKey != '2') {
            setIdToEdit(null);
            setIsEditVisible(false);
        }
        setActiveKey(eventKey)
    }

    const fetchData = () => {
        getStratumGeologicalFormationGroupCentreList(offset, sorting_query)
    }

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getStratumGeologicalFormationGroupCentreList(0, sorting_query)
        }
    }, [sorting_query, getStratumGeologicalFormationGroupCentreList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return stratumGeologicalFormationGroupCentreList.results
        }
    }

    return (
        <div>
        <Dialog open={open} onClose={handleDeleteClose}>
            <DialogTitle>
                Are you sure you want to delete this??
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDelete}>
                    YES
                </Button>
                <Button onClick={handleDeleteClose}>
                    NO!
                </Button>
            </DialogActions>
        </Dialog>

        <Accordion activeKey = {activeKey} onSelect={handleChangeAccordian}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Add Stratum: Geological Formation Group / Centre</Accordion.Header>
                    <Accordion.Body>
                        <Paper sx={{
                            borderRadius: "2px",
                            bpxShadow: (theme) => theme.shadows[4],
                            padding: (theme) => theme.spacing(2, 4, 3)
                        }}>

                            <Grid item>
                                <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                            </Grid>
                            <Typography variant="h6" mh={4}>
                                Add Stratum: Geological Formation Group / Centre
                            </Typography>

                            <br />

                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid container spacing={3}>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="label"
                                                            label="Label"
                                                            {...formik.getFieldProps('label')}
                                                            error={formik.touched.label && Boolean(formik.errors.label)}
                                                            helperText={formik.touched.label && formik.errors.label}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="code"
                                                            label="Code"
                                                            {...formik.getFieldProps('code')}
                                                            error={formik.touched.code && Boolean(formik.errors.code)}
                                                            helperText={formik.touched.code && formik.errors.code}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} >

                                                        <Button
                                                            type="submit"
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Create New Stratum: Geological Formation Group / Centre
                                                        </Button>

                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
                        </Paper>
                    </Accordion.Body>
                </Accordion.Item>
 
            <Accordion.Item eventKey="1">
                <Accordion.Header>Stratum: Geological Formation Group / Centre</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <TableContainer component={Paper}>
                                <h3 style={headerStyle}>
                                    Stratum: Geological Formation Group / Centre
                                </h3>

                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="stratum_geological_formation_grp"
                                            label="Stratum Geological Formation Group / Centre"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                
                                <div style={{ height: "20px" }} />

                                <Table size="small">
                                    <TableHead width="100%">
                                        <TableRow>

                                            <TableCell align="center" width="50%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("label")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Label</div><div>{sorting_query === "label" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-label")}></FaSortUp> : 
                                                    sorting_query === "-label" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("label")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="20%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("code")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Code</div><div>{sorting_query === "code" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-code")}></FaSortUp> : 
                                                    sorting_query === "-code" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("code")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>


                                            <TableCell align="right">
                                                Action
                                            </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {
                                            getList().map((r) => {
                                                return (
                                                    <TableRow
                                                    >
                                                        <TableCell align="center" width="50%">
                                                            {r.label}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.code}
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                                <IconButton size="small" onClick={() => {
                                                                    handleEditWindow(r);
                                                                }}>
                                                                    <EditIcon />
                                                                </IconButton>

                                                                <IconButton size="small" onClick={() => {
                                                                    handleConfirmDelete(r.id)
                                                                }}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                <Button onClick={fetchData}>
                                            LOAD MORE!
                                </Button>
                            </div>}
                        </div >
                    </Accordion.Body>
                </Accordion.Item>

                { isEditVisible? 
                <Accordion.Item eventKey="2" ref={sectionRef}>
                <Accordion.Header>Edit Stratum: Geological Formation Group / Centre </Accordion.Header>
                <Accordion.Body>
                        
                        <Paper sx={{
                                borderRadius: "2px",
                                bpxShadow: (theme) => theme.shadows[4],
                                padding: (theme) => theme.spacing(2, 4, 3)
                            }}>
    
                                <Grid item>
                                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                                </Grid>
                                <Typography variant="h6" mh={4}>
                                    Edit Stratum: Geological Formation Group / Centre
                                </Typography>
    
                                <br />
                                <Formik onSubmit={handleEditSubmit}
                                    initialValues={editedValues}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                >
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                id="label"
                                                                label="Label"
                                                                {...formik.getFieldProps('label')}
                                                                error={formik.touched.label && Boolean(formik.errors.label)}
                                                                helperText={formik.touched.label && formik.errors.label}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                id="code"
                                                                label="Code"
                                                                {...formik.getFieldProps('code')}
                                                                error={formik.touched.code && Boolean(formik.errors.code)}
                                                                helperText={formik.touched.code && formik.errors.code}
                                                            />
                                                        </Grid>
    
                                                        <Grid item xs={12} >
    
                                                            <Button
                                                                type="submit"
                                                                size="medium"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Save Stratum: Geological Formation Group / Centre
                                                            </Button>
    
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Paper>
                        </Accordion.Body>
                </Accordion.Item>
                :<div></div>
                }
        </Accordion>
        </div>

    )
}
export default StratumGeologicalFormationGroupCentre;