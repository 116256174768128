import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import DatePicker from 'react-datepicker';
import SignatureCanvas from "react-signature-canvas";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import AddIcon from '@mui/icons-material/Add';
import Overlay from 'react-bootstrap/Overlay';
import 'src/style.css'

import useRequestResource from 'src/hooks/useRequestResource';
import { Input } from 'reactstrap';
import { filter } from 'lodash';

const validationSchema = yup.object({
    project: yup.number().typeError("Select a project from the dropdown").required('Project is required'),
    drawn_by: yup.string().max(255, "Max Length is 255!"),
    drawn_by_date: yup.string(),
    checked_by: yup.string().max(255, "Max Length is 255!"),
    checked_by_date: yup.string(),
    section_number: yup.string().max(50, "Max Length is 50!"),
    horizontal_scale: yup.string()
    .test('number', 'Horizontal scale must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Horizontal scale must must be a whole number', (value) => {
        if (value) {
            const pattern = /^(-?0|-?[1-9]\d*)$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Horizontal scale must be greater than or equal to 1', (value) => {
        if (value) {
            return parseFloat(value) >= 1;
        }
        return true;
    }),
    convention_choice: yup.string().required("Convention Choice is required"),
    unit: yup.string().required("Unit is required"),
    borehole1: yup.number().typeError("Select a borehole from the dropdown").required('Borehole 1 is required'),
    borehole1_distance: yup.string()
    .test('number', 'Borehole 1 distance must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Borehole 1 distance must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Borehole 1 distance must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Borehole 1 distance must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
    borehole2: yup.number().typeError("Select a borehole from the dropdown"),
    borehole2_distance: yup.string()
    .test('number', 'Borehole 2 distance must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Borehole 2 distance must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Borehole 2 distance must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Borehole 2 distance must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
    borehole3: yup.number().typeError("Select a borehole from the dropdown"),
    borehole3_distance: yup.string()
    .test('number', 'Borehole 3 distance must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Borehole 3 distance must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Borehole 3 distance must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Borehole 3 distance must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
    borehole4: yup.number().typeError("Select a borehole from the dropdown"),
    borehole4_distance: yup.string()
    .test('number', 'Borehole 4 distance must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Borehole 4 distance must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Borehole 4 distance must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Borehole 4 distance must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
    borehole5: yup.number().typeError("Select a borehole from the dropdown"),
    borehole5_distance: yup.string()
    .test('number', 'Borehole 5 distance must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Borehole 5 distance must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Borehole 5 distance must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Borehole 5 distance must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
    borehole6: yup.number().typeError("Select a borehole from the dropdown"),
    borehole6_distance: yup.string()
    .test('number', 'Borehole 6 distance must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Borehole 6 distance must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Borehole 6 distance must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Borehole 6 distance must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
    borehole7: yup.number().typeError("Select a borehole from the dropdown"),
    borehole7_distance: yup.string()
    .test('number', 'Borehole 7 distance must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Borehole 7 distance must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Borehole 7 distance must be greater than or equal to -99999999.99', (value) => {
        if (value) {
            return parseFloat(value) >= -99999999.99;
        }
        return true;
    })
    .test('max-value', 'Borehole 7 distance must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
})

export default function CrosssectionlogCreate() {
    const { id } = useParams();
    const infiniteScrollRef = useRef(null); 
    const projectTextField = useRef(null); 
    const { filterList, filterResources } = useRequestResource({ endpoint: "auth/borehole_log", query: "borehole", resourceLabel: "Borehole Details" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "projects", resourceLabel: "Project list"})
    const { addResource, getResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "crosssectionlog", resourceLabel: "create crosssection"})
    const navigate = useNavigate();
    const [checked_by_date, handleCheckedByDateChange] = React.useState(null);
    const [drawn_by_date, handleDrawnByDateChange] = React.useState(null);
    const [images, setImages] = useState([])
    const [imageURLs, setImageURLs] = useState([])
    const [is_supervisor_signed, setSupervisorSign] = useState(false)
    const [is_witness_signed, setWitnessSign] = useState(false)
    const sigSupervisorCanvas = useRef(null)
    const sigWitnessCanvas = useRef(null)
    const [ project, setProject ] = useState("")
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const offset = 25;
    const [boreholeCount, setCount] = useState(1)
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [message, setMessage] = useState("")
    const [exp, setExp] = useState("")
    const [initialValues, setInitialValues] = useState({
        project: "",
        drawn_by: "",
        drawn_by_date: Date.now(),
        checked_by: "",
        checked_by_date: Date.now(),
        section_number: "",
        horizontal_scale: 1,
        convention_choice: "",
        unit: "",
        enforce_horizontal_scale: false,
        borehole1: "",
        borehole1_distance: "",
        borehole2: "",
        borehole2_distance: "",
        borehole3: "",
        borehole3_distance: "",
        borehole4: "",
        borehole4_distance: "",
        borehole5: "",
        borehole5_distance: "",
        borehole6: "",
        borehole6_distance: "",
        borehole7: "",
        borehole7_distance: "",
    });

    const conventionOptions = [
        { value: 'NEW', label: 'NEW' },
        { value: 'OLD', label: 'OLD' },
    ]

    const unitOptions = [
        { value: 'mSHD', label: 'mSHD' },
        { value: 'm', label: 'm' },
        { value: 'mCD', label: 'mCD' },
    ]
    
    const formatValues = values => {
        const formattedValues = {
            project: values.project ? values.project : null,
            unit: values.unit ? values.unit : null,
            section_num: values.section_number ? values.section_number : null,
            drawn_by: values.drawn_by ? values.drawn_by : null,
            checked_by: values.checked_by ? values.checked_by : null,
            convention_choice: values.convention_choice ? values.convention_choice : null,
            checked_by_date: checked_by_date ? convertDate(checked_by_date) : null,
            drawn_by_date: drawn_by_date ? convertDate(drawn_by_date) : null,
            horizontal_scale: values.horizontal_scale ? values.horizontal_scale  : null,
            enforce_horizontal_scale: values.enforce_horizontal_scale,
            borehole1_distance: values.borehole1_distance ? values.borehole1_distance : null,
            borehole1: values.borehole1,
            borehole2_distance: values.borehole2_distance ? values.borehole2_distance : null,
            borehole2: values.borehole2 ? values.borehole2 : null,
            borehole3_distance: values.borehole3_distance ? values.borehole3_distance : null,
            borehole3: values.borehole3 ? values.borehole3 : null,
            borehole4_distance: values.borehole4_distance ? values.borehole4_distance : null,
            borehole4: values.borehole4 ? values.borehole4 : null,
            borehole5_distance: values.borehole5_distance ? values.borehole5_distance : null,
            borehole5: values.borehole5 ? values.borehole5 : null,
            borehole6_distance: values.borehole6_distance ? values.borehole6_distance : null,
            borehole6: values.borehole6 ? values.borehole6 : null,
            borehole7_distance: values.borehole7_distance ? values.borehole7_distance : null,
            borehole7: values.borehole7 ? values.borehole7 : null,
        }
        return JSON.stringify(formattedValues);
    }

    const handleSubmit = values => {
        addResource(formatValues(values), () => {
            navigate(`/crosssectionlogs`)
        })
    }

    const handleSubmitReload = values => {
        addResource(formatValues(values), () => {
            window.location.reload();
        })
    }
    
    const clearSupervisorCanvas = () => {
        if (sigSupervisorCanvas.current) {
            sigSupervisorCanvas.current.clear()
            setSupervisorSign(prev => false)
        }
    }

    const clearWitnessCanvas = () => {
        if (sigWitnessCanvas.current) {
            sigWitnessCanvas.current.clear()
            setWitnessSign(prev => false)
        }
    }

    function supervisorSigned() {
        setSupervisorSign(prev => true)
    }

    function witnessSigned() {
        setWitnessSign(prev => true)
    }

    useEffect(() => {
        // if (images.length < 1) return;
        if (images.length >= 1) {
            const newImageUrls = []
            images.forEach(image => newImageUrls.push(URL.createObjectURL(image)))
            setImageURLs(newImageUrls)
        }
        getResourceList();
    }, [getResourceList, images])

    useEffect(() => {
    }, [filterResources, filterList])


    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (projectTextField.current && projectTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    function onImageChange(e) {
        setImages([...e.target.files])
    }

    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setProject(exp)
            getSearchAllList(exp, 0)
        } else {
            setProject("")
            getResourceList(0)
        }
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    function getProjectBoreholes(id) {
        const values = {
            project: id
        }
        filterResources(values)
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>
                <Typography variant="h6" mh={4}>
                    Cross Section Log Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>
                                    <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="project"
                                                label="Project"
                                                {...formik.getFieldProps('project')}
                                                value={project}
                                                onChange={(e) => {formik.setFieldValue("project", ""); searchAll(e.target.value)}}
                                                ref={projectTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={(formik.touched.project) && Boolean(formik.errors.project)}
                                                helperText={(formik.touched.project) && formik.errors.project}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("project", option.id)
                                                                formik.setFieldTouched("project", false)
                                                                formik.setFieldError("project", "")
                                                                setProject(`${option.project_title}`)
                                                                setInfiniteScroll(false)
                                                                getProjectBoreholes(option.id)
                                                                setShow(false)
                                                            }
                                                        }>
                                                            {option.project_title}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="section_number"
                                                label="Section Number"
                                                {...formik.getFieldProps('section_number')}
                                                error={formik.touched.section_number && Boolean(formik.errors.section_number)}
                                                helperText={formik.touched.section_number && formik.errors.section_number}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="horizontal_scale"
                                                label="Horizontal Scale"
                                                inputMode='numeric'
                                                {...formik.getFieldProps('horizontal_scale')}
                                                error={formik.touched.horizontal_scale && Boolean(formik.errors.horizontal_scale)}
                                                helperText={formik.touched.horizontal_scale && formik.errors.horizontal_scale}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="Enforce Horizontal Scale" onChange={(e) => {formik.setFieldValue("enforce_horizontal_scale", e.target.checked)}}/>
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="convention_choice"
                                                label="Convention Choice"
                                                {...formik.getFieldProps('convention_choice')}
                                                select
                                                value={formik.values.convention_choice}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.convention_choice && Boolean(formik.errors.convention_choice)}
                                                helperText={formik.touched.convention_choice && formik.errors.convention_choice}
                                            >
                                                {conventionOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="unit"
                                                label="Unit"
                                                {...formik.getFieldProps('unit')}
                                                select
                                                value={formik.values.unit}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.unit && Boolean(formik.errors.unit)}
                                                helperText={formik.touched.unit && formik.errors.unit}
                                            >
                                                {unitOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="drawn_by"
                                                label="Drawn By"
                                                {...formik.getFieldProps('drawn_by')}
                                                error={formik.touched.drawn_by && Boolean(formik.errors.drawn_by)}
                                                helperText={formik.touched.drawn_by && formik.errors.drawn_by}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Drawn by Date'
                                                selected={ drawn_by_date }
                                                onChange={(e) => {handleDrawnByDateChange(e)}  }
                                                name="drawn_by_date"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="checked_by"
                                                label="Checked By"
                                                {...formik.getFieldProps('checked_by')}
                                                error={formik.touched.checked_by && Boolean(formik.errors.checked_by)}
                                                helperText={formik.touched.checked_by && formik.errors.checked_by}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Checked by Date'
                                                selected={ checked_by_date }
                                                onChange={ (e) => {handleCheckedByDateChange(e)} }
                                                name="checked_by_date"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={10}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                        <div ref={target} onClick={filterList.results.length > 0 ? boreholeCount < 7 ? () => {setCount(boreholeCount + 1)} : () => {setMessage("Only 7 Boreholes allowed"); setShow(true) } : () => {setShow(true); setMessage("Please Select Project first"); }} >
                                        <Overlay target={target.current} show={show} placement="right">
                                            {({
                                            placement: _placement,
                                            arrowProps: _arrowProps,
                                            show: _show,
                                            popper: _popper,
                                            hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                            ...props
                                            }) => (
                                            <div
                                                {...props}
                                                style={{
                                                position: 'absolute',
                                                backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                                padding: '2px 10px',
                                                color: 'white',
                                                borderRadius: 3,
                                                ...props.style,
                                                }}
                                            >
                                                {message}
                                            </div>
                                            )}
                                        </Overlay>
                                        <Card >
                                            <Card.Body style={{alignItems:"center", justifyContent: "center"}}> <AddIcon /> Add Borehole</Card.Body>
                                        </Card>
                                        </div>
                                        </Grid>
                                        { filterList.results.length > 0 ? <Grid item xs={12}>
                                            <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole1"
                                                    label="Borehole 1"
                                                    {...formik.getFieldProps('borehole1')}
                                                    select
                                                    value={formik.values.borehole1}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.borehole1 && Boolean(formik.errors.borehole1)}
                                                    helperText={formik.touched.borehole1 && formik.errors.borehole1}
                                                >
                                                    {filterList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                        {option.borehole_number}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole1_distance"
                                                        label="Borehole 1 Distance"
                                                        inputMode='numeric'
                                                        {...formik.getFieldProps('borehole1_distance')}
                                                        error={formik.touched.borehole1_distance && Boolean(formik.errors.borehole1_distance)}
                                                        helperText={formik.touched.borehole1_distance && formik.errors.borehole1_distance}
                                                    />
                                                </Grid>
                                                </Card.Body>
                                            </Card>
                                            { boreholeCount >= 2 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole2"
                                                    label="Borehole 2"
                                                    {...formik.getFieldProps('borehole2')}
                                                    select
                                                    value={formik.values.borehole2}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.borehole2 && Boolean(formik.errors.borehole2)}
                                                    helperText={formik.touched.borehole2 && formik.errors.borehole2}
                                                >
                                                    {filterList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                        {option.borehole_number}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole2_distance"
                                                        label="Borehole 2 Distance"
                                                        inputMode='numeric'
                                                        {...formik.getFieldProps('borehole2_distance')}
                                                        error={formik.touched.borehole2_distance && Boolean(formik.errors.borehole2_distance)}
                                                        helperText={formik.touched.borehole2_distance && formik.errors.borehole2_distance}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                {
                                                    boreholeCount === 2 ? <Button
                                                    onClick={() => {setCount(boreholeCount - 1); formik.setFieldValue("borehole2", ""); formik.setFieldValue("borehole2_distance", "")}}
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Remove
                                                </Button> : <div></div>
                                                }
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            { boreholeCount >= 3 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole3"
                                                    label="Borehole 3"
                                                    {...formik.getFieldProps('borehole3')}
                                                    select
                                                    value={formik.values.borehole3}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.borehole3 && Boolean(formik.errors.borehole3)}
                                                    helperText={formik.touched.borehole3 && formik.errors.borehole3}
                                                >
                                                    {filterList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                        {option.borehole_number}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole3_distance"
                                                        label="Borehole 3 Distance"
                                                        inputMode='numeric'
                                                        {...formik.getFieldProps('borehole3_distance')}
                                                        error={formik.touched.borehole3_distance && Boolean(formik.errors.borehole3_distance)}
                                                        helperText={formik.touched.borehole3_distance && formik.errors.borehole3_distance}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                {
                                                    boreholeCount === 3 ? <Button
                                                    onClick={() => {setCount(boreholeCount - 1); formik.setFieldValue("borehole3", ""); formik.setFieldValue("borehole3_distance", "")}}
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Remove
                                                </Button> : <div></div>
                                                }
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            { boreholeCount >= 4 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole4"
                                                    label="Borehole 4"
                                                    {...formik.getFieldProps('borehole4')}
                                                    select
                                                    value={formik.values.borehole4}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.borehole4 && Boolean(formik.errors.borehole4)}
                                                    helperText={formik.touched.borehole4 && formik.errors.borehole4}
                                                >
                                                    {filterList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                        {option.borehole_number}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole4_distance"
                                                        label="Borehole 4 Distance"
                                                        inputMode='numeric'
                                                        {...formik.getFieldProps('borehole4_distance')}
                                                        error={formik.touched.borehole4_distance && Boolean(formik.errors.borehole4_distance)}
                                                        helperText={formik.touched.borehole4_distance && formik.errors.borehole4_distance}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                {
                                                    boreholeCount === 4 ? <Button
                                                    onClick={() => {setCount(boreholeCount - 1); formik.setFieldValue("borehole4", ""); formik.setFieldValue("borehole4_distance", "")}}
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Remove
                                                </Button> : <div></div>
                                                }
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            { boreholeCount >= 5 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole5"
                                                    label="Borehole 5"
                                                    {...formik.getFieldProps('borehole5')}
                                                    select
                                                    value={formik.values.borehole5}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.borehole5 && Boolean(formik.errors.borehole5)}
                                                    helperText={formik.touched.borehole5 && formik.errors.borehole5}
                                                >
                                                    {filterList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                        {option.borehole_number}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole5_distance"
                                                        label="Borehole 5 Distance"
                                                        inputMode='numeric'
                                                        {...formik.getFieldProps('borehole5_distance')}
                                                        error={formik.touched.borehole5_distance && Boolean(formik.errors.borehole5_distance)}
                                                        helperText={formik.touched.borehole5_distance && formik.errors.borehole5_distance}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                {
                                                    boreholeCount === 5 ? <Button
                                                    onClick={() => {setCount(boreholeCount - 1); formik.setFieldValue("borehole5", ""); formik.setFieldValue("borehole5_distance", "")}}
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Remove
                                                </Button> : <div></div>
                                                }
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            { boreholeCount >= 6 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole6"
                                                    label="Borehole 6"
                                                    {...formik.getFieldProps('borehole6')}
                                                    select
                                                    value={formik.values.borehole6}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.borehole6 && Boolean(formik.errors.borehole6)}
                                                    helperText={formik.touched.borehole6 && formik.errors.borehole6}
                                                >
                                                    {filterList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                        {option.borehole_number}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole6_distance"
                                                        label="Borehole 6 Distance"
                                                        inputMode='numeric'
                                                        {...formik.getFieldProps('borehole6_distance')}
                                                        error={formik.touched.borehole6_distance && Boolean(formik.errors.borehole6_distance)}
                                                        helperText={formik.touched.borehole6_distance && formik.errors.borehole6_distance}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                {
                                                    boreholeCount === 6 ? <Button
                                                    onClick={() => {setCount(boreholeCount - 1); formik.setFieldValue("borehole6", ""); formik.setFieldValue("borehole6_distance", "")}}
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Remove
                                                </Button> : <div></div>
                                                }
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            {boreholeCount === 7 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole7"
                                                    label="Borehole 7"
                                                    {...formik.getFieldProps('borehole7')}
                                                    select
                                                    value={formik.values.borehole7}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.borehole7 && Boolean(formik.errors.borehole7)}
                                                    helperText={formik.touched.borehole7 && formik.errors.borehole7}
                                                >
                                                    {filterList.results.map((option, index) => (
                                                        <MenuItem key={index} value={option.id}>
                                                        {option.borehole_number}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole7_distance"
                                                        label="Borehole 7 Distance"
                                                        inputMode='numeric'
                                                        {...formik.getFieldProps('borehole7_distance')}
                                                        error={formik.touched.borehole7_distance && Boolean(formik.errors.borehole7_distance)}
                                                        helperText={formik.touched.borehole7_distance && formik.errors.borehole7_distance}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                {
                                                    boreholeCount === 7 ? <Button
                                                    onClick={() => {setCount(boreholeCount - 1); setShow(false); formik.setFieldValue("borehole7", ""); formik.setFieldValue("borehole7_distance", "")}}
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Remove
                                                </Button> : <div></div>
                                                }
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                        </Grid> : <div></div>}
                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/crosssectionlogs`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Cross Section Log
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                onClick={(event) => handleSubmitReload(formik.values)}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save and add another
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}