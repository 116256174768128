import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { Box } from "@mui/material";
import { SnackbarProvider } from "notistack";

import LoadingOverlayResource from "./components/LoadingOverlayResource";
import SignIn from "./pages/Auth/SignIn";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import Profile from "./pages/Profile/Profile";
import AuthContextProvider from "./contexts/AuthContextProvider";
import RequireAuth from "./components/RequireAuth";
import RequireNotAuth from "./components/RequireNotAuth";
import BaseLayout from "./components/BaseLayout";
//Dashboard
import DashBoard from "./pages/DashBoard/DashBoard";

//AGS XLS File Writer
import AGSToXLS from "./pages/converters/AGSToXLS";
import AGSWriter from "./pages/converters/AGSWriter";
import XLSToAGS from "./pages/converters/XLSToAGS";

//Borehole Log
import BoreholeList from "./pages/BoreHoleData/Borehole/BoreholeList";
import BoreHoleCreate from "./pages/BoreHoleData/Borehole/BoreHoleCreate";
import BoreHoleDetails from "./pages/BoreHoleData/Borehole/BoreHoleDetails";
import CrosssectionlogList from "./pages/BoreHoleData/Crosssectionlog/Crosssectionloglist";
import CrosssectionlogCreate from "./pages/BoreHoleData/Crosssectionlog/CreateCrosssectionlog";
import DailyWaterLevel from "./pages/BoreHoleData/DailyWaterLevel";
import ExcelWriter from "./pages/BoreHoleData/ExcelWriter";
import InstrumentInstallationSheets from "./pages/BoreHoleData/InstrumentInstallationSheet";
import InstrumentInstallationList from "./pages/BoreHoleData/InstrumentInstallation/InstrumentInstallationList";
import InstrumentInstallationCreate from "./pages/BoreHoleData/InstrumentInstallation/CreateInstrumentInstallation";
import InstrumentInstallationEdit from "./pages/BoreHoleData/InstrumentInstallation/EditInstrumentInstallation";
import ProjectLists from "./pages/BoreHoleData/Project/ProjectLists";
import CreateProject from "./pages/BoreHoleData/Project/CreateProject"; 
import EditProjectDetails from "./pages/BoreHoleData/Project/EditProjectDetails";
import Borelogs from "./pages/BoreHoleData/Borelog";
import BorelogView from "./pages/BoreHoleData/BorelogView";
import RockResults from "./pages/BoreHoleData/RockResults";
import SPTs from "./pages/BoreHoleData/SPTs"
import SampleDescriptionForMajorSoilName from "./pages/BoreHoleData/SampleDescriptionForMajorSoilName";
import SampleDescriptionForMinorSoilName1 from "./pages/BoreHoleData/SampleDescriptionForMinorSoilName1";
import SampleDescriptionForMinorSoilName2 from "./pages/BoreHoleData/SampleDescriptionForMinorSoilName2";

import SiteTestList from "./pages/BoreHoleData/SiteTest/SiteTestList";
import SiteTestCreate from "./pages/BoreHoleData/SiteTest/CreateSiteTest";
import SiteTestEdit from "./pages/BoreHoleData/SiteTest/EditSiteTest";

import StratumColorChroma from "./pages/BoreHoleData/StratumColorChroma";
import StratumColorHue from "./pages/BoreHoleData/StratumColorHue";
import StratumColorLightness from "./pages/BoreHoleData/StratumColorLightness";
import StratumColorNonUniform from "./pages/BoreHoleData/StratumColorNonUniform";
import StratumConsistencyOrRelativeDensityRock from "./pages/BoreHoleData/StratumConsistencyOrRelativeDensityRock";
import StratumConsistencyOrRelativeDensitySoil from "./pages/BoreHoleData/StratumConsistencyOrRelativeDensitySoil";
import StratumGeologicalClassifications from "./pages/BoreHoleData/StratumGeologicalClassifications";
import StratumGeologicalFormationPlutons from "./pages/BoreHoleData/StratumGeologicalFormationPlutons";
import StratumGeologicalFormationGroupCentre from "./pages/BoreHoleData/StratumGeologicalFormationGroupCentre";
import StratumGeologicalFormationMembers from "./pages/BoreHoleData/StratumGeologicalFormationMembers";
import StratumOthers from "./pages/BoreHoleData/StratumOthers";
import StratumPatterns from "./pages/BoreHoleData/StratumPatterns";
import StratumTrialPitsHandAugers from "./pages/BoreHoleData/StratumTrialPitsHandAugers";

//Daily Journal 
import Activity from "./pages/DailyJournal/Activities/Activities";
import JournalEntryList from "./pages/DailyJournal/JournalEntries/JournalEntryList";
import JournalEntryCreate from "./pages/DailyJournal/JournalEntries/CreateJournalEntry";
import JournalEntryEdit from "./pages/DailyJournal/JournalEntries/EditJournalEntry";

//Laboratory
import ChemicalTest from "./pages/Laboratory/ChemicalTest";
import ConsolidationTests from "./pages/Laboratory/ConsolidationTest";
import LaboratoryPermeabilityTests from "./pages/Laboratory/LaboratoryPermeabilityTest";
import NormalLaboratoryTest from "./pages/Laboratory/NormalLaboratoryTest";
import RockTest from "./pages/Laboratory/RockTest"
import SievingHydrometerTest from "./pages/Laboratory/SievingHydrometerTest"
import TestSpecimen from "./pages/Laboratory/TestSpecimen";
import ToxicityTest from "./pages/Laboratory/ToxicityTest"
import TriaxialTests from "./pages/Laboratory/Triaxial";
import SampleCreate from "./pages/BoreHoleData/Sample/CreateSample";
import StratumResultCreate from "./pages/BoreHoleData/StratumResult/CreateStratumResult";
import StratumResultList from "./pages/BoreHoleData/StratumResult/StratumResultList";
import SampleEdit from "./pages/BoreHoleData/Sample/EditSample";
import SampleList from "./pages/BoreHoleData/Sample/SampleList";
import { Dashboard, Logout } from "@mui/icons-material";
import { GeologistSeniorGeologistManager, ManagerLabTech, ManagerRoutes, SeniorGeologistManager } from "./routes/CommonRoutes";
import ResetPassword from "./pages/Auth/ResetPassword";
import EmailSent from "./pages/Auth/EmailSent";


export function RestrictedRoute({status}) {

  const is_geologist = localStorage.getItem("is_geologist")
  const is_senior_geologist = localStorage.getItem("is_senior_geologist")
  const is_manager = localStorage.getItem("is_manager")
  const is_lab_technician = localStorage.getItem("is_lab_technician")

  const is_geologist_seniorgeologist_manager = (status === "gsm" && (is_geologist === "true" || is_manager === "true" || is_senior_geologist === "true"))
  const is_seniorgeologist_manager = (status === "sm" && (is_manager === "true" || is_senior_geologist === "true"))
  const is_manager_labtech = (status === "ml" && (is_lab_technician === "true" || is_manager === "true"))
  const is_manager_route = (status === "m" && (is_manager === "true"))

  
  if (is_geologist_seniorgeologist_manager || is_seniorgeologist_manager || is_manager_labtech || is_manager_route) {
    return (<Outlet/>)
  }

  return (
      <Navigate to="/unauthorized" />
  )
}


export const Unauthorized = () => <h1>Unauthorized</h1>;

export default function App() {

  const [padding, setPadding] = useState({});
  
  useEffect(() => {
    // Get the viewport width
    const viewportWidth = window.innerWidth;

    // Calculate padding values based on the screen width
    let paddingTop, paddingRight, paddingBottom, paddingLeft;

    if (viewportWidth >= 1200) {
      // For screens with a width of 1200px or more
      paddingTop = '20px';
      paddingRight = '100px';
      paddingBottom = '20px';
      paddingLeft = '100px';
    } else if (viewportWidth >= 768) {
      // For screens with a width of 768px or more
      paddingTop = '15px';
      paddingRight = '40px';
      paddingBottom = '15px';
      paddingLeft = '40px';
    } else {
      // For smaller screens
      paddingTop = '0px';
      paddingRight = '0px';
      paddingBottom = '0px';
      paddingLeft = '0px';
    }
    // Update the padding object
    setPadding({
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
    });
  }, []);

  const user = localStorage.getItem("user")
  const isAuthenticated = true;

  return  <div style={padding}>
    <CssBaseline />
    <AuthContextProvider>
      <SnackbarProvider>
        <Router>
          <Box   
            sx={{ bgcolor: (theme) => theme.palette.background.default, minHeight: "100vh" }}
            style={{
              backgroundImage: `url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.solidbackgrounds.com%2F3600x3600-white-solid-color-background.html&psig=AOvVaw0X6j2kEMQYPmcX-Bcpt-np&ust=1653631446948000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCLj_3qm__PcCFQAAAAAdAAAAABAO")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {/* Restricted Access routes */}
            {/* <Routes>
            <RestrictedRoute path="/" condition={isAuthenticated} component={<DashBoard></DashBoard>} ></RestrictedRoute>
            </Routes> */}
            <ManagerRoutes></ManagerRoutes>
            <GeologistSeniorGeologistManager></GeologistSeniorGeologistManager>
            <SeniorGeologistManager></SeniorGeologistManager>
            <ManagerLabTech></ManagerLabTech>


            <Routes>
              <Route path="/" element={<SignIn></SignIn>}></Route>
              <Route path="/email-sent" element={<EmailSent></EmailSent>}></Route>
              <Route path={"/send-reset-password/:username/:token/:expiration"} element={<ResetPassword />} />
              
              {/* To access the database content, you must be authenticated */}
              <Route element={<RequireAuth />}>

                  <Route element={<BaseLayout />}>
                  {/*Admin*/}
                  <Route path={"/dashboard"} element={<DashBoard />} /* Dashboard Stat */ />
                  <Route path={"/profile"} element={<Profile />} /* Profile Page */ />
                  <Route path={"/unauthorized"} element={<Unauthorized/>} />
                </Route>
              </Route>
              {/* You don't need authentication for accessing signup & signin page */}
              <Route element={<RequireNotAuth />} >
                <Route path={"/login/token"} element={<SignIn />} />
                <Route path={"/login/reset"} element={<ForgetPassword />} />
              </Route>
            </Routes>
          </Box>
        </Router>
      </SnackbarProvider>
    </AuthContextProvider>
  </div>
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);