import React, { useEffect, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import DatePicker from 'react-datepicker';
import {Grid, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogActions} from "@mui/material"; 
import useRequestResource from 'src/hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "../../../App.css"

function PDFViewer({ backendUrl }) {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
    return (
      <div>
        {backendUrl && (
          <Document className="centered-container" file={backendUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} renderTextLayer={false}/>
          </Document>
        )}
        {/* {numPages && (
          <p>
            Page {pageNumber} of {numPages}
          </p>
        )} */}
      </div>
    );
  }
  

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  export default function CrossSectionlogView() {

    const {getResource, resources, deleteResource} = useRequestResource({endpoint: "auth/borehole_log", query: "crosssectionlog", resourceLabel: "CrossSectionLog"})
    const { id } = useParams()
    useEffect(() => {
        getResource(id)
    }, [getResource, id])

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
        window.history.back()
    }

    return (
        <div>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
            <h3 style={headerStyle}>
                Cross Section Log
            </h3>
            { resources ? <div>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="0">Details</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="project"
                                                label= "Project"
                                                disabled={true}
                                                value={resources.project_title}
                                            >
                                            </TextField>
                                        </Grid>
                                        {/* { showInfiniteScroll ? <Grid item xs={12}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                //console.log("inside on click")
                                                                formik.setFieldValue("project", option.id)
                                                                formik.setFieldTouched("project", false)
                                                                formik.setFieldError("project", "")
                                                                setProject(`${option.project_title}`)
                                                                setInfiniteScroll(false)
                                                                getProjectBoreholes(option.id)
                                                                setShow(false)
                                                            }
                                                        }>
                                                            {option.project_title}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </Grid> : <div></div>} */}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="section_number"
                                                label="Section Number"
                                                disabled={true}
                                                value={resources.section_num}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="horizontal_scale"
                                                label="Horizontal Scale"
                                                inputMode='numeric'
                                                disabled={true}
                                                value={resources.horizontal_scale}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="Enforce Horizontal Scale" checked={resources.enforce_horizontal_scale} disabled={true}/>
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="convention_choice"
                                                label="Convention Choice"
                                                disabled={true}
                                                value={resources.convention_choice}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="unit"
                                                label="Unit"
                                                disabled={true}
                                                value={resources.unit}
                                            >
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="drawn_by"
                                                label="Drawn By"
                                                disabled={true}
                                                value={resources.drawn_by}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Drawn by Date'
                                                name="drawn_by_date"
                                                value={resources.drawn_by_date ? new Date(resources.drawn_by_date).toLocaleString() : null}
                                                disabled={true}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="checked_by"
                                                label="Checked By"
                                                disabled={true}
                                                value={resources.checked_by}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Checked by Date'
                                                value={resources.checked_by_date ? new Date(resources.checked_by_date).toLocaleString() : null}
                                                disabled={true}
                                                name="checked_by_date"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={10}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole1"
                                                    label="Borehole 1"
                                                    disabled={true}
                                                    value={resources.borehole1_number}
                                                >
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole1_distance"
                                                        label="Borehole 1 Distance"
                                                        disabled={true}
                                                        inputMode='numeric'
                                                        value={resources.borehole1_distance}
                                                    />
                                                </Grid>
                                                </Card.Body>
                                            </Card>
                                            { resources.borehole2 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole2"
                                                    label="Borehole 2"
                                                    disabled={true}
                                                    select
                                                    value={resources.borehole2}
                                                >
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole2_distance"
                                                        label="Borehole 2 Distance"
                                                        disabled={true}
                                                        inputMode='numeric'
                                                        value={resources.borehole2_distance}
                                                    />
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            { resources.borehole3 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole3"
                                                    label="Borehole 3"
                                                    disabled={true}
                                                    select
                                                    value={resources.borehole3}
                                                    
                                                >
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole3_distance"
                                                        label="Borehole 3 Distance"
                                                        disabled={true}
                                                        inputMode='numeric'
                                                        value={resources.borehole3_distance}
                                                    />
                                                </Grid>
                                                
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            { resources.borehole4 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole4"
                                                    label="Borehole 4"
                                                    disabled={true}
                                                    select
                                                    value={resources.borehole4}
                                                >
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole4_distance"
                                                        label="Borehole 4 Distance"
                                                        disabled={true}
                                                        inputMode='numeric'
                                                        value={resources.borehole4_distance}
                                                    />
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            { resources.borehole5 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole5"
                                                    label="Borehole 5"
                                                    disabled={true}
                                                    select
                                                    value={resources.borehole5}
                                                >
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole5_distance"
                                                        label="Borehole 5 Distance"
                                                        disabled={true}
                                                        inputMode='numeric'
                                                        value={resources.borehole5_distance}
                                                    />
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            { resources.borehole6 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole6"
                                                    label="Borehole 6"
                                                    disabled={true}
                                                    select
                                                    value={resources.borehole6}
                                                >
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole6_distance"
                                                        label="Borehole 6 Distance"
                                                        disabled={true}
                                                        inputMode='numeric'
                                                        value={resources.borehole6_distance}
                                                    />
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            {resources.borehole7 ? <Card>
                                                <Card.Body>
                                                <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="borehole7"
                                                    label="Borehole 7"
                                                    disabled={true}
                                                    select
                                                    value={resources.borehole7}
                                                >
                                                </TextField>
                                                </Grid>
                                                <div style={{margin: "10px"}}></div>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="borehole7_distance"
                                                        label="Borehole 7 Distance"
                                                        disabled={true}
                                                        inputMode='numeric'
                                                        value={resources.borehole7_distance}
                                                    />
                                                </Grid>
                                                </Card.Body>
                                            </Card> : <div></div>}
                                            <Grid item xs={12} >

                                                    <Button
                                                        onClick={() => handleConfirmDelete(resources.id)}
                                                        type="delete"
                                                        size="medium"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Delete Report
                                                    </Button>

                                                </Grid>
                                        </Grid>
                                    </Grid>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="1">View</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                        <PDFViewer backendUrl={`${process.env.REACT_APP_API_URL}${resources.upload}`}></PDFViewer>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>: <div></div>}
        </div>
    )
  }