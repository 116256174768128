import React from 'react';
import { Navigate, Outlet } from "react-router-dom";

import { AuthContext } from 'src/contexts/AuthContextProvider';

export default function RequireAuth() {

    const { isAuthenticated } = React.useContext(AuthContext);

    // Return loading screen if not authenticated, use bandwidth
    // throttling if you want to see it
    if (isAuthenticated === null) {
        return <div>Loading...</div>
    }

    // Return the routing route user is accessing if isAutenticated

    /** Removing this line due to redirection bug, user will have to login each time
    if (isAuthenticated === true) {
        return <Navigate to="/dashboard" />
    }    
     */


    return (
        <Outlet />
    )
}
