import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';

import useRequestResource from 'src/hooks/useRequestResource';
import Accordion from 'react-bootstrap/Accordion';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";


const validationSchema = yup.object({
    specimen: yup.number().typeError("Select a specimen from the dropdown").required('Specimen is required'),
    uncorrected_point_load: yup.string()
    .test('number', 'Uncorrected point load must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Uncorrected point load must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,1})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Uncorrected point load must be greater than or equal to -999.9', (value) => {
        if (value) {
            return parseFloat(value) >= -999.9;
        }
        return true;
    })
    .test('max-value', 'Uncorrected point load must be less than or equal to 999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 999.9;
        }
        return true;
    }),
    size_corrected_point_load_index: yup.string()
    .test('number', 'Size corrected point load index must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Size corrected point load index must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,1})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Size corrected point load index must be greater than or equal to -999.9', (value) => {
        if (value) {
            return parseFloat(value) >= -999.9;
        }
        return true;
    })
    .test('max-value', 'Size corrected point load index must be less than or equal to 999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 999.9;
        }
        return true;
    }),
    uniaxial_compressive_strength: yup.string()
    .test('number', 'Uniaxial compressive strength must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Uniaxial compressive strength must have at most 1 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,1})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Uniaxial compressive strength must be greater than or equal to -999.9', (value) => {
        if (value) {
            return parseFloat(value) >= -999.9;
        }
        return true;
    })
    .test('max-value', 'Uniaxial compressive strength must be less than or equal to 999.9', (value) => {
        if (value) {
            return parseFloat(value) <= 999.9;
        }
        return true;
    }),
})

const RockTests = () => {
    const offset = 25;
    const { getResourceList: getSpecimenList, resourceList: specimenList, currentListSize: currentSpecimenListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/laboratory", query: "specimens", resourceLabel: "Specimen List" });
    const { getResourceList: getRockTestList, resourceList: rockTestList, currentListSize: currentRockTestListSize } = useRequestResource({ endpoint: "auth/laboratory", query: "rocktests", resourceLabel: "Rock Test List" });
    const { addResource, deleteResource, updateResource, searchResources, searchList} = useRequestResource({ endpoint: "auth/laboratory", query: "rocktest", resourceLabel: "Rock Test" });
    const [exp, setExp] = useState("")
    const [ specimen, setSpecimen ] = useState("")
    const [ showInfiniteAddScroll, setShowInfiniteAddScroll ] = useState(false)
    const [ showInfiniteEditScroll, setShowInfiniteEditScroll ] = useState(false)
    const infiniteScrollRef = useRef(null); 
    const specimenTextField = useRef(null); 

    const editScrollRef = useRef(null); 
    const specimenEditField = useRef(null); 
    const [sorting_query, setSortingQuery] = useState(null)

    const [initialValues, setInitialValues] = useState({
        specimen: "",
        uncorrected_point_load: "",
        size_corrected_point_load_index: "",
        uniaxial_compressive_strength: ""
    });

    const [editedValues, setEditedValues] = useState({
        specimen: "",
        uncorrected_point_load: "",
        size_corrected_point_load_index: "",
        uniaxial_compressive_strength: "",
        has_supervisor_signed_borehole: false,
        has_witness_signed_borehole: false
    });

    const [idToEdit, setIdToEdit] = useState(null);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const sectionRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    useEffect(() => {
        getSpecimenList();
    }, [getSpecimenList])


    useEffect(() => {
        getRockTestList();
    }, [getRockTestList]);

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        console.log("inside handle outside click")
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (specimenTextField.current && specimenTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setShowInfiniteAddScroll(false)
            // Close the InfiniteScroll
        }

        if (editScrollRef.current && !editScrollRef.current.contains(event.target)) {
            if (specimenEditField.current && specimenEditField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setShowInfiniteEditScroll(false)
            // Close the InfiniteScroll
        }
      };



    const handleSubmit = values => {
        const formattedValues = {
            specimen: values.specimen === "" ? null: values.specimen ,
            uncorrected_point_load: values.uncorrected_point_load === "" ? null: values.uncorrected_point_load ,
            size_corrected_point_load_index: values.size_corrected_point_load_index === "" ? null: values.size_corrected_point_load_index ,
            uniaxial_compressive_strength: values.uniaxial_compressive_strength === "" ? null: values.uniaxial_compressive_strength 
        }
        
        addResource(formattedValues, () => {
            window.location.reload(); 
        })
    }

    const handleEditWindow = (e) => {
        setEditedValues({
            specimen: e.specimen === null ? "": e.specimen ,
            uncorrected_point_load: e.uncorrected_point_load === null ? "": e.uncorrected_point_load ,
            size_corrected_point_load_index: e.size_corrected_point_load_index === null ? "": e.size_corrected_point_load_index ,
            uniaxial_compressive_strength: e.uniaxial_compressive_strength === null ? "": e.uniaxial_compressive_strength,
            has_supervisor_signed_borehole: e.has_supervisor_signed_borehole,
            has_witness_signed_borehole: e.has_witness_signed_borehole
        })
        setIdToEdit(e.id);
        setIsEditVisible(true);
        setSpecimen(`${e.specimen_name}`);
    }

    useEffect(() => {
        if (isEditVisible && sectionRef.current) {
            setActiveKey('2')
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            document.addEventListener('click', handleOutsideClick);
          }
        }, [isEditVisible]);

    const handleEditSubmit = values => {
        const formattedValues = {
            specimen: values.specimen === "" ? null: values.specimen ,
            uncorrected_point_load: values.uncorrected_point_load === "" ? null: values.uncorrected_point_load ,
            size_corrected_point_load_index: values.size_corrected_point_load_index === "" ? null: values.size_corrected_point_load_index ,
            uniaxial_compressive_strength: values.uniaxial_compressive_strength === "" ? null: values.uniaxial_compressive_strength 
        }

        if (idToEdit) {
            updateResource(idToEdit, formattedValues, () => {
                window.location.reload(); 
            })
            return;
        } else {
            //console.log("No ID PROVIDED NO SERVICE!")
        }
    }

    const handleChangeAccordian = (eventKey) => {
        if (eventKey != '2') {
            setIdToEdit(null);
            setIsEditVisible(false);
            // in case a borehole drop down is open in edit but not selected, set borehole back to empty string
            // when user clicks out of the edit window.
            setSpecimen("");
        }

        setShowInfiniteAddScroll(false)
        setShowInfiniteEditScroll(false)
        setActiveKey(eventKey)
    }

    const fetchData = () => {
        getRockTestList(offset, sorting_query)
    }

    const fetchSpecimenData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getSpecimenList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setSpecimen(exp)
            getSearchAllList(exp, 0)
        } else {
            setSpecimen("")
            getSpecimenList(0)
        }
    }

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

        const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getRockTestList(0, sorting_query)
        }
    }, [sorting_query, getRockTestList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return rockTestList.results
        }
    }

    return (
        <div>
        <Dialog open={open} onClose={handleDeleteClose}>
            <DialogTitle>
                Are you sure you want to delete this??
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDelete}>
                    YES
                </Button>
                <Button onClick={handleDeleteClose}>
                    NO!
                </Button>
            </DialogActions>
        </Dialog>

        <Accordion activeKey = {activeKey} onSelect={handleChangeAccordian}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Add Rock Test</Accordion.Header>
                    <Accordion.Body>
                        <Paper sx={{
                            borderRadius: "2px",
                            bpxShadow: (theme) => theme.shadows[4],
                            padding: (theme) => theme.spacing(2, 4, 3)
                        }}>

                            <Grid item>
                                <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                            </Grid>
                            <Typography variant="h6" mh={4}>
                                Add Rock Test
                            </Typography>

                            <br />

                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="specimen"
                                                        label="Specimen"
                                                        {...formik.getFieldProps('specimen')}
                                                        value={specimen}
                                                        onChange={(e) => {formik.setFieldValue("specimen", ""); searchAll(e.target.value)}}
                                                        ref={specimenTextField}
                                                        onClick={() => setShowInfiniteAddScroll(true)}
                                                        error={(formik.touched.specimen) && Boolean(formik.errors.specimen)}
                                                        helperText={(formik.touched.specimen) && formik.errors.specimen}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                { showInfiniteAddScroll ? <Grid item xs={12}>
                                                <div ref={infiniteScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={specimenList.results.length}
                                                            next={fetchSpecimenData}
                                                            hasMore={currentSpecimenListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {specimenList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        formik.setFieldValue("specimen", option.id)
                                                                        formik.setFieldTouched("specimen", false)
                                                                        formik.setFieldError("specimen", "")
                                                                        setSpecimen(`${option.project_title} Borehole: ${option.borehole_number} : ${option.sample_type} ${option.sample_number} @ ${option.depth_meters}m`)
                                                                        setShowInfiniteAddScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title} Borehole: {option.borehole_number} : {option.sample_type} {option.sample_number} @ {option.depth_meters}m
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}
                                                    
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="uncorrected_point_load"
                                                            label="Uncorrected Point Load"
                                                            {...formik.getFieldProps('uncorrected_point_load')}
                                                            error={formik.touched.uncorrected_point_load && Boolean(formik.errors.uncorrected_point_load)}
                                                            helperText={formik.touched.uncorrected_point_load && formik.errors.uncorrected_point_load}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="size_corrected_point_load_index"
                                                            label="Size Corrected Point Load Index"
                                                            {...formik.getFieldProps('size_corrected_point_load_index')}
                                                            error={formik.touched.size_corrected_point_load_index && Boolean(formik.errors.size_corrected_point_load_index)}
                                                            helperText={formik.touched.size_corrected_point_load_index && formik.errors.size_corrected_point_load_index}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="uniaxial_compressive_strength"
                                                            label="Uniaxial Compressive Strength"
                                                            {...formik.getFieldProps('uniaxial_compressive_strength')}
                                                            error={formik.touched.uniaxial_compressive_strength && Boolean(formik.errors.uniaxial_compressive_strength)}
                                                            helperText={formik.touched.uniaxial_compressive_strength && formik.errors.uniaxial_compressive_strength}
                                                        />
                                                    </Grid>

                                                    

                                                    <Grid item xs={12} >

                                                        <Button
                                                            type="submit"
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Create New Rock Test
                                                        </Button>

                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
                        </Paper>
                    </Accordion.Body>
                </Accordion.Item>
 
            <Accordion.Item eventKey="1">
                <Accordion.Header>Rock Test List</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <TableContainer component={Paper}>
                                <h3 style={headerStyle}>
                                    Rock Tests
                                </h3>

                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="rock_test"
                                            label="Rock Test"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                
                                <div style={{ height: "20px" }} />

                                <Table size="small">
                                <TableHead width="100%">
                                        <TableRow>
                                        <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("specimen__borehole__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Project</div><div>{sorting_query === "specimen__borehole__project__project_title" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-specimen__borehole__project__project_title")}></FaSortUp> : 
                                                    sorting_query === "-specimen__borehole__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("specimen__borehole__project__project_title")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("specimen__borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Borehole</div><div>{sorting_query === "specimen__borehole__borehole_number" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-specimen__borehole__borehole_number")}></FaSortUp> : 
                                                    sorting_query === "-specimen__borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("specimen__borehole__borehole_number")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                Sample Type and Number
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("specimen__depth_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Depth Meters (m)</div><div>{sorting_query === "specimen__depth_meters" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-specimen__depth_meters")}></FaSortUp> : 
                                                    sorting_query === "-specimen__depth_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("specimen__depth_meters")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="10%">
                                                Action
                                            </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {
                                            getList().map((r) => {
                                                return (
                                                    <TableRow
                                                    >
                                                        <TableCell align="center" width="25%">
                                                            {r.project_title}
                                                        </TableCell>
                                                        <TableCell align="center" width="25%">
                                                            {r.borehole_number}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.sample_type} {r.sample_number}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.specimen_depth}
                                                        </TableCell>
                                                        <TableCell Aaign="right" width="10%">
                                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                                <IconButton size="small" onClick={() => {
                                                                    handleEditWindow(r);
                                                                }}>
                                                                    <PreviewIcon />
                                                                </IconButton>

                                                                { !(r.has_supervisor_signed_borehole && r.has_witness_signed_borehole) ? <IconButton size="small" onClick={() => {
                                                                    handleConfirmDelete(r.id)
                                                                }}>
                                                                    <DeleteIcon />
                                                                </IconButton> : <div></div>}
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {exp !== "" || currentRockTestListSize.current < offset ? <div></div> : <div className='centered-container'>
                                <Button onClick={fetchData}>
                                            LOAD MORE!
                                </Button>
                            </div>}
                        </div >
                    </Accordion.Body>
                </Accordion.Item>

                { isEditVisible? 
                <Accordion.Item eventKey="2" ref={sectionRef}>
                <Accordion.Header>Edit Rock Test</Accordion.Header>
                <Accordion.Body>
                        
                        <Paper sx={{
                                borderRadius: "2px",
                                bpxShadow: (theme) => theme.shadows[4],
                                padding: (theme) => theme.spacing(2, 4, 3)
                            }}>
    
                                <Grid item>
                                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                                </Grid>
                                <Typography variant="h6" mh={4}>
                                    Edit Rock Test
                                </Typography>
    
                                <br />
                                <Formik onSubmit={handleEditSubmit}
                                    initialValues={editedValues}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                >
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                    <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="specimen"
                                                        label="Specimen"
                                                        {...formik.getFieldProps('specimen')}
                                                        value={specimen}
                                                        onChange={(e) => {formik.setFieldValue("specimen", ""); searchAll(e.target.value)}}
                                                        ref={specimenEditField}
                                                        onClick={() => setShowInfiniteEditScroll(true)}
                                                        disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                        error={(formik.touched.specimen) && Boolean(formik.errors.specimen)}
                                                        helperText={(formik.touched.specimen) && formik.errors.specimen}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                { showInfiniteEditScroll ? <Grid item xs={12}>
                                                <div ref={editScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={specimenList.results.length}
                                                            next={fetchSpecimenData}
                                                            hasMore={currentSpecimenListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {specimenList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        //console.log("inside on click")
                                                                        formik.setFieldValue("specimen", option.id)
                                                                        formik.setFieldTouched("specimen", false)
                                                                        formik.setFieldError("specimen", "")
                                                                        setSpecimen(`${option.project_title} Borehole: ${option.borehole_number} : ${option.sample_type} ${option.sample_number} @ ${option.depth_meters}m`)
                                                                        setShowInfiniteEditScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title} Borehole: {option.borehole_number} : {option.sample_type} {option.sample_number} @ {option.depth_meters}m
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}
                                                            
                                                <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="uncorrected_point_load"
                                                            label="Uncorrected Point Load"
                                                            {...formik.getFieldProps('uncorrected_point_load')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.uncorrected_point_load && Boolean(formik.errors.uncorrected_point_load)}
                                                            helperText={formik.touched.uncorrected_point_load && formik.errors.uncorrected_point_load}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="size_corrected_point_load_index"
                                                            label="Size Corrected Point Load Index"
                                                            {...formik.getFieldProps('size_corrected_point_load_index')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.size_corrected_point_load_index && Boolean(formik.errors.size_corrected_point_load_index)}
                                                            helperText={formik.touched.size_corrected_point_load_index && formik.errors.size_corrected_point_load_index}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="uniaxial_compressive_strength"
                                                            label="Uniaxial Compressive Strength"
                                                            {...formik.getFieldProps('uniaxial_compressive_strength')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.uniaxial_compressive_strength && Boolean(formik.errors.uniaxial_compressive_strength)}
                                                            helperText={formik.touched.uniaxial_compressive_strength && formik.errors.uniaxial_compressive_strength}
                                                        />
                                                    </Grid>
    
                                                        
    
                                                    {editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole ? <div></div> : <Grid item xs={12} >
    
                                                            <Button
                                                                type="submit"
                                                                size="medium"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Save Rock Test
                                                            </Button>

                                                        </Grid> }
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Paper>
                        </Accordion.Body>
                </Accordion.Item>
                :<div></div>
                }
        </Accordion>
        </div>

    )
}
export default RockTests;