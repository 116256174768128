import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import ColorPicker from "src/components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from 'src/components/utils/DropdownSelect';
import DatePicker from 'react-datepicker';
import Accordion from 'react-bootstrap/Accordion';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    depth_meters: yup.string()
    .required('Depth meters is required')
    .test('number', 'Depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Depth meters must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Depth meters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Depth meters must be less than or equal to 9999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.99;
        }
        return true;
    }),
    sample_diameter_millimeters: yup.string().required("Sample diameters is required"),
    length_centimeters: yup.string()
    .required('Length centimeters is required')
    .test('number', 'Length centimeters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Length centimeters must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Length centimeters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Length centimeters must be less than or equal to 100', (value) => {
        if (value) {
            return parseFloat(value) <= 100;
        }
        return true;
    }),
    recovery_centimeters: yup.string()
    .required('Recovery centimeters is required')
    .test('number', 'Recovery centimeters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Recovery centimeters must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Recovery centimeters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Recovery centimeters must be less than or equal to 100', (value) => {
        if (value) {
            return parseFloat(value) <= 100;
        }
        return true;
    }),
    total_core_recovery_percentage: yup.string()
    .required('Total core recovery centimeters is required')
    .test('number', 'Total core recovery centimeters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Total core recovery centimeters must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Total core recovery centimeters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Total core recovery centimeters must be less than or equal to 100', (value) => {
        if (value) {    
            return parseFloat(value) <= 100;
        }
        return true;
    }),
    solid_core_recovery_percentage: yup.string()
    .required('Solid core recovery centimeters is required')
    .test('number', 'Solid core recovery centimeters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Solid core recovery centimeters must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Solid core recovery centimeters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Solid core recovery centimeters must be less than or equal to 100', (value) => {
        if (value) {
            return parseFloat(value) <= 100;
        }
        return true;
    }),
    rock_quality_designation_percentage: yup.string()
    .required('Rock quality designation is required')
    .test('number', 'Rock quality designation must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Rock quality designation must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Rock quality designation must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Rock quality designation must be less than or equal to 100', (value) => {
        if (value) {
            return parseFloat(value) <= 100;
        }
        return true;
    }),
    fracture_per_meter: yup.string().required("FRM is required")
        .matches('^([0-9]|1[0-5]|>15|-)$', "Fracture per meter expects values from 0 to 15 or >15 if value is more than 15.")//Need valid data whether value is 0 - 15 or >15 or -
}) 

const RockResults = () => {
    const offset = 25;
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole List" });
    const { addResource: addRockResultList, deleteResource: deleteRockResultList, getResourceList: getRockResultList,
        resourceList: rockResultList, currentListSize: rockResultCurrentListSize, searchResources, searchList} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "rockresults", resourceLabel: "Rock Results" });
    const { resources : rockResultReadingResource, updateResource :  updateRockResultResource} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "rockresult", resourceLabel: "Rock Result" });
    
    const [exp, setExp] = useState("")
    const [ borehole, setBorehole ] = useState("")
    const [ showInfiniteAddScroll, setShowInfiniteAddScroll ] = useState(false)
    const [ showInfiniteEditScroll, setShowInfiniteEditScroll ] = useState(false)
    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 
    const editScrollRef = useRef(null); 
    const boreholeEditField = useRef(null); 
    const [sorting_query, setSortingQuery] = useState(null)

    const [initialValues, setInitialValues ] = useState({
        borehole: "",
        depth_meters: "",
        sample_diameter_millimeters: "",
        length_centimeters: "",
        recovery_centimeters: "",
        total_core_recovery_percentage: "",
        solid_core_recovery_percentage: "",
        rock_quality_designation_percentage: "",
        fracture_per_meter: ""
    });

    const [editedValues, setEditedValues] = useState({
        borehole: "",
        depth_meters: "",
        sample_diameter_millimeters: "",
        length_centimeters: "",
        recovery_centimeters: "",
        total_core_recovery_percentage: "",
        solid_core_recovery_percentage: "",
        rock_quality_designation_percentage: "",
        fracture_per_meter: "",
        has_supervisor_signed_borehole: false,
        has_witness_signed_borehole: false
    });

    // to change to useRef
    const [idToEdit, setIdToEdit] = useState(null);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const sectionRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteRockResultList(idToDelete);
    }

    useEffect(() => {
        getResourceList();
    }, [getResourceList])


    useEffect(() => {
        getRockResultList();
    }, [getRockResultList]);

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setShowInfiniteAddScroll(false)
            // Close the InfiniteScroll
        }

        if (editScrollRef.current && !editScrollRef.current.contains(event.target)) {
            if (boreholeEditField.current && boreholeEditField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setShowInfiniteEditScroll(false)
            // Close the InfiniteScroll
        }
      };
    
    const handleSubmit = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null : values.borehole,
            depth_meters: values.depth_meters === "" ? null : values.depth_meters,
            sample_diameter_millimeters: values.sample_diameter_millimeters === "" ? null : values.sample_diameter_millimeters,
            length_centimeters: values.length_centimeters === "" ? null : values.length_centimeters,
            recovery_centimeters: values.recovery_centimeters === "" ? null : values.recovery_centimeters,
            total_core_recovery_percentage: values.total_core_recovery_percentage === "" ? null : values.total_core_recovery_percentage,
            solid_core_recovery_percentage: values.solid_core_recovery_percentage === "" ? null : values.solid_core_recovery_percentage,
            rock_quality_designation_percentage: values.rock_quality_designation_percentage === "" ? null : values.rock_quality_designation_percentage ,
            fracture_per_meter: values.fracture_per_meter === "" ? null : values.fracture_per_meter,
        }
        addRockResultList(formattedValues, () => {
            window.location.reload(); 
        })
    }

    const handleEditWindow = (e) => {
        setEditedValues({
            borehole: e.borehole,
            depth_meters: e.depth_meters,
            sample_diameter_millimeters: e.sample_diameter_millimeters,
            length_centimeters: e.length_centimeters,
            recovery_centimeters: e.recovery_centimeters,
            total_core_recovery_percentage: e.total_core_recovery_percentage,
            solid_core_recovery_percentage: e.solid_core_recovery_percentage,
            rock_quality_designation_percentage: e.rock_quality_designation_percentage,
            fracture_per_meter: e.fracture_per_meter,
            has_supervisor_signed_borehole: e.has_supervisor_signed_borehole,
            has_witness_signed_borehole: e.has_witness_signed_borehole
        })
        setIdToEdit(e.id);
        setIsEditVisible(true);
        setBorehole(`${e.project_title} Borehole: ${e.borehole_number}`);
    }

    useEffect(() => {
        if (isEditVisible && sectionRef.current) {
            setActiveKey('2')
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            document.addEventListener('click', handleOutsideClick);
          }
        }, [isEditVisible]);


    const handleEditSubmit = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null : values.borehole,
            depth_meters: values.depth_meters === "" ? null : values.depth_meters,
            sample_diameter_millimeters: values.sample_diameter_millimeters === "" ? null : values.sample_diameter_millimeters,
            length_centimeters: values.length_centimeters === "" ? null : values.length_centimeters,
            recovery_centimeters: values.recovery_centimeters === "" ? null : values.recovery_centimeters,
            total_core_recovery_percentage: values.total_core_recovery_percentage === "" ? null : values.total_core_recovery_percentage,
            solid_core_recovery_percentage: values.solid_core_recovery_percentage === "" ? null : values.solid_core_recovery_percentage,
            rock_quality_designation_percentage: values.rock_quality_designation_percentage === "" ? null : values.rock_quality_designation_percentage ,
            fracture_per_meter: values.fracture_per_meter === "" ? null : values.fracture_per_meter,
        }

        if (idToEdit) {
            updateRockResultResource(idToEdit, formattedValues, () => {
                window.location.reload(); 
            })
            return;
        } else {
            //console.log("No ID PROVIDED NO SERVICE!")
        }
    }

    const handleChangeAccordian = (eventKey) => {
        if (eventKey != '2') {
            setIdToEdit(null);
            setIsEditVisible(false);
            // in case a borehole drop down is open in edit but not selected, set borehole back to empty string
            // when user clicks out of the edit window.
            setBorehole("");
        }

        setShowInfiniteAddScroll(false)
        setShowInfiniteEditScroll(false)
        setActiveKey(eventKey)
    }

    const fetchData = () => {
        getRockResultList(offset, sorting_query)
    }

    const fetchBoreholeData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    const sampleDiameterOptions = [
        {value: '52', label: '52'},
        {value: '55', label: '55'},
    ]

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getRockResultList(0, sorting_query)
        }
    }, [sorting_query, getRockResultList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return rockResultList.results
        }
    }
    
    return (
        <div>
        <Dialog open={open} onClose={handleDeleteClose}>
            <DialogTitle>
                Are you sure you want to delete this??
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDelete}>
                    YES
                </Button>
                <Button onClick={handleDeleteClose}>
                    NO!
                </Button>
            </DialogActions>
        </Dialog>

        <Accordion activeKey = {activeKey} onSelect={handleChangeAccordian}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Add Rock Result Information</Accordion.Header>
                    <Accordion.Body>
                        <Paper sx={{
                            borderRadius: "2px",
                            bpxShadow: (theme) => theme.shadows[4],
                            padding: (theme) => theme.spacing(2, 4, 3)
                        }}>

                            <Grid item>
                                <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                            </Grid>
                            <Typography variant="h6" mh={4}>
                                Add Rock Result Information
                            </Typography>

                            <br />

                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="borehole"
                                                        label="Borehole"
                                                        {...formik.getFieldProps('borehole')}
                                                        value={borehole}
                                                        onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                        ref={boreholeTextField}
                                                        onClick={() => setShowInfiniteAddScroll(true)}
                                                        error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                        helperText={(formik.touched.borehole) && formik.errors.borehole}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                { showInfiniteAddScroll ? <Grid item xs={12}>
                                                <div ref={infiniteScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={resourceList.results.length}
                                                            next={fetchBoreholeData}
                                                            hasMore={currentListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {resourceList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        formik.setFieldValue("borehole", option.id)
                                                                        formik.setFieldTouched("borehole", false)
                                                                        formik.setFieldError("borehole", "")
                                                                        setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                        setShowInfiniteAddScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title} Borehole: {option.borehole_number}
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="depth_meters"
                                                        label="Depth Meters"
                                                        {...formik.getFieldProps('depth_meters')}
                                                        error={formik.touched.depth_meters && Boolean(formik.errors.depth_meters)}
                                                        helperText={formik.touched.depth_meters && formik.errors.depth_meters}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="sample_diameter_millimeters"
                                                        label="Sample Diameter Millimeters"
                                                        {...formik.getFieldProps('sample_diameter_millimeters')}
                                                        select
                                                        value={formik.values.sample_diameter_millimeters}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        error={formik.touched.sample_diameter_millimeters && Boolean(formik.errors.sample_diameter_millimeters)}
                                                        helperText={formik.touched.sample_diameter_millimeters && formik.errors.sample_diameter_millimeters}
                                                    >
                                                        {sampleDiameterOptions.map((option, index) => (
                                                            <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="length_centimeters"
                                                        label="Length Centimeters"
                                                        {...formik.getFieldProps('length_centimeters')}
                                                        error={formik.touched.length_centimeters && Boolean(formik.errors.length_centimeters)}
                                                        helperText={formik.touched.length_centimeters && formik.errors.length_centimeters}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="recovery_centimeters"
                                                        label="Recovery Centimeters"
                                                        {...formik.getFieldProps('recovery_centimeters')}
                                                        error={formik.touched.recovery_centimeters && Boolean(formik.errors.recovery_centimeters)}
                                                        helperText={formik.touched.recovery_centimeters && formik.errors.recovery_centimeters}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="total_core_recovery_percentage"
                                                        label="Total Core Recovery Percentage"
                                                        {...formik.getFieldProps('total_core_recovery_percentage')}
                                                        error={formik.touched.total_core_recovery_percentage && Boolean(formik.errors.total_core_recovery_percentage)}
                                                        helperText={formik.touched.total_core_recovery_percentage && formik.errors.total_core_recovery_percentage}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="solid_core_recovery_percentage"
                                                        label="Solid Core Recovery Percentage"
                                                        {...formik.getFieldProps('solid_core_recovery_percentage')}
                                                        error={formik.touched.solid_core_recovery_percentage && Boolean(formik.errors.solid_core_recovery_percentage)}
                                                        helperText={formik.touched.solid_core_recovery_percentage && formik.errors.solid_core_recovery_percentage}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="rock_quality_designation_percentage"
                                                        label="Rock Quality Designation Percentage"
                                                        {...formik.getFieldProps('rock_quality_designation_percentage')}
                                                        error={formik.touched.rock_quality_designation_percentage && Boolean(formik.errors.rock_quality_designation_percentage)}
                                                        helperText={formik.touched.rock_quality_designation_percentage && formik.errors.rock_quality_designation_percentage}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="fracture_per_meter"
                                                        label="Fracture Per Meter"
                                                        {...formik.getFieldProps('fracture_per_meter')}
                                                        error={formik.touched.fracture_per_meter && Boolean(formik.errors.fracture_per_meter)}
                                                        helperText={formik.touched.fracture_per_meter && formik.errors.fracture_per_meter}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} >

                                                    <Button
                                                        type="submit"
                                                        size="medium"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Create New Rock Result
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        </form>
                                    )
                                }
                            }
                        </Formik>
                    </Paper>
                </Accordion.Body>
            </Accordion.Item>
 
            <Accordion.Item eventKey="1">
                <Accordion.Header>Rock Result List</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <TableContainer component={Paper}>
                                <h3 style={headerStyle}>
                                    Rock Results
                                </h3>

                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="rock_result"
                                            label="Rock Result"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>

                                <div style={{ height: "20px" }} />

                                <Table size="small">
                                    <TableHead width="100%">
                                        <TableRow>
                                        <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("borehole__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Project</div><div>{sorting_query === "borehole__project__project_title" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__project__project_title")}></FaSortUp> : 
                                                    sorting_query === "-borehole__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__project__project_title")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Borehole</div><div>{sorting_query === "borehole__borehole_number" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__borehole_number")}></FaSortUp> : 
                                                    sorting_query === "-borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__borehole_number")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("depth_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Depth meters</div><div>{sorting_query === "depth_meters" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-depth_meters")}></FaSortUp> : 
                                                    sorting_query === "-depth_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("depth_meters")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                Action
                                            </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {
                                            getList().map((r) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell align="center" width="30%">
                                                            {r.project_title}
                                                        </TableCell>
                                                        <TableCell align="center" width="30%">
                                                            {r.borehole_number}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.depth_meters}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            <Box sx={{ display: "flex", justifyContent: "center" }} >
                                                                <IconButton size="small" onClick={() => {
                                                                    handleEditWindow(r);
                                                                }}>
                                                                    <PreviewIcon />
                                                                </IconButton>

                                                                { !(r.has_supervisor_signed_borehole && r.has_witness_signed_borehole) ? <IconButton size="small" onClick={() => {
                                                                    handleConfirmDelete(r.id)
                                                                }}>
                                                                    <DeleteIcon />
                                                                </IconButton> : <div></div>}
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {exp !== "" || rockResultCurrentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                <Button onClick={fetchData}>
                                            LOAD MORE!
                                </Button>
                            </div>}
                        </div >
                    </Accordion.Body>
                </Accordion.Item>

                { isEditVisible?  
                <Accordion.Item eventKey="2" ref={sectionRef}>
                <Accordion.Header>Edit Rock Result</Accordion.Header>
                <Accordion.Body>
                        
                    <Paper sx={{
                            borderRadius: "2px",
                            bpxShadow: (theme) => theme.shadows[4],
                            padding: (theme) => theme.spacing(2, 4, 3)
                        }}>

                            <Grid item>
                                <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                            </Grid>
                            <Typography variant="h6" mh={4}>
                                Edit Rock Result
                            </Typography>

                            <br />
                            <Formik onSubmit={handleEditSubmit}
                                initialValues={editedValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="borehole"
                                                        label="Borehole"
                                                        {...formik.getFieldProps('borehole')}
                                                        disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                        value={borehole}
                                                        onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                        ref={boreholeEditField}
                                                        onClick={() => setShowInfiniteEditScroll(true)}
                                                        error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                        helperText={(formik.touched.borehole) && formik.errors.borehole}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                { showInfiniteEditScroll ? <Grid item xs={12}>
                                                <div ref={editScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={resourceList.results.length}
                                                            next={fetchBoreholeData}
                                                            hasMore={currentListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {resourceList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        formik.setFieldValue("borehole", option.id)
                                                                        formik.setFieldTouched("borehole", false)
                                                                        formik.setFieldError("borehole", "")
                                                                        setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                        setShowInfiniteEditScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title} Borehole: {option.borehole_number}
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="depth_meters"
                                                            label="Depth Meters"
                                                            {...formik.getFieldProps('depth_meters')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.depth_meters && Boolean(formik.errors.depth_meters)}
                                                            helperText={formik.touched.depth_meters && formik.errors.depth_meters}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="sample_diameter_millimeters"
                                                            label="Sample Diameter Millimeters"
                                                            {...formik.getFieldProps('sample_diameter_millimeters')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            select
                                                            value={formik.values.sample_diameter_millimeters}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            error={formik.touched.status && Boolean(formik.errors.sample_diameter_millimeters)}
                                                            helperText={formik.touched.status && formik.errors.sample_diameter_millimeters}
                                                        >
                                                            {sampleDiameterOptions.map((option, index) => (
                                                                <MenuItem key={index} value={option.value}>
                                                                {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="length_centimeters"
                                                            label="Length Centimeters"
                                                            {...formik.getFieldProps('length_centimeters')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.length_centimeters && Boolean(formik.errors.length_centimeters)}
                                                            helperText={formik.touched.length_centimeters && formik.errors.length_centimeters}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="recovery_centimeters"
                                                            label="Recovery Centimeters"
                                                            {...formik.getFieldProps('recovery_centimeters')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.recovery_centimeters && Boolean(formik.errors.recovery_centimeters)}
                                                            helperText={formik.touched.recovery_centimeters && formik.errors.recovery_centimeters}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="total_core_recovery_percentage"
                                                            label="Total Core Recovery Percentage"
                                                            {...formik.getFieldProps('total_core_recovery_percentage')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.total_core_recovery_percentage && Boolean(formik.errors.total_core_recovery_percentage)}
                                                            helperText={formik.touched.total_core_recovery_percentage && formik.errors.total_core_recovery_percentage}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="solid_core_recovery_percentage"
                                                            label="Solid Core Recovery Percentage"
                                                            {...formik.getFieldProps('solid_core_recovery_percentage')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.solid_core_recovery_percentage && Boolean(formik.errors.solid_core_recovery_percentage)}
                                                            helperText={formik.touched.solid_core_recovery_percentage && formik.errors.solid_core_recovery_percentage}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="rock_quality_designation_percentage"
                                                            label="Rock Quality Designation Percentage"
                                                            {...formik.getFieldProps('rock_quality_designation_percentage')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.rock_quality_designation_percentage && Boolean(formik.errors.rock_quality_designation_percentage)}
                                                            helperText={formik.touched.rock_quality_designation_percentage && formik.errors.rock_quality_designation_percentage}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="fracture_per_meter"
                                                            label="Fracture Per Meter"
                                                            {...formik.getFieldProps('fracture_per_meter')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.fracture_per_meter && Boolean(formik.errors.fracture_per_meter)}
                                                            helperText={formik.touched.fracture_per_meter && formik.errors.fracture_per_meter}
                                                        />
                                                    </Grid>

                                                    

                                                    {editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole ? <div></div> : <Grid item xs={12} >
    
                                                            <Button
                                                                type="submit"
                                                                size="medium"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Save Rock Result
                                                            </Button>

                                                        </Grid> }
                                                </Grid>
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
                        </Paper>
                    </Accordion.Body>
                </Accordion.Item>
                :<div></div>
                }
        </Accordion>
        </div>

    )
}
export default RockResults;