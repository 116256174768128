import React, { useEffect, useState } from 'react'
import { Formik } from "formik";
import { Grid, TextField, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";


export default function StratumResultList() {
    const { resourceList, getResourceList, currentListSize} = useRequestResource({ endpoint: "auth/borehole_log", query: "strataresult", resourceLabel: "Sample List" });
    const {deleteResource, searchResources, searchList} = useRequestResource({ endpoint: "auth/borehole_log", query: "stratumresult", resourceLabel: "Delete Stratum result" })
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [exp, setExp] = useState("")
    const navigate = useNavigate();
    const offset = 25
    const [sorting_query, setSortingQuery] = useState(null)

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    useEffect(() => {
        getResourceList();
    }, [getResourceList]);

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12} >
                <Grid item xs={12} align="left">
                    <Link
                        to="/createStratumResult" 
                        key="stratumResult"
                    >
                        <Button 
                        size="medium"
                        variant="contained"
                        color="primary"
                        >
                            <AddIcon></AddIcon>
                            Create New Stratum Result
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <div style={{ height: "20px" }} />

            <TableContainer component={Paper}>
                <h3 style={headerStyle}>
                    Stratum Results
                </h3>

                <Grid item xs={12} >
                    <Search fullWidth>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"   
                            inputProps={{ 'aria-label': 'search' }}
                            fullWidth
                            id="stratum_result"
                            label="Stratum Result"
                            value={exp}
                            onChange={(e) => setExp(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Search>
                </Grid>

                <div style={{ height: "20px" }} />

                <Table size="small">
                    <TableHead width="100%">
                        <TableRow>

                            <TableCell align="center" width="40%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("borehole__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Project</div><div>{sorting_query === "borehole__project__project_title" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__project__project_title")}></FaSortUp> : 
                                    sorting_query === "-borehole__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__project__project_title")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>
                            
                            <TableCell align="center" width="25%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Borehole</div><div>{sorting_query === "borehole__borehole_number" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__borehole_number")}></FaSortUp> : 
                                    sorting_query === "-borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__borehole_number")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="25%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("soil_layer_start_depth_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Soil Layer start depth meters</div><div>{sorting_query === "soil_layer_start_depth_meters" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-soil_layer_start_depth_meters")}></FaSortUp> : 
                                    sorting_query === "-soil_layer_start_depth_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("soil_layer_start_depth_meters")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="right"  width="10%">
                                Action
                            </TableCell>

                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {
                            getList().map((r) => {
                                return (
                                    <TableRow
                                        key={r.id}
                                    >
                                        <TableCell align="center" width="40%">
                                            {r.project_title}
                                        </TableCell>
                                        <TableCell align="center" width="25%">
                                            {r.borehole_number}
                                        </TableCell>
                                        <TableCell align="center" width="25%">
                                            {r.soil_layer_start_depth_meters}
                                        </TableCell>
                                        <TableCell align="center" width="10%">
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                <Link 
                                                    to={`/${r.id}/editStratumResult`} 
                                                    key="Instrument-Installation-Details"
                                                >
                                                    <IconButton size="small">
                                                        <EditIcon />
                                                    </IconButton>
                                                </Link>

                                                <IconButton size="small" onClick={() => {
                                                    handleConfirmDelete(r.id)
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                <Button onClick={fetchData}>
                            LOAD MORE!
                </Button>
            </div>}
        </div >
    )
}
