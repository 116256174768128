import React from 'react';
import { Navigate, Outlet } from "react-router-dom";

import { AuthContext } from 'src/contexts/AuthContextProvider';

export default function RequireAuth() {

    const { isAuthenticated } = React.useContext(AuthContext);

    if (isAuthenticated === null) {
        return <div>Loading...</div>
    }
    if (isAuthenticated === true) {
        // Renders the child route's element, if there is one.
        return (<Outlet />);
    }

    return (
        <Navigate to="/login/token" />
    )
}
