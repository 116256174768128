// rfc tab for boilerplate
import React, { useEffect, useState } from 'react';
import { Grid, Button, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import useRequestResource from 'src/hooks/useRequestResource';
import ColorBox from "src/components/ColorBox";
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

export default function ProjectLists() {
    const {getResourceList, resourceList, deleteResource, searchResources, searchList, currentListSize} = useRequestResource({ endpoint: "auth/borehole_log", query: `projects`, resourceLabel: "Project List" });
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    let offset = 25;
    const [exp, setExp] = useState("")
    const [sorting_query, setSortingQuery] = useState(null)

    useEffect(() => {
        getResourceList();
    }, [getResourceList])

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    const is_senior_geologist = localStorage.getItem("is_senior_geologist")
    const is_manager = localStorage.getItem("is_manager")
    const has_edit_access = (is_manager === "true" || is_senior_geologist === "true")

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>

                { has_edit_access ?
                <Grid item xs={12} >
                    <Grid item xs={12} align="left">
                        <Link
                            to="/createproject" 
                            key="project"
                        >
                            <Button 
                            size="medium"
                            variant="contained"
                            color="primary"
                            >
                                <AddIcon></AddIcon>
                                Create New Project
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            : <div></div>}

            <div style={{ height: "20px" }} />

            <TableContainer component={Paper}>
            <h3 style={headerStyle}>
                    Project List
                </h3>

                 
                <Grid item xs={12} >
                    <Search fullWidth>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            fullWidth
                            id="backfillandgrouting"
                            label="Backfill And Grouting"
                            value={exp}
                            onChange={(e) => setExp(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Search>
                </Grid>
                
                <div style={{ height: "20px" }} />
                
                <Table sx={{ minWidth: 360 }} size="small">
                    <TableHead width="100%">
                        <TableRow>
                            <TableCell align="center" width="20%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("client_name")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Client Name</div><div>{sorting_query === "client_name" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-client_name")}></FaSortUp> : 
                                    sorting_query === "-client_name" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("client_name")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="20%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("consultant_name")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Consultant Name</div><div>{sorting_query === "consultant_name" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-consultant_name")}></FaSortUp> : 
                                    sorting_query === "-consultant_name" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("consultant_name")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="40%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Project Title</div><div>{sorting_query === "project_title" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-project_title")}></FaSortUp> : 
                                    sorting_query === "-project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("project_title")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="20%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("location")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Location</div><div>{sorting_query === "location" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-location")}></FaSortUp> : 
                                    sorting_query === "-location" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("location")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            { has_edit_access ? <TableCell align="center" width="25%">
                                Action
                            </TableCell> : <div></div>}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            getList().map((r) => {
                                return (
                                    <TableRow key={r.id}>
                                        <TableCell align="center">
                                            {r.client_name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {r.consultant_name}
                                        </TableCell>
                                        <TableCell align="center">
                                            {r.project_title}
                                        </TableCell>
                                        <TableCell align="center">
                                            {r.location}
                                        </TableCell>
                                        { has_edit_access ? <TableCell align="center">
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                <Link to={`/${r.id}/project`} key="Project-Details">
                                                    <IconButton size="large">
                                                        <EditIcon />
                                                    </IconButton>
                                                </Link>
                                                <IconButton size="large" onClick={() => {
                                                    handleConfirmDelete(r.id)
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell> : <div></div>}
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                
            </TableContainer>
            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                <Button onClick={fetchData}>
                            LOAD MORE!
                </Button>
            </div>}
        </div>
    )
}
