import * as React from 'react';
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik } from "formik";
import * as yup from "yup";
import useRequestAuth from 'src/hooks/useRequestAuth';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { render } from '@testing-library/react';
import useRequestResource from 'src/hooks/useRequestResource';
import { Card } from 'reactstrap';



// Yup is a JavaScript schema builder for value parsing and validation
const validationSchema = yup.object({
    new_password: yup.string().required("New password is required").max(150, "Max Length is 150!"),
    new_password_confirmation: yup.string().required("New password confirmation is required").max(150, "Max Length is 150!")
})




const theme = createTheme();

export default function EmailSent() {
    // Link the useRequestAuth function and value

    function handleSignIn() {
        window.location = "/"
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" style={{textAlign: "center"}}>
                    Password reset Email has been Sent. Please check you email for details
                </Typography>
                <div style={{ margin: '20px' }}></div>
                <Grid item xs={12}>
                    <Button
                        onClick={handleSignIn}
                        size="medium"
                        variant="contained"
                        color="primary"
                    >
                        Go To Sign In 
                    </Button>
                </Grid>
            </Box>
        </Container>
    );
}