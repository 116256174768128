import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import InfiniteScroll from 'react-infinite-scroll-component';


const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    test_type: yup.string().required("Test Type is required"),
    test_number: yup.string()
    .required('Test number is required')
    .test('number', 'Test number must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Test number must must be a whole number', (value) => {
        if (value) {
        const pattern = /^(-?0|-?[1-9]\d*)$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Test number must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    }),
    depth_to_top_meters: yup.string()
    .required('Depth to top meters is required')
    .test('number', 'Depth to top meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Depth to top meters must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Depth to top meters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Depth to top meters must be less than or equal to 9999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.99;
        }
        return true;
    }),
    depth_to_base_meters: yup.string()
    .required('Depth to base meters is required')
    .test('number', 'Depth to base meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Depth to base meters must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Depth to base meters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Depth to base meters must be less than or equal to 9999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 9999.99;
        }
        return true;
    }),
    result_1: yup.string()
    .test('number', 'Result 1 must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Result 1 must have at most 10 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,10})?$/;
        return pattern.test(value);
        }
        return true;
    }),
    result_2: yup.string()
    .test('number', 'Result 2 must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Result 2 must have at most 10 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,10})?$/;
        return pattern.test(value);
        }
        return true;
    }),
    result_3: yup.string()
    .test('number', 'Result 3 must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Result 3 must have at most 10 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,10})?$/;
        return pattern.test(value);
        }
        return true;
    }),
    result_4: yup.string()
    .test('number', 'Result 4 must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Result 4 must have at most 10 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,10})?$/;
        return pattern.test(value);
        }
        return true;
    }),
})

export default function SiteTestDetails() {
    const { addResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "sitetest", resourceLabel: "Site Test Details" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList } = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole list"})
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [borehole, setBorehole] = useState("")
    const [showInfiniteScroll, setInfiniteScroll] = useState(false)
    let offset = 25;
    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 
    const [exp, setExp] = useState("")


    const [initialValues, setInitialValues] = useState({
        borehole: "",
        test_type: "",
        test_number: "",
        depth_to_top_meters: "",
        depth_to_base_meters: "",
        result_1: "",
        result_2: "",
        result_3: "",
        result_4: "",
    });


    const testTypeOptions = [
        {value: 'VST', label: 'Vane Shear Test'},
        {value: 'PMT', label: 'Pressuremeter Test'},
        {value: 'PBT', label: 'Permeability Test'},
        {value: 'PKT', label: 'Packer Test'}
    ]
        
    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }

    useEffect(() => {
        if (image != null) {
            setImageURL(URL.createObjectURL(image));
        } else {
            setImageURL(null);
        }
        getResourceList();
    }, [getResourceList, image])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

      function onImageChange(e) {
        setImage(e.target.files[0])
    }

    function deleteImage(e) {
        e.preventDefault();
        setImage(null);
    }


    const formatValues = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null : values.borehole,
            test_type: values.test_type === "" ? null : values.test_type,
            test_number: values.test_number === "" ? null : values.test_number,
            depth_to_top_meters: values.depth_to_top_meters === "" ? null : values.depth_to_top_meters,
            depth_to_base_meters: values.depth_to_base_meters === "" ? null : values.depth_to_base_meters,
            result_1: values.result_1 === "" ? null : values.result_1,
            result_2: values.result_2 === "" ? null : values.result_2,
            result_3: values.result_3 === "" ? null : values.result_3,
            result_4: values.result_4 === "" ? null : values.result_4,
        }
        
        let formData = new FormData();
        formData.append(`image`, image);
        formData.set("json_data", JSON.stringify(formattedValues));
        return formData;
    }

    const handleSubmit = values => {
        addResource(formatValues(values), () => {
            navigate(`/sitetests`)
        })
    }

    const handleSubmitReload = values => {
        addResource(formatValues(values), () => {
            window.location.reload();
        })
    }


    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
                
            }}>
                <Typography variant="h6" mh={4}>
                    Site Test
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                ref={boreholeTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="test_type"
                                                label="Test Type"
                                                {...formik.getFieldProps('test_type')}
                                                select
                                                value={formik.values.test_type}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                }}
                                                error={formik.touched.test_type && Boolean(formik.errors.test_type)}
                                                helperText={formik.touched.test_type && formik.errors.test_type}
                                            >
                                                {testTypeOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="test_number"
                                                label="Site Test Number"
                                                {...formik.getFieldProps('test_number')}
                                                error={formik.touched.test_number && Boolean(formik.errors.test_number)}
                                                helperText={formik.touched.test_number && formik.errors.test_number}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="depth_to_top_meters"
                                                label="Depth to Top meters"
                                                {...formik.getFieldProps('depth_to_top_meters')}
                                                error={formik.touched.depth_to_top_meters && Boolean(formik.errors.depth_to_top_meters)}
                                                helperText={formik.touched.depth_to_top_meters && formik.errors.depth_to_top_meters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="depth_to_base_meters"
                                                label="Depth to Base meters"
                                                {...formik.getFieldProps('depth_to_base_meters')}
                                                error={formik.touched.depth_to_base_meters && Boolean(formik.errors.depth_to_base_meters)}
                                                helperText={formik.touched.depth_to_base_meters && formik.errors.depth_to_base_meters}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="result_1"
                                                label="Result 1"
                                                {...formik.getFieldProps('result_1')}
                                                error={formik.touched.result_1 && Boolean(formik.errors.result_1)}
                                                helperText={formik.touched.result_1 && formik.errors.result_1}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="result_2"
                                                label="Result 2"
                                                {...formik.getFieldProps('result_2')}
                                                error={formik.touched.result_2 && Boolean(formik.errors.result_2)}
                                                helperText={formik.touched.result_2 && formik.errors.result_2}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="result_3"
                                                label="Result 3"
                                                {...formik.getFieldProps('result_3')}
                                                error={formik.touched.result_3 && Boolean(formik.errors.result_3)}
                                                helperText={formik.touched.result_3 && formik.errors.result_3}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="result_4"
                                                label="Result 4"
                                                {...formik.getFieldProps('result_4')}
                                                error={formik.touched.result_4 && Boolean(formik.errors.result_4)}
                                                helperText={formik.touched.result_4 && formik.errors.result_4}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <input type="file" accept="image/*" onChange={onImageChange} />
                                        </Grid>


                                        <Grid item xs={12}>
                                            {imageURL != null && (
                                                <Grid> 
                                                <img src={imageURL} />
                                                <button onClick={(e) => { deleteImage(e) }} disabled={initialValues.has_supervisor_signed_borehole && initialValues.has_witness_signed_borehole}>Delete</button>
                                                </Grid>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/sitetests`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Site Test
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                onClick={(event) => handleSubmitReload(formik.values)}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save and add another
                                            </Button>
                                        </Grid>


                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}