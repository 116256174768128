import React, { useEffect, useState } from 'react'
import { Grid, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";


export default function SiteTestList() {
    const { resourceList, getResourceList, currentListSize} = useRequestResource({ endpoint: "auth/borehole_log", query: "sitetests", resourceLabel: "Site Test List" });
    const {deleteResource, searchResources, searchList} = useRequestResource({ endpoint: "auth/borehole_log", query: "sitetest", resourceLabel: "Delete Site Test" })
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [exp, setExp] = useState("")
    const navigate = useNavigate();
    const offset = 25;
    const [sorting_query, setSortingQuery] = useState(null)

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };
    
    useEffect(() => {
        getResourceList();
    }, [getResourceList]);

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
        window.location.reload(); 
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }


    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    return (
        <div>
        <Dialog open={open} onClose={handleDeleteClose}>
            <DialogTitle>
                Are you sure you want to delete this??
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDelete}>
                    YES
                </Button>
                <Button onClick={handleDeleteClose}>
                    NO!
                </Button>
            </DialogActions>
        </Dialog>

        <Grid item xs={12} >
            <Grid item xs={12} align="left">
                <Link
                    to="/createsitetest" 
                    key="sitetest"
                >
                    <Button 
                    size="medium"
                    variant="contained"
                    color="primary"
                    >
                        <AddIcon></AddIcon>
                        Create New Site Test
                    </Button>
                </Link>
            </Grid>
        </Grid>

        <div style={{ height: "20px" }} />

        <TableContainer component={Paper}>
                <h3 style={headerStyle}>
                    Site Tests
                </h3>

                <Grid item xs={12} >
                    <Search fullWidth>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"   
                            inputProps={{ 'aria-label': 'search' }}
                            fullWidth
                            id="site_test"
                            label="Site Test"
                            value={exp}
                            onChange={(e) => setExp(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Search>
                </Grid>

                <div style={{ height: "20px" }} />

                <Table size="small">
                    <TableHead width="100%">
                        <TableRow>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("borehole__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Project</div><div>{sorting_query === "borehole__project__project_title" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__project__project_title")}></FaSortUp> : 
                                    sorting_query === "-borehole__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__project__project_title")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>
                            
                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Borehole</div><div>{sorting_query === "borehole__borehole_number" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__borehole_number")}></FaSortUp> : 
                                    sorting_query === "-borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__borehole_number")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("test_type")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Test Type</div><div>{sorting_query === "test_type" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-test_type")}></FaSortUp> : 
                                    sorting_query === "-test_type" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("test_type")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("test_number")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Test Number</div><div>{sorting_query === "test_number" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-test_number")}></FaSortUp> : 
                                    sorting_query === "-test_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("test_number")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("depth_to_top_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Depth to Top meters</div><div>{sorting_query === "depth_to_top_meters" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-depth_to_top_meters")}></FaSortUp> : 
                                    sorting_query === "-depth_to_top_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("depth_to_top_meters")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("depth_to_base_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Depth to Base meters</div><div>{sorting_query === "depth_to_base_meters" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-depth_to_base_meters")}></FaSortUp> : 
                                    sorting_query === "-depth_to_base_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("depth_to_base_meters")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>

                            <TableCell align="center" width="10%">
                                Action
                            </TableCell>

                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {
                            getList().map((r) => {
                                return (
                                    <TableRow
                                        key={r.id}
                                    >
                                        <TableCell align="center" width="15%">
                                            {r.project_title}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                            {r.borehole_number}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                            {r.test_type}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                            {r.test_number}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                            {r.depth_to_top_meters}
                                        </TableCell>
                                        <TableCell align="center" width="15%">
                                            {r.depth_to_base_meters}
                                        </TableCell>     
                                        <TableCell align="center" width="10%">
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                <Link 
                                                    to={`/${r.id}/sitetest`} 
                                                    key="Site-Test-Details"
                                                >
                                                    <IconButton size="small">
                                                        <PreviewIcon />
                                                    </IconButton>
                                                </Link>

                                                { !(r.has_supervisor_signed_borehole && r.has_witness_signed_borehole) ? <IconButton size="small" onClick={() => {
                                                                    handleConfirmDelete(r.id)
                                                                }}>
                                                    <DeleteIcon />
                                                </IconButton> : <div></div>}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                <Button onClick={fetchData}>
                            LOAD MORE!
                </Button>
            </div>}
        </div >
    )
}