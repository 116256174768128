import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import InfiniteScroll from 'react-infinite-scroll-component';

const validationSchema = yup.object({
    client_name: yup.string().required("Client name is required").max(255, "Max Length is 255!"),
    consultant_name: yup.string().max(255, "Max Length is 255!"),
    project_title: yup.string().required("Project title is required").max(255, "Max Length is 255!"),
    location: yup.string().required("Location is required").max(255, "Max Length is 255!"),
    access_geologists: yup.array()
})

const EditProjectDetails = () => {
    const { id } = useParams();
    const infiniteScrollRef = useRef(null); 
    const accessGeologistTextField = useRef(null); 
    const { resources, getResource, updateResource } = useRequestResource({ endpoint: "auth/borehole_log", query: "project", resourceLabel: "Project" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList} = useRequestResource({ endpoint: "auth/borehole_log", query: "geologists", resourceLabel: "Geologists List" });
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const [ geologists, setAccessGeologists ] = useState([])
    const [geologist, setGeologist] = useState("")
    const [exp, setExp] = useState("")
    let offset = 25;

    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        client_name: "",
        consultant_name: "",
        project_title: "",
        location: "",
        access_geologists: []
    });

    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setGeologist(exp)
            getSearchAllList(exp, 0)
        } else {
            setGeologist("")
            getResourceList(0)
        }
    }

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);
    
    useEffect(() => {
        getResourceList();
    }, [getResourceList])

    useEffect(() => {
        if (resources) {
            setAccessGeologists(resources.access_geologists)
            setInitialValues({
                client_name: resources.client_name,
                consultant_name: resources.consultant_name,
                project_title: resources.project_title,
                location: resources.location,
                access_geologists: resources.access_geologists.map(obj => obj.id)
            })
        }
    }, [resources]);

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (accessGeologistTextField.current && accessGeologistTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {
        const formattedValues = {
            client_name: values.client_name === "" ? null: values.client_name ,
            consultant_name: values.consultant_name === "" ? null: values.consultant_name,
            project_title: values.project_title === "" ? null: values.project_title,
            location: values.location === "" ? null: values.location,
            access_geologists: geologists.map(obj => obj.id)
        }

        if (id) {
            updateResource(id, formattedValues, () => {
                navigate(`/projects`)
            })
            return;
        } else {
            //console.log("No ID PROVIDED NO SERVICE!")
        }
    }

    function addGeologist(geologist) {
        const unique = {}
        const results = []
        for (const geo of geologists) {
            const key = geo.id
            unique[key] = true;
            results.push(geo)

        }
        const key = geologist.id
        if (!unique[key]) {
            results.push(geologist)
        }
        setAccessGeologists(results)
    }

    function deleteGeologist(option) {
        const set = new Set([...geologists])
        set.delete(option)
        setAccessGeologists([...set])
    }

    const access_geologists_list = 
    (
        <Grid item xs={12}>
            {geologists.map((option, index) => {
                return (
                    <MenuItem>
                        <Grid item xs={12}>
                            {option.first_name} {option.last_name}
                            <IconButton size="small" onClick={() => {deleteGeologist(option)}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </MenuItem>
                )   
            })}
        </Grid>
    )

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>

                <Grid item>
                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                </Grid>
                <Typography variant="h6" mh={4}>
                    Project Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="client_name"
                                                label="Client Name"
                                                {...formik.getFieldProps('client_name')}
                                                error={formik.touched.client_name && Boolean(formik.errors.client_name)}
                                                helperText={formik.touched.client_name && formik.errors.client_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="consultant_name"
                                                label="Consultant Name"
                                                {...formik.getFieldProps('consultant_name')}
                                                error={formik.touched.consultant_name && Boolean(formik.errors.consultant_name)}
                                                helperText={formik.touched.consultant_name && formik.errors.consultant_name}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="project_title"
                                                label="Project Title"
                                                {...formik.getFieldProps('project_title')}
                                                error={formik.touched.project_title && Boolean(formik.errors.project_title)}
                                                helperText={formik.touched.project_title && formik.errors.project_title}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="location"
                                                label="Location"
                                                {...formik.getFieldProps('location')}
                                                error={formik.touched.location && Boolean(formik.errors.location)}
                                                helperText={formik.touched.location && formik.errors.location}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="access_geologists"
                                                label="Access Geologist"
                                                value={geologist}
                                                onChange={(e) => {searchAll(e.target.value)}}
                                                ref={accessGeologistTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={formik.touched.access_geologists && Boolean(formik.errors.access_geologists)}
                                                helperText={formik.touched.access_geologists && formik.errors.access_geologists}
                                            >
                                            </TextField>
                                        </Grid>
                                        

                                        { showInfiniteScroll ? <Grid item xs={12}>

                                        <div ref={infiniteScrollRef} onClick={(e) => e.stopPropagation()}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                addGeologist(option)
                                                            }
                                                        }>
                                                            {option.first_name} {option.last_name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                                </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            {access_geologists_list}
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/projects`}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                sx = {{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save Project Details
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}

export default EditProjectDetails