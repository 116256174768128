import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { Grid, Box, IconButton, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as yup from 'yup';
import { Formik } from "formik";
import useRequestResource from 'src/hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";

const validationSchema = yup.object({
    username: yup.string().required("Username is required").max(150, "Max Length is 150!"),
    password: yup.string().required("Password is required").max(255, "Max Length is 255!"),
    email: yup.string().required("Email is required").max(255, "Max Length is 255!"),
    phone_number: yup.string().max(255, "Max Length is 255!"),
    first_name: yup.string().required("First Name is required").max(150, "Max Length is 150!"),
    last_name: yup.string().required("Last Name is required").max(150, "Max Length is 150!")
})

const validationPasswordSchema = yup.object({
    new_password: yup.string().required("New password is required").max(150, "Max Length is 150!"),
    new_password_confirmation: yup.string().required("New password confirmation is required").max(150, "Max Length is 150!")
})


export default function UserEdit() {
    const { id } = useParams()
    const navigate = useNavigate()
    const { getResource, resources, updateResource } = useRequestResource({ endpoint: "auth", query: "user", resourceLabel: "User" })
    const { updateResource: updatePassword } = useRequestResource({ endpoint: "auth", query: "user/reset_password", resourceLabel: "Password" })
    const [is_active, setIsActive] = useState(false)
    const [is_geologist, setIsGeologist] = useState(false)
    const [is_senior_geologist, setIsSeniorGeologist] = useState(false)
    const [is_lab_technician, setIsLabTechnician] = useState(false)
    const [is_manager, setIsManager] = useState(false)
    const [passwordError, setPasswordError] = useState(false)


    function handleSubmit(values) {

        const formattedValues = {
            username: values.username,
            email: values.email,
            phone_number: values.phone_number,
            first_name: values.first_name,
            last_name: values.last_name,
            is_active: is_active,
            is_geologist: is_geologist,
            is_senior_geologist: is_senior_geologist,
            is_lab_technician: is_lab_technician,
            is_manager: is_manager,
            is_superuser: is_manager,
            is_staff: true,
        }
        updateResource(id, formattedValues, () => {
            window.location.reload()
        })
    }

    function handlePasswordChange(values) {
        if (values.new_password !== values.new_password_confirmation) {
            setPasswordError(true)
        } else {
            const formattedValues = {
                username: values.username,
                new_password: values.new_password
            }
            updatePassword(id, formattedValues, () => {
                window.location.reload()
            })
        }
    }

    const [initialValues, setInitialValues] = useState({
        username: "",
        password: "",
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
    });


    const [initialPasswordValues, setInitialPasswordValues] = useState({
        username: "",
        new_password: "",
        new_password_confirmation: ""
    });

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);

    useEffect(() => {
        if (resources) {
            const data = resources
            setInitialValues({
                username: data.username === null ? "" : data.username,
                password: data.password === null ? "" : data.password,
                email: data.email === null ? "" : data.email,
                phone_number: data.phone_number === null ? "" : data.phone_number,
                first_name: data.first_name === null ? "" : data.first_name,
                last_name: data.last_name === null ? "" : data.last_name,
            })
            setInitialPasswordValues({
                username: data.username === null ? "" : data.username
            })
            setIsActive(data.is_active)
            setIsGeologist(data.is_geologist)
            setIsSeniorGeologist(data.is_senior_geologist)
            setIsLabTechnician(data.is_lab_technician)
            setIsManager(data.is_manager)
        }
    }, [resources])

    function handleCheck(value, type) {
        switch (type) {
            case "Active": setIsActive(prev => !prev); break;
            case "Geologist": setIsGeologist(prev => !prev); break;
            case "Sr. Geologist": setIsSeniorGeologist(prev => !prev); break;
            case "Lab Technician": setIsLabTechnician(prev => !prev); break;
            case "Manager": setIsManager(prev => !prev); break;
            default:
        }
    }

    return (
        <Card>
            <Card>
                <Card.Header>
                    Update User Information
                </Card.Header>
                <Card.Body>
                    <Formik onSubmit={handleSubmit}
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                    >
                        {
                            (formik) => {
                                return (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid xs={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="username"
                                                    label="Username"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('username')}
                                                    error={formik.touched.username && Boolean(formik.errors.username)}
                                                    helperText={formik.touched.username && formik.errors.username}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="email"
                                                    label="Email"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('email')}
                                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                                    helperText={formik.touched.email && formik.errors.email}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="phone_number"
                                                    label="Phone Number"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('phone_number')}
                                                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                                                />
                                            </Grid>

                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="first_name"
                                                    label="First Name"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('first_name')}
                                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="last_name"
                                                    label="Last Name"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    {...formik.getFieldProps('last_name')}
                                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <div> Permissions </div>
                                            <div key={`inline-checkbox`} className="mb-3">
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Active')}
                                                    inline
                                                    checked={is_active}
                                                    label='Active'
                                                    name="Permissions"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Active`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Geologist')}
                                                    inline
                                                    checked={is_geologist}
                                                    label='Geologist'
                                                    name="Permissions"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Geologist`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Sr. Geologist')}
                                                    inline
                                                    checked={is_senior_geologist}
                                                    label='Sr. Geologist'
                                                    name="Permissions"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Sr. Geologist`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Lab Technician')}
                                                    inline
                                                    checked={is_lab_technician}
                                                    label={'Lab Technician'}
                                                    name="Permissions"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Lab Technician`}
                                                />
                                                <Form.Check
                                                    style={{ margin: '20px' }}
                                                    onChange={(value) => handleCheck(value, 'Manager')}
                                                    inline
                                                    checked={is_manager}
                                                    label='Manager'
                                                    name="Permissions"
                                                    type='checkbox'
                                                    id={`inline-checkbox-Manager`}
                                                />
                                            </div>
                                            <div style={{ margin: '10px' }}></div>
                                            <Grid item xs={12}>
                                                <Button
                                                    // onClick={ handleSubmit }
                                                    type='submit'
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Save User Information
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )
                            }
                        }
                    </Formik>
                </Card.Body>
            </Card>
            <div style={{ margin: '20px' }}></div>
            <Card>
                <Card.Header>
                    Change Password
                </Card.Header>
                <Card.Body>
                    <Formik onSubmit={handlePasswordChange}
                        initialValues={initialPasswordValues}
                        enableReinitialize
                        validationSchema={validationPasswordSchema}
                    >
                        {
                            (formik) => {
                                return (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid xs={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="new_password"
                                                    label="New Password"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                                                    helperText={formik.touched.new_password && formik.errors.new_password}
                                                    {...formik.getFieldProps('new_password')}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    id="new_password_confirmation"
                                                    label="Confirm Password"
                                                    helperText="Confirm Password must match the Password Above"
                                                    error={passwordError}
                                                    onChange={(e) => {
                                                        if (e.target.value !== formik.values.new_password) {
                                                            setPasswordError(true)
                                                        } else {
                                                            setPasswordError(false)
                                                        }
                                                        formik.handleChange(e);
                                                    }}
                                                />
                                            </Grid>
                                            <div style={{ margin: '20px' }}></div>
                                            <Grid item xs={12}>
                                                <Button
                                                    type='submit'
                                                    size="medium"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Change password
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </form>
                                )
                            }
                        }

                    </Formik>
                </Card.Body>
            </Card>
        </Card>
    )
}
