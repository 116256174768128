import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import DatePicker from 'react-datepicker';
import SignatureCanvas from "react-signature-canvas";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import InfiniteScroll from 'react-infinite-scroll-component';
import useRequestResource from 'src/hooks/useRequestResource';
import { Input } from 'reactstrap';
import 'src/style.css'
import { values } from 'lodash';

const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    date: yup.string(),
    weight_of_cement_required: yup.string()
    .required("Weight of cement is required")
    .test('number', 'Weight of cement must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Weight of cement must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Weight of cement must be greater than of equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true
    })
    .test('max-value', 'Weight of cement must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true
    }),
    weight_of_bentonite_required: yup.string()
    .required("Weight of bentonite is required")
    .test('number', 'Weight of bentonite must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Weight of bentonite must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Weight of bentonite must be greater than of equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true
    })
    .test('max-value', 'Weight of bentonite must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true
    }),
    weight_of_water_required: yup.string()
    .required("Weight of water is required")
    .test('number', 'Weight of water must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Weight of water must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Weight of water must be greater than of equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true
    })
    .test('max-value', 'Weight of water must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true
    }),
    site_supervisor_name: yup.string().max(32, "Max Length is 32!"),
    site_supervisor_signature: yup.string(),
    site_supervisor_signed_date: yup.string(),
    checker_name: yup.string().max(32, "Max Length is 32!"),
    checker_signature: yup.string(),
    checker_signed_date: yup.string(),
})

const Base64Image = ({ base64Url }) => {
    const [imageUrl, setImageUrl] = useState('');
  
    useEffect(() => {
      if (base64Url) {
        fetch(base64Url)
          .then(response => response.blob())
          .then(blob => {
            const objectUrl = URL.createObjectURL(blob);
            setImageUrl(objectUrl);
          });
      }
    }, [base64Url]);
  
    return (
      <img src={imageUrl} alt="Base64 Image" />
    );
  };

export default function BackfillAndGroutingDetails() {
    const { id } = useParams();
    const { getResource, updateResource, resources } = useRequestResource({ endpoint: "auth/borehole_log", query: "backfillandgroutingdetail", resourceLabel: "Backfill and Grouting Details" });
    const { getResourceList, resourceList, currentListSize, getSearchAllList} = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole list"})
    const navigate = useNavigate();
    const [date, handleDateChange] = React.useState(null);
    const [siteSupervisorSignedDate, handleSiteSupervisorSignedDateChange] = React.useState(null);
    const [checkerSignedDate, handleCheckerSignedDateChange] = React.useState(null);
    const [is_supervisor_signed, setSupervisorSign] = useState(false)
    const [is_checker_signed, setCheckerSign] = useState(false)
    const sigSupervisorCanvas = useRef(null)
    const sigCheckerCanvas = useRef(null)
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 
    const [exp, setExp] = useState("")

    let offset = 25;
    const [ borehole, setBorehole ] = useState("")

    const [initialValues, setInitialValues] = useState({
        borehole: "",
        date: Date.now(),
        weight_of_cement_required: "",
        weight_of_bentonite_required: "",
        weight_of_water_required: "",
        site_supervisor_name: "",
        site_supervisor_signature: "",
        site_supervisor_signed_date: Date.now(),
        checker_name: "",
        checker_signature: "",
        checker_signed_date: Date.now()
    });

    useEffect(() => {
        getResourceList();
    }, [getResourceList])


    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    const clearSupervisorCanvas = () => {
        if (sigSupervisorCanvas.current) {
            sigSupervisorCanvas.current.clear()
            setSupervisorSign(prev => false)
            setSupervisorSignatureCanvas(
                <Grid item xs={12}>
                <h5>Supervisor Signature</h5>
                <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 200, height: 200 }}
                    ref={sigSupervisorCanvas}
                />
                <Grid>
                    <Button 
                        onClick={clearSupervisorCanvas}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={ supervisorSigned}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Sign
                    </Button>
                </Grid>
                <Grid>
                    <div style={{ height: "50px" }} />
                </Grid>
            </Grid>)
        }
    }

    const clearCheckerCanvas = () => {
        if (sigCheckerCanvas.current) {
            sigCheckerCanvas.current.clear()
            setCheckerSign(prev => false)
            setCheckerSignatureCanvas(
                <Grid item xs={12}>
                <h5>Checker Signature</h5>
                <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 200, height: 200 }}
                    ref={sigCheckerCanvas}
                />
                <Grid>
                    <Button 
                        onClick={clearCheckerCanvas}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Reset
                    </Button>
                    <Button 
                        onClick={ checkerSigned}
                        size="medium"
                        variant="contained"
                        sx={{ mr: 2 }}
                    >
                        Sign
                    </Button>
                </Grid>
                <Grid>
                    <div style={{ height: "50px" }} />
                </Grid>
            </Grid>)
        }
    }

    const deleteCheckerSignature = () => {
        setCheckerSign(prev => false)
        setCheckerSignatureCanvas(
            <Grid item xs={12}>
            <h5>Checker Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigCheckerCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearCheckerCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ checkerSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        setInitialValues(prevValues => ({
            ...prevValues,
            is_checker_signed: false,
            site_checker_name: "",
            site_checker_signature: "" // Update the value as needed
          }));
    }

    const deleteSupervisorSignature = () => {
        setSupervisorSign(prev => false)
        setSupervisorSignatureCanvas(
            <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        setInitialValues(prevValues => ({
            ...prevValues,
            is_supervisor_signed: false,
            site_supervisor_name: "",
            site_supervisor_signature: "" // Update the value as needed
          }));
    }

    function supervisorSigned() {
        setSupervisorSign(true)
        setSupervisorSignatureCanvas(
            <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                    disabled={true}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>
        )
    }

    function checkerSigned() {
        setCheckerSign(prev => true)
        setCheckerSignatureCanvas(
            <Grid item xs={12}>
            <h5>Checker Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigCheckerCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearCheckerCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={checkerSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                    disabled={true}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>
        )
    }

    const [supervisorSignatureCanvas, setSupervisorSignatureCanvas] = useState(     
        <Grid item xs={12}>
            <h5>Supervisor Signature</h5>
            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigSupervisorCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearSupervisorCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={ supervisorSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)
        

    const [checkerSignatureCanvas, setCheckerSignatureCanvas] = useState( 
        <Grid item xs={12}>
            <h5>Checker Signature</h5>

            <SignatureCanvas
                penColor="blue"
                canvasProps={{ width: 200, height: 200 }}
                ref={sigCheckerCanvas}
            />
            <Grid>
                <Button 
                    onClick={clearCheckerCanvas}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Reset
                </Button>
                <Button 
                    onClick={checkerSigned}
                    size="medium"
                    variant="contained"
                    sx={{ mr: 2 }}
                >
                    Sign
                </Button>
            </Grid>
            <Grid>
                <div style={{ height: "50px" }} />
            </Grid>
        </Grid>)

    useEffect(() => {
        if (id) {
            getResource(id);
        }
    }, [id, getResource]);

    useEffect(() => {
        if (resources) {
            const is_manager = localStorage.getItem("is_manager")
            setBorehole(`${resources.project_title} Borehole: ${resources.borehole_number}`)
            if (resources.date) {
                handleDateChange(new Date(resources.date));
            }
            if (resources.site_supervisor_signed_date) {
                handleSiteSupervisorSignedDateChange(new Date(resources.site_supervisor_signed_date));
            }
            if (resources.checker_signed_date) {
                handleCheckerSignedDateChange(new Date(resources.checker_signed_date));
            }

            if (resources.site_supervisor_signature) {
                setSupervisorSign(true)
                setSupervisorSignatureCanvas(
                    <Grid item xs={12}>
                        <Base64Image base64Url={resources.site_supervisor_signature}/>
                        {is_manager === "true" || !resources.checker_signature? <Button 
                                onClick={deleteSupervisorSignature}
                                size="medium"
                                variant="contained"
                                sx={{ mr: 2 }}
                            >
                                Delete Signature
                            </Button> : <div></div>}
                        <h5>{resources.site_supervisor_name}</h5>
                        <Grid>
                            <div style={{ height: "50px" }} />
                        </Grid>
                    </Grid>);
            } 

            if (resources.checker_signature) {
                setCheckerSign(true)
                setCheckerSignatureCanvas(
                    <Grid item xs={12}>
                        <Base64Image base64Url={resources.checker_signature}/>
                        {!resources.site_supervisor_signature || !resources.checker_signature? <Button 
                                onClick={deleteCheckerSignature}
                                size="medium"
                                variant="contained"
                                sx={{ mr: 2 }}
                            >
                                Delete Signature
                            </Button> : <div></div>}
                        <h5>{resources.checker_name}</h5>
                        <Grid>
                            <div style={{ height: "50px" }} />
                        </Grid>
                    </Grid>);
            }
            
            setInitialValues({
                borehole: resources.borehole === null ? "" : resources.borehole,
                date: resources.date === null ? "" : new Date(resources.date),
                weight_of_cement_required: resources.weight_of_cement_required === null ? "" : resources.weight_of_cement_required,
                weight_of_bentonite_required: resources.weight_of_bentonite_required === null ? "" : resources.weight_of_bentonite_required,
                weight_of_water_required: resources.weight_of_water_required === null ? "" : resources.weight_of_water_required,
                site_supervisor_name: resources.site_supervisor_name === null ? "" : resources.site_supervisor_name,
                site_supervisor_signature: resources.site_supervisor_signature === null ? "" : resources.site_supervisor_signature,
                site_supervisor_signed_date: resources.site_supervisor_signed_date === null ? "" : new Date(resources.site_supervisor_signed_date),
                checker_name: resources.checker_name === null ? "" : resources.checker_name,
                checker_signature: resources.checker_signature === null ? "" : resources.checker_signature,
                checker_signed_date: resources.checker_signed_date === null ? "" : new Date(resources.checker_signed_date)
            })
        }
    }, [resources])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {

        const formattedValues = {
            borehole: values.borehole === "" ? null : values.borehole,
            date: date ? convertDate(date) : null,
            weight_of_cement_required: values.weight_of_cement_required  === "" ? null : values.weight_of_cement_required,
            weight_of_bentonite_required: values.weight_of_bentonite_required === "" ? null : values.weight_of_bentonite_required,
            weight_of_water_required: values.weight_of_water_required  === "" ? null : values.weight_of_water_required,
            site_supervisor_name: values.site_supervisor_name === "" ? null : values.site_supervisor_name,
            site_supervisor_signature: is_supervisor_signed ? (sigSupervisorCanvas.current !== null ? sigSupervisorCanvas.current.toDataURL() : values.site_supervisor_signature) : null,
            site_supervisor_signed_date: siteSupervisorSignedDate ? convertDate(siteSupervisorSignedDate) : null,
            checker_name: values.checker_name  === "" ? null : values.checker_name,
            checker_signature: is_checker_signed ? (sigCheckerCanvas.current !== null ? sigCheckerCanvas.current.toDataURL() : values.checker_signature) : null,
            checker_signed_date: checkerSignedDate ? convertDate(checkerSignedDate) : null
        }

        updateResource(id, formattedValues, () => {
            navigate(`/backfillandgroutings`)
        })
    }

    const fetchData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
            }}>
                <Typography variant="h6" mh={4}>
                    Backfill And Grouting Information
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label={borehole ? "" : "Borehole"}
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                ref={boreholeTextField}
                                                onClick={() => setInfiniteScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={infiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={resourceList.results.length}
                                                    next={fetchData}
                                                    hasMore={currentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {resourceList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Date'
                                                selected={ date }
                                                onChange={ handleDateChange }
                                                name="date"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="weight_of_cement_required"
                                                label="Weight of Cement"
                                                {...formik.getFieldProps('weight_of_cement_required')}
                                                error={formik.touched.weight_of_cement_required && Boolean(formik.errors.weight_of_cement_required)}
                                                helperText={formik.touched.weight_of_cement_required && formik.errors.weight_of_cement_required}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="weight_of_bentonite_required"
                                                label="Weight of Bentonite"
                                                {...formik.getFieldProps('weight_of_bentonite_required')}
                                                error={formik.touched.weight_of_bentonite_required && Boolean(formik.errors.weight_of_bentonite_required)}
                                                helperText={formik.touched.weight_of_bentonite_required && formik.errors.weight_of_bentonite_required}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="weight_of_water_required"
                                                label="Weight of Water"
                                                {...formik.getFieldProps('weight_of_water_required')}
                                                error={formik.touched.weight_of_water_required && Boolean(formik.errors.weight_of_water_required)}
                                                helperText={formik.touched.weight_of_water_required && formik.errors.weight_of_water_required}
                                            />
                                        </Grid>
                                        


                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="site_supervisor_name"
                                                label="Site Supervisor Name"
                                                {...formik.getFieldProps('site_supervisor_name')}
                                                error={formik.touched.site_supervisor_name && Boolean(formik.errors.site_supervisor_name)}
                                                helperText={formik.touched.site_supervisor_name && formik.errors.site_supervisor_name}
                                            />
                                        </Grid>

                                        
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Supervisor Signed Date'
                                                selected={ siteSupervisorSignedDate }
                                                onChange={ handleSiteSupervisorSignedDateChange }
                                                name="date"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="checker_name"
                                                label="Checker Name"
                                                {...formik.getFieldProps('checker_name')}
                                                error={formik.touched.checker_name && Boolean(formik.errors.checker_name)}
                                                helperText={formik.touched.checker_name && formik.errors.checker_name}
                                            />
                                        </Grid>
                                        <br></br>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Checker Signed Date'
                                                selected={ checkerSignedDate }
                                                onChange={ handleCheckerSignedDateChange }
                                                name="date"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>

                                       
                                        {supervisorSignatureCanvas}

                                        {checkerSignatureCanvas}

                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/backfillandgroutings`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>

                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                                sx={{ mr: 2 }}
                                            >
                                                Save Backfill And Grouting Detail
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}
