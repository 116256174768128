import React, { useEffect, useState } from 'react'
import { Formik } from "formik";
import { Grid, TextField, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from 'react-datepicker';
import useRequestResource from 'src/hooks/useRequestResource';
import ColorPicker from "src/components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

function CompletionStatus(props) {
    const isCompleted = props.completion;

    if (isCompleted === "C") {
        return (
            <TableCell align="center" width="50%">
                Completed
            </TableCell>
        );
    }

    return (
        <TableCell align="center" width="50%">
            Pending
        </TableCell>
    );
}

export default function CrosssectionlogList() {
    const { resourceList, getResourceList, deleteResource, currentListSize} = useRequestResource({ endpoint: "auth/borehole_log", query: "crosssectionlogs", resourceLabel: "Borehole List" });
    const { searchResources, searchList} = useRequestResource({ endpoint: "auth/borehole_log", query: "crosssectionlogs", resourceLabel: "Search List" })
    const { deleteResourcesBefore } = useRequestResource({ endpoint: "auth/borehole_log", query: "crosssectionlog", resourceLabel: "create crosssection"})
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const offset = 25
    const [exp, setExp] = useState("")
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);
    const is_manager = localStorage.getItem("is_manager")
    const [sorting_query, setSortingQuery] = useState(null)

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    const incompleteBorderStyle = {
        borderLeft: '3px solid rgba(255, 0, 0, 1)'
    };

    const completeBorderStyle = {
        borderLeft: '3px solid rgba(0, 255, 0, 1)'
    };

    useEffect(() => {
        getResourceList();
    }, [getResourceList]);

    function onRowClick(e) {
        navigate(`/${e.id}/viewcrosssectionlog`)
    }

    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    // useEffect(() => {
    //     searchResources(exp);
    // }, [searchResources])

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12} style={{ display: 'flex' }} >
                <Link
                    to="/createcrosssectionlog" 
                    key="crosssectionlog"
                >
                    <Button 
                    size="medium"
                    variant="contained"
                    color="primary"
                    >
                        <AddIcon></AddIcon>
                        Create Cross Section Log
                    </Button>
                </Link>
            </Grid>

            <div style={{ height: "20px" }} />

            <TableContainer component={Paper}>
                <h3 style={headerStyle}>
                    Cross Section Logs
                </h3>

                <Grid item xs={12} style={{ display: 'flex' }}>
                    { is_manager === "true" ? 
                    <div style={{ display: 'flex'}}>
                        <DatePicker
                                placeholderText='Delete Before'
                                selected={ deleteByDate }
                                onChange={ handleDeleteByDateChange }
                                name="startDate"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={20}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                autoComplete='off'
                            >    
                        </DatePicker>
                        <Button 
                                onClick={ onDeleteByDateSubmit }
                                size="small"
                                variant="contained"
                            >
                                Delete
                        </Button>
                    </div>: <div></div>}
                </Grid>
        
                <div style={{ height: "20px" }} />

                <Grid item xs={12} >
                    <Search fullWidth>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            fullWidth
                            id="cross-section-log"
                            label="Cross Section Log"
                            value={exp}
                            onChange={(e) => setExp(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Search>
                </Grid>

                <div style={{ height: "20px" }} />
                
                <Table size="small">
                    <TableHead width="100%">
                        <TableRow>
                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Project</div><div>{sorting_query === "project__project_title" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-project__project_title")}></FaSortUp> : 
                                    sorting_query === "-project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("project__project_title")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>
                            <TableCell align="center" width="15%">
                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                    <div onClick={() => {toggleSort("date_of_creation")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Date of Creation</div><div>{sorting_query === "date_of_creation" ? 
                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-date_of_creation")}></FaSortUp> : 
                                    sorting_query === "-date_of_creation" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("date_of_creation")}}></FaSortDown> : 
                                    <div></div>}</div>
                                </div>
                            </TableCell>
                            <TableCell align="center" width="20%">
                                Status
                            </TableCell>
                            <TableCell align="center" width="20%">
                                File
                            </TableCell>
                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {
                            getList().map((r) => {
                                return (
                                    <TableRow
                                        // style={r.status === "C" ? completeBorderStyle : incompleteBorderStyle}
                                        key={r.id} onClick={() => onRowClick(r)}
                                    >
                                        <TableCell align="center" width="30%">
                                            {r.project_title}
                                        </TableCell>
                                        <TableCell align="center" width="30%">
                                            {new Date(r.date_of_creation).toLocaleString()}
                                        </TableCell>

                                        <CompletionStatus completion={r.status} width="20%"/>

                                        <TableCell align="center" width="20%">
                                            <a href={`${process.env.REACT_APP_API_URL}${r.upload}`}>
                                             {process.env.REACT_APP_API_URL}{r.upload}
                                            </a>
                                        </TableCell>

                                        {/* <TableCell align="center">
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >

                                                <IconButton size="small" onClick={() => {
                                                    handleConfirmDelete(r.id)
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </TableCell> */}
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                <Button onClick={fetchData}>
                            LOAD MORE!
                </Button>
            </div>}
        </div >
    )
}
