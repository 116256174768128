import React from "react";
import { Route, Routes } from 'react-router-dom';
import { RestrictedRoute, Unauthorized } from "src/App";
import BoreholeList from "src/pages/BoreHoleData/Borehole/BoreholeList";
import BoreHoleCreate from "src/pages/BoreHoleData/Borehole/BoreHoleCreate";
import BoreHoleDetails from "src/pages/BoreHoleData/Borehole/BoreHoleDetails";
import CrosssectionlogList from "src/pages/BoreHoleData/Crosssectionlog/Crosssectionloglist";
import CrosssectionlogCreate from "src/pages/BoreHoleData/Crosssectionlog/CreateCrosssectionlog";
import DailyWaterLevel from "src/pages/BoreHoleData/DailyWaterLevel";
import ExcelWriter from "src/pages/BoreHoleData/ExcelWriter";
import InstrumentInstallationSheets from "src/pages/BoreHoleData/InstrumentInstallationSheet";
import InstrumentInstallationList from "src/pages/BoreHoleData/InstrumentInstallation/InstrumentInstallationList";
import InstrumentInstallationCreate from "src/pages/BoreHoleData/InstrumentInstallation/CreateInstrumentInstallation";
import InstrumentInstallationEdit from "src/pages/BoreHoleData/InstrumentInstallation/EditInstrumentInstallation";
import ProjectLists from "src/pages/BoreHoleData/Project/ProjectLists";
import Borelogs from "src/pages/BoreHoleData/Borelog";
import BorelogView from "src/pages/BoreHoleData/BorelogView";
import RockResults from "src/pages/BoreHoleData/RockResults";
import SPTs from "src/pages/BoreHoleData/SPTs"
import SampleDescriptionForMajorSoilName from "src/pages/BoreHoleData/SampleDescriptionForMajorSoilName";
import SampleDescriptionForMinorSoilName1 from "src/pages/BoreHoleData/SampleDescriptionForMinorSoilName1";
import SampleDescriptionForMinorSoilName2 from "src/pages/BoreHoleData/SampleDescriptionForMinorSoilName2";
import SampleCreate from "src/pages/BoreHoleData/Sample/CreateSample";
import StratumResultCreate from "src/pages/BoreHoleData/StratumResult/CreateStratumResult";
import StratumResultEdit from "src/pages/BoreHoleData/StratumResult/EditStratumResult";
import StratumResultList from "src/pages/BoreHoleData/StratumResult/StratumResultList";
import SampleEdit from "src/pages/BoreHoleData/Sample/EditSample";
import SampleList from "src/pages/BoreHoleData/Sample/SampleList";
import BaseLayout from "src/components/BaseLayout";
import RequireAuth from "src/components/RequireAuth";
import SiteTestList from "src/pages/BoreHoleData/SiteTest/SiteTestList";
import SiteTestCreate from "src/pages/BoreHoleData/SiteTest/CreateSiteTest";
import SiteTestEdit from "src/pages/BoreHoleData/SiteTest/EditSiteTest";
import StratumColorChroma from "src/pages/BoreHoleData/StratumColorChroma";
import StratumColorHue from "src/pages/BoreHoleData/StratumColorHue";
import StratumColorLightness from "src/pages/BoreHoleData/StratumColorLightness";
import StratumColorNonUniform from "src/pages/BoreHoleData/StratumColorNonUniform";
import StratumConsistencyOrRelativeDensityRock from "src/pages/BoreHoleData/StratumConsistencyOrRelativeDensityRock";
import StratumConsistencyOrRelativeDensitySoil from "src/pages/BoreHoleData/StratumConsistencyOrRelativeDensitySoil";
import StratumGeologicalClassifications from "src/pages/BoreHoleData/StratumGeologicalClassifications";
import StratumGeologicalFormationPlutons from "src/pages/BoreHoleData/StratumGeologicalFormationPlutons";
import StratumGeologicalFormationGroupCentre from "src/pages/BoreHoleData/StratumGeologicalFormationGroupCentre";
import StratumGeologicalFormationMembers from "src/pages/BoreHoleData/StratumGeologicalFormationMembers";
import StratumOthers from "src/pages/BoreHoleData/StratumOthers";
import StratumPatterns from "src/pages/BoreHoleData/StratumPatterns";
import StratumTrialPitsHandAugers from "src/pages/BoreHoleData/StratumTrialPitsHandAugers";

import Activity from "src/pages/DailyJournal/Activities/Activities";
import JournalEntryList from "src/pages/DailyJournal/JournalEntries/JournalEntryList";
import JournalEntryCreate from "src/pages/DailyJournal/JournalEntries/CreateJournalEntry";
import JournalEntryEdit from "src/pages/DailyJournal/JournalEntries/EditJournalEntry";

import AGSToXLS from "src/pages/converters/AGSToXLS";
import XLSToAGS from "src/pages/converters/XLSToAGS";
import AGSWriter from "src/pages/converters/AGSWriter";

import ChemicalTest from "src/pages/Laboratory/ChemicalTest";
import ConsolidationTests from "src/pages/Laboratory/ConsolidationTest";
import LaboratoryPermeabilityTests from "src/pages/Laboratory/LaboratoryPermeabilityTest";
import NormalLaboratoryTest from "src/pages/Laboratory/NormalLaboratoryTest";
import RockTests from "src/pages/Laboratory/RockTest";
import SievingHydrometerTests from "src/pages/Laboratory/SievingHydrometerTest";
import TestSpecimen from "src/pages/Laboratory/TestSpecimen";
import ToxicityTests from "src/pages/Laboratory/ToxicityTest";
import TriaxialTests from "src/pages/Laboratory/Triaxial";
import CreateProject from "src/pages/BoreHoleData/Project/CreateProject";
import EditProjectDetails from "src/pages/BoreHoleData/Project/EditProjectDetails";
import AdminPanel from "src/pages/Admin/admin";
import UserEdit from "src/pages/Admin/editUser";
import CrossSectionlogView from "src/pages/BoreHoleData/Crosssectionlog/CrossSectionLogView";
import FieldLogs from "src/pages/BoreHoleData/FieldLog/FieldLogReport";
import FieldLogView from "src/pages/BoreHoleData/FieldLog/FieldLogView";
import BackfillAndGroutingList from "src/pages/BoreHoleData/BackfillAndGrouting/BackfillAndGroutingList";
import BackfillAndGroutingCreate from "src/pages/BoreHoleData/BackfillAndGrouting/BackfillAndGroutingCreate";
import BackfillAndGroutingDetails from "src/pages/BoreHoleData/BackfillAndGrouting/BackfillAndGroutingDetails";
import DrillinglogCreate from "src/pages/BoreHoleData/Drillinglog/CreateDrillinglog";
import DrillinglogList from "src/pages/BoreHoleData/Drillinglog/DrillinglogList";
import DrillinglogEdit from "src/pages/BoreHoleData/Drillinglog/EditDrillinglog";

import DrillingRigMovementRecordCreate from "src/pages/BoreHoleData/DrillingRigMovementRecord/DrillingRigMovementRecordCreate";
import DrillingRigMovementRecordDetails from "src/pages/BoreHoleData/DrillingRigMovementRecord/DrillingRigMovementRecordDetails";
import DrillingRigMovementRecordList from "src/pages/BoreHoleData/DrillingRigMovementRecord/DrillingRigMovementRecordList";

import Log from "src/pages/Logger/LogDetail";
import Logs from "src/pages/Logger/Logs";

export const GeologistSeniorGeologistManager = () => <Routes>
        <Route element={<RequireAuth />}>
        <Route element={<BaseLayout />}>
       <Route element={<RestrictedRoute status={"gsm"}></RestrictedRoute>}>
        <Route path={"/createSample"} element={<SampleCreate />} />
        <Route path={"/createStratumResult"} element={<StratumResultCreate />} />
        <Route path={"/:id/editStratumResult"} element={<StratumResultEdit />} />
        <Route path={"/:id/duplicateStratumResult"} element={<StratumResultCreate />} />
        <Route path={"/stratumresults"} element={<StratumResultList/>} />
        <Route path={"/:id/editSample"} element={<SampleEdit />} /* Project Information Editing Page */ />
        <Route path={"/samples"} element={<SampleList />} />
        <Route path={"/instrumentinstallationsheets"} element={<InstrumentInstallationSheets />} />
        <Route path={"/boreholes"} element={<BoreholeList />} /* Home Page Shwoing Project List */ />
        <Route path={"/createborehole"} element={<BoreHoleCreate />} /* Create New BoreHole */ />
        <Route path={"/:id/borehole"} element={<BoreHoleDetails />} /* Borehole Info Page */ />
        <Route path={"/dailywaterlevel"} element={<DailyWaterLevel />} /* Borehole Daily Water Level */ />
        <Route path={"/instrumentinstallationsheets"} element={<InstrumentInstallationSheets />} />
        <Route path={"/instrumentinstallations"} element={<InstrumentInstallationList />} /* Home Page Shwoing Project List */ />
        <Route path={"/createinstrumentinstallation"} element={<InstrumentInstallationCreate />} /* Home Page Shwoing Project List */ />
        <Route path={"/:id/instrumentinstallation"} element={<InstrumentInstallationEdit />} /* Home Page Shwoing Project List */ />
        <Route path={"/projects"} element={<ProjectLists />} /* Home Page Shwoing Project List */ />
        <Route path={"/:id/viewfieldlog"} element={<FieldLogView/>}/>
        <Route path={"/fieldlogs"} element={<FieldLogs />} />
        <Route path={"/drillinglogs"} element={<DrillinglogList />} /* Home Page Shwoing Project List */ />
        <Route path={"/createdrillinglog"} element={<DrillinglogCreate />} /* Home Page Shwoing Project List */ />
        <Route path={"/:id/editdrillinglog"} element={<DrillinglogEdit />} /* Home Page Shwoing Project List */ />
        
        <Route path={"/drillingrigmovementrecords"} element={<DrillingRigMovementRecordList />} /* Drilling Log Movement Record List */ />
        <Route path={"/createdrillingrigmovementrecord"} element={<DrillingRigMovementRecordCreate />} /* Create a new drilling log movement record  */ />
        <Route path={"/:id/drillingrigmovementrecord"} element={<DrillingRigMovementRecordDetails/>} /* Editing a drilling log movement record  */ />

        <Route path={"/rockresults"} element={<RockResults />} /* Rock Results */ />
        <Route path={"/spts"} element={<SPTs />} /* Borehole SPTs */ />
        <Route path={"/sitetests"} element={<SiteTestList />} /* Borehole Site Tests */ />
        <Route path={"/createsitetest"} element={<SiteTestCreate />} /* Borehole Site Tests */ />
        <Route path={"/:id/sitetest"} element={<SiteTestEdit />} /* Borehole Site Tests */ />

        <Route path={"/backfillandgroutings"} element={<BackfillAndGroutingList />} /* Home Page Showing BackfillAndGrouting List */ />
        <Route path={"/createbackfillandgrouting"} element={<BackfillAndGroutingCreate />} /* Create New BackfillAndGrouting */ />
        <Route path={"/:id/backfillandgrouting"} element={<BackfillAndGroutingDetails />} /* BackfillAndGrouting Info Page */ />
        <Route path={"/journalentries"} element={<JournalEntryList />} /* Journal Entry List */ />
        <Route path={"/createjournalentry"} element={<JournalEntryCreate />} /* Create a new journal entry */ />
        <Route path={"/:id/journalentry"} element={<JournalEntryEdit />} /* Journal Entry Info Page */ />
       </Route>
</Route>
</Route>
</Routes>



export const SeniorGeologistManager = () => <Routes>
<Route element={<RequireAuth />}>
<Route element={<BaseLayout />}>
<Route element={<RestrictedRoute status={"sm"}></RestrictedRoute>}>

    <Route path={"/createproject"} element={<CreateProject />} /* Create new project */ />
    <Route path={"/:id/project"} element={<EditProjectDetails />} /* Project Information Editing Page */ />
    <Route path={"/agstoxls"} element={<AGSToXLS />} />
    <Route path={"/xlstoags"} element={<XLSToAGS />} />
    <Route path={"/agswriter"} element={<AGSWriter />} />
    <Route path={"/excelwriter"} element={<ExcelWriter />} />
    <Route path={"/stratumcolorchroma"} element={<StratumColorChroma />} /* Stratum Color Chroma */ />
    <Route path={"/stratumcolorhue"} element={<StratumColorHue />} /* Stratum Color Hue */ />
    <Route path={"/stratumcolorlightness"} element={<StratumColorLightness />} /* Stratum Color Lightness */ />
    <Route path={"/stratumcolornonuniform"} element={<StratumColorNonUniform />} /* Stratum Color Non-Uniform */ />
    <Route path={"/stratumconsistencyorrelativedensityrock"} element={<StratumConsistencyOrRelativeDensityRock/>} /* Stratum Consistency Or Relative Density Rock */ />
    <Route path={"/stratumconsistencyorrelativedensitysoil"} element={<StratumConsistencyOrRelativeDensitySoil/>} /* Stratum Consistency Or Relative Density Soil */ />          
    <Route path={"/stratumgeologicalclassifications"} element={<StratumGeologicalClassifications/>} /* Stratum Geological Classifications */ /> 
    <Route path={"/stratumgeologicalformationgroupcentre"} element={<StratumGeologicalFormationGroupCentre/>} /* Stratum Geological Formation Group / Centre */ />
    <Route path={"/stratumgeologicalformationmembers"} element={<StratumGeologicalFormationMembers/>} /* Stratum Geological Formation Members*/ />
    <Route path={"/stratumgeologicalformationplutons"} element={<StratumGeologicalFormationPlutons/>} /* Stratum Geological Formation Plutons*/ />
    <Route path={"/stratumothers"} element={<StratumOthers />} /* Stratum Others */ />
    <Route path={"/stratumpatterns"} element={<StratumPatterns />} /* Stratum Patterns */ />
    <Route path={"/stratumtrialpitshandaugers"} element={<StratumTrialPitsHandAugers />} /* Stratum Others */ />
    <Route path={"/sampledescriptionformajorsoilname"} element={<SampleDescriptionForMajorSoilName/>} /* Sample Description For Major Soil Name */ />
    <Route path={"/sampledescriptionforminorsoilname1"} element={<SampleDescriptionForMinorSoilName1/>} /* Sample Description for Minor Soil Name 1 */ />
    <Route path={"/sampledescriptionforminorsoilname2"} element={<SampleDescriptionForMinorSoilName2/>} /* Sample Description for Minor Soil Name 2 */ />
    <Route path={"/:id/viewborelog"} element={<BorelogView/>}/>
    <Route path={"/borelogs"} element={<Borelogs />} />
    <Route path={"/createcrosssectionlog"} element={<CrosssectionlogCreate />} />
    <Route path={"/:id/viewcrosssectionlog"} element={<CrossSectionlogView />} />
    <Route path={"/crosssectionlogs"} element={< CrosssectionlogList/>} />
    <Route path={"/activities"} element={<Activity/>} /* Daily Journal Activities */ />
</Route>
</Route>
</Route>
</Routes>



export const ManagerLabTech = () => <Routes>
<Route element={<RequireAuth />}>
    <Route element={<BaseLayout />}>
        <Route element={<RestrictedRoute status={"ml"} ></RestrictedRoute>}>
            <Route path={"/chemicaltests"} element={<ChemicalTest />} /* Laboratory Test: Chemical Test */ />
            <Route path={"/consolidationtests"} element={<ConsolidationTests />} /* Consolidation Tests */ />
            <Route path={"/laboratorypermeabilitytests"} element={<LaboratoryPermeabilityTests />} /* Consolidation Tests */ />
            <Route path={"/normallaboratorytests"} element={<NormalLaboratoryTest />} /* Laboratory Test: Normal Laboratory Test */ />
            <Route path={"/rocktests"} element={<RockTests />} /* Rock Test */ />
            <Route path={"/sievinghydrometertests"} element={<SievingHydrometerTests />} /* SievingHydrometerTest */ />
            <Route path={"/specimens"} element={<TestSpecimen />} /* Laboratory Test: Specimen */ />  
            <Route path={"/toxicitytests"} element={<ToxicityTests />} /* Toxicity Test */ />
            <Route path={"/triaxialtests"} element={<TriaxialTests />} /* Triaxial Test */ />
        </Route>
    </Route>
</Route>
</Routes>


export const ManagerRoutes = () => <Routes>
<Route element={<RequireAuth />}>
    <Route element={<BaseLayout />}>
        <Route element={<RestrictedRoute status={"m"} ></RestrictedRoute>}>
            <Route path={"/admin"} element={<AdminPanel />} /* User List */ />
            <Route path={"/:id/editUser"} element={<UserEdit />} /* View User */ />
            <Route path={"/logs"} element={<Logs />} /* Log List */ />
            <Route path={"/:id/log"} element={<Log />} /* View Log */ />
        </Route>
    </Route>
</Route>
</Routes>