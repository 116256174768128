import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import Accordion from 'react-bootstrap/Accordion';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const validationSchema = yup.object({
    label: yup.string().required("Label is required").max(50, "Max Length is 50!"),
    formation_grp: yup.number().typeError("Select a formation group from the dropdown").required("Formation Group is required"),
    code: yup.string().max(50, "Max Length is 50!"),
})

const StratumGeologicalFormationPlutons = () => {
    const offset = 25;
    const { getResourceList, resourceList, currentListSize } = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "stratageologicalformationgrp", resourceLabel: "Stratum: Geological Formation Groups" });
    const { addResource: addStratumGeologicalFormationPlutonList, deleteResource: deleteStratumGeologicalFormationPlutonList, getResourceList: getStratumGeologicalFormationPlutonList, 
        resourceList: stratumGeologicalFormationPlutonList, currentListSize: StratumGeologicalFormationPlutonCurrentListSize} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "stratageologicalformationpluton", resourceLabel: "Stratum: Geological Formation Plutons" });
    const { resources : stratumGeologicalFormationPlutonResource, updateResource :  updateStratumGeologicalFormationPlutonResource, searchResources, searchList} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "stratumgeologicalformationpluton", resourceLabel: "Stratum: Geological Formation Pluton" });

    const [ formationGrp, setFormationGrp ] = useState("")
    const [ showInfiniteAddScroll, setShowInfiniteAddScroll ] = useState(false)
    const [ showInfiniteEditScroll, setShowInfiniteEditScroll ] = useState(false)
    const infiniteScrollRef = useRef(null); 
    const formationGroupTextField = useRef(null); 

    const [exp, setExp] = useState("")

    const editScrollRef = useRef(null); 
    const formationGroupEditField = useRef(null); 
    const [sorting_query, setSortingQuery] = useState(null)

    const [initialValues, setInitialValues] = useState({
        label: "",
        formation_grp: "",
        code: ""
    });

    const [editedValues, setEditedValues] = useState({
        label: "",
        formation_grp: "",
        code: ""
    });

    const [idToEdit, setIdToEdit] = useState(null);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const sectionRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteStratumGeologicalFormationPlutonList(idToDelete);
    }

    useEffect(() => {
        getResourceList();
    }, [getResourceList])


    useEffect(() => {
        getStratumGeologicalFormationPlutonList();
    }, [getStratumGeologicalFormationPlutonList]);

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (formationGroupTextField.current && formationGroupTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setShowInfiniteAddScroll(false)
            // Close the InfiniteScroll
        }

        if (editScrollRef.current && !editScrollRef.current.contains(event.target)) {
            if (formationGroupEditField.current && formationGroupEditField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
              setShowInfiniteEditScroll(false)
            // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {
        const formattedValues = {
            label: values.label,
            formation_grp: values.formation_grp,
            code: values.code
        }
        
        addStratumGeologicalFormationPlutonList(formattedValues, () => {
            window.location.reload(); 
        })
    }

    const handleEditWindow = (e) => {
        setEditedValues({
            label: e.label === null ? "" : e.label,
            formation_grp: e.formation_grp === null ? "" : e.formation_grp,
            code: e.code === null ? "" : e.code
        })
        setIdToEdit(e.id);
        setIsEditVisible(true);
        setFormationGrp(e.formation_grp_label);
    }

    useEffect(() => {
        if (isEditVisible && sectionRef.current) {
            setActiveKey('2')
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            document.addEventListener('click', handleOutsideClick);
          }
        }, [isEditVisible]);

    const handleEditSubmit = values => {
        const formattedValues = {
            label: values.label,
            formation_grp: values.formation_grp,
            code: values.code
        }

        if (idToEdit) {
            updateStratumGeologicalFormationPlutonResource(idToEdit, formattedValues, () => {
                window.location.reload(); 
            })
            return;
        } else {
            //console.log("No ID PROVIDED NO SERVICE!")
        }
    }

    const handleChangeAccordian = (eventKey) => {
        if (eventKey != '2') {
            setIdToEdit(null);
            setIsEditVisible(false);
            // in case a formation group drop down is open in edit but not selected,
            // set borehole back to empty string when user clicks out of the edit window.
            setFormationGrp("");
        }

        setShowInfiniteAddScroll(false)
        setShowInfiniteEditScroll(false)
        setActiveKey(eventKey)
    }

    const fetchData = () => {
        getStratumGeologicalFormationPlutonList(offset, sorting_query)
    }

    const fetchForeignKeyData = () => {
        getResourceList(offset)
    }

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getStratumGeologicalFormationPlutonList(0, sorting_query)
        }
    }, [sorting_query, getStratumGeologicalFormationPlutonList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return stratumGeologicalFormationPlutonList.results
        }
    }

    return (
        <div>
        <Dialog open={open} onClose={handleDeleteClose}>
            <DialogTitle>
                Are you sure you want to delete this??
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDelete}>
                    YES
                </Button>
                <Button onClick={handleDeleteClose}>
                    NO!
                </Button>
            </DialogActions>
        </Dialog>

        <Accordion activeKey = {activeKey} onSelect={handleChangeAccordian}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Add Stratum: Geological Formation Pluton</Accordion.Header>
                    <Accordion.Body>
                        <Paper sx={{
                            borderRadius: "2px",
                            bpxShadow: (theme) => theme.shadows[4],
                            padding: (theme) => theme.spacing(2, 4, 3)
                        }}>

                            <Grid item>
                                <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                            </Grid>
                            <Typography variant="h6" mh={4}>
                                Add Stratum: Geological Formation Pluton
                            </Typography>

                            <br />

                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid container spacing={3}>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="label"
                                                            label="Label"
                                                            {...formik.getFieldProps('label')}
                                                            error={formik.touched.label && Boolean(formik.errors.label)}
                                                            helperText={formik.touched.label && formik.errors.label}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete='off'
                                                            id="Formation Group"
                                                            label="Formation Group"
                                                            {...formik.getFieldProps('formation_grp')}
                                                            value={formationGrp}
                                                            ref={formationGroupTextField}
                                                            onClick={() => setShowInfiniteAddScroll(true)}
                                                            error={(formik.touched.formation_grp) && Boolean(formik.errors.formation_grp)}
                                                            helperText={(formik.touched.formation_grp) && formik.errors.formation_grp}
                                                        >
                                                        </TextField>
                                                    </Grid>
                                                    { showInfiniteAddScroll ? <Grid item xs={12}>
                                                    <div ref={infiniteScrollRef}>
                                                    <InfiniteScroll
                                                                dataLength={resourceList.results.length}
                                                                next={fetchForeignKeyData}
                                                                hasMore={currentListSize.current >= offset}
                                                                loader={<h4>Loading...</h4>}
                                                                height={400}
                                                                endMessage={
                                                                    <p style={{ textAlign: "center" }}>
                                                                    <b>Yay! You have seen it all</b>
                                                                    </p>
                                                                
                                                                }>
                                                                
                                                                {resourceList.results.map((option, index) => {
                                                                    return (
                                                                    <MenuItem key={index} value={option.id} onClick={
                                                                        () => {
                                                                            formik.setFieldValue("formation_grp", option.id)
                                                                            formik.setFieldTouched("formation_grp", false)
                                                                            formik.setFieldError("formation_grp", "")
                                                                            setFormationGrp(option.label)
                                                                            setShowInfiniteAddScroll(false)
                                                                        }
                                                                    }>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                    );}
                                                                )}

                                                            </InfiniteScroll>
                                                    </div>
                                                    </Grid> : <div></div>}
                                                    
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="code"
                                                            label="Code"
                                                            {...formik.getFieldProps('code')}
                                                            error={formik.touched.code && Boolean(formik.errors.code)}
                                                            helperText={formik.touched.code && formik.errors.code}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} >

                                                        <Button
                                                            type="submit"
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Create New Stratum: Geological Formation Pluton
                                                        </Button>

                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
                        </Paper>
                    </Accordion.Body>
                </Accordion.Item>
 
            <Accordion.Item eventKey="1">
                <Accordion.Header>Stratum: Geological Formation Pluton List</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <TableContainer component={Paper}>
                                <h3 style={headerStyle}>
                                    Stratum: Geological Formation Plutons
                                </h3>

                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="stratum_geological_formation_plutons"
                                            label="Stratum Geological Formation Plutons"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                
                                <div style={{ height: "20px" }} />

                                <Table size="small">
                                    <TableHead width="100%">
                                        <TableRow>
                                            <TableCell align="center" width="30%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("label")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Label</div><div>{sorting_query === "label" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-label")}></FaSortUp> : 
                                                    sorting_query === "-label" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("label")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="40%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("formation_grp__label")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Formaton Group</div><div>{sorting_query === "formation_grp__label" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-formation_grp__label")}></FaSortUp> : 
                                                    sorting_query === "-formation_grp__label" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("formation_grp__label")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="20%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("code")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Code</div><div>{sorting_query === "code" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-code")}></FaSortUp> : 
                                                    sorting_query === "-code" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("code")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="right" width="10%">
                                                Action
                                            </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {
                                            getList().map((r) => {
                                                return (
                                                    <TableRow
                                                    >
                                                        <TableCell align="center" width="30%">
                                                            {r.label}
                                                        </TableCell>
                                                        <TableCell align="center" width="40%">
                                                            {r.formation_grp_label}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.code}
                                                        </TableCell>
                                                        <TableCell align="right" width="10%">
                                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                                <IconButton size="small" onClick={() => {
                                                                    handleEditWindow(r);
                                                                }}>
                                                                    <EditIcon />
                                                                </IconButton>

                                                                <IconButton size="small" onClick={() => {
                                                                    handleConfirmDelete(r.id)
                                                                }}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {exp !== "" || StratumGeologicalFormationPlutonCurrentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                <Button onClick={fetchData}>
                                            LOAD MORE!
                                </Button>
                            </div>}
                        </div >
                    </Accordion.Body>
                </Accordion.Item>

                { isEditVisible? 
                <Accordion.Item eventKey="2" ref={sectionRef}>
                <Accordion.Header>Edit Stratum: Geological Formation Pluton </Accordion.Header>
                <Accordion.Body>
                        
                        <Paper sx={{
                                borderRadius: "2px",
                                bpxShadow: (theme) => theme.shadows[4],
                                padding: (theme) => theme.spacing(2, 4, 3)
                            }}>
    
                                <Grid item>
                                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                                </Grid>
                                <Typography variant="h6" mh={4}>
                                    Edit Stratum: Geological Formation Pluton
                                </Typography>
    
                                <br />
                                <Formik onSubmit={handleEditSubmit}
                                    initialValues={editedValues}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                >
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                    <Grid container spacing={3}>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                id="label"
                                                                label="Label"
                                                                {...formik.getFieldProps('label')}
                                                                error={formik.touched.label && Boolean(formik.errors.label)}
                                                                helperText={formik.touched.label && formik.errors.label}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                autoComplete='off'
                                                                id="Formation Group"
                                                                label="Formation Group"
                                                                {...formik.getFieldProps('formation_grp')}
                                                                value={formationGrp}
                                                                ref={formationGroupEditField}
                                                                onClick={() => setShowInfiniteEditScroll(true)}
                                                                error={(formik.touched.formation_grp) && Boolean(formik.errors.formation_grp)}
                                                                helperText={(formik.touched.formation_grp) && formik.errors.formation_grp}
                                                            >
                                                            </TextField>
                                                        </Grid>
                                                        { showInfiniteEditScroll ? <Grid item xs={12}>
                                                        <div ref={editScrollRef}>
                                                        <InfiniteScroll
                                                                    dataLength={resourceList.results.length}
                                                                    next={fetchForeignKeyData}
                                                                    hasMore={currentListSize.current >= offset}
                                                                    loader={<h4>Loading...</h4>}
                                                                    height={400}
                                                                    endMessage={
                                                                        <p style={{ textAlign: "center" }}>
                                                                        <b>Yay! You have seen it all</b>
                                                                        </p>
                                                                    
                                                                    }>
                                                                    
                                                                    {resourceList.results.map((option, index) => {
                                                                        return (
                                                                        <MenuItem key={index} value={option.id} onClick={
                                                                            () => {
                                                                                formik.setFieldValue("formation_grp", option.id)
                                                                                formik.setFieldTouched("formation_grp", false)
                                                                                formik.setFieldError("formation_grp", "")
                                                                                setFormationGrp(option.label)
                                                                                setShowInfiniteEditScroll(false)
                                                                            }
                                                                        }>
                                                                            {option.label}
                                                                        </MenuItem>
                                                                        );}
                                                                    )}

                                                                </InfiniteScroll>
                                                        </div>
                                                        </Grid> : <div></div>}
    
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                id="code"
                                                                label="Code"
                                                                {...formik.getFieldProps('code')}
                                                                error={formik.touched.code && Boolean(formik.errors.code)}
                                                                helperText={formik.touched.code && formik.errors.code}
                                                            />
                                                        </Grid>
    
                                                        <Grid item xs={12} >
    
                                                            <Button
                                                                type="submit"
                                                                size="medium"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Save Stratum: Geological Formation Pluton
                                                            </Button>
    
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Paper>
                        </Accordion.Body>
                </Accordion.Item>
                :<div></div>
                }
        </Accordion>
        </div>

    )
}
export default StratumGeologicalFormationPlutons;