import React, { useEffect, useState, useRef} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, Dialog, DialogTitle, DialogActions, IconButton, Box, Button, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"; 
import useRequestResource from 'src/hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import InfiniteScroll from 'react-infinite-scroll-component';
import * as yup from 'yup';
import DeleteIcon from "@mui/icons-material/Delete"
import DatePicker from 'react-datepicker';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

const validationSchema = yup.object({
    instrument: yup.number().typeError("Select an instrument from the dropdown").required('Instrument is required')
})

export default function InstrumentInstallationSheets() {
    // const { getResourceList: getProjectList, resourceList: projectList, currentListSize: currentProjectListSize} = useRequestResource({ endpoint: "auth/borehole_log", query: "projects", resourceLabel: "Project list"})
    const { resourceList, getResourceList, deleteResource, deleteResourcesBefore, currentListSize} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "instrumentinstallationsheets", resourceLabel: "Instrument Installation Sheets" });
    const { resourceList: instrumentList, getResourceList: getInstrumentList, currentListSize: currentInstrumentListSize, getSearchAllList: getInstrumentSearchAllList} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "instrumentinstallations", resourceLabel: "Instrument" });
    const { addResource, getResource, resources,  } = useRequestResource({endpoint: "auth/borehole_log", query: "instrumentinstallationsheet", resourceLabel: "Instrument Installation Sheet"})
    const { searchResources, searchList} = useRequestResource({ endpoint: "auth/borehole_log", query: "instrumentinstallationsheet", resourceLabel: "Search List" }) 

    const offset = 25;
    const [exp, setExp] = useState("")
    const [sorting_query, setSortingQuery] = useState(null)

    // const [project_id, setProjectId] = useState()
    const [ borehole, setBorehole ] = useState("")
    const [ instrument, setInstrument ] = useState("")
    const [ project, setProject ] = useState("")
    const [ showInstrumentInfiniteScroll, setInstrumentInfiniteScroll ] = useState(false)
    const instrumentTextField = useRef(null)
    const instrumentInfiniteScrollRef = useRef(null)

    const [file, setFile] = useState()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);
    const is_manager = localStorage.getItem("is_manager")

    const conventionChoices = [
        { value: 'OLD', label: 'Old' },
        { value: 'NEW', label: 'New' },
    ]

    useEffect(() => {
        getResourceList();
    }, [getResourceList]);

    useEffect(() => {
        getInstrumentList();
    }, [getInstrumentList]);

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }



    const fetchInstrumentListData = () => {
        if (exp && exp.trim() !== "") {
            getInstrumentSearchAllList(exp, offset)
        } else {
            getInstrumentList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setInstrument(exp)
            getInstrumentSearchAllList(exp, 0)
        } else {
            setInstrument("")
            getInstrumentList(0)
        }
    }

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (instrumentInfiniteScrollRef.current && !instrumentInfiniteScrollRef.current.contains(event.target)) {
            if (instrumentTextField.current && instrumentTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setInstrumentInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    function onFileChange(value) {
        setFile(value.target.files[0])
    }

    function onProjectChange(e) {
        setProject(e.target.value)
    }

    function onBoreholeChange(e) {
        setBorehole(e.target.value)
    }

    const [initialValues, setInitialValues] = useState({
        instrument: "",
        convention_choice: "NEW"
    })

    const handleSubmit = values => {
        const formattedValues = {
            instrument_installed: values.instrument,
            convention_choice: values.convention_choice
        }

        addResource(formattedValues, () => {
            window.location.reload();
        })
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    return (
        <div>
           <h3 style={headerStyle}>
                    Instrument Installation Sheet
                </h3>

                <Dialog open={open} onClose={handleDeleteClose}>
                    <DialogTitle>
                        Are you sure you want to delete this??
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDelete}>
                            YES
                        </Button>
                        <Button onClick={handleDeleteClose}>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="0">Create Sheet</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Formik onSubmit={handleSubmit}
                                    initialValues={initialValues}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                >
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="instrument"
                                                        label="Instrument"
                                                        {...formik.getFieldProps('instrument')}
                                                        value={instrument}
                                                        onChange={(e) => {formik.setFieldValue("instrument", ""); searchAll(e.target.value)}}
                                                        ref={instrumentTextField}
                                                        onClick={() => setInstrumentInfiniteScroll(true)}
                                                        error={(formik.touched.instrument) && Boolean(formik.errors.instrument)}
                                                        helperText={(formik.touched.instrument) && formik.errors.instrument}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                { showInstrumentInfiniteScroll ? <Grid item xs={12}>
                                                <div ref={instrumentInfiniteScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={instrumentList.results.length}
                                                            next={fetchInstrumentListData}
                                                            hasMore={currentInstrumentListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {instrumentList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        formik.setFieldValue("instrument", option.id)
                                                                        formik.setFieldTouched("instrument", false)
                                                                        formik.setFieldError("instrument", "")
                                                                        setInstrument(`${option.project_title} Borehole: ${option.borehole_number} Instrument Installation No.${option.instrument_installation_number}`)
                                                                        setInstrumentInfiniteScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title} Borehole: {option.borehole_number} Instrument Installation No.{option.instrument_installation_number}
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="convention_choice"
                                                        label="Convention Choice"
                                                        {...formik.getFieldProps('convention_choice')}
                                                        select
                                                        value={formik.values.convention_choice}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        error={formik.touched.convention_choice && Boolean(formik.errors.convention_choice)}
                                                        helperText={formik.touched.convention_choice && formik.errors.convention_choice}
                                                    >
                                                        {conventionChoices.map((option, index) => (
                                                            <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>                                              
                                                <Grid item xs={12} >

                                                </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            // onClick={() => handleSubmit(formik.values)}
                                                            type='submit'
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header style={{ display: 'flex' }}>
                            <CustomToggle eventKey="1">Converted List</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    { is_manager === "true" ? 
                                    <div style={{ display: 'flex'}}>
                                        <DatePicker
                                                placeholderText='Delete Before'
                                                selected={ deleteByDate }
                                                onChange={ handleDeleteByDateChange }
                                                name="startDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                            >    
                                        </DatePicker>
                                        <Button 
                                                onClick={ onDeleteByDateSubmit }
                                                size="small"
                                                variant="contained"
                                            >
                                                Delete
                                        </Button>
                                    </div>: <div></div>}
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="instrument_installation_sheet"
                                            label="Instrument Installation Sheet"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                                <div style={{ height: "20px" }} />                                
                                <Table>
                                    <TableHead width="100%">
                                        <TableRow>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("instrument_installed__borehole__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Project</div><div>{sorting_query === "instrument_installed__borehole__project__project_title" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-instrument_installed__borehole__project__project_title")}></FaSortUp> : 
                                                    sorting_query === "-instrument_installed__borehole__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("instrument_installed__borehole__project__project_title")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("instrument_installed__borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Borehole</div><div>{sorting_query === "instrument_installed__borehole__borehole_number" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-instrument_installed__borehole__borehole_number")}></FaSortUp> : 
                                                    sorting_query === "-instrument_installed__borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("instrument_installed__borehole__borehole_number")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("instrument_installed__instrument_installation_number")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Instrument Installation Number</div><div>{sorting_query === "instrument_installed__instrument_installation_number" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-instrument_installed__instrument_installation_number")}></FaSortUp> : 
                                                    sorting_query === "-instrument_installed__instrument_installation_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("instrument_installed__instrument_installation_number")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("instrument_installed__instrument_type")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Instrument Type</div><div>{sorting_query === "instrument_installed__instrument_type" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-instrument_installed__instrument_type")}></FaSortUp> : 
                                                    sorting_query === "-instrument_installed__instrument_type" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("instrument_installed__instrument_type")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="20%">
                                                    File
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("date_of_creation")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Date of Creation</div><div>{sorting_query === "date_of_creation" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-date_of_creation")}></FaSortUp> : 
                                                    sorting_query === "-date_of_creation" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("date_of_creation")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("convention_choice")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Convention Choice</div><div>{sorting_query === "convention_choice" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-convention_choice")}></FaSortUp> : 
                                                    sorting_query === "-convention_choice" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("convention_choice")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="5%">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        getList().map((r) => {
                                            return (
                                                <TableRow key={r.id}>
                                                    <TableCell align="center" width="15%">
                                                        {r.project_title}
                                                    </TableCell>     
                                                    <TableCell align="center" width="15%">
                                                        {r.borehole_number} 
                                                    </TableCell>                                                   
                                                    <TableCell align="center" width="10%">
                                                        {r.instrument_installation_number}
                                                    </TableCell>
                                                    <TableCell align="center" width="10%">
                                                        {r.instrument_type}    
                                                    </TableCell>
                                                    <TableCell align="center" width="20%">
                                                    <a href={`${process.env.REACT_APP_API_URL}${r.upload}`}>
                                                        {`${process.env.REACT_APP_API_URL}${r.upload}`}
                                                            </a>
                                                    </TableCell>
                                                    <TableCell align="center" width="15%">
                                                        {new Date(r.date_of_creation).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center" width="10%">
                                                                {r.convention_choice}
                                                            </TableCell>
                                                    <TableCell align="center" width="5%">
                                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }} >

                                                            <IconButton size="small" onClick={() => {
                                                                handleConfirmDelete(r.id)
                                                            }}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                                </Table>
                                {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                    <Button onClick={fetchData}>
                                                LOAD MORE!
                                    </Button>
                                </div>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        </div>
    )
}


