import React, { useEffect, useState, useRef} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, Dialog, DialogTitle, DialogActions, IconButton, Box, Button, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"; 
import useRequestResource from 'src/hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import InfiniteScroll from 'react-infinite-scroll-component';
import * as yup from 'yup';
import DeleteIcon from "@mui/icons-material/Delete"
import DatePicker from 'react-datepicker';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

const validationSchema = yup.object({
    mode: yup.string().required("Mode is required"),
    project: yup.number().typeError("Select a project from the dropdown"),
    borehole: yup.number().typeError("Select a borehole from the dropdown"),
    contract_number: yup.string().max(100, "Max Length is 100!"),
    wo_number: yup.string().max(100, "Max Length is 100!"),
    contractor_ref_number: yup.string().max(100, "Max Length is 100!"),
})

export default function AGSWriter() {
    const { getResourceList: getProjectList, resourceList: projectList, currentListSize: currentProjectListSize, getSearchAllList: searchAllProjectList} = useRequestResource({ endpoint: "auth/borehole_log", query: "projects", resourceLabel: "Project list"})
    const { resourceList: boreholeList, getResourceList: getBoreholeList, deleteResource: deleteBorehole, currentListSize: currentBoreholeListSize, getSearchAllList: searchAllBoreholeList} = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole List" });
    const { resourceList, getResourceList, deleteResource, currentListSize} = useRequestResource({ endpoint: "auth/agsxlsutils", query: "agswriters", resourceLabel: "AGS Writer List" });
    const { addResource, getResource, resources, deleteResourcesBefore } = useRequestResource({endpoint: "auth/agsxlsutils", query: "agswriter", resourceLabel: "AGS Writer"})
    const { searchResources, searchList} = useRequestResource({ endpoint: "auth/agsxlsutils", query: "agswriter", resourceLabel: "Search List" }) 

    const offset = 25;
    const [exp, setExp] = useState("")
    const [boreholeExp, setBoreholeExp] = useState("")
    const [projectExp, setProjectExp] = useState("")
    const [sorting_query, setSortingQuery] = useState(null)

    // const [project_id, setProjectId] = useState()
    const [ borehole, setBorehole ] = useState("")
    const [ project, setProject ] = useState("")
    const [ showBoreholeInfiniteScroll, setBoreholeInfiniteScroll ] = useState(false)
    const [ showProjectInfiniteScroll, setProjectInfiniteScroll ] = useState(false)
    const boreholeScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 
    const projectScrollRef = useRef(null); 
    const projectTextField = useRef(null); 

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);
    const is_manager = localStorage.getItem("is_manager")

    const modeOptions = [
        { value: 'Project', label: 'Project' },
        { value: 'Borehole', label: 'Borehole' },
    ]

    useEffect(() => {
        getResourceList();
    }, [getResourceList]);

    useEffect(() => {
        getProjectList();
    }, [getProjectList]);

    const searchAllProjects = (exp) => {
        setProjectExp(exp)
        if (exp && exp.trim() !== "") {
            setProject(exp)
            searchAllProjectList(exp, 0);
        } else {
            setProject("")
            getProjectList(0)
        }
    }

    useEffect(() => {
        getBoreholeList();
    }, [getBoreholeList]);

    const searchAllBoreholes = (exp) => {
        setBoreholeExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            searchAllBoreholeList(exp, 0);
        } else {
            setBorehole("")
            getBoreholeList(0)
        }
    }

    const fetchProjectData = () => {
        if (projectExp && projectExp.trim() !== "") {
            searchAllProjectList(projectExp, offset)
        } else {
            getProjectList(offset)            
        }
    }
    const fetchBoreholeData = () => {
        if (boreholeExp && boreholeExp.trim() !== "") {
            searchAllBoreholeList(boreholeExp, offset)
        } else {
            getBoreholeList(offset)            
        }
    }
    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (boreholeScrollRef.current && !boreholeScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setBoreholeInfiniteScroll(false); // Close the InfiniteScroll
        }

        if (projectScrollRef.current && !projectScrollRef.current.contains(event.target)) {
            if (projectTextField.current && projectTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setProjectInfiniteScroll(false); // Close the InfiniteScroll
        }
      }

    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    const [initialValues, setInitialValues] = useState({
        mode: "",
        borehole: "",
        project: "",
        contract_number: "",
        wo_number: "",
        contractor_ref_number: ""
    })

    const handleSubmit = values => {
        const formattedValues = {
            mode: values.mode === "" ? null : values.mode,
            borehole: values.mode === "Borehole" ? (values.borehole === "" ? null : values.borehole) : null,
            project: values.mode === "Project" ? (values.project === "" ? null : values.project) : null,
            contract_no: values.contract_number === "" ? null : values.contract_number,
            wo_no: values.wo_number === "" ? null : values.wo_number,
            contractor_ref_no: values.contractor_ref_number === "" ? null : values.contractor_ref_number
        }


        addResource(formattedValues, () => {
            window.location.reload();
        })
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    return (
        <div>
           <h3 style={headerStyle}>
                    AGS Writer
                </h3>

                <Dialog open={open} onClose={handleDeleteClose}>
                    <DialogTitle>
                        Are you sure you want to delete this??
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDelete}>
                            YES
                        </Button>
                        <Button onClick={handleDeleteClose}>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Card.Header>
                            <CustomToggle eventKey="0">Write</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Formik onSubmit={handleSubmit}
                                    initialValues={initialValues}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                >
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="mode"
                                                        label="Mode"
                                                        {...formik.getFieldProps('mode')}
                                                        select
                                                        value={formik.values.mode}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        error={formik.touched.mode && Boolean(formik.errors.mode)}
                                                        helperText={formik.touched.mode && formik.errors.mode}
                                                    >
                                                        {modeOptions.map((option, index) => (
                                                            <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                { formik.values.mode === "Project" ?
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="project"
                                                        label="Project"
                                                        {...formik.getFieldProps('project')}
                                                        value={project}
                                                        onChange={(e) => {formik.setFieldValue("project", ""); searchAllProjects(e.target.value)}}
                                                        ref={projectTextField}
                                                        onClick={() => setProjectInfiniteScroll(true)}
                                                        error={(formik.touched.project) && Boolean(formik.errors.project)}
                                                        helperText={(formik.touched.project) && formik.errors.project}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                : <div></div>}
                                                { showProjectInfiniteScroll ? <Grid item xs={12}>
                                                <div ref={projectScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={projectList.results.length}
                                                            next={fetchProjectData}
                                                            hasMore={currentProjectListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {projectList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        formik.setFieldValue("project", option.id)
                                                                        formik.setFieldTouched("project", false)
                                                                        formik.setFieldError("project", "")
                                                                        setProject(`${option.project_title}`)
                                                                        setProjectInfiniteScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title}
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}
                                            
                                        { formik.values.mode === "Borehole" ?                            
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAllBoreholes(e.target.value)}}
                                                ref={boreholeTextField}
                                                onClick={() => setBoreholeInfiniteScroll(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        : <div></div> }
                                        { showBoreholeInfiniteScroll ? <Grid item xs={12}>
                                        <div ref={boreholeScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={boreholeList.results.length}
                                                    next={fetchBoreholeData}
                                                    hasMore={currentBoreholeListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {boreholeList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setBoreholeInfiniteScroll(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                                <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="contract_number"
                                                            label="Contract Number"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('contract_number')}
                                                            error={formik.touched.contract_number && Boolean(formik.errors.contract_number)}
                                                            helperText={formik.touched.contract_number && formik.errors.contract_number}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="wo_number"
                                                            label="Wo. Number"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('wo_number')}
                                                            error={formik.touched.wo_number && Boolean(formik.errors.wo_number)}
                                                            helperText={formik.touched.wo_number && formik.errors.wo_number}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="contractor_ref_number"
                                                            label="Contractor Ref. Number"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('contractor_ref_number')}
                                                            error={formik.touched.contractor_ref_number && Boolean(formik.errors.contractor_ref_number)}
                                                            helperText={formik.touched.contractor_ref_number && formik.errors.contractor_ref_number}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            onClick={() => handleSubmit(formik.values)}
                                                            type='submit'
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Write
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header style={{ display: 'flex' }}>
                            <CustomToggle eventKey="1">Converted List</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                            <   Grid item xs={12} style={{ display: 'flex' }}>
                                    { is_manager === "true" ? 
                                    <div style={{ display: 'flex'}}>
                                        <DatePicker
                                                placeholderText='Delete Before'
                                                selected={ deleteByDate }
                                                onChange={ handleDeleteByDateChange }
                                                name="startDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                            >    
                                        </DatePicker>
                                        <Button 
                                                onClick={ onDeleteByDateSubmit }
                                                size="small"
                                                variant="contained"
                                            >
                                                Delete
                                        </Button>
                                    </div>: <div></div>}
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="agswriter"
                                            label="AGSWriter"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Table>
                                    <TableHead width="100%">
                                        <TableRow>
                                        <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("project__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Project</div><div>{sorting_query === "project__project__project_title" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-project__project__project_title")}></FaSortUp> : 
                                                    sorting_query === "-project__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("project__project__project_title")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("borehole__borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Borehole</div><div>{sorting_query === "borehole__borehole__borehole_number" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__borehole__borehole_number")}></FaSortUp> : 
                                                    sorting_query === "-borehole__borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__borehole__borehole_number")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center">
                                                    Mode
                                            </TableCell>

                                            <TableCell align="center">
                                                    Upload File
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("contract_no")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Contract Number</div><div>{sorting_query === "contract_no" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-contract_no")}></FaSortUp> : 
                                                    sorting_query === "-contract_no" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("contract_no")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("wo_no")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Wo. Number</div><div>{sorting_query === "wo_no" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-wo_no")}></FaSortUp> : 
                                                    sorting_query === "-wo_no" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("wo_no")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("contractor_ref_no")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Contractor Ref. Number</div><div>{sorting_query === "contractor_ref_no" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-contractor_ref_no")}></FaSortUp> : 
                                                    sorting_query === "-contractor_ref_no" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("contractor_ref_no")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("date_of_creation")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Date of Creation</div><div>{sorting_query === "date_of_creation" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-date_of_creation")}></FaSortUp> : 
                                                    sorting_query === "-date_of_creation" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("date_of_creation")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>

                                            <TableCell align="right">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        getList().map((r) => {
                                            return (
                                                <TableRow key={r.id}>
                                                    <TableCell align="center">
                                                        {r.project_title}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {r.borehole_number}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {r.mode}    
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    <a href={`${process.env.REACT_APP_API_URL}${r.upload}`} >
                                                        {`${process.env.REACT_APP_API_URL}${r.upload}`}  
                                                    </a>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {r.contract_no}    
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {r.wo_no}    
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {r.contractor_ref_no}    
                                                    </TableCell>
                                                    <TableCell align="center">
                                                    {new Date(r.date_of_creation).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }} >

                                                            <IconButton size="small" onClick={() => {
                                                                handleConfirmDelete(r.id)
                                                            }}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                                </Table>
                                {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                    <Button onClick={fetchData}>
                                                LOAD MORE!
                                    </Button>
                                </div>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        </div>
    )
}


