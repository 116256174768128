import React, { useEffect, useState } from 'react'
import { Grid, TextField, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate} from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

export default function BackfillAndGroutingList() {
    const { resourceList, getResourceList, currentListSize} = useRequestResource({ endpoint: "auth/borehole_log", query: "backfillandgroutingdetails", resourceLabel: "Backfill And Grouting List" });
    const { deleteResource, searchResources, searchList} = useRequestResource({ endpoint: "auth/borehole_log", query: "backfillandgroutingdetail", resourceLabel: "Backfill And Grouting" })
    const navigate = useNavigate();
    const offset = 25
    const [exp, setExp] = useState("")
    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);
    const [sorting_query, setSortingQuery] = useState(null)

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        getResourceList();
    }, [getResourceList]);


    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }


    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }
    
    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>

            
            <Grid item xs={12} >
                <Grid item xs={12} align="left">
                    <Link
                        to="/createbackfillandgrouting" 
                        key="backfillandgrouting"
                    >
                        <Button 
                        size="medium"
                        variant="contained"
                        color="primary"
                        >
                            <AddIcon></AddIcon>
                            Create New Backfill And Grouting
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            <div style={{ height: "20px" }} />
            <TableContainer component={Paper}>
                <Grid item xs={12} >
                    <h3 style={headerStyle}>
                        Backfill And Grouting
                    </h3>
                </Grid>

                <Grid item xs={12} >
                    <Search fullWidth>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            fullWidth
                            id="backfillandgrouting"
                            label="Backfill And Grouting"
                            value={exp}
                            onChange={(e) => setExp(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </Search>
                </Grid>

                <div style={{ height: "20px" }} />
                
                <Grid item xs={12} >
                    <Table size="small">
                        <TableHead width="100%">
                            <TableRow>
                                <TableCell align="center" width="15%">
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                        <div onClick={() => {toggleSort("borehole__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Project</div><div>{sorting_query === "borehole__project__project_title" ? 
                                        <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__project__project_title")}></FaSortUp> : 
                                        sorting_query === "-borehole__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__project__project_title")}}></FaSortDown> : 
                                        <div></div>}</div>
                                    </div>
                                </TableCell>
                                
                                <TableCell align="center" width="15%">
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                        <div onClick={() => {toggleSort("borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Borehole</div><div>{sorting_query === "borehole__borehole_number" ? 
                                        <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__borehole_number")}></FaSortUp> : 
                                        sorting_query === "-borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__borehole_number")}}></FaSortDown> : 
                                        <div></div>}</div>
                                    </div>
                                </TableCell>
                                <TableCell align="center" width="15%">
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                        <div onClick={() => {toggleSort("date")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Date</div><div>{sorting_query === "date" ? 
                                        <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-date")}></FaSortUp> : 
                                        sorting_query === "-date" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("date")}}></FaSortDown> : 
                                        <div></div>}</div>
                                    </div>
                                </TableCell>
                                <TableCell align="center" width="15%">
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                        <div onClick={() => {toggleSort("weight_of_cement_required")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Weight of Cement Required</div><div>{sorting_query === "weight_of_cement_required" ? 
                                        <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-weight_of_cement_required")}></FaSortUp> : 
                                        sorting_query === "-weight_of_cement_required" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("weight_of_cement_required")}}></FaSortDown> : 
                                        <div></div>}</div>
                                    </div>
                                </TableCell>
                                <TableCell align="center" width="15%">
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                        <div onClick={() => {toggleSort("weight_of_bentonite_required")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Weight of Bentonite Required</div><div>{sorting_query === "weight_of_bentonite_required" ? 
                                        <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-weight_of_bentonite_required")}></FaSortUp> : 
                                        sorting_query === "-weight_of_bentonite_required" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("weight_of_bentonite_required")}}></FaSortDown> : 
                                        <div></div>}</div>
                                    </div>
                                </TableCell>
                                <TableCell align="center" width="15%">
                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                        <div onClick={() => {toggleSort("weight_of_water_required")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Weight of Water Required</div><div>{sorting_query === "weight_of_water_required" ? 
                                        <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-weight_of_water_required")}></FaSortUp> : 
                                        sorting_query === "-weight_of_water_required" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("weight_of_water_required")}}></FaSortDown> : 
                                        <div></div>}</div>
                                    </div>
                                </TableCell>
                                <TableCell align="center" width="10%">
                                    Action
                                </TableCell>
                            </TableRow>

                        </TableHead>

                        <TableBody>
                            {
                                getList().map((r) => {
                                    return (
                                        <TableRow
                                            key={r.id}
                                        >
                                            <TableCell align="center" width="15%">
                                                {r.project_title}
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                {r.borehole_number}
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                {new Date(r.date).toLocaleString()}
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                {r.weight_of_cement_required}
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                {r.weight_of_bentonite_required}
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                {r.weight_of_water_required}
                                            </TableCell>                                        
                                            <TableCell align="center" width="10%">
                                                <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                                                                        
                                                    <Link 
                                                        to={`/${r.id}/backfillandgrouting`} 
                                                        key="Backfill-And_Grouting-Details"
                                                    >
                                                        <IconButton size="small">
                                                            <PreviewIcon />
                                                        </IconButton>
                                                    </Link>

                                                    <IconButton size="small" onClick={() => {
                                                        handleConfirmDelete(r.id)
                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </TableContainer>
            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                <Button onClick={fetchData}>
                            LOAD MORE!
                </Button>
            </div>}
        </div >
    )
}
