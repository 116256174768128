import React, { useEffect, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, Box, IconButton, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogActions} from "@mui/material"; 
import DeleteIcon from "@mui/icons-material/Delete";
import useRequestResource from 'src/hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import PreviewIcon from '@mui/icons-material/Preview';
import * as yup from 'yup';
import { Formik } from "formik";
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

const validationSchema = yup.object({
    username: yup.string().required("Username is required").max(150, "Max Length is 150!"),
    password: yup.string().required("Password is required").max(255, "Max Length is 255!"),
    email: yup.string().required("Email is required").max(255, "Max Length is 255!"),
    phone_number: yup.string().max(255, "Max Length is 255!"),
    first_name: yup.string().required("First Name is required").max(150, "Max Length is 150!"),
    last_name: yup.string().required("Last Name is required").max(150, "Max Length is 150!")
})

export default function AdminPanel() {
    const { resourceList, getResourceList, deleteResource, currentListSize} = useRequestResource({ endpoint: "auth", query: "users", resourceLabel: "User List" });
    const { addResource, getResource, resources } = useRequestResource({endpoint: "auth", query: "user", resourceLabel: "User"})
    const { searchResources, searchList} = useRequestResource({ endpoint: "auth", query: "user", resourceLabel: "Search List" }) 
    const offset = 25;
    const [exp, setExp] = useState("")
    const [initialValues, setInitialValues] = useState({
        username : "",
        password : "",
        email : "",
        phone_number: "",
        first_name : "",
        last_name : "",
    });

    const [is_active, setIsActive] = useState(false)
    const [is_geologist, setIsGeologist] = useState(false)
    const [is_senior_geologist, setIsSeniorGeologist] = useState(false)
    const [is_lab_technician, setIsLabTechnician] = useState(false)
    const [is_manager, setIsManager] = useState(false)

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const [passwordError, setPasswordError] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [sorting_query, setSortingQuery] = useState(null)

    useEffect(() => {
        getResourceList();
    }, [getResourceList]);

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    function handleSubmit(values) {

        if (values.password !== confirmPassword) {
            setPasswordError(true)
        } else {
            const formattedValues = {
                username : values.username,
                password : values.password,
                email : values.email,
                phone_number: values.phone_number,
                first_name : values.first_name,
                last_name : values.last_name,
                is_active: is_active,
                is_geologist: is_geologist,
                is_senior_geologist: is_senior_geologist,
                is_lab_technician: is_lab_technician,
                is_manager: is_manager,
                is_superuser: is_manager,
                is_staff: true,
            }
            addResource(formattedValues, () => {
                window.location.reload();
            })
        }
    }

    function handleCheck(value, type) {
        switch(type) {
            case "Active": setIsActive(prev => !prev); break;
            case "Geologist": setIsGeologist(prev => !prev); break;
            case "Sr. Geologist": setIsSeniorGeologist(prev => !prev); break;
            case "Lab Technician": setIsLabTechnician(prev => !prev); break;
            case "Manager": setIsManager(prev => !prev); break;
            default : 
        }
    }

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteResource(idToDelete);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }


    return (
        <div>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>
                    Are you sure you want to delete this??
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDelete}>
                        YES
                    </Button>
                    <Button onClick={handleDeleteClose}>
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
            <h3 style={headerStyle}>
                Users
            </h3>
            <Accordion defaultActiveKey="1">
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="0">Create new User</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                                <Grid xs={3}>
                                                    <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="username"
                                                        label="Username"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        {...formik.getFieldProps('username')}
                                                        error={formik.touched.username && Boolean(formik.errors.username)}
                                                        helperText={formik.touched.username && formik.errors.username}
                                                    />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="email"
                                                            label="Email"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('email')}
                                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                                            helperText={formik.touched.email && formik.errors.email}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="phone_number"
                                                            label="Phone Number"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('phone_number')}
                                                            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                                            helperText={formik.touched.phone_number && formik.errors.phone_number}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="password"
                                                            label="Password"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('password')}
                                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                                            helperText={formik.touched.password && formik.errors.password}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="confirm-password"
                                                            label="Confirm Password"
                                                            helperText="Confirm Password must match the Password Above"
                                                            error={passwordError}
                                                            onChange={(e) => {
                                                                if (e.target.value !== formik.values.password) {
                                                                    setPasswordError(true)
                                                                } else {
                                                                    setPasswordError(false)
                                                                }
                                                                setConfirmPassword(e.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="first_name"
                                                            label="First Name"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('first_name')}
                                                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                                            helperText={formik.touched.first_name && formik.errors.first_name} 
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="last_name"
                                                            label="Last Name"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            {...formik.getFieldProps('last_name')}
                                                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                                            helperText={formik.touched.last_name && formik.errors.last_name}
                                                        />
                                                    </Grid>
                                                    <div style={{ margin: '20px' }}></div>
                                                    <div> Permissions </div>
                                                    <div key={`inline-checkbox`} className="mb-3">
                                                        {['Active', 'Geologist', 'Sr. Geologist', 'Lab Technician', 'Manager'].map((type) => (
                                                            <Form.Check
                                                            style={{ margin: '20px' }}
                                                            onChange={(value) => handleCheck(value, type)}
                                                            inline
                                                            label={`${type}`}
                                                            name="Permissions"
                                                            type='checkbox'
                                                            id={`inline-checkbox-${type}`}
                                                        />
                                                        ))}
                                                    </div>
                                                    <div style={{ margin: '10px' }}></div>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            // onClick={ handleSubmit }
                                                            type='submit'
                                                            size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Add User
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                    <CustomToggle eventKey="1">User List</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Grid item xs={12} >
                                <Search fullWidth>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                        fullWidth
                                        id="user"
                                        label="User"
                                        value={exp}
                                        onChange={(e) => setExp(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                </Search>
                            </Grid>
                            <Table>
                                <TableHead width="100%">
                                    <TableRow>
                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("username")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Username</div><div>{sorting_query === "username" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-username")}></FaSortUp> : 
                                                sorting_query === "-username" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("username")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("email")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Email</div><div>{sorting_query === "email" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-email")}></FaSortUp> : 
                                                sorting_query === "-email" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("email")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("phone_number")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Phone Number</div><div>{sorting_query === "phone_number" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-phone_number")}></FaSortUp> : 
                                                sorting_query === "-phone_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("phone_number")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("first_name")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>First Name</div><div>{sorting_query === "first_name" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-first_name")}></FaSortUp> : 
                                                sorting_query === "-first_name" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("first_name")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("last_name")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Last Name</div><div>{sorting_query === "last_name" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-last_name")}></FaSortUp> : 
                                                sorting_query === "-last_name" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("last_name")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("is_active")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Active</div><div>{sorting_query === "is_active" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-is_active")}></FaSortUp> : 
                                                sorting_query === "-is_active" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("is_active")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("is_geologist")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Geologist Status</div><div>{sorting_query === "is_geologist" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-is_geologist")}></FaSortUp> : 
                                                sorting_query === "-is_geologist" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("is_geologist")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("is_senior_geologist")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Sr. Geologist Status</div><div>{sorting_query === "is_senior_geologist" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-is_senior_geologist")}></FaSortUp> : 
                                                sorting_query === "-is_senior_geologist" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("is_senior_geologist")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>
                                        
                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("is_lab_technician")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Lab Technician Status</div><div>{sorting_query === "is_lab_technician" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-is_lab_technician")}></FaSortUp> : 
                                                sorting_query === "-is_lab_technician" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("is_lab_technician")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" width="15%">
                                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                <div onClick={() => {toggleSort("is_manager")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Manager Status</div><div>{sorting_query === "is_manager" ? 
                                                <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-is_manager")}></FaSortUp> : 
                                                sorting_query === "-is_manager" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("is_manager")}}></FaSortDown> : 
                                                <div></div>}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell align="center">
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    getList().map((r) => {
                                        return (
                                            <TableRow>
                                                <TableCell align="center">
                                                    {r.username}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.email}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.phone_number}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.first_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {r.last_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Form>
                                                    <div key={`default-checkbox`} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            disabled
                                                            checked={r.is_active}
                                                            type='checkbox'
                                                            id={`default-checkbox`}
                                                        />
                                                        </div>
                                                    </Form>
                                                </TableCell>
                                                <TableCell align="center">
                                                <Form>
                                                    <div key={`default-checkbox`} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            disabled
                                                            checked={r.is_geologist}
                                                            type='checkbox'
                                                            id={`default-checkbox`}
                                                        />
                                                        </div>
                                                    </Form>
                                                </TableCell>
                                                <TableCell align="center">
                                                <Form>
                                                    <div key={`default-checkbox`} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            disabled
                                                            checked={r.is_senior_geologist}
                                                            type='checkbox'
                                                            id={`default-checkbox`}
                                                        />
                                                        </div>
                                                    </Form>
                                                </TableCell>
                                                <TableCell align="center">
                                                <Form>
                                                    <div key={`default-checkbox`} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            disabled
                                                            checked={r.is_lab_technician}
                                                            type='checkbox'
                                                            id={`default-checkbox`}
                                                        />
                                                        </div>
                                                    </Form>
                                                </TableCell>
                                                <TableCell align="center">
                                                <Form>
                                                    <div key={`default-checkbox`} className="mb-3">
                                                        <Form.Check // prettier-ignore
                                                            disabled
                                                            checked={r.is_manager}
                                                            type='checkbox'
                                                            id={`default-checkbox`}
                                                        />
                                                        </div>
                                                    </Form>
                                                </TableCell>
                                    
                            
                                                <TableCell>
                                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Link 
                                                        to={`/${r.id}/editUser`} 
                                                        key="User-Details"
                                                    >
                                                        <IconButton size="small">
                                                            <PreviewIcon />
                                                        </IconButton>
                                                    </Link>
                                                    <IconButton size="small" onClick={() => {
                                                    handleConfirmDelete(r.id)
                                                }}>
                                                    <DeleteIcon />
                                                    </IconButton>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            </Table>
                            {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                <Button onClick={fetchData}>
                                            LOAD MORE!
                                </Button>
                            </div>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}


