import React, { useEffect, useState, useRef } from 'react';
import { Formik } from "formik";
import { Grid, TextField, MenuList, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions, getRadioUtilityClass } from "@mui/material";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'src/style.css'


const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    geologist: yup.number().typeError("Select a geologist from the dropdown").required("Geologist is required"),
    activity: yup.number().typeError("Select an activity from the dropdown").required("Activity is required"),
    weather_condition: yup.string().max(255, "Max Length is 255!"),
    remarks: yup.string().max(255, "Max Length is 255!"),
})

export default function JournalEntryDetails() {
    const { addResource } = useRequestResource({ endpoint: "auth/daily_journal", query: "journalentry", resourceLabel: "Journal Entry Details" });
    const { getResourceList: getBoreholeList, resourceList: boreholeList, currentListSize: boreholeListCurrentListSize, getSearchAllList: getBoreholeSearchAllList } = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole list"})
    const { getResourceList: getGeologistList, resourceList: geologistList, currentListSize: geologistListCurrentListSize, getSearchAllList: getGeologistSearchAllList } = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "geologists", resourceLabel: "Geologist list"})
    const { getResourceList: getActivityList, resourceList: activityList, currentListSize: activityListCurrentListSize, getSearchAllList: getActivitySearchAllList } = 
        useRequestResource({ endpoint: "auth/daily_journal", query: "activities", resourceLabel: "Activity list"})
    
        const navigate = useNavigate();
    const [entryDate, handleEntryDateChange] = React.useState(null);
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [borehole, setBorehole] = useState("")
    const [geologist, setGeologist] = useState("")
    const [activity, setActivity] = useState("")
    const [showInfiniteScrollBorehole, setInfiniteScrollBorehole] = useState(false)
    const [showInfiniteScrollGeologist,  setInfiniteScrollGeologist] = useState(false)
    const [showInfiniteScrollActivity, setInfiniteScrollActivity] = useState(false)
    const boreholeInfiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 
    const geologistInfiniteScrollRef = useRef(null)
    const geologistTextField = useRef(null)
    const activityInfiniteScrollRef = useRef(null)
    const activityTextField = useRef(null)
    const [boreholeExp, setBoreholeExp] = useState("")
    const [geologistExp, setGeologistExp] = useState("")
    const [activityExp, setActivityExp] = useState("")
    let offset = 25;


    const [initialValues, setInitialValues] = useState({
        borehole: "",
        geologist: "",
        activity: "",
        time: Date.now(),
        weather_condition: "",
        remarks: ""
    });

    const fetchDataBorehole = () => {
        if (boreholeExp && boreholeExp.trim() !== "") {
            getBoreholeSearchAllList(boreholeExp, offset)
        } else {
            getBoreholeList(offset)
        }
    }
        
    const fetchDataGeologist = () => {
        if (geologistExp && geologistExp.trim() !== "") {
            getGeologistSearchAllList(geologistExp, offset)
        } else {
            getGeologistList(offset)
        }
    }

    const fetchDataActivity = () => {
        if (activityExp && activityExp.trim() !== "") {
            getActivitySearchAllList(activityExp, offset)
        } else {
            getActivityList(offset)
        }
    }

    const searchAllBorehole = (exp) => {
        setBoreholeExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getBoreholeSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getBoreholeList(0)
        }
    }
    const searchAllGeologist = (exp) => {
        setGeologistExp(exp)
        if (exp && exp.trim() !== "") {
            setGeologist(exp)
            getGeologistSearchAllList(exp, 0)
        } else {
            setGeologist("")
            getGeologistList(0)
        }
    }

    const searchAllActivity = (exp) => {
        setActivityExp(exp)
        if (exp && exp.trim() !== "") {
            setActivity(exp)
            getActivitySearchAllList(exp, 0)
        } else {
            setActivity("")
            getActivityList(0)
        }
    }

    useEffect(() => {
        if (image != null) {
            setImageURL(URL.createObjectURL(image));
        } else {
            setImageURL(null);
        }
        getBoreholeList();
        getGeologistList();
        getActivityList();
    }, [getBoreholeList, getGeologistList, getActivityList, image])

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (boreholeInfiniteScrollRef.current && !boreholeInfiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setInfiniteScrollBorehole(false); // Close the InfiniteScroll
        }

        if (geologistInfiniteScrollRef.current && !geologistInfiniteScrollRef.current.contains(event.target)) {
            if (geologistTextField.current && geologistTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setInfiniteScrollGeologist(false); // Close the InfiniteScroll
        }

        if (activityInfiniteScrollRef.current && !activityInfiniteScrollRef.current.contains(event.target)) {
            if (activityTextField.current && activityTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setInfiniteScrollActivity(false); // Close the InfiniteScroll
        }
      };

    function onImageChange(e) {
        setImage(e.target.files[0])
    }
        
    function deleteImage(e) {
        e.preventDefault();
        setImage(null);
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    } 

    const formatValues = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null : values.borehole,
            geologist: values.geologist === "" ? null : values.geologist,
            activity: values.activity === "" ? null : values.activity,
            time: entryDate === null ? null : convertDate(entryDate),
            weather_condition: values.weather_condition === "" ? null : values.weather_condition,
            remarks: values.remarks === "" ? null : values.remarks
        }
        //console.log(formattedValues);
        
        let formData = new FormData();
        formData.append(`image`, image);
        formData.set("json_data", JSON.stringify(formattedValues));
        
        return formData;
    }

    const handleSubmit = values => {
        addResource(formatValues(values), () => {
            navigate(`/journalentries`)
        })
    }

    const handleSubmitReload = values => {
        addResource(formatValues(values), () => {
            window.location.reload();
        })
    }

    return (
        <div>
            <Paper sx={{
                borderRadius: "2px",
                bpxShadow: (theme) => theme.shadows[4],
                padding: (theme) => theme.spacing(2, 4, 3)
                
            }}>
                <Typography variant="h6" mh={4}>
                    Journal Entry
                </Typography>

                <br />

                <Formik onSubmit={handleSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {
                        (formik) => {
                            return (
                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        formik.handleSubmit(event)
                                    }
                                }}>

                                    <Grid container spacing={3}>
                                        
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="borehole"
                                                label="Borehole"
                                                {...formik.getFieldProps('borehole')}
                                                value={borehole}
                                                onChange={(e) => {formik.setFieldValue("borehole", ""); searchAllBorehole(e.target.value)}}
                                                ref={boreholeTextField}
                                                onClick={() => setInfiniteScrollBorehole(true)}
                                                error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                helperText={(formik.touched.borehole) && formik.errors.borehole}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScrollBorehole ? <Grid item xs={12}>
                                        <div ref={boreholeInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={boreholeList.results.length}
                                                    next={fetchDataBorehole}
                                                    hasMore={boreholeListCurrentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {boreholeList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("borehole", option.id)
                                                                formik.setFieldTouched("borehole", false)
                                                                formik.setFieldError("borehole", "")
                                                                setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                setInfiniteScrollBorehole(false)
                                                            }
                                                        }>
                                                            {option.project_title} Borehole: {option.borehole_number}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="geologist"
                                                label="Geologist"
                                                {...formik.getFieldProps('geologist')}
                                                value={geologist}
                                                onChange={(e) => {formik.setFieldValue("geologist", ""); searchAllGeologist(e.target.value)}}
                                                ref={geologistTextField}
                                                onClick={() => setInfiniteScrollGeologist(true)}
                                                error={(formik.touched.geologist) && Boolean(formik.errors.geologist)}
                                                helperText={(formik.touched.geologist) && formik.errors.geologist}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScrollGeologist ? <Grid item xs={12}>
                                        <div ref={geologistInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={geologistList.results.length}
                                                    next={fetchDataGeologist}
                                                    hasMore={geologistListCurrentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {geologistList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("geologist", option.id)
                                                                formik.setFieldTouched("geologist", false)
                                                                formik.setFieldError("geologist", "")
                                                                setGeologist(`${option.first_name} ${option.last_name}`)
                                                                setInfiniteScrollGeologist(false)
                                                            }
                                                        }>
                                                            {option.first_name} {option.last_name}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete='off'
                                                id="activity"
                                                label="Activity"
                                                {...formik.getFieldProps('activity')}
                                                value={activity}
                                                onChange={(e) => {formik.setFieldValue("activity", ""); searchAllActivity(e.target.value)}}
                                                ref={activityTextField}
                                                onClick={() => setInfiniteScrollActivity(true)}
                                                error={(formik.touched.activity) && Boolean(formik.errors.activity)}
                                                helperText={(formik.touched.activity) && formik.errors.activity}
                                            >
                                            </TextField>
                                        </Grid>
                                        { showInfiniteScrollActivity ? <Grid item xs={12}>
                                        <div ref={activityInfiniteScrollRef}>
                                        <InfiniteScroll
                                                    dataLength={activityList.results.length}
                                                    next={fetchDataActivity}
                                                    hasMore={activityListCurrentListSize.current >= offset}
                                                    loader={<h4>Loading...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                        <b>Yay! You have seen it all</b>
                                                        </p>
                                                    
                                                    }>
                                                    
                                                    {activityList.results.map((option, index) => {
                                                        return (
                                                        <MenuItem key={index} value={option.id} onClick={
                                                            () => {
                                                                formik.setFieldValue("activity", option.id)
                                                                formik.setFieldTouched("activity", false)
                                                                formik.setFieldError("activity", "")
                                                                setActivity(`${option.label}`)
                                                                setInfiniteScrollActivity(false)
                                                            }
                                                        }>
                                                            {option.label}
                                                        </MenuItem>
                                                        );}
                                                    )}

                                                </InfiniteScroll>
                                        </div>
                                        </Grid> : <div></div>}


                                        <Grid item xs={12}>
                                            <DatePicker
                                                placeholderText='Journal Entry Date'
                                                selected={ entryDate }
                                                onChange={ handleEntryDateChange }
                                                name="entryDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                required
                                                autoComplete="off"
                                                popperPlacement="top-start"
                                            >    
                                            </DatePicker>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="weather_condition"
                                                label="Weather Condition"
                                                {...formik.getFieldProps('weather_condition')}
                                                error={formik.touched.weather_condition && Boolean(formik.errors.weather_condition)}
                                                helperText={formik.touched.weather_condition && formik.errors.weather_condition}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id="remarks"
                                                label="Remarks"
                                                {...formik.getFieldProps('remarks')}
                                                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                                                helperText={formik.touched.remarks && formik.errors.remarks}
                                            />
                                        </Grid>
                                    
                                        <Grid item xs={12}>
                                            <input type="file" accept="image/*" onChange={onImageChange} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            {imageURL != null && (
                                                <Grid> 
                                                <img src={imageURL} />
                                                <button onClick={(e) => { deleteImage(e) }}>Delete</button>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Button component={Link}
                                                to={`/journalentries`}
                                                size="medium"
                                                variant="contained"
                                                sx={{ mr: 2 }}
                                            >
                                                Back
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                type="submit"
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Create New Journal Entry
                                            </Button>
                                            <div style={{ height: "20px" }} />
                                            <Button
                                                onClick={(event) => handleSubmitReload(formik.values)}
                                                size="medium"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Save and add another
                                            </Button>
                                        </Grid>


                                    </Grid>
                                </form>
                            )
                        }
                    }
                </Formik>
            </Paper>
        </div>
    )
}