import React, { useEffect, useState, useRef} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {Grid, MenuItem, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"; 
import useRequestResource from 'src/hooks/useRequestResource';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from 'formik';
import * as yup from 'yup';
import { WindowSharp } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-datepicker';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const headerStyle = {
    color: "white",
    backgroundColor: "Green",
    padding: "10px",
    fontFamily: "Arial"
};


function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
  
    return (
      <button
        type="button"
        style={{ backgroundColor: '#0dcaf0' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }


const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    spt_number_of_values: yup.string().required("SPT number of values is required"),
})

export default function FieldLogs() {
    const { resourceList, getResourceList, deleteResource, currentListSize} = useRequestResource({ endpoint: "auth/borehole_log", query: "fieldboringlogs", resourceLabel: "Field Boring Log List" });
    const { addResource, getResource, resources, deleteResourcesBefore } = useRequestResource({endpoint: "auth/borehole_log", query: "fieldboringlog", resourceLabel: "Field log"})
    const { resourceList: boreholeList, getResourceList: getBoreholeList, deleteResource: deleteBorehole, currentListSize: currentBoreholeList, getSearchAllList} = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole List" });
    const { searchResources, searchList} = useRequestResource({ endpoint: "auth/borehole_log", query: "fieldboringlog", resourceLabel: "Search List" }) 
    const [exp, setExp] = useState("")
    const offset = 25;
    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null);
    const [searchExp, setSearchExp] = useState("")

    const [project_id, setProjectId] = useState()
    const [borehole, setBorehole] = useState("")
    const [ showInfiniteScroll, setInfiniteScroll ] = useState(false)
    const [sorting_query, setSortingQuery] = useState(null)

    const [file, setFile] = useState()
    const navigate = useNavigate();
    const [deleteByDate, handleDeleteByDateChange] = React.useState(null);
    const is_manager = localStorage.getItem("is_manager")

    const sptOptions = [
        { value: '6', label: 'Six-Value-SPT' },
        { value: '3', label: 'Three-Value-SPT' },
    ]

    const displayVaneSheerTestReadings = [
        { value: true, label: 'True' },
        { value: false, label: 'False' },
    ]

    const conventionChoices = [
        { value: 'OLD', label: 'Old' },
        { value: 'NEW', label: 'New' },
    ]
    
    useEffect(() => {
        getBoreholeList();
        getResourceList();
    }, [getResourceList]);

    const fetchData = () => {
        getResourceList(offset, sorting_query)
    }

    const fetchBoreholeData = () => {
        getBoreholeList(offset)
    }

    const searchAll = (exp) => {
        setSearchExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }
    
    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
          setInfiniteScroll(false); // Close the InfiniteScroll
        }
      };

    

    function onDeleteByDateSubmit() {
        if (deleteByDate == null) {
            return;
        }

        deleteResourcesBefore(convertDate(deleteByDate), () => {
            window.location.reload()
        })
    }

    function convertDate(value) {
        let temp = new Date(value) 
        let date = new Date(temp.getTime() - temp.getTimezoneOffset() * 60 * 1000);
        let arr = date.toISOString().split(".")
        return arr[0]
    }

    function onRowClick(e) {
        navigate(`/${e.id}/viewfieldlog`)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getResourceList(0, sorting_query)
        }
    }, [sorting_query, getResourceList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return resourceList.results
        }
    }

    const [initialValues, setInitialValues] = useState({
        borehole: "",
        spt_number_of_values: "",
        convention_choice: "NEW",
        display_vane_sheer_test_readings: true,
    })

    const handleSubmit = values => {
        const formattedValues = {
            borehole: values.borehole,
            spt_number_of_values: values.spt_number_of_values,
            convention_choice: values.convention_choice,
            display_vane_sheer_test_readings: values.display_vane_sheer_test_readings
            
        }
        addResource(formattedValues, () => {
            window.location.reload();
        })
    }

    return (
        <div>
           <h3 style={headerStyle}>
                    Field Boring Log
                </h3>
                <Accordion defaultActiveKey="1">
                <Card>
                    <Card.Header>
                        <CustomToggle eventKey="0">Add Field Boring Log</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                        <Formik onSubmit={handleSubmit}
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchema}
                        >
                            {
                                (formik) => {
                                    return (
                                        <form onSubmit={formik.handleSubmit}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="borehole"
                                                        label={borehole ? "" : "Borehole"}
                                                        {...formik.getFieldProps('borehole')}
                                                        value={borehole}
                                                        onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                        ref={boreholeTextField}
                                                        onClick={() => setInfiniteScroll(true)}
                                                        error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                        helperText={(formik.touched.borehole) && formik.errors.borehole}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                { showInfiniteScroll ? <Grid item xs={12}>
                                                <div ref={infiniteScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={boreholeList.results.length}
                                                            next={fetchBoreholeData}
                                                            hasMore={currentBoreholeList.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {boreholeList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        formik.setFieldValue("borehole", option.id)
                                                                        formik.setFieldTouched("borehole", false)
                                                                        formik.setFieldError("borehole", "")
                                                                        setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                        setInfiniteScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title} Borehole: {option.borehole_number}
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="spt_number_of_values"
                                                        label="SPT number of values"
                                                        {...formik.getFieldProps('spt_number_of_values')}
                                                        select
                                                        value={formik.values.spt_number_of_values}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        error={formik.touched.spt_number_of_values && Boolean(formik.errors.spt_number_of_values)}
                                                        helperText={formik.touched.spt_number_of_values && formik.errors.spt_number_of_values}
                                                    >
                                                        {sptOptions.map((option, index) => (
                                                            <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="convention_choice"
                                                        label="Convention Choice"
                                                        {...formik.getFieldProps('convention_choice')}
                                                        select
                                                        value={formik.values.convention_choice}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        error={formik.touched.convention_choice && Boolean(formik.errors.convention_choice)}
                                                        helperText={formik.touched.convention_choice && formik.errors.convention_choice}
                                                    >
                                                        {conventionChoices.map((option, index) => (
                                                            <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>  
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="display_vane_sheer_test_readings"
                                                        label="Display Vane Sheer Test Readings"
                                                        {...formik.getFieldProps('display_vane_sheer_test_readings')}
                                                        select
                                                        value={formik.values.display_vane_sheer_test_readings}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        error={formik.touched.display_vane_sheer_test_readings && Boolean(formik.errors.display_vane_sheer_test_readings)}
                                                        helperText={formik.touched.display_vane_sheer_test_readings && formik.errors.display_vane_sheer_test_readings}
                                                    >
                                                        {displayVaneSheerTestReadings.map((option, index) => (
                                                            <MenuItem key={index} value={option.value}>
                                                            {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>                                        
                                                <Grid item xs={12} >

                                                    <Button
                                                        type="submit"
                                                        size="medium"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Add
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        </form>
                                    )
                                }
                            }
                        </Formik>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                    <Card>
                        <Card.Header style={{ display: 'flex' }}>
                            <CustomToggle eventKey="1">Field Boring Log List</CustomToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    { is_manager === "true" ? 
                                    <div style={{ display: 'flex'}}>
                                        <DatePicker
                                                placeholderText='Delete Before'
                                                selected={ deleteByDate }
                                                onChange={ handleDeleteByDateChange }
                                                name="startDate"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={20}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                autoComplete='off'
                                            >    
                                        </DatePicker>
                                        <Button 
                                                onClick={ onDeleteByDateSubmit }
                                                size="small"
                                                variant="contained"
                                            >
                                                Delete
                                        </Button>
                                    </div>: <div></div>}
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="ags-xls"
                                            label="AGS-XLS"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                                <div style={{ height: "20px" }} />
                                <Table>
                                    <TableHead width="100%">
                                        <TableRow>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("borehole__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Project</div><div>{sorting_query === "borehole__project__project_title" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__project__project_title")}></FaSortUp> : 
                                                    sorting_query === "-borehole__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__project__project_title")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Borehole</div><div>{sorting_query === "borehole__borehole_number" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__borehole_number")}></FaSortUp> : 
                                                    sorting_query === "-borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__borehole_number")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("date_of_creation")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Date of Creation</div><div>{sorting_query === "date_of_creation" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-date_of_creation")}></FaSortUp> : 
                                                    sorting_query === "-date_of_creation" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("date_of_creation")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="25%">
                                                Field Log
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("spt_number_of_values")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>SPT Number of Values</div><div>{sorting_query === "spt_number_of_values" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-spt_number_of_values")}></FaSortUp> : 
                                                    sorting_query === "-spt_number_of_values" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("spt_number_of_values")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("convention_choice")}} style={{ paddingTop: '20px', paddingBottom: '20px'}}>Convention Choice</div><div>{sorting_query === "convention_choice" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-convention_choice")}></FaSortUp> : 
                                                    sorting_query === "-convention_choice" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("convention_choice")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        getList().map((r) => {
                                            return (
                                                <TableRow key={r.id} onClick={() => onRowClick(r)}>
                                                        <TableCell style={{background: "green"}} align="center" width="15%">
                                                            {r.project_title}
                                                        </TableCell>
                                                        <TableCell style={{background: "green"}} align="center" width="15%">
                                                            {r.borehole_number}
                                                        </TableCell>
                                                    <TableCell align="center" width="15%">
                                                        {new Date(r.date_of_creation).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell align="center" width="25%">
                                                    <a href={`${process.env.REACT_APP_API_URL}${r.upload}`}>
                                                        {`${process.env.REACT_APP_API_URL}${r.upload}`}
                                                    </a>
                                                    </TableCell>
                                                    <TableCell align="center" width="15%">
                                                        {r.spt_number_of_values}
                                                    </TableCell>
                                                    <TableCell align="center" width="15%">
                                                        {r.convention_choice}
                                                    </TableCell>
                                                </TableRow>                                                
                                            )
                                        })
                                    }
                                    </TableBody>

                                </Table>
                                {exp !== "" || currentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                    <Button onClick={fetchData}>
                                                LOAD MORE!
                                    </Button>
                                </div>}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
        </div>
    )
}


