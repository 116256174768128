import { useContext } from "react";
import { AuthContext } from "src/contexts/AuthContextProvider";

function GetCommonOptions() {
    const { setIsAuthenticated } = useContext(AuthContext);
    const authToken = localStorage.getItem("authToken");
    
    // If no token return empty object
    if (!authToken) {
        setIsAuthenticated(false);
        window.location = "/login/token";
        return {};
    }

    // Return a key value pair and a key value pair inside the key value
    return {
        method: 'GET',
        headers: {
            'Content-Type': 'Application/JSON',
            'Authorization': `Bearer ${authToken}`,
        }
    }
}

export default GetCommonOptions;