import React, { useEffect, useState, useRef } from 'react'
import { Formik } from "formik";
import { Grid, TextField, MenuItem, Typography, Paper, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogActions } from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from 'yup';
import { Link, useNavigate, useParams } from "react-router-dom";

import useRequestResource from 'src/hooks/useRequestResource';
import ColorPicker from "src/components/ColorPicker";
import { FormatLineSpacing } from '@mui/icons-material';
import DropdownSelect from 'src/components/utils/DropdownSelect';
import DatePicker from 'react-datepicker';
import Accordion from 'react-bootstrap/Accordion';
import InfiniteScroll from 'react-infinite-scroll-component';
import { xor } from 'lodash';
import {Search, SearchIconWrapper, StyledInputBase} from 'src/components/utils/SearchBar'
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import { FaSort, FaSortDown, FaSortUp} from "react-icons/fa";

const validationSchema = yup.object({
    borehole: yup.number().typeError("Select a borehole from the dropdown").required('Borehole is required'),
    sample: yup.number().typeError("Select a sample from the dropdown").required('Sample is required'),
    depth_meters: yup.string().required("Depth meters is required")
    .test('number', 'Depth meters must be a number', (value) => {
        if (value) {
            const pattern = /^-?\d+(?:\.\d+)?$/;
            return pattern.test(value);
        }
        return true;
    })
    .test('decimal-places', 'Depth meters must have at most 2 decimal places', (value) => {
        if (value) {
        const pattern = /^-?\d+(\.\d{1,2})?$/;
        return pattern.test(value);
        }
        return true;
    })
    .test('min-value', 'Depth meters must be greater than or equal to 0', (value) => {
        if (value) {
            return parseFloat(value) >= 0;
        }
        return true;
    })
    .test('max-value', 'Depth meters must be less than or equal to 99999999.99', (value) => {
        if (value) {
            return parseFloat(value) <= 99999999.99;
        }
        return true;
    }),
})

const TestSpecimen = () => {
    const offset = 25;
    const { getResourceList, resourceList, currentListSize, getSearchAllList} = useRequestResource({ endpoint: "auth/borehole_log", query: "boreholes", resourceLabel: "Borehole List"});
    const { getResourceList: getSampleResourceList, resourceList: sampleResourceList, currentListSize: sampleCurrentListSize, filterResources, filterList} = 
        useRequestResource({ endpoint: "auth/borehole_log", query: "samples", resourceLabel: "Sample List"});

    const { addResource: addTestSpecimenList, deleteResource: deleteTestSpecimenList, getResourceList: getTestSpecimenList, resourceList: testSpecimenList, currentListSize: testSpecimenCurrentListSize} = 
        useRequestResource({ endpoint: "auth/laboratory", query: "specimens", resourceLabel: "Test: Specimens" });
    const { resources : testSpecimenResource, updateResource :  updateTestSpecimenResource, searchResources, searchList} = 
        useRequestResource({ endpoint: "auth/laboratory", query: "specimen", resourceLabel: "Test: Specimen" });
    
    const [ borehole, setBorehole ] = useState("")

    const [ showInfiniteAddScroll, setShowInfiniteAddScroll ] = useState(false)
    const [ showInfiniteEditScroll, setShowInfiniteEditScroll ] = useState(false)
    const infiniteScrollRef = useRef(null); 
    const boreholeTextField = useRef(null); 

    const [exp, setExp] = useState("")

    const editScrollRef = useRef(null); 
    const boreholeEditField = useRef(null);


    const [initialValues, setInitialValues] = useState({
        borehole: "",
        sample: "",
        depth_meters: ""
    });

    const [editedValues, setEditedValues] = useState({
        borehole: "",
        sample: "",
        depth_meters: "",
        has_supervisor_signed_borehole: false,
        has_witness_signed_borehole: false
    });

    const [idToEdit, setIdToEdit] = useState(null);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('1');
    const sectionRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState([]);
    const [sorting_query, setSortingQuery] = useState(null)

    const handleConfirmDelete = (id) => {
        setIdToDelete(id);
        setOpen(true);
    }

    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        deleteTestSpecimenList(idToDelete);
    }

    useEffect(() => {
        getResourceList();
    }, [getResourceList])

    useEffect(() => {
        getSampleResourceList();
    }, [getSampleResourceList])

    useEffect(() => {
        getTestSpecimenList();
    }, [getTestSpecimenList]);

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);
        return () => {
          // Clean up the click event listener on component unmount
          document.removeEventListener('click', handleOutsideClick);
        };
      }, []); // Run the effect only once during component mount


      const handleOutsideClick = (event) => {
        //console.log("inside handle outside click")
        // Check if the clicked element is within the InfiniteScroll container or its children
        if (infiniteScrollRef.current && !infiniteScrollRef.current.contains(event.target)) {
            if (boreholeTextField.current && boreholeTextField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setShowInfiniteAddScroll(false)
            // Close the InfiniteScroll
        }

        if (editScrollRef.current && !editScrollRef.current.contains(event.target)) {
            if (boreholeEditField.current && boreholeEditField.current.contains(event.target)) {
                return; // Do nothing if the clicked element is the excluded component
              }
            setShowInfiniteEditScroll(false)
            // Close the InfiniteScroll
        }
      };

    const handleSubmit = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null: values.borehole,
            sample: values.sample === "" ? null: values.sample,
            depth_meters: values.depth_meters === "" ? null: values.depth_meters
        }
        
        addTestSpecimenList(formattedValues, () => {
            window.location.reload(); 
        })
    }

    const handleEditWindow = (e) => {
        //console.log("inside edit window")
        //console.log(e)
        setEditedValues({
            borehole: e.borehole === null ? "": e.borehole,
            sample: e.sample === null ? "": e.sample,
            depth_meters: e.depth_meters === null ? "": e.depth_meters,
            has_supervisor_signed_borehole: e.has_supervisor_signed_borehole,
            has_witness_signed_borehole: e.has_witness_signed_borehole
        })

        filterResources({"borehole":e.borehole})
        setIdToEdit(e.id);
        setIsEditVisible(true);
        setBorehole(`${e.project_title} Borehole: ${e.borehole_number}`);
    }

    const handleEditSubmit = values => {
        const formattedValues = {
            borehole: values.borehole === "" ? null: values.borehole,
            sample: values.sample === "" ? null: values.sample,
            depth_meters: values.depth_meters === "" ? null: values.depth_meters
        }

        if (idToEdit) {
            updateTestSpecimenResource(idToEdit, formattedValues, () => {
                window.location.reload(); 
            })
            return;
        } else {
            //console.log("No ID PROVIDED NO SERVICE!")
        }
    }

    useEffect(() => {
        if (isEditVisible && sectionRef.current) {
            setActiveKey('2')
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            document.addEventListener('click', handleOutsideClick);
          }
        }, [isEditVisible]);

    // TOOD:  update for sample state as well
    const handleChangeAccordian = (eventKey) => {
        if (eventKey != '2') {
            setIdToEdit(null);
            setIsEditVisible(false);
            // In case a borehole drop down is open in edit but not selected, 
            // set borehole back to empty string when user clicks out of the edit window.
            setBorehole("");
        }

        setShowInfiniteAddScroll(false)
        setShowInfiniteEditScroll(false)
        setActiveKey(eventKey)
    }

    const fetchData = () => {
        getTestSpecimenList(offset, sorting_query)
    }

    const fetchBoreholeData = () => {
        if (exp && exp.trim() !== "") {
            getSearchAllList(exp, offset)
        } else {
            getResourceList(offset)
        }
    }

    const searchAll = (exp) => {
        setExp(exp)
        if (exp && exp.trim() !== "") {
            setBorehole(exp)
            getSearchAllList(exp, 0)
        } else {
            setBorehole("")
            getResourceList(0)
        }
    }

    const headerStyle = {
        color: "white",
        backgroundColor: "Green",
        padding: "10px",
        fontFamily: "Arial"
    };
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchData();
        }
    };

    const searchData = () => {
        searchResources(exp, sorting_query)
    }

    useEffect(() => {
        if (exp !== "") {
            searchData()
        } else {
            getTestSpecimenList(0, sorting_query)
        }
    }, [sorting_query, getTestSpecimenList])

    function changeSort(elem) {
        setSortingQuery(elem)
    }

    function toggleSort(elem) {
        if (sorting_query === elem || sorting_query === `-${elem}`) {
            setSortingQuery(null)
        } else {
            setSortingQuery(elem)
        }
    }

    const getList = () => {
        if (exp !== "") {
            return searchList.results
        } else {
            return testSpecimenList.results
        }
    }

    return (
        <div>
        <Dialog open={open} onClose={handleDeleteClose}>
            <DialogTitle>
                Are you sure you want to delete this??
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDelete}>
                    YES
                </Button>
                <Button onClick={handleDeleteClose}>
                    NO!
                </Button>
            </DialogActions>
        </Dialog>

        <Accordion activeKey = {activeKey} onSelect={handleChangeAccordian}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Add Test: Specimen Information</Accordion.Header>
                    <Accordion.Body>
                        <Paper sx={{
                            borderRadius: "2px",
                            bpxShadow: (theme) => theme.shadows[4],
                            padding: (theme) => theme.spacing(2, 4, 3)
                        }}>

                            <Grid item>
                                <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                            </Grid>
                            <Typography variant="h6" mh={4}>
                                Add Test: Specimen Information
                            </Typography>

                            <br />

                            <Formik onSubmit={handleSubmit}
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {
                                    (formik) => {
                                        return (
                                            <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                if (event.key === "Enter") {
                                                    formik.handleSubmit(event)
                                                }
                                            }}>
                                            <Grid container spacing={3}>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="borehole"
                                                        label="Borehole"
                                                        {...formik.getFieldProps('borehole')}
                                                        value={borehole}
                                                        onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                        ref={boreholeTextField}
                                                        onClick={() => setShowInfiniteAddScroll(true)}

                                                        error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                        helperText={(formik.touched.borehole) && formik.errors.borehole}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                { showInfiniteAddScroll ? <Grid item xs={12}>
                                                <div ref={infiniteScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={resourceList.results.length}
                                                            next={fetchBoreholeData}
                                                            hasMore={currentListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {resourceList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        //console.log("inside on click")
                                                                        formik.setFieldValue("borehole", option.id)
                                                                        formik.setFieldTouched("borehole", false)
                                                                        formik.setFieldError("borehole", "")
                                                                        formik.setFieldValue("sample", "")
                                                                        
                                                                        filterResources({"borehole":option.id})
                                                                        setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                        setShowInfiniteAddScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title} Borehole: {option.borehole_number}
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}

                                                {filterList.results.length > 0 ? 
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            autoComplete='off'
                                                            id="sample"
                                                            label="Sample"
                                                            {...formik.getFieldProps('sample')}
                                                            select
                                                            value={formik.values.sample}
                                                            SelectProps={{
                                                                multiple: false,
                                                            }}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            error={formik.touched.sample && Boolean(formik.errors.sample)}
                                                            helperText={formik.touched.sample && formik.errors.sample}
                                                            >
                                                            {filterList.results.map((option, index) => (
                                                                <MenuItem key={index} value={option.id}>
                                                                {`${option.sample_type} ${option.sample_number}`}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>:
                                                    <Grid item xs={12}>
                                                        <Alert variant="outlined" severity="error">
                                                            Select a borehole with valid samples!
                                                        </Alert>
                                                    </Grid>
                                                }                                                    

                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="depth_meters"
                                                        label="Depth Meters"
                                                        {...formik.getFieldProps('depth_meters')}
                                                        error={formik.touched.depth_meters && Boolean(formik.errors.depth_meters)}
                                                        helperText={formik.touched.depth_meters && formik.errors.depth_meters}
                                                    />
                                                </Grid> 
                                                        
                                                <Grid item xs={12} >

                                                    <Button
                                                        type="submit"
                                                        size="medium"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Add Test: Specimen
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        </form>
                                        )
                                    }
                                }
                            </Formik>
                        </Paper>
                    </Accordion.Body>
                </Accordion.Item>
 
            <Accordion.Item eventKey="1">
                <Accordion.Header>Test: Specimen List</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            <TableContainer component={Paper}>
                                <h3 style={headerStyle}>
                                    Test: Specimen
                                </h3>
                                <Grid item xs={12} >
                                    <Search fullWidth>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            fullWidth
                                            id="specimen"
                                            label="Specimen"
                                            value={exp}
                                            onChange={(e) => setExp(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Search>
                                </Grid>
                
                                <div style={{ height: "20px" }} />

                                <Table size="small">
                                <TableHead width="100%">
                                        <TableRow>
                                        <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("borehole__project__project_title")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Project</div><div>{sorting_query === "borehole__project__project_title" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__project__project_title")}></FaSortUp> : 
                                                    sorting_query === "-borehole__project__project_title" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__project__project_title")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("borehole__borehole_number")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Borehole</div><div>{sorting_query === "borehole__borehole_number" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-borehole__borehole_number")}></FaSortUp> : 
                                                    sorting_query === "-borehole__borehole_number" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("borehole__borehole_number")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                Sample Type and Number
                                            </TableCell>
                                            <TableCell align="center" width="15%">
                                                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center"}}>
                                                    <div onClick={() => {toggleSort("depth_meters")}} style={{ paddingTop: '20px', paddingBottom: '20px' }}>Depth Meters (m)</div><div>{sorting_query === "depth_meters" ? 
                                                    <FaSortUp size={20} style={{ marginTop: "5px"}} onClick={() => changeSort("-depth_meters")}></FaSortUp> : 
                                                    sorting_query === "-depth_meters" ? <FaSortDown size={20} style={{ marginTop: "5px"}} onClick={() => {changeSort("depth_meters")}}></FaSortDown> : 
                                                    <div></div>}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="10%">
                                                Action
                                            </TableCell>
                                        </TableRow>

                                    </TableHead>

                                    <TableBody>
                                        {
                                            getList().map((r) => {
                                                return (
                                                    <TableRow
                                                    >
                                                        <TableCell align="center" width="25%">
                                                            {r.project_title}
                                                        </TableCell>
                                                        <TableCell align="center" width="25%">
                                                            {r.borehole_number}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.sample_type} {r.sample_number}
                                                        </TableCell>
                                                        <TableCell align="center" width="20%">
                                                            {r.depth_meters}
                                                        </TableCell>
                                                        <TableCell align="center" width="10%">
                                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
                                                                <IconButton size="small" onClick={() => {
                                                                    handleEditWindow(r);
                                                                }}>
                                                                    <PreviewIcon />
                                                                </IconButton>

                                                                { !(r.has_supervisor_signed_borehole && r.has_witness_signed_borehole) ? <IconButton size="small" onClick={() => {
                                                                    handleConfirmDelete(r.id)
                                                                }}>
                                                                    <DeleteIcon />
                                                                </IconButton> : <div></div>}
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {exp !== "" || testSpecimenCurrentListSize.current < offset ? <div></div> : <div className='centered-container'>
                                <Button onClick={fetchData}>
                                            LOAD MORE!
                                </Button>
                            </div>}
                        </div >
                    </Accordion.Body>
                </Accordion.Item>

                { isEditVisible? 
                <Accordion.Item eventKey="2" ref={sectionRef}>
                <Accordion.Header>Edit Test: Specimen</Accordion.Header>
                <Accordion.Body>
                        
                        <Paper sx={{
                                borderRadius: "2px",
                                bpxShadow: (theme) => theme.shadows[4],
                                padding: (theme) => theme.spacing(2, 4, 3)
                            }}>
    
                                <Grid item>
                                    <Box sx={{ display: "flex", margin: (theme) => theme.spacing(1), marginTop: (theme) => theme.spacing(3) }} />
                                </Grid>
                                <Typography variant="h6" mh={4}>
                                    Edit Test: Specimen
                                </Typography>
    
                                <br />
                                <Formik onSubmit={handleEditSubmit}
                                    initialValues={editedValues}
                                    enableReinitializeit
                                    validationSchema={validationSchema}
                                >
                                    {
                                        (formik) => {
                                            return (
                                                <form onSubmit={formik.handleSubmit} onKeyUp={(event) => {
                                                    if (event.key === "Enter") {
                                                        formik.handleSubmit(event)
                                                    }
                                                }}>
                                                    <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="borehole"
                                                        label="Borehole"
                                                        {...formik.getFieldProps('borehole')}
                                                        value={borehole}
                                                        onChange={(e) => {formik.setFieldValue("borehole", ""); searchAll(e.target.value)}}
                                                        ref={boreholeEditField}
                                                        onClick={() => setShowInfiniteEditScroll(true)}
                                                        disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                        error={(formik.touched.borehole) && Boolean(formik.errors.borehole)}
                                                        helperText={(formik.touched.borehole) && formik.errors.borehole}
                                                    >
                                                    </TextField>
                                                </Grid>
                                                { showInfiniteEditScroll ? <Grid item xs={12}>
                                                <div ref={editScrollRef}>
                                                <InfiniteScroll
                                                            dataLength={resourceList.results.length}
                                                            next={fetchBoreholeData}
                                                            hasMore={currentListSize.current >= offset}
                                                            loader={<h4>Loading...</h4>}
                                                            height={400}
                                                            endMessage={
                                                                <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                                </p>
                                                            
                                                            }>
                                                            
                                                            {resourceList.results.map((option, index) => {
                                                                return (
                                                                <MenuItem key={index} value={option.id} onClick={
                                                                    () => {
                                                                        //console.log("inside on click")
                                                                        formik.setFieldValue("borehole", option.id)
                                                                        formik.setFieldTouched("borehole", false)
                                                                        formik.setFieldError("borehole", "")
                                                                        formik.setFieldValue("sample", "")
                                                                        filterResources({"borehole":option.id})
                                                                        setBorehole(`${option.project_title} Borehole: ${option.borehole_number}`)
                                                                        setShowInfiniteEditScroll(false)
                                                                    }
                                                                }>
                                                                    {option.project_title} Borehole: {option.borehole_number}
                                                                </MenuItem>
                                                                );}
                                                            )}

                                                        </InfiniteScroll>
                                                </div>
                                                </Grid> : <div></div>}


                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        autoComplete='off'
                                                        id="sample"
                                                        label="Sample"
                                                        {...formik.getFieldProps('sample')}
                                                        select
                                                        value={formik.values.sample}
                                                        SelectProps={{
                                                            multiple: false,
                                                        }}
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                        error={formik.touched.sample && Boolean(formik.errors.sample)}
                                                        helperText={formik.touched.sample && formik.errors.sample}
                                                        >
                                                        {filterList.results.map((option, index) => (
                                                            <MenuItem key={index} value={option.id}>
                                                            {`${option.sample_type} ${option.sample_number}`}
                                                            </MenuItem>
                                                        ))}
                                                        </TextField>
                                                        </Grid>

                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            id="depth_meters"
                                                            label="Depth Meters"
                                                            {...formik.getFieldProps('depth_meters')}
                                                            disabled={editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole}
                                                            error={formik.touched.depth_meters && Boolean(formik.errors.depth_meters)}
                                                            helperText={formik.touched.depth_meters && formik.errors.depth_meters}
                                                        />
                                                    </Grid>

                                                    {editedValues.has_supervisor_signed_borehole && editedValues.has_witness_signed_borehole ? <div></div> : <Grid item xs={12} >
    
                                                            <Button
                                                                type="submit"
                                                                size="medium"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Save Test : Specimen
                                                            </Button>

                                                        </Grid> }
                                                    </Grid>
                                                </form>
                                            )
                                        }
                                    }
                                </Formik>
                            </Paper>
                        </Accordion.Body>
                </Accordion.Item>
                :<div></div>
                }
        </Accordion>
        </div>

    )
}
export default TestSpecimen;